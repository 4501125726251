import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LuxonDateModule } from '@angular/material-luxon-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  AADUser,
  ConfirmDialogData,
  ConfirmPopupComponent,
  ContextMenuAction,
  DatePickerFormatDirective,
  Group,
  GroupUser,
  LoaderService,
  TruncatedTextTooltipDirective,
} from 'processdelight-angular-components';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  Subscription,
  catchError,
  combineLatest,
  debounceTime,
  delay,
  first,
  forkJoin,
  map,
  observable,
  of,
  retry,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { ColumnType } from 'src/app/core/domain/enums/column-type.enum';
import { TileAction } from 'src/app/core/domain/enums/tile-action.enum';
import { TilePageType } from 'src/app/core/domain/enums/tile-page-type.enum';
import {
  TileShortcutActions,
  TileShortcutApps,
} from 'src/app/core/domain/enums/tile-shortcut.enums';
import { DataRecord } from 'src/app/core/domain/models/form-template.model';
import { Library } from 'src/app/core/domain/models/library.model';
import { MetadataParameter } from 'src/app/core/domain/models/metadata-parameter.model';
import { Permission } from 'src/app/core/domain/models/permission.model';
import { TilePageSegmentTile } from 'src/app/core/domain/models/tile-page-segment-tile.model';
import { TilePageSegment } from 'src/app/core/domain/models/tile-page-segment.model';
import { TilePageTilePageSegment } from 'src/app/core/domain/models/tile-page-tile-page-segment.model';
import { TilePage } from 'src/app/core/domain/models/tile-page.model';
import { TilePermission } from 'src/app/core/domain/models/tile-permission.model';
import { Tile } from 'src/app/core/domain/models/tile.model';
import { Ishtar365ActionsService } from 'src/app/core/services/ishtar365-actions.service';
import { Ishtar365Service } from 'src/app/core/services/ishtar365.service';
import { RouteBuilderService } from 'src/app/core/services/route-builder.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { AppSubscriptionFacade } from 'src/app/core/store/app-subscription/app-subscription.facade';
import { FormTemplateFacade } from 'src/app/core/store/formTemplate/formTemplate.facade';
import { GroupFacade } from 'src/app/core/store/group/group.facade';
import { LibraryFacade } from 'src/app/core/store/library/library.facade';
import { MetadataParameterFacade } from 'src/app/core/store/metadataParameter/metadataParameter.facade';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { TilePageFacade } from 'src/app/core/store/tilepage/tilepage.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';

const intranetUrlValidator = (control: AbstractControl) =>
  (control.parent?.get('type') as FormControl<TilePageType>)?.value ==
  TilePageType.Intranet
    ? Validators.required(control)
    : null;

@Component({
  selector: 'app-tilepage-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    DragDropModule,
    MatTooltipModule,
    TruncatedTextTooltipDirective,
    DatePickerFormatDirective,
    LuxonDateModule,
  ],
  templateUrl: './tilepage-settings.component.html',
  styleUrls: ['./tilepage-settings.component.scss'],
})
export class TilepageSettingsComponent implements OnInit, OnDestroy {
  tilePages?: FormArray<FormGroup>;
  tilePages$ = this.tilePageFacade.tilePages$;

  tileShortcutApps = [
    { name: TileShortcutApps.DMS },
    { name: TileShortcutApps.List },
    // { name: TileShortcutApps.Projects },
    // { name: TileShortcutApps.Tasks }
  ];

  tileShortcutActions: string[] = [
    TileShortcutActions.FilterDocument.toString(),
    TileShortcutActions.FilterTemplate.toString(),
    TileShortcutActions.OpenTemplate.toString(),
  ];

  appsWithSubscription$ = this.appSubscriptionFacade.appsWithSubscription$.pipe(
    map((apps) => apps.filter((app) => app.name != 'Ishtar.365'))
  );

  filteredApps$ = this.appSubscriptionFacade.appsWithSubscription$.pipe(
    map((apps) =>
      apps.filter((app) =>
        this.tileShortcutApps.find((sa) => sa.name === app.name)
      )
    )
  );

  groupUsers$ = this.organizationFacade.groupUsers$;
  groups$ = this.organizationFacade.groups$;
  users$ = this.organizationFacade.users$;

  libraries$ = this.libraryFacade.libraries$;
  formTemplates$ =
    this.formTemplateFacade.formTemplatesByAppName$('IshtarList');
  metadataParameter$ = this.metadataParameterFacade.metaParams$;

  formTemplates: DataRecord[] = [];
  metadataParams: MetadataParameter[] = [];
  activeTilePage?: FormGroup;
  activeSegment?: FormGroup;
  activeTile?: FormGroup;
  activeTilePermission?: FormGroup;
  activeTileMetadata?: FormGroup;

  selectedAppShortcut: string | undefined;

  TilePageType = TilePageType;
  TileAction = TileAction;
  TileShortcutApps = TileShortcutApps;
  TileShortcutActions = TileShortcutActions;

  tilePagesToDelete = new BehaviorSubject<string[]>([]);
  segmentsToDelete = new BehaviorSubject<string[]>([]);
  tilesToDelete = new BehaviorSubject<string[]>([]);
  tilePermissionsToDelete = new BehaviorSubject<string[]>([]);

  isSubmitEnabled$: Observable<boolean> = of(true);
  ColumnType = ColumnType;

  destroy$ = new Subject<void>();

  saveButtonSub?: Subscription;

  @ViewChildren('titleTilePage') titleTilePageInputs?: QueryList<
    ElementRef<HTMLInputElement>
  >;
  @ViewChildren('titleSegment') titleSegmentInputs?: QueryList<
    ElementRef<HTMLInputElement>
  >;
  @ViewChildren('titleTile') titleTileInputs?: QueryList<
    ElementRef<HTMLInputElement>
  >;
  libraries: Library[] = [];
  groupUserCompareFn = (o1?: GroupUser, o2?: GroupUser) => o1?.xId === o2?.xId;

  constructor(
    private readonly tilePageFacade: TilePageFacade,
    private readonly userFacade: UserFacade,
    private readonly groupFacade: GroupFacade,
    private readonly appSubscriptionFacade: AppSubscriptionFacade,
    private readonly organizationFacade: OrganizationFacade,
    private readonly libraryFacade: LibraryFacade,
    private readonly formTemplateFacade: FormTemplateFacade,
    private readonly metadataParameterFacade: MetadataParameterFacade,
    private readonly loader: LoaderService,
    private readonly translations: TranslationService,
    private readonly ishtar365: Ishtar365Service,
    private readonly routeBuilder: RouteBuilderService,
    private readonly ishtar365Actions: Ishtar365ActionsService,
    private readonly sanitizer: DomSanitizer,
    private readonly matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.libraries$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.libraries = [...data].sort((a, b) => a.title.localeCompare(b.title));
    });
    this.metadataParameter$
      .pipe(takeUntil(this.destroy$))
      .subscribe((metadataParams) => (this.metadataParams = metadataParams));

    this.tilePages$.pipe(takeUntil(this.destroy$)).subscribe((pages) => {
      this.tilePages = new FormArray(
        [...pages]
          .sort((p1, p2) => p1.title.localeCompare(p2.title))
          .map(
            (p) =>
              new FormGroup({
                new: new FormControl(false),
                id: new FormControl(p.id),
                title: new FormControl(p.title, [
                  Validators.required,
                  Validators.maxLength(30),
                ]),
                type: new FormControl(p.type),
                homepage: new FormControl(p.homepage),
                intranetUrl: new FormControl(
                  p.intranetUrl,
                  intranetUrlValidator
                ),
                segments: new FormArray<FormGroup>(
                  Array.isArray(p.segments)
                  ? [...p.segments]
                    .sort(
                      (s1, s2) =>
                        s1.tilePageSegment.position -
                        s2.tilePageSegment.position
                    )
                    .map(
                      (s) =>
                        new FormGroup({
                          new: new FormControl(false),
                          id: new FormControl(s.tilePageSegmentId),
                          linkId: new FormControl(s.id),
                          title: new FormControl(s.tilePageSegment.title, [
                            Validators.required,
                            Validators.maxLength(30),
                          ]),
                          disabled: new FormControl(
                            s.tilePageSegment.disabled ?? false
                          ),
                          hideTitle: new FormControl(
                            s.tilePageSegment.hideTitle ?? false
                          ),
                          tiles: new FormArray<FormGroup>(
                            [...s.tilePageSegment.tiles]
                              .sort(
                                (t1, t2) => t1.tile.position - t2.tile.position
                              )
                              .map((tile) => {
                                const group = new FormGroup({
                                  new: new FormControl(false),
                                  id: new FormControl(tile.tile.id),
                                  linkId: new FormControl(tile.id),
                                  title: new FormControl(tile.tile.title, [
                                    Validators.required,
                                    Validators.maxLength(30),
                                  ]),
                                  type: new FormControl(
                                    tile.tile.tileActionType
                                  ),
                                  action: new FormControl(
                                    tile.tile.tileAction,
                                    Validators.required
                                  ),
                                  app: new FormControl(
                                    this.getTileActionApplication(tile.tile) !=
                                    null
                                      ? this.getTileActionApplication(tile.tile)
                                      : ''
                                  ),
                                  library: new FormControl(
                                    this.getTileActionConfiguration(
                                      tile.tile
                                    ) != null
                                      ? this.getTileActionConfiguration(
                                          tile.tile
                                        )?.library
                                      : ''
                                  ),
                                  metadataParams:
                                    this.createMetadataParamsArray(
                                      this.getTileActionConfiguration(
                                        tile.tile
                                      ) != null
                                        ? this.getTileActionConfiguration(
                                            tile.tile
                                          )?.metadataParams
                                        : []
                                    ),
                                  formTemplate: new FormControl(
                                    this.getTileActionConfiguration(
                                      tile.tile
                                    ) != null
                                      ? this.getTileActionConfiguration(
                                          tile.tile
                                        )?.formTemplate
                                      : ''
                                  ),
                                  filter: new FormControl(
                                    this.getTileActionConfiguration(
                                      tile.tile
                                    ) != null
                                      ? this.getTileActionConfiguration(
                                          tile.tile
                                        )?.filter
                                      : ''
                                  ),

                                  iconUrl: new FormControl(
                                    tile.tile.iconUrl,
                                    Validators.required
                                  ),
                                  iconBlob: new FormControl<Blob | undefined>(
                                    undefined
                                  ),
                                  oldIconUrl: new FormControl(
                                    tile.tile.iconUrl
                                  ),
                                  hideTileInView: new FormControl(
                                    tile.tile.hideTileInView
                                  ),
                                  disabled: new FormControl(tile.tile.disabled),
                                  permissions: new FormArray(
                                    tile.tile.permissions?.map(
                                      (p) =>
                                        new FormGroup({
                                          new: new FormControl(false),
                                          id: new FormControl(p.permissionId),
                                          linkId: new FormControl(p.id),
                                          permissionType: new FormControl(
                                            p.permission.permissionType,
                                            Validators.required
                                          ),
                                          groupUser: new FormControl(
                                            p.permission.groupUser,
                                            Validators.required
                                          ),
                                        })
                                    ) ?? []
                                  ),
                                });

                                return group;
                              })
                          ),
                        })
                    )
                    :[]
                ),
              })
          )
      );
      this.isSubmitEnabled$ = combineLatest([
        this.tilePages!.valueChanges.pipe(
          startWith(this.tilePages?.value ?? [])
        ),
        this.tilePagesToDelete,
        this.segmentsToDelete,
        this.tilesToDelete,
        this.tilePermissionsToDelete,
      ]).pipe(
        map(
          ([
            pages,
            pagesToDelete,
            segmentsToDelete,
            tilesToDelete,
            tilePermissionsToDelete,
          ]) => {
            return (
              this.tilePages!.controls.every((c) => this.isTilePageValid(c)) &&
              (this.tilePages!.controls.some((c) => c.dirty) ||
                pagesToDelete.length > 0 ||
                segmentsToDelete.length > 0 ||
                tilesToDelete.length > 0 ||
                tilePermissionsToDelete.length > 0) &&
              !pagesToDelete.includes(
                this.getId(
                  this.tilePages!.controls.find(
                    (t) => this.getHomepageControl(t).value
                  )
                )
              )
            );
          }
        )
      );
      this.selectTilePage(
        this.activeTilePage
          ? this.tilePages.controls.find(
              (t) => this.getId(this.activeTilePage) == this.getId(t)
            )
          : undefined
      );

      if (this.saveButtonSub) this.saveButtonSub.unsubscribe();
      this.saveButtonSub = combineLatest([
        this.getTranslation$('save'),
        this.tilePages.valueChanges.pipe(startWith(this.tilePages.value)),
      ])
        .pipe(
          takeUntil(this.destroy$),
          tap(
            ([label]) =>
              (this.ishtar365Actions.buttonActions = [
                new ContextMenuAction<unknown>({
                  label,
                  action: () => this.submit(),
                  disabled: this.isSubmitEnabled$.pipe(map((b) => !b)),
                  icon: 'save',
                  iconOutline: true,
                }),
              ])
          ),
          debounceTime(500)
        )
        .subscribe(
          ([label]) =>
            (this.ishtar365Actions.buttonActions = [
              new ContextMenuAction<unknown>({
                label,
                action: () => this.submit(),
                disabled: this.isSubmitEnabled$.pipe(map((b) => !b)),
                icon: 'save',
                iconOutline: true,
              }),
            ])
        );
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.tilePagesToDelete.complete();
    this.segmentsToDelete.complete();
    this.tilesToDelete.complete();
    this.tilePermissionsToDelete.complete();
    this.ishtar365Actions.buttonActions = [];
  }
  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  updateIntranetUrlValidity(group: FormGroup) {
    group.controls.intranetUrl?.updateValueAndValidity();
  }

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  getAppIcons$(group: FormGroup) {
    const type = this.getTypeControl(group).value as TileAction;
    if (type != TileAction.IshtarApp) return of([]);
    const appId = this.getTileActionControl(group).value;
    return this.appSubscriptionFacade.apps$.pipe(
      first(),
      map((apps) => apps.find((a) => a.productId == +appId)),
      map((app) =>
        !app
          ? []
          : [
              'Default',
              'LichtBlauw_Blauw',
              'Blauw_Wit',
              'DonkerBlauw_Wit',
              'Geel_Blauw',
              'Geel_Wit',
              'Groen_Blauw',
              'Groen_Wit',
              'LichtBlauw_Wit',
              'Rood_Blauw',
              'Rood_Wit',
            ].map(
              (color) =>
                `${
                  environment.cdnUrl +
                  environment.cdnIconsPath +
                  app.name.split('.').pop()
                }_${color}.svg`
            )
      )
    );
  }

  appIconError(event: Event) {
    const el = event.target as HTMLImageElement;
    el.src = environment.cdnImageNotFound;
  }

  //#region Shortcut functions
  getTileActionConfiguration(tile: Tile): any {
    if (tile.tileActionType == TileAction.Shortcut) {
      const tileActionResult = this.parseTileAction(tile.tileAction);
      return typeof tileActionResult === 'object' && tileActionResult !== null
        ? tileActionResult.configuration
        : null;
    }
    return null;
  }
  getTileActionApplication(tile: Tile): any {
    if (tile.tileActionType == TileAction.Shortcut) {
      const tileActionResult = this.parseTileAction(tile.tileAction);
      return typeof tileActionResult === 'object' &&
        tileActionResult !== null &&
        tileActionResult?.application
        ? tileActionResult.application
        : null;
    }
    return null;
  }
  parseTileAction(action: any): any {
    try {
      if (
        action &&
        typeof action === 'string' &&
        action.trim().startsWith('{')
      ) {
        return JSON.parse(action);
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  getTileShortcutActions() {
    if (this.selectedAppShortcut === TileShortcutApps.DMS.toString())
      return this.tileShortcutActions.filter(
        (x) => x == TileShortcutActions.FilterDocument.toString()
      );
    else
      return this.tileShortcutActions.filter(
        (x) => x != TileShortcutActions.FilterDocument.toString()
      );
  }
  loadFormTemplate(group: FormGroup) {
    this.filteredApps$
      .pipe(
        takeUntil(this.destroy$),
        map((apps) =>
          apps.find(
            (app) =>
              app.productId.toString() ===
              this.getAppControl(this.activeTile).value
          )
        ),
        first()
      )
      .subscribe((app) => {
        this.selectedAppShortcut = app?.name;
        if (app) {
          if (app.name !== TileShortcutApps.DMS.toString()) {
            const appName = app.name?.split('.')[1];
            this.formTemplates$
              .pipe(
                takeUntil(this.destroy$),
                map((templates) =>
                  templates
                    .filter((x) => x.form.application?.name.includes(appName))
                    .sort((a, b) => a.form.name.localeCompare(b.form.name))
                ),
                first()
              )
              .subscribe((templates) => (this.formTemplates = templates));
          }
        }
      });
  }
  getFilteredParams() {
    const selectedLibrary = this.libraries.find(
      (l) => l.id === this.getLibraryControl(this.activeTile).value
    );
    const filteredParams = selectedLibrary
      ? this.metadataParams.filter(
          (p) =>
            p.createdByParam ||
            p.createdOnParam ||
            p.modifiedByParam ||
            p.modifiedOnParam ||
            p.fileNameParam ||
            selectedLibrary?.configuredParams.some((c) => c.paramId == p.id)
        )
      : this.metadataParams;
    return [...filteredParams].sort((a, b) => a.title.localeCompare(b.title));
  }
  focusElement(element: HTMLInputElement) {
    setTimeout(() => {
      element.focus();
    }, 0);
  }
  getFilteredGroupusers$(
    param: MetadataParameter | undefined
  ): Observable<GroupUser[]> {
    if (!param) return of([]);

    if (param.showGroups && param.showUsers)
      return this.groupUsers$.pipe(
        map((g) =>
          g
            ? [...g].sort(
                (a, b) =>
                  (a.group ? 0 : 1) - (b.group ? 0 : 1) ||
                  (a.displayName ?? '').localeCompare(b.displayName ?? '')
              )
            : []
        )
      );
    else if (param.showGroups)
      return this.groups$.pipe(
        map((g) =>
          g
            ? [...g].sort((a, b) =>
                (a.displayName ?? '').localeCompare(b.displayName ?? '')
              )
            : []
        )
      );
    else if (param.showUsers)
      return this.users$.pipe(
        map((g) =>
          g
            ? [...g].sort((a, b) =>
                (a.displayName ?? '').localeCompare(b.displayName ?? '')
              )
            : []
        )
      );
    return of([]);
  }
  getParamById = (id: string) => this.metadataParams.find((x) => x.id === id);

  deleteMetadata(i: number) {
    this.getMetadataParamsGroup(this.activeTile).removeAt(i);
    this.changeActiveTileActionAsJson(this.activeTile);
    (this.activeTilePage as FormGroup).markAsDirty();
  }

  getMetadataType(index: number) {
    return this.metadataParams.find(
      (m) =>
        m.id ===
        this.getMetadataParamsGroup(this.activeTile).at(index).controls
          .metadataParam.value
    )?.type;
  }
  getMetadataTitle(index: number) {
    return this.metadataParams.find(
      (m) =>
        m.id ===
        this.getMetadataParamsGroup(this.activeTile).at(index).controls
          .metadataParam.value
    )?.title;
  }
  getMetadataMulti(index: number) {
    return this.metadataParams.find(
      (m) =>
        m.id ===
        this.getMetadataParamsGroup(this.activeTile).at(index).controls
          .metadataParam.value
    )?.multi;
  }
  getMetadataMaxLength(index: number) {
    return this.metadataParams.find(
      (m) =>
        m.id ===
        this.getMetadataParamsGroup(this.activeTile).at(index).controls
          .metadataParam.value
    )?.maxLength;
  }
  getMetadataFormat(index: number) {
    return this.metadataParams.find(
      (m) =>
        m.id ===
        this.getMetadataParamsGroup(this.activeTile).at(index).controls
          .metadataParam.value
    )?.format;
  }
  getMetadataChoices(index: number) {
    return this.metadataParams.find(
      (m) =>
        m.id ===
        this.getMetadataParamsGroup(this.activeTile).at(index).controls
          .metadataParam.value
    )?.choices;
  }
  getMetadataConsolidatedChoices(index: number) {
    return this.metadataParams.find(
      (m) =>
        m.id ===
        this.getMetadataParamsGroup(this.activeTile).at(index).controls
          .metadataParam.value
    )?.consolidatedChoices;
  }

  addShortcutMetadataParams() {
    this.getMetadataParamsGroup(this.activeTile).push(
      this.createMetadataParamsFormGroup()
    );
  }

  setMetadataType(group: FormGroup, type: ColumnType | undefined) {
    if (type) group?.controls?.metadataType.setValue(type);
  }

  changeActiveTileActionAsJson(activeTile: FormGroup<any> | undefined) {
    if (activeTile?.controls?.action) {
      activeTile?.controls.action.setValue(
        JSON.stringify({
          application: activeTile?.controls.app.value,
          configuration: {
            library: activeTile?.controls.library.value,
            formTemplate: activeTile?.controls.formTemplate.value,
            filter: activeTile?.controls.filter.value,
            metadataParams: this.convertMetadataParamsToJson(
              activeTile?.controls.metadataParams as FormArray
            ),
          },
        })
      );
    }
  }

  convertMetadataParamsToJson(metadataParams: FormArray<FormGroup>) {
    if (this.selectedAppShortcut !== 'Ishtar.DMS') return [];
    const metadataParamsArray = metadataParams?.controls.map(
      (group: FormGroup) => {
        return {
          metadataParam: group.get('metadataParam')?.value,
          metadataType: group.get('metadataType')?.value,
          metadataChoice: group.get('metadataChoice')?.value,
          metadataValue: group.get('metadataValue')?.value,
          metadataDate: group.get('metadataDate')?.value,
          metadataGroupUser: group.get('metadataGroupUser')?.value,
        };
      }
    );
    return metadataParamsArray;
  }
  createMetadataParamsFormGroup = (param: any = {}): FormGroup => {
    return new FormGroup({
      metadataParam: new FormControl(param?.metadataParam || ''),
      metadataType: new FormControl(param?.metadataType || ''),
      metadataChoice: new FormControl(param?.metadataChoice || ''),
      metadataGroupUser: new FormControl(
        this.getGroupUser(param?.metadataGroupUser) || ''
      ),
      metadataValue: new FormControl(param?.metadataValue || ''),
      metadataDate: new FormGroup({
        start: new FormControl(param.metadataDate?.start || ''),
        end: new FormControl(param.metadataDate?.end || ''),
      }),
    });
  };
  getGroupUser(data: any): GroupUser | GroupUser[] | undefined {
    if (data) {
      if (Array.isArray(data)) {
        return data.map((guser) => {
          return new GroupUser({
            ...guser,
            user: guser?.user ? new AADUser(guser?.user) : undefined,
            group: guser?.group ? new Group(guser?.group) : undefined,
          });
        });
      }
      return new GroupUser({
        ...data,
        user: data?.user ? new AADUser(data?.user) : undefined,
        group: data?.group ? new Group(data?.group) : undefined,
      });
    }
    return undefined;
  }
  createMetadataParamsArray = (
    metadataParams: Array<any> = []
  ): FormArray<FormGroup> => {
    const array = new FormArray<FormGroup>(
      metadataParams.map((param) => this.createMetadataParamsFormGroup(param))
    );
    return array;
  };
  //#endregion

  // isSubmitEnabled() {
  //   return (
  //     this.tilePages?.controls.every((c) => this.isTilePageValid(c)) &&
  //     (this.tilePages.controls.some((c) => c.dirty) ||
  //       this.tilePagesToDelete.length ||
  //       this.segmentsToDelete.length ||
  //       this.tilesToDelete.length ||
  //       this.tilePermissionsToDelete.length) &&
  //     !this.tilePagesToDelete.includes(
  //       this.getId(
  //         this.tilePages.controls.find((t) => this.getHomepageControl(t).value)
  //       )
  //     )
  //   );
  // }

  isTilePermissionValid(group: FormGroup) {
    return !this.isTilePermissionDeleted(group) ? group.valid : true;
  }
  isTileValid(group: FormGroup) {
    return !this.isTileDeleted(group)
      ? this.getTitleControl(group).valid &&
          this.getTypeControl(group).valid &&
          this.getTileActionControl(group).valid &&
          this.getIconBlobControl(group).valid &&
          this.getIconUrlControl(group).valid &&
          this.getPermissions(group).controls.every((p) =>
            this.isTilePermissionValid(p)
          )
      : true;
  }
  isSegmentValid(group: FormGroup) {
    return !this.isSegmentDeleted(group)
      ? this.getTitleControl(group).valid &&
          this.getTiles(group).controls.every((t) => this.isTileValid(t))
      : true;
  }
  isTilePageValid(group: FormGroup) {
    return !this.isTilePageDeleted(group)
      ? this.getTitleControl(group).valid &&
          this.getIntranetUrlControl(group).valid &&
          this.getSegments(group).controls.every((t) => this.isSegmentValid(t))
      : true;
  }

  isTilePageDeleted(group: FormGroup) {
    return this.tilePagesToDelete.value.includes(this.getId(group));
  }
  toggleDeleteTilePage(group: FormGroup) {
    const pagesToDeleteArray = this.tilePagesToDelete.value;
    if (this.isTilePageDeleted(group))
      this.tilePagesToDelete.next(
        pagesToDeleteArray.filter((id) => id != this.getId(group))
      );
    else {
      pagesToDeleteArray.push(this.getId(group));
      this.tilePagesToDelete.next(pagesToDeleteArray);
    }
  }
  isSegmentDeleted(group: FormGroup) {
    return this.segmentsToDelete.value.includes(this.getId(group));
  }
  toggleDeleteSegment(group: FormGroup) {
    group.parent?.parent?.markAsDirty();
    const segmentsToDeleteArray = this.segmentsToDelete.value;
    if (this.isSegmentDeleted(group))
      this.segmentsToDelete.next(
        segmentsToDeleteArray.filter((id) => id != this.getId(group))
      );
    else {
      segmentsToDeleteArray.push(this.getId(group));
      this.segmentsToDelete.next(segmentsToDeleteArray);
    }
  }
  isTileDeleted(group: FormGroup) {
    return this.tilesToDelete.value.includes(this.getId(group));
  }
  toggleDeleteTile(group: FormGroup) {
    group.parent?.parent?.markAsDirty();
    const tilesToDeleteArray = this.tilesToDelete.value;
    if (this.isTileDeleted(group))
      this.tilesToDelete.next(
        tilesToDeleteArray.filter((id) => id != this.getId(group))
      );
    else {
      tilesToDeleteArray.push(this.getId(group));
      this.tilesToDelete.next(tilesToDeleteArray);
    }
  }
  isTilePermissionDeleted(group: FormGroup) {
    return this.tilePermissionsToDelete.value.includes(this.getId(group));
  }
  toggleDeleteTilePermission(group: FormGroup) {
    group.parent?.parent?.markAsDirty();
    const tilePermissionsToDeleteArray = this.tilePermissionsToDelete.value;
    if (this.isTilePermissionDeleted(group))
      this.tilePermissionsToDelete.next(
        tilePermissionsToDeleteArray.filter((id) => id != this.getId(group))
      );
    else {
      tilePermissionsToDeleteArray.push(this.getId(group));
      this.tilePermissionsToDelete.next(tilePermissionsToDeleteArray);
    }
  }

  getId(group?: FormGroup) {
    return group?.controls.id.value as string;
  }
  getLinkId(group?: FormGroup) {
    return group?.controls.linkId.value as string;
  }
  getTitle(group?: FormGroup) {
    return group?.controls.title.value as string;
  }
  getTitleControl(group?: FormGroup) {
    return group?.controls.title as FormControl<string>;
  }
  getTypeControl(group?: FormGroup) {
    return group?.controls.type as FormControl<number>;
  }
  getIntranetUrlControl(group?: FormGroup) {
    return group?.controls.intranetUrl as FormControl<string>;
  }
  getDisabledControl(group?: FormGroup) {
    return group?.controls.disabled as FormControl<boolean>;
  }
  getHideTitleControl(group?: FormGroup) {
    return group?.controls.hideTitle as FormControl<boolean>;
  }
  getTileActionControl(group?: FormGroup) {
    return group?.controls.action as FormControl<string>;
  }
  getAppControl(group?: FormGroup) {
    return group?.controls.app as FormControl<string>;
  }
  getFilterControl(group?: FormGroup) {
    return group?.controls.filter as FormControl<string>;
  }
  getIconUrlControl(group?: FormGroup) {
    return group?.controls.iconUrl as FormControl<string>;
  }
  getOldIconUrlControl(group?: FormGroup) {
    return group?.controls.oldIconUrl as FormControl<string>;
  }
  getIconBlobControl(group?: FormGroup) {
    return group?.controls.iconBlob as FormControl<Blob | undefined>;
  }
  getNewControl(group?: FormGroup) {
    return group?.controls.new as FormControl<boolean>;
  }
  getHomepageControl(group?: FormGroup) {
    return group?.controls.homepage as FormControl<boolean>;
  }
  getLibraryControl(group?: FormGroup) {
    return group?.controls.library as FormControl<string>;
  }
  getFormTemplateControl(group?: FormGroup) {
    return group?.controls.formTemplate as FormControl<string>;
  }
  getPermissionTypeControl(group?: FormGroup) {
    return group?.controls.permissionType as FormControl<
      'FullControl' | 'View'
    >;
  }
  getGroupUserControl(group?: FormGroup) {
    return group?.controls.groupUser as FormControl<GroupUser | undefined>;
  }
  getSegments(group?: FormGroup) {
    return group?.controls.segments as FormArray<FormGroup>;
  }
  getTiles(group?: FormGroup) {
    return group?.controls.tiles as FormArray<FormGroup>;
  }
  getPermissions(group?: FormGroup) {
    return group?.controls.permissions as FormArray<FormGroup>;
  }
  getMetadataParamsGroup(group?: FormGroup): FormArray<FormGroup> {
    return group?.controls.metadataParams as FormArray<FormGroup>;
  }
  getFormControlFromGroup(group: FormGroup, key: string): FormControl {
    return group?.get(key) as FormControl;
  }
  getFormGroupFromGroup(group: FormGroup, key: string): FormGroup {
    return group?.get(key) as FormGroup;
  }
  getFormControlFromGroupWithIndex(index: number, key: string): FormControl {
    const array = this.activeTile?.controls
      .metadataParams as FormArray<FormGroup>;
    const group = array.at(index) as FormGroup;
    return group?.get(key) as FormControl;
  }
  getMetadataParamValue(group?: FormGroup) {
    return group?.controls.metadataParamValue as FormArray<FormControl>;
  }
  updateHomepage(group: FormGroup) {
    const prev = this.getHomepageControl(
      this.tilePages?.controls.find((t) => this.getHomepageControl(t).value)
    );
    prev?.patchValue(false);
    prev?.markAsDirty();
    this.getHomepageControl(group).patchValue(true);
    group.markAsDirty();
  }

  possibleTilePageTiles: FormGroup[] = [];

  selectTilePage(group?: FormGroup) {
    this.activeTilePage = group;
    this.selectSegment(undefined);
    if (group) this.possibleTilePageTiles = this.getFilteredTilePages(group);
    this.selectedAppShortcut = undefined;
  }
  selectSegment(group?: FormGroup) {
    this.activeSegment = group;
    this.selectTile(undefined);
  }
  selectTile(group?: FormGroup) {
    this.activeTile = group;
    this.selectTilePermission(undefined);
    if (group && this.getIconUrlControl(group).value) {
      this.activeIconUrl = this.transform(this.getIconUrlControl(group).value);
    } else {
      this.activeIconUrl = undefined;
    }
    if (
      group?.controls.type.value &&
      group?.controls.type.value == TileAction.Shortcut
    ) {
      this.setSelectedAppShorcutName();
      this.loadFormTemplate(group);
    }
  }
  applicationSelectionChanged() {
    this.setSelectedAppShorcutName();
    this.resetControls(
      this.activeTile!,
      'metadataParams',
      'library',
      'formTemplate',
      'filter',
      'action'
    );
  }
  filterSelectionChanged() {
    if (
      this.getFilterControl(this.activeTile).value ==
      TileShortcutActions.FilterDocument
    ) {
      this.resetControls(this.activeTile!, 'library', 'formTemplate', 'action');
      (this.activeTile?.get('metadataParams') as FormArray<FormGroup>).clear();
      this.changeActiveTileActionAsJson(this.activeTile);
    } else {
      this.resetControls(
        this.activeTile!,
        'metadataParams',
        'library',
        'formTemplate',
        'action'
      );
    }
  }
  librarySelectionChanged() {
    (this.activeTile?.get('metadataParams') as FormArray<FormGroup>).clear();
  }
  resetControls(group: FormGroup, ...controlKeys: string[]) {
    controlKeys.forEach((controlKey) => {
      group.get(controlKey)?.reset();
    });
  }

  setSelectedAppShorcutName() {
    this.filteredApps$
      .pipe(
        map((apps) =>
          apps.find(
            (app) =>
              app.productId.toString() ===
              this.getAppControl(this.activeTile).value
          )
        ),
        first()
      )
      .subscribe((app) => {
        this.selectedAppShortcut = app?.name;
      });
  }
  selectTilePermission(group?: FormGroup) {
    this.activeTilePermission = group;
  }

  selectTileMetadata(group?: FormGroup) {
    this.activeTileMetadata = group;
  }

  addTilePage() {
    const newPage = new FormGroup({
      new: new FormControl(true),
      id: new FormControl(uuid.v4()),
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
      ]),
      type: new FormControl(TilePageType.Default),
      homepage: new FormControl(false),
      intranetUrl: new FormControl('', intranetUrlValidator),
      segments: new FormArray([]),
    });
    this.tilePages?.push(newPage);
    this.selectTilePage(newPage);
    setTimeout(() => {
      this.titleTilePageInputs?.last?.nativeElement?.focus();
    }, 0);
  }

  addSegment() {
    const newSegment = new FormGroup({
      new: new FormControl(true),
      id: new FormControl(uuid.v4()),
      linkId: new FormControl(uuid.v4()),
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
      ]),
      disabled: new FormControl(false),
      hideTitle: new FormControl(false),
      tiles: new FormArray([]),
    });
    this.getSegments(this.activeTilePage)?.push(newSegment);
    this.selectSegment(newSegment);
    setTimeout(() => {
      this.titleSegmentInputs?.last?.nativeElement?.focus();
    }, 0);
  }
  addTile() {
    const newTile = new FormGroup({
      new: new FormControl(true),
      id: new FormControl(uuid.v4()),
      linkId: new FormControl(uuid.v4()),
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
      ]),
      type: new FormControl(TileAction.TilePage),
      action: new FormControl('', Validators.required),
      app: new FormControl(''),
      library: new FormControl(''),
      filter: new FormControl(''),
      metadataParams: this.createMetadataParamsArray(),
      formTemplate: new FormControl(''),
      iconUrl: new FormControl('', Validators.required),
      iconBlob: new FormControl<Blob | undefined>(undefined),
      oldIconUrl: new FormControl(undefined),
      hideTileInView: new FormControl(false),
      disabled: new FormControl(false),
      permissions: new FormArray([]),
    });
    this.getTiles(this.activeSegment)?.push(newTile);
    this.selectTile(newTile);
    this.activeIconUrl = undefined;
    setTimeout(() => {
      this.titleTileInputs?.last?.nativeElement?.focus();
    }, 0);
  }

  addTilePermission() {
    const newTilePermission = new FormGroup({
      new: new FormControl(true),
      id: new FormControl(uuid.v4()),
      linkId: new FormControl(uuid.v4()),
      permissionType: new FormControl('View', Validators.required),
      groupUser: new FormControl<GroupUser | undefined>(
        undefined,
        Validators.required
      ),
    });
    this.getPermissions(this.activeTile)?.push(newTilePermission);
    this.selectTilePermission(newTilePermission);
  }
  getFilteredTilePages(group: FormGroup) {
    const tilePagesToDelete = this.tilePagesToDelete.value;
    const blackListedPageIds: string[] = [
      this.getId(group),
      ...tilePagesToDelete,
    ];
    if (this.tilePages) {
      let newBlackListed: string[] = [];
      do {
        newBlackListed = [];
        for (const page of this.tilePages.controls.filter(
          (t) => !blackListedPageIds.includes(this.getId(t))
        ))
          for (const segment of this.getSegments(page).controls)
            for (const tile of this.getTiles(segment).controls.filter(
              (t) => this.getTypeControl(t).value == TileAction.TilePage
            ))
              if (
                blackListedPageIds.includes(
                  this.getTileActionControl(tile).value
                )
              )
                newBlackListed.push(this.getId(page));
        blackListedPageIds.push(...newBlackListed);
      } while (newBlackListed.length);
    }
    return (
      this.tilePages?.controls.filter(
        (t) => !blackListedPageIds.includes(this.getId(t))
      ) ?? []
    );
  }

  private blobToBase64(blob: Blob) {
    const subject = new Subject<string>();
    const fr = new FileReader();
    fr.onloadend = () => {
      subject.next(fr.result as string);
      subject.complete();
    };
    fr.readAsDataURL(blob);
    return subject.asObservable();
  }

  checkTilePermissions(type: TileAction, group: FormGroup) {
    const typeControl = this.getTypeControl(group);
    const prevType = typeControl.value;
    typeControl.patchValue(type);
    if (
      type != TileAction.TilePage &&
      prevType == TileAction.TilePage &&
      this.getPermissions(group).controls.some(
        (c) =>
          !this.isTilePermissionDeleted(c) &&
          this.getPermissionTypeControl(c).value == 'FullControl'
      )
    ) {
      const config = new ConfirmDialogData({
        title: this.translations.translations$.value.caution,
        description:
          this.translations.translations$.value
            .tilePermissionFullControlResetWarning,
        descriptionInHtml: true,
        cancelBtnText: this.translations.translations$.value.cancel,
        confirmBtnText: this.translations.translations$.value.confirm,
        onConfirm: () => {
          typeControl.markAsDirty();
          typeControl.markAsTouched();
          this.getPermissions(group)
            .controls.filter(
              (c) => this.getPermissionTypeControl(c).value == 'FullControl'
            )
            .forEach((c) => {
              const control = this.getPermissionTypeControl(c);
              control.patchValue('View');
              control.markAsDirty();
            });
        },
        onCancel: () =>
          this.getTypeControl(group).patchValue(TileAction.TilePage),
      });
      this.matDialog.open(ConfirmPopupComponent, {
        data: config,
        autoFocus: true,
        closeOnNavigation: true,
      });
    } else {
      if (type != TileAction.TilePage)
        this.getPermissions(group)
          .controls.filter(
            (c) => this.getPermissionTypeControl(c).value == 'FullControl'
          )
          .forEach((c) => {
            const control = this.getPermissionTypeControl(c);
            control.patchValue('View');
            control.markAsDirty();
          });
      typeControl.markAsDirty();
      typeControl.markAsTouched();
    }
  }

  reorder(event: CdkDragDrop<any>, array?: FormArray) {
    if (array) {
      moveItemInArray(array.controls, event.previousIndex, event.currentIndex);
      array.markAsDirty();
      this.tilePages?.updateValueAndValidity();
    }
  }

  submit() {
    this.isSubmitEnabled$.pipe(first()).subscribe((b) => {
      if (b) {
        const groupToPermission = (group: FormGroup) =>
          new TilePermission({
            id: this.getLinkId(group),
            permissionId: this.getId(group),
            permission: new Permission({
              id: this.getId(group),
              permissionType: this.getPermissionTypeControl(group).value,
              groupUser: this.getGroupUserControl(group).value,
            }),
          });
        const groupToTile = (group: FormGroup) =>
          this.getTypeControl(group).value != TileAction.IshtarApp &&
          this.getIconBlobControl(group).value
            ? this.blobToBase64(this.getIconBlobControl(group).value!).pipe(
                switchMap((b64) => {
                  const oldIconUrl = this.getOldIconUrlControl(group)?.value;
                  const upload = this.ishtar365.uploadTileIcon(
                    this.getId(group),
                    (this.getIconBlobControl(group).value as File).name
                      .split('.')
                      .pop() ?? 'png',
                    b64
                  );
                  return oldIconUrl
                    ? this.ishtar365
                        .deleteTileIcon(this.getOldIconUrlControl(group).value)
                        .pipe(
                          switchMap(() => upload),
                          catchError(() => upload)
                        )
                    : upload;
                }),
                map(
                  (iconUrl) =>
                    new TilePageSegmentTile({
                      id: this.getLinkId(group),
                      tileId: this.getId(group),
                      tile: new Tile({
                        id: this.getId(group),
                        title: this.getTitle(group),
                        disabled: this.getDisabledControl(group).value,
                        tileAction: this.getTileActionControl(group).value,
                        tileActionType: this.getTypeControl(group).value,
                        hideTileInView: false,
                        position: (
                          (group.parent?.parent as FormGroup).controls
                            .tiles as FormArray
                        ).controls
                          .filter((c) => !this.isTileDeleted(c as FormGroup))
                          .findIndex(
                            (s) =>
                              this.getId(s as FormGroup) == this.getId(group)
                          ),
                        iconUrl,
                        permissions: this.getPermissions(group)
                          .controls.filter(
                            (s) => !this.isTilePermissionDeleted(s as FormGroup)
                          )
                          .map(
                            (p) =>
                              new TilePermission({
                                ...groupToPermission(p as FormGroup),
                                tileId: this.getId(group),
                              })
                          ),
                      }),
                    })
                )
              )
            : of(
                new TilePageSegmentTile({
                  id: this.getLinkId(group),
                  tileId: this.getId(group),
                  tile: new Tile({
                    id: this.getId(group),
                    title: this.getTitle(group),
                    disabled: this.getDisabledControl(group).value,
                    tileAction: this.getTileActionControl(group).value,
                    tileActionType: this.getTypeControl(group).value,
                    hideTileInView: false,
                    position: (
                      (group.parent?.parent as FormGroup).controls
                        .tiles as FormArray
                    ).controls
                      .filter((c) => !this.isTileDeleted(c as FormGroup))
                      .findIndex(
                        (s) => this.getId(s as FormGroup) == this.getId(group)
                      ),
                    iconUrl:
                      this.getTypeControl(group).value == TileAction.IshtarApp
                        ? this.getIconUrlControl(group).value
                        : this.getOldIconUrlControl(group).value,
                    permissions: this.getPermissions(group)
                      .controls.filter(
                        (s) => !this.isTilePermissionDeleted(s as FormGroup)
                      )
                      .map(
                        (p) =>
                          new TilePermission({
                            ...groupToPermission(p as FormGroup),
                            tileId: this.getId(group),
                          })
                      ),
                  }),
                })
              );
        const groupToSegment = (group: FormGroup) => {
          const tiles = this.getTiles(group).controls.filter(
            (s) => !this.isTileDeleted(s as FormGroup)
          );
          return tiles.length
            ? forkJoin(
                tiles.map((t) => groupToTile(t as FormGroup).pipe(retry(3)))
              ).pipe(
                map(
                  (tiles) =>
                    new TilePageTilePageSegment({
                      id: this.getLinkId(group),
                      tilePageSegmentId: this.getId(group),
                      tilePageSegment: new TilePageSegment({
                        id: this.getId(group),
                        title: this.getTitle(group),
                        disabled: this.getDisabledControl(group).value,
                        hideTitle: this.getHideTitleControl(group).value,
                        position: (
                          (group.parent?.parent as FormGroup).controls
                            .segments as FormArray
                        ).controls
                          .filter((c) => !this.isSegmentDeleted(c as FormGroup))
                          .findIndex(
                            (s) =>
                              this.getId(s as FormGroup) == this.getId(group)
                          ),
                        tiles: tiles.map(
                          (t) =>
                            new TilePageSegmentTile({
                              ...t,
                              tilePageSegmentId: this.getId(group),
                            })
                        ),
                      }),
                    })
                )
              )
            : of(
                new TilePageTilePageSegment({
                  id: this.getLinkId(group),
                  tilePageSegmentId: this.getId(group),
                  tilePageSegment: new TilePageSegment({
                    id: this.getId(group),
                    title: this.getTitle(group),
                    disabled: this.getDisabledControl(group).value,
                    hideTitle: this.getHideTitleControl(group).value,
                    position: (
                      (group.parent?.parent as FormGroup).controls
                        .segments as FormArray
                    ).controls
                      .filter((c) => !this.isSegmentDeleted(c as FormGroup))
                      .findIndex(
                        (s) => this.getId(s as FormGroup) == this.getId(group)
                      ),
                    tiles: [],
                  }),
                })
              );
        };
        const groupToTilePage = (group: FormGroup) => {
          const segments = this.getSegments(group).controls.filter(
            (s) => !this.isSegmentDeleted(s as FormGroup)
          );
          return segments.length
            ? forkJoin(
                segments.map((s) => groupToSegment(s as FormGroup))
              ).pipe(
                map(
                  (segments) =>
                    new TilePage({
                      id: this.getId(group),
                      title: this.getTitle(group),
                      type: this.getTypeControl(group).value,
                      intranetUrl: this.getIntranetUrlControl(group).value,
                      homepage: this.getHomepageControl(group).value,
                      disabled: false,
                      segments: segments.map(
                        (s) =>
                          new TilePageTilePageSegment({
                            ...s,
                            tilePageId: this.getId(group),
                          })
                      ),
                    })
                )
              )
            : of(
                new TilePage({
                  id: this.getId(group),
                  title: this.getTitle(group),
                  type: this.getTypeControl(group).value,
                  intranetUrl: this.getIntranetUrlControl(group).value,
                  homepage: this.getHomepageControl(group).value,
                  disabled: false,
                  segments: [],
                })
              );
        };
        this.getTranslation$('savingConfiguration')
          .pipe(first())
          .subscribe((label) =>
            this.loader.startLoading(label, () =>
              forkJoin(
                this.tilePages!.controls.filter(
                  (s) => !this.isTilePageDeleted(s as FormGroup)
                ).map((p) => groupToTilePage(p as FormGroup))
              ).pipe(
                switchMap((pages) =>
                  this.tilePageFacade.updateTilePages$(pages)
                ),
                // switchMap(() => this.tilePageFacade.getTilePages$()),
                delay(10),
                switchMap(() => this.routeBuilder.initRoutes(false))
              )
            )
          );
      }
    });
  }

  dragging = false;

  dragStart(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dragging) return;
    this.dragging = true;
  }

  dragStop(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.dragging) return;
    this.dragging = false;
  }

  imageDropped(event: DragEvent, group: FormGroup) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
    if (event.dataTransfer?.files && event.dataTransfer.files.length) {
      const file = event.dataTransfer.files[0];

      this.getIconBlobControl(group).patchValue(file);
      this.updateIconBlobUrl(group);
    }
  }

  activeIconUrl?: SafeHtml;

  onIconInputChange(event: Event, group: FormGroup) {
    const input = event.target as HTMLInputElement;

    if (input?.files && input.files.length) {
      const file = input.files[0];

      this.getIconBlobControl(group).patchValue(file);
      this.updateIconBlobUrl(group);
      input.value = '';
    }
  }

  clearIconInput(group: FormGroup) {
    this.getIconBlobControl(group).patchValue(undefined);
    this.getIconUrlControl(group).patchValue(
      this.getOldIconUrlControl(group).value
    );
    this.updateIconBlobUrl(group);
  }

  updateIconBlobUrl(group: FormGroup) {
    const iconUrlControl = this.getIconUrlControl(group);
    if (iconUrlControl.value) {
      URL.revokeObjectURL(iconUrlControl.value);
      iconUrlControl.patchValue('');
    }
    const iconBlobControl = this.getIconBlobControl(group);
    iconBlobControl.markAsDirty();
    if (iconBlobControl.value)
      iconUrlControl.patchValue(URL.createObjectURL(iconBlobControl.value));
    this.activeIconUrl = iconUrlControl.value
      ? this.transform(iconUrlControl.value)
      : undefined;
  }
}
