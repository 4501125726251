import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountInfo,
  Configuration,
  IPublicClientApplication,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';
import * as microsoftTeams from '@microsoft/teams-js';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const PERSONAL_ACCOUNT_TENANT_ID =
  '9188040d-6c67-4c5b-b112-36a304b66dad';

@Injectable({
  providedIn: 'root',
})
export class MicrosoftAuthenticationService {
  get isAuthenticated(): boolean {
    return this.accountInfo !== undefined;
  }

  get username(): string {
    return this.isAuthenticated ? this.accountInfo.username : '';
  }

  get name(): string | undefined {
    return this.isAuthenticated ? this.accountInfo.name : undefined;
  }

  get id(): string {
    return this.isAuthenticated
      ? this.accountInfo.homeAccountId.split('.')[0]
      : '';
  }

  get tenantId(): string {
    return this.isAuthenticated ? this.accountInfo.tenantId : '';
  }

  get isPersonalAccount(): boolean {
    return this.tenantId == PERSONAL_ACCOUNT_TENANT_ID;
  }

  get accounts(): AccountInfo[] {
    return this.client?.getAllAccounts() ?? [];
  }

  private interactiveScopes: string[] = ['openid', 'profile', 'user.read'];
  private scopesForUserConsent: string[] = [
    'https://management.azure.com//user_impersonation',
  ];

  get scopes(): string[] {
    return [...this.interactiveScopes, ...this.scopesForUserConsent];
  }

  get accountInfo(): AccountInfo {
    return this.accounts[0];
  }

  signedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  needsSignBackIn = false;
  runningOnTeams = false;

  authError = '';

  private specificAuthority?: string;

  private config?: Configuration;
  private client?: IPublicClientApplication;
  private interceptors: Map<string, string[]> = new Map();

  private adminConsentUrl(tenantId?: string): string {
    return `https://login.microsoftonline.com/${
      tenantId ?? this.tenantId
    }/adminconsent
		?client_id=${environment.adminConsentClientId}
		&state=
		&redirect_uri=${this.config?.auth.redirectUri}
		&scope=api://${environment.adminConsentClientId}/.default`;
  }

  constructor(private readonly router: Router) {
    microsoftTeams.app
      .initialize()
      .then(() => (this.runningOnTeams = true))
      .catch(() => console.log('Not running in Teams'));
  }

  configure(c: Configuration, interceptors?: Map<string, InterceptorScope>) {
    this.config = c;
    this.specificAuthority = c.auth.authority;
    this.interceptors.set('default', this.interactiveScopes);
    // this.interceptors.set(environment.ishtarFunctions, [
    //   environment.ishtarFunctionsScope,
    // ]);
    this.interceptors.set(location.origin, [environment.onBehalfOfScope]);
    if (interceptors) {
      interceptors.forEach((i, k) => {
        if (i.interactive)
          this.interactiveScopes = this.interactiveScopes.concat(i.scopes);
        this.interceptors.set(k, i.scopes);
      });
    }
    this.client = new PublicClientApplication(this.config);
    return this.client.initialize();
  }

  addInterceptor(url: string, scopes: string[]): void {
    this.interceptors.set(url, scopes);
  }

  /**
   * Initializes the authentication process
   * based on the information of IshtarContext
   */
  authenticate(state = ''): void {
    if (this.runningOnTeams) {
      microsoftTeams.authentication
        .authenticate({
          url: window.location.origin + '/teams-authentication',
          width: 600,
          height: 535,
        })
        .then(() => this.silentSignIn())
        .catch((reason: string) => console.error(reason));
    } else {
      this.signIn(state);
    }
  }

  adminConsent(tenantId?: string): Promise<void> {
    if (this.runningOnTeams) {
      return microsoftTeams.authentication
        .authenticate({
          url: `${window.location.origin}/teams-admin-consent${
            tenantId ? '?tenantId=' + tenantId : ''
          }`,
          width: 600,
          height: 535,
        })
        .then(() => this.silentSignIn())
        .catch((reason: string) => console.error(reason));
    } else {
      return this.grantAdminConsent(tenantId, true);
    }
  }

  grantAdminConsent(tenantId?: string, popup?: boolean): Promise<void> {
    return new Promise<void>((resolve) => {
      if (popup) {
        window.open(
          this.adminConsentUrl(tenantId),
          '_blank',
          'popup,left=100,top=100,width=600,height=600'
        );
        const handleMessage = () => {
          window.removeEventListener('message', handleMessage);
          resolve();
        };
        window.addEventListener('message', handleMessage);
      } else {
        window.location.replace(this.adminConsentUrl(tenantId));
      }
    });
  }

  /**
   * Checks the cache for an account and acquires
   * the access tokens if set.
   */
  silentSignIn(): void {
    const accounts = this.client?.getAllAccounts() ?? [];
    if (accounts.length) {
      this.signedIn.next(true);
    }
  }

  /**
   * Initializes the sign-in process.
   * @param state current state of the router.
   * @param popup whether to use the redirect or popup flow.
   */
  signIn(state = '', options: any = {}, navigate = true, popup = false): void {
    const accounts = this.client?.getAllAccounts() ?? [];
    if (accounts.length && !this.needsSignBackIn) {
      this.signedIn.next(true);
    } else {
      popup
        ? this.popup(state, options, navigate)
        : this.redirect(state, options);
    }
  }

  userConsent(options: any = {}, routeOnError = false): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.client
        ?.loginPopup({
          scopes: this.scopesForUserConsent,
          prompt: 'consent',
          ...options,
        })
        .then(() => {
          resolve();
        })
        .catch((e) => {
          console.error(e);
          if (routeOnError) this.navigateToAuthErrorPage(e);
          reject(e);
        });
    });
  }

  /**
   * Completes the sign-in process after the user
   * is redirected to the application.
   */
  completeSignIn(): void {
    this.client
      ?.handleRedirectPromise()
      .then((r) => {
        this.onCompleteSignIn(r);
      })
      .catch((e) => {
        console.error(e);
        this.navigateToAuthErrorPage(e);
      });
  }

  signOut(): Promise<void> {
    if (this.runningOnTeams) {
      return microsoftTeams.authentication
        .authenticate({
          url: `${location.origin}/teams-sign-out`,
          width: 535,
          height: 600,
        })
        .then(() => this.completeSignout());
    } else return this.signOutRedirect().then(() => this.completeSignout());
  }

  signOutRedirect(): Promise<void> {
    return (
      this.client?.logoutRedirect({ account: this.accountInfo }) ??
      new Promise<void>((_, reject) => reject('No client available'))
    );
  }

  completeSignout(): void {
    this.client?.handleRedirectPromise().then(() => {
      this.signedIn.next(false);
      setTimeout(() => {
        location.assign(`${location.origin}/?teams_reload=true`);
      }, 100);
    });
  }

  async changeAuthority(authority: string) {
    this.specificAuthority = authority;
  }

  runningAccessTokenGets: { [key: string]: Observable<string> } = {};

  getAccessToken(url = 'default'): Observable<string> {
    if (this.runningAccessTokenGets[url])
      return this.runningAccessTokenGets[url];
    let scopes: string[] = this.interactiveScopes;
    this.interceptors.forEach((i: string[], key: string) =>
      url.includes(key) ? (scopes = i) : undefined
    );
    const sub = new Subject<string>();
    new Observable<string>((o) => {
      const complete = (v: string) => {
        delete this.runningAccessTokenGets[url];
        o.next(v);
        o.complete();
      };
      const reject = (v: string) => {
        delete this.runningAccessTokenGets[url];
        o.error(v);
        o.complete();
      };
      if (this.client) {
        const request: SilentRequest = {
          account: this.accountInfo,
          scopes: scopes,
          authority: this.specificAuthority,
        };
        this.client
          .acquireTokenSilent(request)
          .then((result) => {
            this.needsSignBackIn = false;
            complete(result.accessToken);
          })
          .catch((err) => {
            switch (err.errorCode) {
              case 'user_cancelled':
              case 'interaction_required':
              case 'invalid_grant':
                if (/not consented/.test(err.errorMessage)) {
                  console.error(err);
                  this.navigateToAuthErrorPage(err);
                } else {
                  this.needsSignBackIn = true;
                  delete this.runningAccessTokenGets[url];
                  if ((err as any).claims)
                    request.claims = JSON.stringify((err as any).claims);
                  this.client
                    ?.acquireTokenRedirect(request)
                    .then(() => this.completeSignIn());
                }
                break;
              default:
                this.needsSignBackIn = true;
                console.error(err);
                this.navigateToAuthErrorPage(err);
                break;
            }
            reject(err.errorMessage);
          });
      } else {
        reject('No auth client available');
      }
    })
      .pipe(
        first(),
        catchError((e) => {
          sub.error(e);
          sub.complete();
          return of(e);
        })
      )
      .subscribe((s: string) => {
        sub.next(s);
        sub.complete();
      });
    this.runningAccessTokenGets[url] = sub.asObservable();
    return sub.asObservable();
  }

  denyAccess(): void {
    this.router.navigate(['/NoAccess']);
  }

  private redirect(state: string, options: any = {}): void {
    const request: RedirectRequest = {
      scopes: this.interactiveScopes,
      state: state,
      authority: this.specificAuthority,
      extraScopesToConsent: this.scopesForUserConsent,
      ...options,
    };
    this.client?.loginRedirect(request).catch(() => {
      this.client
        ?.handleRedirectPromise()
        .then(() => {
          if (!this.client?.getActiveAccount()) {
            this.navigateToAuthErrorPage('No active account found');
          }
        })
        .catch((e) => {
          console.error(e);
          this.navigateToAuthErrorPage(e);
        });
    });
  }

  private popup(state: string, options: any = {}, navigate = true): void {
    const request: PopupRequest = {
      scopes: this.interactiveScopes,
      state: state,
      authority: this.specificAuthority,
      extraScopesToConsent: this.scopesForUserConsent,
      ...options,
    };
    this.client
      ?.loginPopup(request)
      .then((r: AuthenticationResult) => this.onCompleteSignIn(r, navigate))
      .catch((e) => {
        console.error(e);
        this.navigateToAuthErrorPage(e);
      });
  }

  private onCompleteSignIn(resp: AuthenticationResult | null, navigate = true) {
    if (resp) {
      this.signedIn.next(true);
      if (navigate) this.navigateToReturnUrl(resp.state || '');
    }
  }

  private async navigateToReturnUrl(returnUrl: string) {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    await this.router.navigateByUrl(returnUrl, {
      replaceUrl: true,
    });
  }

  private navigateToAuthErrorPage(error: string) {
    this.authError = error;
    this.router.navigate(['/auth-error']);
  }
}

export interface InterceptorScope {
  interactive?: boolean;
  scopes: string[];
}
