import { Component, OnInit } from '@angular/core';
import { lastRouteKey } from 'src/app/core/guards/initial-navigation.guard';

@Component({
  selector: 'app-startup-error',
  templateUrl: './startup-error.component.html',
  styleUrls: ['./startup-error.component.scss'],
})
export class StartupErrorComponent implements OnInit {
  ngOnInit(): void {
    localStorage.setItem(lastRouteKey, '/');
  }
}
