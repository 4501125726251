import { Action, createReducer, on } from '@ngrx/store';
import { InterestGroup } from 'processdelight-angular-components';
import { AppState } from 'src/app/shared/store/app.reducer';
import {
  addInterestGroupsResolved,
  deleteInterestGroupsResolved,
  getInterestGroupsResolved,
  updateInterestGroupsResolved,
} from './interest-group.actions';

export const featureSlice = 'interestGroup';

export interface State {
  interestGroups: InterestGroup[];
}

const defaultState: State = {
  interestGroups: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return interestGroupReducer(state, action);
}

export const initialState: State = defaultState;

export const interestGroupReducer = createReducer(
  initialState,
  on(getInterestGroupsResolved, (state, { result }) => ({
    ...state,
    interestGroups: [...result],
  })),
  on(updateInterestGroupsResolved, (state, { updatedInterestGroups }) => ({
    ...state,
    interestGroups: state.interestGroups.map(
      (s) => updatedInterestGroups.find((u) => u.id === s.id) ?? s
    ),
  })),
  on(addInterestGroupsResolved, (state, { addedInterestGroups }) => ({
    ...state,
    interestGroups: [...state.interestGroups, ...addedInterestGroups],
  })),
  on(deleteInterestGroupsResolved, (state, { result }) => ({
    ...state,
    interestGroups: state.interestGroups.filter((s) => !result.includes(s.id)),
  }))
);

export const interestGroupState = (state: AppState) =>
  state.coreFeature.interestGroup;
