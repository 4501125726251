import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { CanActivateFn, Router, Routes } from '@angular/router';
import { ActionBarComponent } from 'processdelight-angular-components';
import { Subject, filter, first, map, of, takeUntil } from 'rxjs';
import { CoreModule } from '../core/core.module';
import { TileAction } from '../core/domain/enums/tile-action.enum';
import { TilePageSegment } from '../core/domain/models/tile-page-segment.model';
import { TilePage } from '../core/domain/models/tile-page.model';
import { UserSettings } from '../core/domain/models/user-settings.model';
import { Ishtar365ActionsService } from '../core/services/ishtar365-actions.service';
import { TranslationService } from '../core/services/translation.service';
import { AppSubscriptionFacade } from '../core/store/app-subscription/app-subscription.facade';
import { OrganizationFacade } from '../core/store/organization/organization.facade';
import { TilePageFacade } from '../core/store/tilepage/tilepage.facade';
import { UserFacade } from '../core/store/user/user.facade';
import { MicrosoftAuthenticationService } from '../msal/injectables/microsoft-authentication.service';
import { ApplicationBarService } from '../shared/services/application-bar.service';
import { ApplicationService } from '../shared/services/application.service';
import { AppTileComponent } from '../tile-page/app-tile/app-tile.component';
import { CustomTileComponent } from '../tile-page/custom-tile/custom-tile.component';
import { AutomationFlowComponent } from './components/automation-flows/automation-flow.component';
import {
  ISHTAR_SHIFTS,
  PermanenceSyncComponent,
} from './components/azure-sync/Ishtar.Permanence/permanence-sync.component';
import { AzureSyncComponent } from './components/azure-sync/azure-sync.component';
import { SyncSettingsComponent } from './components/azure-sync/sync-settings/sync-settings.component';
import { GroupSettingsComponent } from './components/group-settings/group-settings.component';
import { OrganigramComponent } from './components/group-settings/organigram/organigram.component';
import { LicenseSettingsComponent } from './components/license-settings/license-settings.component';
import { SubscriptionLicenseSettingsComponent } from './components/license-settings/subscription-license-settings/subscription-license-settings.component';
import { PersonalizationComponent } from './components/personalization/personalization.component';
import { SkillsSettingsComponent } from './components/skills-settings/skills-settings.component';
import { TilepageSettingsComponent } from './components/tilepage-settings/tilepage-settings.component';
import { WorkRegimeComponent } from './components/work-regime/work-regime.component';
import { InterestGroupsComponent } from './components/interest-groups/interest-groups.component';

@Component({
  standalone: true,
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  imports: [
    CoreModule,
    MatTabsModule,
    ActionBarComponent,
    AppTileComponent,
    CustomTileComponent,
  ],
})
export default class SettingsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  isMainAdmin$ = this.userFacade.isMainAdmin$;
  hasOoO$ = this.userFacade.userLicenses$.pipe(
    map((licenses) =>
      licenses.some((l) => l.subscriptionInfo.productName == 'Ishtar.OoO')
    )
  );

  page?: TilePage;

  tilePages?: TilePage[] = [];

  TileAction = TileAction;

  menuOpened = false;

  userSettings = new UserSettings();

  sideBarHover = false;

  firstTopMargin = 0;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.firstTopMargin =
      (event.target as Window)?.innerWidth < 550 ||
      (event.target as Window)?.innerHeight < 450
        ? 30
        : 80;
  }

  navColor$ = this.organizationFacade.tempProperties$.pipe(
    map((p) => p.navColor)
  );
  navContrast$ = this.organizationFacade.tempProperties$.pipe(
    map((p) => p.navContrast)
  );
  get navActions() {
    return this.ishtar365Actions.navActions;
  }
  get buttonActions() {
    return this.ishtar365Actions.buttonActions;
  }
  get iconActions() {
    return this.ishtar365Actions.iconActions;
  }
  get segments() {
    return this.page?.segments
      .map((s) => s.tilePageSegment)
      .filter((s) => !s.disabled)
      .sort((a, b) => a.position - b.position);
  }
  signedIn$ = this.applicationService.signedIn.asObservable();

  constructor(
    private readonly userFacade: UserFacade,
    private readonly translations: TranslationService,
    private readonly organizationFacade: OrganizationFacade,
    private readonly ishtar365Actions: Ishtar365ActionsService,
    private readonly applicationService: ApplicationService,
    private readonly applicationBarService: ApplicationBarService,
    private readonly msal: MicrosoftAuthenticationService,
    private readonly tilePageFacade: TilePageFacade
  ) {}

  ngOnInit(): void {
    this.firstTopMargin =
      window.innerWidth < 550 || window.innerHeight < 450 ? 30 : 80;
    this.applicationBarService.appBarExtended$
      .pipe(takeUntil(this.destroy$))
      .subscribe((b) => {
        this.sideBarHover = b;
      });
    this.applicationBarService.tilePageExtended$
      .pipe(takeUntil(this.destroy$))
      .subscribe((b) => {
        this.menuOpened = b;
      });
    this.tilePageFacade.homepage$
      .pipe(
        filter((p) => !!p),
        first()
      )
      .subscribe((p) => (this.page = p));

    this.tilePageFacade.tilePages$
      .pipe(takeUntil(this.destroy$))
      .subscribe((p) => (this.tilePages = p));
    this.userFacade.userSettings$
      .pipe(takeUntil(this.destroy$))
      .subscribe((s) => (this.userSettings = new UserSettings(s)));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  getTiles(segment: TilePageSegment) {
    return segment.tiles
      .map((t) => t.tile)
      .filter(
        (t) =>
          !t.disabled &&
          (!t.permissions?.length ||
            t.permissions
              .map((p) => p.permission)
              .some((p) =>
                p.groupUser.user
                  ? p.groupUser.id == this.msal.id
                  : p.groupUser.group?.members?.some(
                      (m) => m.id == this.msal.id
                    )
              )) &&
          !(
            t.tileActionType == TileAction.TilePage &&
            !this.tilePages?.some((p) => p.id == t.tileAction)
          )
      )
      .sort((a, b) => a.position - b.position);
  }

  togglePinned() {
    this.userSettings.sideBarPinned = !this.userSettings.sideBarPinned;
    this.userFacade
      .updateUserSettings$(new UserSettings(this.userSettings))
      .subscribe();
  }
  toggleTilePage() {
    this.applicationBarService.toggleTilePage();
  }
}

const canActivateSettings: (path: string) => CanActivateFn =
  (path) => (_route, state) => {
    if (!state.url.endsWith(path)) return of(true);
    const appFacade = inject(AppSubscriptionFacade);
    const router = inject(Router);
    return appFacade.activeApp$.pipe(
      first(),
      map((app) =>
        app ? router.createUrlTree(['settings', path, app.name]) : true
      )
    );
  };

const canActivateSettingsChildren: (path: string) => CanActivateFn =
  (path) => (route) => {
    const appFacade = inject(AppSubscriptionFacade);
    const router = inject(Router);
    return appFacade.apps$.pipe(
      first(),
      map((apps) => {
        const app = apps.find(
          (app) => app.name == route.paramMap.get('appName')
        );
        if (app) {
          appFacade.setActiveApp(app);
          return true;
        } else return router.createUrlTree(['settings', path]);
      })
    );
  };

export const settingsRoutes: Routes = [
  {
    path: '',
    providers: [],
    canActivate: [
      () => {
        const userFacade = inject(UserFacade);
        return userFacade.isAdmin$;
      },
    ],
    children: [
      {
        path: 'groups',
        component: GroupSettingsComponent,
        canActivate: [canActivateSettings('groups')],
        children: [
          {
            path: ':appName',
            component: OrganigramComponent,
            canActivate: [canActivateSettingsChildren('groups')],
          },
        ],
      },
      {
        path: 'licenses',
        component: LicenseSettingsComponent,
        canActivate: [canActivateSettings('licenses')],
        children: [
          {
            path: ':appName',
            component: SubscriptionLicenseSettingsComponent,
            canActivate: [canActivateSettingsChildren('licenses')],
          },
        ],
      },
      {
        path: 'tilepages',
        component: TilepageSettingsComponent,
      },
      {
        path: 'personalization',
        component: PersonalizationComponent,
      },
      {
        path: 'skills',
        component: SkillsSettingsComponent,
      },
      {
        path: 'workregime',
        component: WorkRegimeComponent,
      },
      {
        path: 'azureSync',
        component: AzureSyncComponent,
        children: [
          {
            path: ISHTAR_SHIFTS,
            component: PermanenceSyncComponent,
          },
          {
            path: ':appName',
            component: SyncSettingsComponent,
          },
        ],
      },
      {
        path: 'automationflow',
        component: AutomationFlowComponent,
      },
      {
        path: 'interestGroups',
        component: InterestGroupsComponent,
      },
    ],
  },
];
