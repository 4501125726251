import {
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  Injector,
  enableProdMode,
  importProvidersFrom,
  inject,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { StoreModule, provideStore } from '@ngrx/store';
import {
  StoreDevtoolsModule,
  provideStoreDevtools,
} from '@ngrx/store-devtools';
import { SessionExpiredModule } from 'processdelight-angular-components';
import { AppComponent } from './app/app.component';
import { ServiceLocator } from './app/core/services/locator.service';
import { microsoftAuthenticationInterceptor } from './app/msal/injectables/microsoft-authentication.interceptor';
import { MSALModule } from './app/msal/msal.module';
import { ApplicationService } from './app/shared/services/application.service';
import { environment } from './environments/environment';
import { routes } from './app/app.routes';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        const injector = inject(Injector);
        ServiceLocator.injector = injector;
        const service = inject(ApplicationService);
        return () => service.boot();
      },
    },
    provideRouter(routes),
    provideStore({}),
    provideEffects([]),
    provideStoreDevtools({ logOnly: false }),
    provideHttpClient(withInterceptors([microsoftAuthenticationInterceptor])),
    importProvidersFrom(
      StoreModule.forRoot({}),
      EffectsModule.forRoot([]),
      StoreDevtoolsModule.instrument({ logOnly: environment.production }),
      MSALModule,
      SessionExpiredModule,
      HttpClientModule
    ),
    provideAnimations(),
  ],
});
