import { MetadataParameter } from './metadata-parameter.model';

export class MetadataChoice{
  id!: string;
  value!: string;
  metadataParameterId!: string;
  metadataParameter!: MetadataParameter;
  position!: number;

  constructor(obj: Partial<MetadataChoice>) {
    Object.assign(this, obj);
    if (obj.metadataParameter)
      this.metadataParameter = obj.metadataParameter;
  }
}