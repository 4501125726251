import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AutomationFlowApplication,
  AutomationFlow,
  Lookup,
  Operator,
  PropertyType,
  TriggerType,
  AutomationFlowConditionType,
  Month,
  Day,
} from 'processdelight-angular-components';
import {
  camelcaseKeys,
  capitalizeKeys,
} from 'src/app/core/extensions/object.extensions';
import { AutomationFlowActionType } from 'processdelight-angular-components';

@Injectable({
  providedIn: 'root',
})
export class AutomationFlowService {
  apiBase = `${location.origin}/web/automationflow`;

  constructor(private httpClient: HttpClient) {}

  public getAutomationFlows(): Observable<AutomationFlow[]> {
    return this.httpClient
      .get<AutomationFlow[]>(`${this.apiBase}/automationFlows`)
      .pipe(
        map((automationFlows) =>
          automationFlows.map((af) => new AutomationFlow(camelcaseKeys(af)))
        )
      );
  }

  public createAutomationFlow(
    automationFlow: AutomationFlow
  ): Observable<AutomationFlow> {
    return this.httpClient
      .post<AutomationFlow>(
        `${this.apiBase}/automationFlows`,
        capitalizeKeys({ ...automationFlow })
      )
      .pipe(map((af) => new AutomationFlow(camelcaseKeys(af))));
  }

  public updateAutomationFlow(
    automationFlow: AutomationFlow
  ): Observable<AutomationFlow> {
    return this.httpClient
      .patch<AutomationFlow>(
        `${this.apiBase}/automationFlows`,
        capitalizeKeys(automationFlow)
      )
      .pipe(map((af) => new AutomationFlow(camelcaseKeys(af))));
  }

  public deleteAutomationFlow(automationFlowId: string): Observable<string> {
    return this.httpClient
      .delete<string[]>(`${this.apiBase}/automationFlows/${automationFlowId}`)
      .pipe(map((deletedIds) => deletedIds[0]));
  }

  public getApplications(
    email: string
  ): Observable<AutomationFlowApplication[]> {
    return this.httpClient
      .get<AutomationFlowApplication[]>(`${this.apiBase}/applications/${email}`)
      .pipe(
        map((applications) =>
          applications.map(
            (a) => new AutomationFlowApplication(camelcaseKeys(a))
          )
        )
      );
  }

  public getActionTypes(): Observable<AutomationFlowActionType[]> {
    return this.httpClient
      .get<AutomationFlowActionType[]>(`${this.apiBase}/actionTypes`)
      .pipe(
        map((actionTypes) =>
          actionTypes.map(
            (at) => new AutomationFlowActionType(camelcaseKeys(at))
          )
        )
      );
  }

  public getConditionTypes(): Observable<AutomationFlowConditionType[]> {
    return this.httpClient
      .get<AutomationFlowConditionType[]>(`${this.apiBase}/conditionTypes`)
      .pipe(
        map((conditionTypes) =>
          conditionTypes.map(
            (ct) => new AutomationFlowConditionType(camelcaseKeys(ct))
          )
        )
      );
  }

  public getTriggerTypes(): Observable<TriggerType[]> {
    return this.httpClient
      .get<TriggerType[]>(`${this.apiBase}/triggerTypes`)
      .pipe(
        map((triggerTypes) =>
          triggerTypes.map((tt) => new TriggerType(camelcaseKeys(tt)))
        )
      );
  }

  public getPropertyTypes(): Observable<PropertyType[]> {
    return this.httpClient
      .get<PropertyType[]>(`${this.apiBase}/propertyTypes`)
      .pipe(
        map((propertyTypes) =>
          propertyTypes.map((pt) => new PropertyType(camelcaseKeys(pt)))
        )
      );
  }

  public getDays(): Observable<Day[]> {
    return this.httpClient
      .get<Day[]>(`${this.apiBase}/days`)
      .pipe(map((days) => days.map((d) => new Day(camelcaseKeys(d)))));
  }

  public getMonths(): Observable<Month[]> {
    return this.httpClient
      .get<Month[]>(`${this.apiBase}/months`)
      .pipe(map((months) => months.map((m) => new Month(camelcaseKeys(m)))));
  }

  public getFrequencies(): Observable<Lookup[]> {
    return this.httpClient
      .get<Lookup[]>(`${this.apiBase}/frequencies`)
      .pipe(map((frequencies) => frequencies.map((f) => new Lookup(f))));
  }

  public getRankings(): Observable<Lookup[]> {
    return this.httpClient
      .get<Lookup[]>(`${this.apiBase}/rankings`)
      .pipe(map((rankings) => rankings.map((r) => new Lookup(r))));
  }

  public getOperators(): Observable<Operator[]> {
    return this.httpClient
      .get<Operator[]>(`${this.apiBase}/operators`)
      .pipe(
        map((operators) => operators.map((o) => new Operator(camelcaseKeys(o))))
      );
  }

  public getObjectTypeRecords(objectTypeName: string): Observable<Lookup[]> {
    switch (objectTypeName) {
      case 'Task':
        return this.httpClient.get<Lookup[]>(
          `${this.apiBase}/objectTypeRecords/tasks`
        );

      case 'Batch':
        return this.httpClient.get<Lookup[]>(
          `${this.apiBase}/objectTypeRecords/batches`
        );

      case 'Client':
        return this.httpClient.get<Lookup[]>(
          `${this.apiBase}/objectTypeRecords/clients`
        );

      case 'Contact':
        return this.httpClient.get<Lookup[]>(
          `${this.apiBase}/objectTypeRecords/contacts`
        );

      default:
        return of([]);
    }
  }
}
