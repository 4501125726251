import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MicrosoftAuthenticationService } from '../../injectables/microsoft-authentication.service';

@Component({
  selector: 'app-teams-admin-consent',
  templateUrl: './teams-admin-consent.component.html',
  styleUrls: ['./teams-admin-consent.component.scss'],
})
export class TeamsAdminConsentComponent implements OnInit {
  constructor(
    private readonly msalService: MicrosoftAuthenticationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.msalService.grantAdminConsent(
      this.route.snapshot.queryParamMap.get('tenantId') ?? undefined
    );
  }
}
