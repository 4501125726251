import { createAction, props } from '@ngrx/store';
import { IshtarApp } from '../../domain/models/ishtar-app.model';
import { IshtarLicense } from '../../domain/models/ishtar-license.model';
import { IshtarSubscription } from '../../domain/models/ishtar-subscription.model';
import { ActionBase } from '../action-base.interface';

export const getApps = createAction(
  '[App Subscription] Get apps',
  props<ActionBase<IshtarApp[]>>()
);
export const getAppsResolved = createAction(
  '[App Subscription] Get apps resolved',
  props<{ apps: IshtarApp[] }>()
);

export const getSubscriptions = createAction(
  '[App Subscription] Get subscriptions',
  props<ActionBase<IshtarSubscription[]>>()
);
export const getSubscriptionsResolved = createAction(
  '[App Subscription] Get subscriptions resolved',
  props<{ subscriptions: IshtarSubscription[] }>()
);
export const getLicensesForUserResolved = createAction(
  '[App Subscription] Get licenses for user resolved',
  props<{ licenses: IshtarLicense[] }>()
);
export const getLicensesForApp = createAction(
  '[App Subscription] Get licenses for app',
  props<
    {
      resetCache: boolean;
      appId: string;
    } & ActionBase<IshtarLicense[]>
  >()
);
export const getLicensesForAppResolved = createAction(
  '[App Subscription] Get licenses for app resolved',
  props<{ appId: string; licenses: IshtarLicense[] }>()
);
export const addLicense = createAction(
  '[App Subscription] Add license',
  props<
    {
      appId: string;
      email: string;
      name: string;
      admin: boolean | undefined;
    } & ActionBase<IshtarLicense>
  >()
);
export const addAppLicenseResolved = createAction(
  '[App Subscription] Add app license resolved',
  props<{ license: IshtarLicense }>()
);
export const removeLicense = createAction(
  '[App Subscription] Remove license',
  props<{ appId: string; email: string } & ActionBase<IshtarLicense>>()
);
export const removeAppLicenseResolved = createAction(
  '[App Subscription] Remove app license resolved',
  props<{ license: IshtarLicense }>()
);
export const updateLicense = createAction(
  '[App Subscription] Update license',
  props<
    { appId: string; email: string; admin: boolean } & ActionBase<IshtarLicense>
  >()
);
export const updateAppLicenseResolved = createAction(
  '[App Subscription] Update app license resolved',
  props<{ license: IshtarLicense }>()
);
export const setActiveApp = createAction(
  '[App Subscription] Set active app',
  props<{ app?: IshtarApp }>()
);
export const getAppConfig = createAction(
  '[App Subscription] Get app config',
  props<
    {
      appName: string;
      constructor: new (obj: Partial<any>) => any;
    } & ActionBase<any>
  >()
);
export const getAppConfigResolved = createAction(
  '[App Subscription] Get app config resolved',
  props<{ appName: string; config: any }>()
);
export const updateAppConfig = createAction(
  '[App Subscription] Update app config',
  props<
    {
      appName: string;
      constructor: new (obj: Partial<any>) => any;
      config: any;
    } & ActionBase<any>
  >()
);

export const getAADSyncConfig = createAction(
  '[App Subscription] Get AADSync config',
  props<
    {
      appName: string;
      constructor: new (obj: Partial<any>) => any;
    } & ActionBase<any>
  >()
);
export const getAADSyncConfigResolved = createAction(
  '[App Subscription] Get AADSync config resolved',
  props<{ appName: string; config: any }>()
);
export const updateAADSyncConfig = createAction(
  '[App Subscription] Update AADSync config',
  props<
    {
      appName: string;
      constructor: new (obj: Partial<any>) => any;
      config: any;
    } & ActionBase<any>
  >()
);

export const addPossibleAppToFrames = createAction(
  '[App Subscription] Add possible app to frames',
  props<{ appName: string }>()
);
export const removePossibleAppFromFrames = createAction(
  '[App Subscription] Remove possible app from frames',
  props<{ appName: string }>()
);

export const resetSlice = createAction('[App Subscription] Reset slice');
