import { BaseEntity } from 'processdelight-angular-components';
import { InterestGroupSubgroup } from './interest-group-subgroup.model';

export class InterestGroup extends BaseEntity {
  title!: string;
  description?: string;
  icon?: string;
  color!: string;
  isDeleted?: boolean;
  subGroups?: InterestGroupSubgroup[] = [];

  constructor(obj: Partial<InterestGroup>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.subGroups)
      this.subGroups = obj.subGroups.map((x) => new InterestGroupSubgroup(x));
  }
}
