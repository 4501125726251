export enum OrderStatus {
  PENDING,
  PROCESSING,
  ONHOLD,
  COMPLETED,
  CANCELLED,
  REFUNDED,
  FAILED,
  TRASH,
}
