<div class="group-settings-wrapper">
  <div class="d-flex flex-row h-100">
    <mat-card class="h-fit-content">
      <mat-card-header>
        <mat-card-title
          *ngIf="getTranslation$('manageGroups') | async as manageGroupsLabel"
        >
          {{ manageGroupsLabel }}</mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        <ng-container
          *ngIf="appsWithSubscription$ | async as appsWithSubscription"
        >
          <mat-action-list *ngIf="appsWithSubscription.length; else noApps">
            <mat-list-item
              class="clickable"
              color="accent"
              [class.active]="activeApp?.name === app.name"
              *ngFor="let app of appsWithSubscription"
              (click)="setActiveApp(app)"
            >
              {{ app.name }}!
            </mat-list-item>
          </mat-action-list>
        </ng-container>
        <ng-template #noApps>
          <p
            *ngIf="
              getTranslation$('errorNoActiveSubscriptions')
                | async as errorNoActiveSubscriptionsLabel
            "
          >
            {{ errorNoActiveSubscriptionsLabel }}
          </p>
        </ng-template>
      </mat-card-content>
    </mat-card>
    <router-outlet></router-outlet>
  </div>
</div>
