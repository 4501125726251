import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import * as uuid from 'uuid';

export class LineConnectorInputs {
  id: string = uuid.v4();
  parent?: HTMLElement;
  start?: HTMLElement;
  end?: HTMLElement;
  redraw$?: Observable<string>;
  startSocket:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topleft'
    | 'topright'
    | 'bottomleft'
    | 'bottomright'
    | 'auto' = 'top';
  endSocket:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topleft'
    | 'topright'
    | 'bottomleft'
    | 'bottomright'
    | 'auto' = 'bottom';

  startSize = 10;
  endSize = 10;
  size = 4;
  color = 'black';

  constructor(obj: Partial<LineConnectorInputs>) {
    Object.assign(this, obj);
  }
}
class Point {
  x!: number;
  y!: number;
  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

@Component({
  standalone: true,
  selector: 'app-line-connector',
  templateUrl: './line-connector.component.html',
  styleUrls: ['./line-connector.component.scss'],
  imports: [CommonModule],
})
export class LineConnectorComponent implements OnInit, OnDestroy {
  @Input() id: string = uuid.v4();
  @Input() parent?: HTMLElement;
  @Input() start?: HTMLElement;
  @Input() end?: HTMLElement;
  @Input() redraw$?: Observable<string>;

  @Input() startSocket:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topleft'
    | 'topright'
    | 'bottomleft'
    | 'bottomright'
    | 'auto' = 'top';
  @Input() endSocket:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topleft'
    | 'topright'
    | 'bottomleft'
    | 'bottomright'
    | 'auto' = 'bottom';

  @Input() startSize = 10;
  @Input() endSize = 10;
  @Input() size = 4;
  @Input() color = 'black';

  startPoint?: Point;
  endPoint?: Point;
  startControlPoint?: Point;
  endControlPoint?: Point;

  padding = 100;

  get path() {
    if (
      !this.startPoint ||
      !this.endPoint ||
      !this.startControlPoint ||
      !this.endControlPoint
    )
      return undefined;
    return `M ${this.startPoint.x - this.left + this.padding} ${
      this.startPoint.y - this.top + this.padding
    } C ${this.startControlPoint.x - this.left + this.padding} ${
      this.startControlPoint.y - this.top + this.padding
    } ${this.endControlPoint.x - this.left + this.padding} ${
      this.endControlPoint.y - this.top + this.padding
    } ${this.endPoint.x - this.left + this.padding} ${
      this.endPoint.y - this.top + this.padding
    }`;
  }

  get width() {
    if (!this.startPoint || !this.endPoint) return undefined;
    return Math.abs(this.startPoint.x - this.endPoint.x) + this.padding * 2;
  }
  get height() {
    if (!this.startPoint || !this.endPoint) return undefined;
    return Math.abs(this.startPoint.y - this.endPoint.y) + this.padding * 2;
  }

  left = 0;
  top = 0;

  get leftStyle() {
    return `${this.left - this.padding}px`;
  }
  get topStyle() {
    return `${this.top - this.padding}px`;
  }

  get viewbox() {
    if (!this.width || !this.height) return undefined;
    return `${this.left - this.padding} ${this.top - this.padding} ${
      this.width
    } ${this.height}`;
  }

  destroy$ = new Subject<void>();

  ngOnInit(): void {
    if (!this.parent) this.parent = document.body;
    if (this.redraw$)
      this.redraw$
        .pipe(
          takeUntil(this.destroy$),
          filter((id) => this.id.includes(id))
        )
        .subscribe(() => this.position());

    setTimeout(() => {
      this.position();
    }, 0);
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  private pointOnRect(
    x: number,
    y: number,
    minX: number,
    minY: number,
    maxX: number,
    maxY: number
  ): Point {
    const midX = (minX + maxX) / 2;
    const midY = (minY + maxY) / 2;
    // if (midX - x == 0) -> m == ±Inf -> minYx/maxYx == x (because value / ±Inf = ±0)
    const m = (midY - y) / (midX - x);

    if (x <= midX) {
      // check "left" side
      const minXy = m * (minX - x) + y;
      if (minY <= minXy && minXy <= maxY) return { x: minX, y: minXy };
    }

    if (x >= midX) {
      // check "right" side
      const maxXy = m * (maxX - x) + y;
      if (minY <= maxXy && maxXy <= maxY) return { x: maxX, y: maxXy };
    }

    if (y <= midY) {
      // check "top" side
      const minYx = (minY - y) / m + x;
      if (minX <= minYx && minYx <= maxX) return { x: minYx, y: minY };
    }

    if (y >= midY) {
      // check "bottom" side
      const maxYx = (maxY - y) / m + x;
      if (minX <= maxYx && maxYx <= maxX) return { x: maxYx, y: maxY };
    }

    // edge case when finding midpoint intersection: m = 0/0 = NaN
    return { x: x, y: y };
  }
  position(): void {
    if (!this.start || !this.end)
      throw Error('start and end should be specified');
    const parentRect = this.parent!.getBoundingClientRect();
    const startRectTemp = this.start.getBoundingClientRect();
    const startRect = new DOMRect(
      startRectTemp.x - parentRect.x,
      startRectTemp.y - parentRect.y,
      startRectTemp.width,
      startRectTemp.height
    );
    const endRectTemp = this.end.getBoundingClientRect();
    const endRect = new DOMRect(
      endRectTemp.x - parentRect.x,
      endRectTemp.y - parentRect.y,
      endRectTemp.width,
      endRectTemp.height
    );
    switch (this.startSocket) {
      case 'bottom':
        this.startPoint = new Point(
          startRect.left + startRect.width / 2,
          startRect.bottom
        );
        this.startControlPoint = new Point(
          this.startPoint.x,
          this.startPoint.y + 1
        );
        break;
      case 'top':
        this.startPoint = new Point(
          startRect.left + startRect.width / 2,
          startRect.top
        );
        this.startControlPoint = new Point(
          this.startPoint.x,
          this.startPoint.y - 1
        );
        break;
      case 'left':
        this.startPoint = new Point(
          startRect.left,
          startRect.top + startRect.height / 2
        );
        this.startControlPoint = new Point(
          this.startPoint.x - 1,
          this.startPoint.y
        );
        break;
      case 'right':
        this.startPoint = new Point(
          startRect.right,
          startRect.top + startRect.height / 2
        );
        this.startControlPoint = new Point(
          this.startPoint.x + 1,
          this.startPoint.y
        );
        break;
      case 'bottomleft':
        this.startPoint = new Point(startRect.bottom, startRect.left);
        this.startControlPoint = new Point(
          this.startPoint.x - 1,
          this.startPoint.y + 1
        );
        break;
      case 'bottomright':
        this.startPoint = new Point(startRect.bottom, startRect.right);
        this.startControlPoint = new Point(
          this.startPoint.x + 1,
          this.startPoint.y + 1
        );
        break;
      case 'topleft':
        this.startPoint = new Point(startRect.top, startRect.left);
        this.startControlPoint = new Point(
          this.startPoint.x - 1,
          this.startPoint.y - 1
        );
        break;
      case 'topright':
        this.startPoint = new Point(startRect.top, startRect.right);
        this.startControlPoint = new Point(
          this.startPoint.x + 1,
          this.startPoint.y - 1
        );
        break;
    }
    switch (this.endSocket) {
      case 'bottom':
        this.endPoint = new Point(
          endRect.left + endRect.width / 2,
          endRect.bottom
        );
        this.endControlPoint = new Point(this.endPoint.x, this.endPoint.y + 1);
        break;
      case 'top':
        this.endPoint = new Point(
          endRect.left + endRect.width / 2,
          endRect.top
        );
        this.endControlPoint = new Point(this.endPoint.x, this.endPoint.y - 1);
        break;
      case 'left':
        this.endPoint = new Point(
          endRect.left,
          endRect.top + endRect.height / 2
        );
        this.endControlPoint = new Point(this.endPoint.x - 1, this.endPoint.y);
        break;
      case 'right':
        this.endPoint = new Point(
          endRect.right,
          endRect.top + endRect.height / 2
        );
        this.endControlPoint = new Point(this.endPoint.x + 1, this.endPoint.y);
        break;
      case 'bottomleft':
        this.endPoint = new Point(endRect.bottom, endRect.left);
        this.endControlPoint = new Point(
          this.endPoint.x - 1,
          this.endPoint.y + 1
        );
        break;
      case 'bottomright':
        this.endPoint = new Point(endRect.bottom, endRect.right);
        this.endControlPoint = new Point(
          this.endPoint.x + 1,
          this.endPoint.y + 1
        );
        break;
      case 'topleft':
        this.endPoint = new Point(endRect.top, endRect.left);
        this.endControlPoint = new Point(
          this.endPoint.x - 1,
          this.endPoint.y - 1
        );
        break;
      case 'topright':
        this.endPoint = new Point(endRect.top, endRect.right);
        this.endControlPoint = new Point(
          this.endPoint.x + 1,
          this.endPoint.y - 1
        );
        break;
    }
    if (this.startSocket == 'auto' && this.endSocket == 'auto') {
      const startCenter = new Point(
        startRect.left + startRect.width / 2,
        startRect.top + startRect.height / 2
      );
      const endCenter = new Point(
        endRect.left + endRect.width / 2,
        endRect.top + endRect.height / 2
      );
      const startIntersection = this.pointOnRect(
        endCenter.x,
        endCenter.y,
        startRect.left,
        startRect.top,
        startRect.right,
        startRect.bottom
      );
      const endIntersection = this.pointOnRect(
        startCenter.x,
        startCenter.y,
        endRect.left,
        endRect.top,
        endRect.right,
        endRect.bottom
      );
      this.startPoint = new Point(startIntersection.x, startIntersection.y);
      const startVectorX = startIntersection.x - startCenter.x;
      const startVectorY = startIntersection.y - startCenter.y;
      const startVectorMag = Math.sqrt(
        startVectorX * startVectorX + startVectorY * startVectorY
      );
      const startNormalizedX = startVectorX / startVectorMag;
      const startNormalizedY = startVectorY / startVectorMag;
      this.startControlPoint = new Point(
        startIntersection.x + startNormalizedX,
        startIntersection.y + startNormalizedY
      );
      this.endPoint = new Point(endIntersection.x, endIntersection.y);
      const endVectorX = endIntersection.x - endCenter.x;
      const endVectorY = endIntersection.y - endCenter.y;
      const endVectorMag = Math.sqrt(
        endVectorX * endVectorX + endVectorY * endVectorY
      );
      const endNormalizedX = endVectorX / endVectorMag;
      const endNormalizedY = endVectorY / endVectorMag;
      this.endControlPoint = new Point(
        endIntersection.x + endNormalizedX,
        endIntersection.y + endNormalizedY
      );
    } else if (this.startSocket == 'auto') {
      const intersection = this.pointOnRect(
        this.endControlPoint!.x,
        this.endControlPoint!.y,
        startRect.left,
        startRect.top,
        startRect.right,
        startRect.bottom
      );
      this.startPoint = new Point(intersection.x, intersection.y);
      const startCenter = new Point(
        startRect.left + startRect.width / 2,
        startRect.top + startRect.height / 2
      );
      const vectorX = intersection.x - startCenter.x;
      const vectorY = intersection.y - startCenter.y;
      const mag = Math.sqrt(vectorX * vectorX + vectorY * vectorY);
      const normalizedX = vectorX / mag;
      const normalizedY = vectorY / mag;
      this.startControlPoint = new Point(
        intersection.x + normalizedX,
        intersection.y + normalizedY
      );
    } else if (this.endSocket == 'auto') {
      const intersection = this.pointOnRect(
        this.startControlPoint!.x,
        this.startControlPoint!.y,
        endRect.left,
        endRect.top,
        endRect.right,
        endRect.bottom
      );
      this.endPoint = new Point(intersection.x, intersection.y);
      const endCenter = new Point(
        endRect.left + endRect.width / 2,
        endRect.top + endRect.height / 2
      );
      const vectorX = intersection.x - endCenter.x;
      const vectorY = intersection.y - endCenter.y;
      const mag = Math.sqrt(vectorX * vectorX + vectorY * vectorY);
      const normalizedX = vectorX / mag;
      const normalizedY = vectorY / mag;
      this.endControlPoint = new Point(
        intersection.x + normalizedX,
        intersection.y + normalizedY
      );
    }
    if (this.startPoint && this.endPoint) {
      const dist = Math.sqrt(
        Math.pow(this.startPoint.x - this.endPoint.x, 2) +
          Math.pow(this.startPoint.y - this.endPoint.y, 2)
      );
      const magnitude = Math.min(dist / 5, 200);
      this.startControlPoint = new Point(
        this.startPoint.x +
          (this.startControlPoint!.x - this.startPoint.x) * magnitude,
        this.startPoint.y +
          (this.startControlPoint!.y - this.startPoint.y) * magnitude
      );
      this.endControlPoint = new Point(
        this.endPoint.x +
          (this.endControlPoint!.x - this.endPoint.x) * magnitude,
        this.endPoint.y +
          (this.endControlPoint!.y - this.endPoint.y) * magnitude
      );
      this.left = Math.min(this.startPoint.x, this.endPoint.x);
      this.top = Math.min(this.startPoint.y, this.endPoint.y);
    }
  }
}
