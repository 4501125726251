<div
  class="tile-wrapper"
  [style.width.px]="size"
  [style.margin-left.px]="margin"
  [style.margin-right.px]="margin"
  [style.margin-bottom.px]="!inSidebar ? 10 : 0"
  [title]="tile.title"
>
  <div class="tile-icon clickable" (click)="open()">
    <img
      *ngIf="objectUrl"
      [src]="objectUrl"
      [width]="size"
      [height]="size"
      alt="{{ tile.title }} tile icon"
      (error)="imgErrored($event)"
    />
  </div>
  <div
    class="clickable"
    [ngClass]="isSingleWord(tile.title) ? 'tile-title' : 'tile-title-long'"
    [style.font-size.px]="fontSize"
    [style.max-width.px]="intranet ? size : size * 2"
    [style.white-space]="isLongerThanTwoLines(tile.title) ? 'nowrap' : 'normal'"
    (click)="open()"
  >
    {{ tile.title }}
  </div>
</div>
