import { IshtarSubscription } from './ishtar-subscription.model';
import { IshtarUser } from './ishtar-user.model';

export class IshtarLicense {
  subscriptionInfo!: IshtarSubscription;
  userInfo!: IshtarUser;
  isAdmin!: boolean;

  constructor(obj: Partial<IshtarLicense>) {
    Object.assign(this, obj);
  }
}
