export class IshtarApp {
  productId!: number;
  name!: string;
  description!: string;
  appUrl!: string;
  storeUrl!: string;
  iconUrl!: string;
  created!: Date;

  constructor(obj: Partial<IshtarApp>) {
    Object.assign(this, obj);
  }
}
