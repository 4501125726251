import { BaseEntity } from 'processdelight-angular-components';
import { TilePageSegment } from './tile-page-segment.model';
import { Tile } from './tile.model';

export class TilePageSegmentTile extends BaseEntity {
  tilePageSegmentId!: string;
  tilePageSegment!: TilePageSegment;
  tileId!: string;
  tile!: Tile;

  constructor(obj: Partial<TilePageSegmentTile>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.tilePageSegment)
      this.tilePageSegment = new TilePageSegment(obj.tilePageSegment);
    if (obj.tile) this.tile = new Tile(obj.tile);
  }
}
