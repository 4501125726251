<div class="dialogContainer">
  <h1 mat-dialog-title>Unsaved Changes!</h1>
  <mat-icon class="closeButtonIcon" (click)="onCloseDialog('close')"
    >close</mat-icon
  >
  <div
    mat-dialog-content
    class="p-3 d-flex flex-column justify-content-center align-items-center"
  >
    <p>Do you want to submit your unsaved changes?</p>
    <div class="buttons">
      <button
        mat-raised-button
        class="menuButton"
        color="primary"
        (click)="onCloseDialog('submit')"
        [disabled]="data.formGroup.invalid"
      >
        <mat-icon class="material-icons-outlined">save</mat-icon>
        <span>{{ getTranslation$("save") | async }}</span>
      </button>
      <button
        mat-raised-button
        class="menuButton"
        color="primary"
        (click)="onCloseDialog('discard')"
      >
        <mat-icon class="material-icons-outlined">cancel</mat-icon>
        <span>{{ getTranslation$("discard") | async }}</span>
      </button>
      <button
        mat-raised-button
        class="menuButton"
        color="primary"
        (click)="onCloseDialog('close')"
      >
        <mat-icon class="material-icons-outlined">close</mat-icon>
        <span>{{ getTranslation$("cancel") | async }}</span>
      </button>
    </div>
    <div
      *ngIf="data.formGroup.invalid"
      class="errorMessage"
      style="color: red; font-size: 0.7rem"
    >
      Save disabled because one or more fields are invalid
    </div>
  </div>
</div>
