<div class="ms-0 ms-md-3 mt-3 mt-md-0" *ngIf="app">
  <mat-card>
    <mat-card-header class="align-items-center mb-2">
      <img [src]="app.iconUrl" mat-card-avatar class="mb-0" />
      <div class="d-flex flex-column ps-2">
        <mat-card-title>{{ app.name }}!</mat-card-title>
        <ng-container *ngIf="licenses && subscription">
          <mat-card-subtitle
            *ngIf="getTranslation$('licenses') | async as licensesLabel"
          >
            {{ licensesLabel }}: {{ licenses.length }}/{{ subscription.plan }}
          </mat-card-subtitle>
        </ng-container>
      </div>
      <button
        *ngIf="licenses && subscription"
        mat-mini-fab
        color="primary"
        class="ms-auto"
        [matMenuTriggerFor]="addUserMenu"
        (click)="focusInput(searchControl)"
        [disabled]="licenses.length >= subscription.plan"
      >
        <mat-icon>add</mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <mat-label *ngIf="getTranslation$('filter') | async as filter">{{
          filter
        }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="filter"
          [formControl]="filterSearchControl"
          #input
          class="filter"
        />
      </mat-form-field>
      <mat-accordion displayMode="flat" #accordion="matAccordion">
        <mat-expansion-panel *ngIf="activeGraphUser" expanded>
          <mat-expansion-panel-header>
            <mat-icon class="material-icons-outlined" (click)="assignLicense()"
              >add_circle_outline</mat-icon
            >
            <span class="ms-2">
              {{ activeGraphUser.displayName }}
            </span>
          </mat-expansion-panel-header>
          <div class="d-flex flex-row user-expanded">
            <div class="mt-2 me-2">{{ activeGraphUser.mail }}</div>
            <div
              class="d-flex flex-column justify-content-start align-items-start ms-auto"
            >
              <button
                mat-button
                color="primary"
                (click)="assignLicense()"
                *ngIf="
                  getTranslation$('assignLicense') | async as assignLicenseLabel
                "
              >
                {{ assignLicenseLabel }}
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <cdk-virtual-scroll-viewport
          appendOnly
          itemSize="48"
          class="example-viewport"
          [minBufferPx]="500"
          [maxBufferPx]="500"
          #cdkVirtualScrollViewport
        >
          <mat-expansion-panel
            *cdkVirtualFor="
              let license of filteredLicenses;
              trackBy: trackByFn;
              templateCacheSize: 30
            "
          >
            <mat-expansion-panel-header>
              <button
                mat-icon-button
                (click)="removeLicense(license, accordion)"
                [disabled]="
                  isIshtar365 && userIsOrgAdminFunc(license.userInfo.email)
                "
              >
                <mat-icon class="material-icons-outlined"
                  >highlight_off</mat-icon
                >
              </button>
              <span class="ms-2">
                {{ license.userInfo.name }}
              </span>
            </mat-expansion-panel-header>
            <div class="d-flex flex-row user-expanded">
              <div class="mt-2 me-2">{{ license.userInfo.email }}</div>
              <div
                class="d-flex flex-column justify-content-start align-items-start ms-auto"
              >
                <ng-container
                  *ngIf="
                    getTranslation$('removeAdminRights')
                      | async as removeAdminRightsLabel
                  "
                >
                  <button
                    mat-button
                    color="primary"
                    *ngIf="license.isAdmin; else addAdminButton"
                    [disabled]="
                      isIshtar365 && userIsOrgAdminFunc(license.userInfo.email)
                    "
                    (click)="removeAdmin(license)"
                  >
                    {{ removeAdminRightsLabel }}
                  </button>
                </ng-container>
                <ng-template #addAdminButton>
                  <button
                    mat-button
                    color="primary"
                    (click)="makeAdmin(license)"
                    *ngIf="
                      getTranslation$('addAdminRights')
                        | async as addAdminRightsLabel
                    "
                  >
                    {{ addAdminRightsLabel }}
                  </button>
                </ng-template>
                <button
                  mat-button
                  color="primary"
                  class="mt-2"
                  (click)="removeLicense(license, accordion)"
                  [disabled]="
                    isIshtar365 && userIsOrgAdminFunc(license.userInfo.email)
                  "
                  *ngIf="
                    getTranslation$('removeLicense')
                      | async as removeLicenseLabel
                  "
                >
                  {{ removeLicenseLabel }}
                </button>
              </div>
            </div>
          </mat-expansion-panel>
        </cdk-virtual-scroll-viewport>
      </mat-accordion>
    </mat-card-content>
  </mat-card>
  <mat-menu
    class="add-user-license-menu"
    xPosition="before"
    (closed)="userSearchControl.patchValue('')"
    #addUserMenu
  >
    <mat-form-field appearance="fill" (click)="$event.stopPropagation()">
      <mat-label>
        <ng-container *ngIf="getTranslation$('search') | async as searchLabel"
          >{{ searchLabel }}...</ng-container
        >
      </mat-label>
      <input
        matInput
        type="text"
        [formControl]="userSearchControl"
        #searchControl
      />
    </mat-form-field>
    <div class="d-flex flex-column">
      <div
        class="graph-user-item clickable"
        *ngFor="let user of foundUsers"
        (click)="setActiveGraphUser(user)"
        [title]="user.mail"
      >
        {{ user.displayName }}
      </div>
    </div>
  </mat-menu>
</div>
