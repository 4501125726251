export class CustomSecurityAttribute {
  public id?: string;
  public attributeSet?: string;
  public description?: string;
  public name?: string;
  public status?: string;
  public odataType?: string;
  public isSearchable?: boolean;
  public isCollection?: boolean;
  public usePreDefinedValuesOnly?: boolean;

  constructor(obj: Partial<CustomSecurityAttribute>) {
    Object.assign(this, obj);
  }
}
