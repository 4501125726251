import { Action, createReducer, on } from '@ngrx/store';
import { ArticleTypeConfiguration } from '../../domain/models/article-types/article-type-configuration.model';
import { articleTypeActions } from './article-type.actions';
import { WarehouseLocation } from '../../domain/models/article-types/warehouse-location.model';

export const featureSlice = 'articleType';

export interface State {
  articleTypeConfigurations: ArticleTypeConfiguration[];
  warehouseLocations: WarehouseLocation[];
}

const defaultState: State = {
  articleTypeConfigurations: [],
  warehouseLocations: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return articleTypeReducer(state, action);
}

export const initialState: State = defaultState;
export const articleTypeReducer = createReducer(
  initialState,
  on(
    articleTypeActions.getArticleTypeConfigurationsResolved,
    (state, { articleTypeConfigurations }) => ({
      ...state,
      articleTypeConfigurations,
    })
  ),
  on(
    articleTypeActions.updateArticleTypeConfigurationsResolved,
    (state, { configurations }) => ({
      ...state,
      articleTypeConfigurations: configurations,
    })
  ),
  on(
    articleTypeActions.getWarehouseLocationsResolved,
    (state, { warehouseLocations }) => ({
      ...state,
      warehouseLocations,
    })
  )
);
