import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ContextMenuAction,
  TopLayoutComponent,
  TranslationService,
} from 'processdelight-angular-components';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';

@Component({
  selector: 'app-user-settings',
  standalone: true,
  imports: [
    CommonModule,
    TopLayoutComponent,
    MatTabsModule,
    NotificationSettingsComponent,
  ],
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent {
  constructor(
    private ref: MatDialogRef<UserSettingsComponent>,
    public translations: TranslationService
  ) {}

  buttonActions: ContextMenuAction<unknown>[] = [];

  iconActions = [
    new ContextMenuAction<unknown>({
      icon: 'close',
      action: () => this.ref.close(),
    }),
  ];
}
