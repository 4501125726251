import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Subject, forkJoin, switchMap, takeUntil } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import {
  ContextMenuAction,
  Day,
  GroupUser,
} from 'processdelight-angular-components';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { WorkRegimeFacade } from 'src/app/core/store/workRegime/workRegime.facade';
import { WorkRegimeType } from 'src/app/core/domain/models/workRegimeType.model';
import { WorkRegime } from 'src/app/core/domain/models/workRegime.model';
import { WorkRegimeDay } from 'src/app/core/domain/models/workRegimeDay.model';
import { TranslationService } from 'src/app/core/services/translation.service';
import { MatIconModule } from '@angular/material/icon';
import { ViewChild, ElementRef } from '@angular/core';
import { ActionBarComponent } from 'processdelight-angular-components';

@Component({
  standalone: true,
  selector: 'app-work-regime-settings',
  templateUrl: './work-regime-settings.component.html',
  styleUrls: ['./work-regime-settings.component.scss'],
  imports: [
    MatTabsModule,
    RouterModule,
    CommonModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatListModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    ActionBarComponent,
  ],
})
export class WorkRegimeSettingsComponent implements OnInit, OnDestroy {
  user: GroupUser;
  regime: WorkRegimeDay[];
  amountOfWorkDays: number;
  selectedWorkRegime: string;
  @ViewChild('validationParagraph') validationParagraph!: ElementRef;

  form!: FormGroup;

  formArray!: FormArray;

  destroy$ = new Subject<void>();

  weekOneForm!: FormGroup;
  weekTwoForm!: FormGroup;
  weekThreeForm!: FormGroup;
  weekFourForm!: FormGroup;

  workRegimeTypes$ = this.workRegimeFacade.workRegimeTypes$;
  workRegimeTypes: WorkRegimeType[] = [];

  days$ = this.workRegimeFacade.days$;
  days: Day[] = [];

  saveDisabled$ = new BehaviorSubject<boolean>(true);

  actions: ContextMenuAction<unknown>[] = [];
  iconActions: ContextMenuAction<unknown>[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      user: GroupUser;
      regime: WorkRegimeDay[];
      amountOfWorkDays: number;
      selectedWorkRegime: string;
    },
    private formBuilder: FormBuilder,
    private workRegimeFacade: WorkRegimeFacade,
    private readonly translations: TranslationService,
    public dialogRef: MatDialogRef<WorkRegimeSettingsComponent>
  ) {
    this.user = data.user;
    this.regime = data.regime;
    this.amountOfWorkDays = data.amountOfWorkDays;
    this.selectedWorkRegime = data.selectedWorkRegime;
  }

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  ngOnInit(): void {
    this.workRegimeTypes$.pipe(takeUntil(this.destroy$)).subscribe((items) => {
      this.workRegimeTypes = items || [];
    });
    this.workRegimeFacade.getWorkRegimeType$().subscribe();

    this.days$.pipe(takeUntil(this.destroy$)).subscribe((items) => {
      this.days = items || [];
    });
    this.workRegimeFacade.getDay$().subscribe();

    this.weekOneForm = this.formBuilder.group({
      Monday0: new FormControl('-'),
      Tuesday0: new FormControl('-'),
      Wednesday0: new FormControl('-'),
      Thursday0: new FormControl('-'),
      Friday0: new FormControl('-'),
      Saturday0: new FormControl('-'),
      Sunday0: new FormControl('-'),
    });

    this.weekTwoForm = this.formBuilder.group({
      Monday1: new FormControl('-'),
      Tuesday1: new FormControl('-'),
      Wednesday1: new FormControl('-'),
      Thursday1: new FormControl('-'),
      Friday1: new FormControl('-'),
      Saturday1: new FormControl('-'),
      Sunday1: new FormControl('-'),
    });

    this.weekThreeForm = this.formBuilder.group({
      Monday2: new FormControl('-'),
      Tuesday2: new FormControl('-'),
      Wednesday2: new FormControl('-'),
      Thursday2: new FormControl('-'),
      Friday2: new FormControl('-'),
      Saturday2: new FormControl('-'),
      Sunday2: new FormControl('-'),
    });

    this.weekFourForm = this.formBuilder.group({
      Monday3: new FormControl('-'),
      Tuesday3: new FormControl('-'),
      Wednesday3: new FormControl('-'),
      Thursday3: new FormControl('-'),
      Friday3: new FormControl('-'),
      Saturday3: new FormControl('-'),
      Sunday3: new FormControl('-'),
    });

    this.formArray = new FormArray([
      this.weekOneForm,
      this.weekTwoForm,
      this.weekThreeForm,
      this.weekFourForm,
    ]);

    this.form = this.formBuilder.group({
      weeks: this.formArray,
    });

    this.regime.forEach((e) => {
      switch (e.day.name) {
        case 'Monday':
          switch (e.week) {
            case 0:
              this.weekOneForm.controls.Monday0.setValue(e.workRegimeType.name);
              break;
            case 1:
              this.weekTwoForm.controls.Monday1.setValue(e.workRegimeType.name);
              break;
            case 2:
              this.weekThreeForm.controls.Monday2.setValue(
                e.workRegimeType.name
              );
              break;
            case 3:
              this.weekFourForm.controls.Monday3.setValue(
                e.workRegimeType.name
              );
              break;
            default:
              break;
          }
          break;
        case 'Tuesday':
          switch (e.week) {
            case 0:
              this.weekOneForm.controls.Tuesday0.setValue(
                e.workRegimeType.name
              );
              break;
            case 1:
              this.weekTwoForm.controls.Tuesday1.setValue(
                e.workRegimeType.name
              );
              break;
            case 2:
              this.weekThreeForm.controls.Tuesday2.setValue(
                e.workRegimeType.name
              );
              break;
            case 3:
              this.weekFourForm.controls.Tuesday3.setValue(
                e.workRegimeType.name
              );
              break;
            default:
              break;
          }
          break;
        case 'Wednesday':
          switch (e.week) {
            case 0:
              this.weekOneForm.controls.Wednesday0.setValue(
                e.workRegimeType.name
              );
              break;
            case 1:
              this.weekTwoForm.controls.Wednesday1.setValue(
                e.workRegimeType.name
              );
              break;
            case 2:
              this.weekThreeForm.controls.Wednesday2.setValue(
                e.workRegimeType.name
              );
              break;
            case 3:
              this.weekFourForm.controls.Wednesday3.setValue(
                e.workRegimeType.name
              );
              break;
            default:
              break;
          }
          break;
        case 'Thursday':
          switch (e.week) {
            case 0:
              this.weekOneForm.controls.Thursday0.setValue(
                e.workRegimeType.name
              );
              break;
            case 1:
              this.weekTwoForm.controls.Thursday1.setValue(
                e.workRegimeType.name
              );
              break;
            case 2:
              this.weekThreeForm.controls.Thursday2.setValue(
                e.workRegimeType.name
              );
              break;
            case 3:
              this.weekFourForm.controls.Thursday3.setValue(
                e.workRegimeType.name
              );
              break;
            default:
              break;
          }
          break;
        case 'Friday':
          switch (e.week) {
            case 0:
              this.weekOneForm.controls.Friday0.setValue(e.workRegimeType.name);
              break;
            case 1:
              this.weekTwoForm.controls.Friday1.setValue(e.workRegimeType.name);
              break;
            case 2:
              this.weekThreeForm.controls.Friday2.setValue(
                e.workRegimeType.name
              );
              break;
            case 3:
              this.weekFourForm.controls.Friday3.setValue(
                e.workRegimeType.name
              );
              break;
            default:
              break;
          }
          break;
        case 'Saturday':
          switch (e.week) {
            case 0:
              this.weekOneForm.controls.Saturday0.setValue(
                e.workRegimeType.name
              );
              break;
            case 1:
              this.weekTwoForm.controls.Saturday1.setValue(
                e.workRegimeType.name
              );
              break;
            case 2:
              this.weekThreeForm.controls.Saturday2.setValue(
                e.workRegimeType.name
              );
              break;
            case 3:
              this.weekFourForm.controls.Saturday3.setValue(
                e.workRegimeType.name
              );
              break;
            default:
              break;
          }
          break;
        case 'Sunday':
          switch (e.week) {
            case 0:
              this.weekOneForm.controls.Sunday0.setValue(e.workRegimeType.name);
              break;
            case 1:
              this.weekTwoForm.controls.Sunday1.setValue(e.workRegimeType.name);
              break;
            case 2:
              this.weekThreeForm.controls.Sunday2.setValue(
                e.workRegimeType.name
              );
              break;
            case 3:
              this.weekFourForm.controls.Sunday3.setValue(
                e.workRegimeType.name
              );
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    });
    this.form.valueChanges.subscribe(() => {
      this.getDayCounter();
    });

    this.actions.push(
      new ContextMenuAction({
        label: 'save',
        icon: 'save',
        iconOutline: true,
        isSymbol: true,
        disabled: this.saveDisabled$.asObservable(),
        action: () => this.save(),
      })
    );

    this.iconActions.push(
      new ContextMenuAction({
        label: 'close',
        icon: 'close',
        iconOutline: true,
        isSymbol: true,
        action: () => this.dialogRef.close(),
      })
    );
  }

  getDayCounter() {
    let selectedDayCount = 0;

    for (let i = 0; i < this.formArray.length; i++) {
      const weekForm = this.formArray.at(i) as FormGroup;

      for (const day of [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ]) {
        const dayControl = weekForm.get(day + i) as FormControl;
        const selectedValue = dayControl.value as string;

        if (selectedValue === 'Morning' || selectedValue === 'Afternoon') {
          selectedDayCount += 0.5;
        } else if (selectedValue === 'Complete') {
          selectedDayCount++;
        }
      }
    }

    if (
      selectedDayCount / (this.amountOfWorkDays * 4) !==
      this.fractionStringToDecimal(this.selectedWorkRegime)
    ) {
      let validationString = '';
      this.saveDisabled$.next(true);
      if (this.selectedWorkRegime === 'halfTime') {
        validationString =
          'The selected work regime(Halftime) does not match the selected days.';
      } else if (this.selectedWorkRegime === 'fulltime') {
        validationString =
          'The selected work regime(Fulltime) does not match the selected days.';
      } else {
        validationString =
          'The selected work regime(' +
          this.selectedWorkRegime +
          ') does not match the selected days.';
      }
      this.validationParagraph.nativeElement.textContent = validationString;
    } else {
      this.saveDisabled$.next(false);
      this.validationParagraph.nativeElement.textContent = '';
    }
  }

  fractionStringToDecimal(fractionString: string) {
    if (fractionString === 'halfTime') return 0.5;
    if (fractionString === 'fullTime') return 1;
    const [numerator, denominator] = fractionString.split('/').map(Number);
    return numerator / denominator;
  }

  save() {
    const formData = this.form.value;
    const workRegimeDays: WorkRegimeDay[] = [];
    const workRegimeDaysToUpdate: WorkRegimeDay[] = [];

    formData.weeks.forEach((week: any, weekIndex: number) => {
      Object.keys(week).forEach((day) => {
        const dayName = day.substr(0, day.length - 1);
        const workRegimeType: string = week[day] || '-';

        const workRegimeDay = new WorkRegimeDay({
          workRegimeType: {
            id:
              this.workRegimeTypes.find((w) => w.name == workRegimeType)?.id ||
              '',
            name: workRegimeType,
          },
          week: weekIndex,
          day: {
            id: this.days.find((d) => d.name == dayName)?.id || '',
            name: this.days.find((d) => d.name == dayName)?.name || '',
          },
        });
        workRegimeDays.push(workRegimeDay);
      });
    });

    const workRegime = new WorkRegime({
      user: this.user,
      capacity: 0,
    });

    if (this.regime.length > 0) {
      workRegime.id = this.regime[0]?.workRegime?.id || '';
      this.workRegimeFacade.updateWorkRegimes([workRegime]).subscribe();

      this.regime.forEach((r) => {
        workRegimeDays.forEach((w) => {
          w.workRegime = {
            id: this.regime[0]?.workRegime?.id || '',
            user: this.user,
            capacity: this.regime[0]?.workRegime?.capacity || 0,
          };

          if (r.week == w.week && r.day.name == w.day.name) {
            w.id = r.id;
            workRegimeDaysToUpdate.push(w);
            workRegimeDays.splice(workRegimeDays.indexOf(w), 1);
          }
        });
      });
      this.workRegimeFacade
        .updateWorkRegimeDays(workRegimeDaysToUpdate)
        .subscribe();
      this.workRegimeFacade.addWorkRegimeDays(workRegimeDays).subscribe;
    } else {
      this.workRegimeFacade
        .addWorkRegimes([workRegime])
        .pipe(
          switchMap((workRegimes) => {
            workRegimeDays.forEach((w) => {
              w.workRegime = {
                id: workRegimes[0]?.id || '',
                user: this.user,
                capacity: 0,
              };
            });
            return forkJoin([
              this.workRegimeFacade.updateWorkRegimeDays(
                workRegimeDaysToUpdate
              ),
              this.workRegimeFacade.addWorkRegimeDays(workRegimeDays),
            ]);
          })
        )
        .subscribe();
      this.dialogRef.close(workRegimeDays);
    }
  }

  // private calculateCapacity(week: any): number {
  //   let totalCapacity = 0;
  //   const capacityMapping: { [key: string]: number } = {
  //     'Morning': 4,
  //     'Afternoon': 4,
  //     '-': 0,
  //     'Complete': 8
  //   };

  //   Object.keys(week).forEach(day => {
  //     totalCapacity += capacityMapping[week[day]] || 0;
  //   });

  //   return totalCapacity;
  // }

  getGroupControls(formgroup: AbstractControl): FormGroup {
    return formgroup as FormGroup;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
