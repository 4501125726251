import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { GraphService } from '../../services/graph.service';
import { Ishtar365Service } from '../../services/ishtar365.service';
import {
  getLicensesForUser,
  getLicensesForUserResolved,
  getUserInfo,
  getUserSettings,
  getUserSettingsResolved,
  updateUserLanguage,
  updateUserLastTenantId,
  updateUserResolved,
  updateUserSettings,
  updateUserSettingsResolved,
} from './user.actions';
import { UserFacade } from './user.facade';

@Injectable({ providedIn: 'root' })
export class UserEffects {
  constructor(
    private actions$: Actions,
    private ishtar365: Ishtar365Service,
    private graph: GraphService,
    private userFacade: UserFacade
  ) {}

  getUserInfo = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserInfo),
      switchMap(({ email, name, callback }) =>
        this.ishtar365.getUser(email, name).pipe(
          tap((userInfo) => (callback ? callback(userInfo) : undefined)),
          switchMap((userInfo) => [updateUserResolved({ userInfo })]),
          catchError(() => [])
        )
      )
    )
  );

  getLicensesForUser = createEffect(() =>
    this.actions$.pipe(
      ofType(getLicensesForUser),
      switchMap(({ resetCache, name, callback }) =>
        this.ishtar365.getLicensesForUser(name, resetCache).pipe(
          switchMap((licenses) => [getLicensesForUserResolved({ licenses })]),
          tap(() => (callback ? callback() : undefined)),
          catchError(() => [])
        )
      )
    )
  );

  updateUserLanguage = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserLanguage),
      switchMap(({ lang, callback }) =>
        this.ishtar365.updateLanguage(lang).pipe(
          switchMap((userInfo) => of(updateUserResolved({ userInfo }))),
          tap(() => (callback ? callback() : undefined)),
          catchError(() => [])
        )
      )
    )
  );

  updateUserLastTenantId = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserLastTenantId),
      switchMap(({ tenantId, callback }) =>
        this.ishtar365.updateLastTenantId(tenantId).pipe(
          switchMap((userInfo) => of(updateUserResolved({ userInfo }))),
          tap(() => (callback ? callback() : undefined)),
          catchError(() => [])
        )
      )
    )
  );

  getUserSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSettings),
      switchMap(({ callback }) =>
        this.ishtar365.getUserSettings().pipe(
          switchMap((settings) => [getUserSettingsResolved({ settings })]),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  updateUserSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserSettings),
      withLatestFrom(this.userFacade.userInfo$),
      switchMap(([{ settings, callback }, userInfo]) =>
        userInfo
          ? this.ishtar365.updateUserSettings(settings).pipe(
              switchMap((settings) =>
                of(updateUserSettingsResolved({ settings }))
              ),
              tap(() => (callback ? callback() : undefined))
            )
          : of()
      )
    )
  );
}
