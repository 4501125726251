import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { TilePage } from '../../domain/models/tile-page.model';
import { BaseFacade } from '../base.facade';
import { getTilePages, resetSlice, updateTilePages } from './tilepage.actions';
import { tilePageSelectors } from './tilepage.selectors';

@Injectable({
  providedIn: 'root',
})
export class TilePageFacade extends BaseFacade {
  tilePages$ = this._store.pipe(select(tilePageSelectors.getTilePages));
  segments$ = this._store.pipe(select(tilePageSelectors.getSegments));
  homepage$ = this._store.pipe(select(tilePageSelectors.getHomePage));

  tilePagesForSegmentFunc$ = this._store.pipe(
    select(tilePageSelectors.getTilePagesForSegmentFunc)
  );
  segmentsForTileFunc$ = this._store.pipe(
    select(tilePageSelectors.getSegmentsForTileFunc)
  );
  getTilePageByIdFunc$ = this._store.pipe(
    select(tilePageSelectors.getTilePageByIdFunc)
  );

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getTilePages(callback?: (pages: TilePage[]) => void) {
    this._store.dispatch(getTilePages({ callback }));
  }
  getTilePages$() {
    return this.dispatchAction$(getTilePages);
  }
  updateTilePages$(pages: TilePage[]) {
    return this.dispatchAction$(updateTilePages, { pages });
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
