import { ConfiguredParam } from './configuredParam.model'


export class Library{
    
    libraryTriggerTemplateId: any;
    title!: string;
    sharepointUrl!: string;
    isApp!: boolean;
    configuredParams!: ConfiguredParam[];
    id!: string;

    constructor(obj: Partial<Library>) {
        Object.assign(this, obj);
    }
  }
  
