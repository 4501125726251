import {
  CdkVirtualScrollViewport,
  ScrollingModule,
} from '@angular/cdk/scrolling';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  debounceTime,
  filter,
  first,
  of,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { Ishtar365Service } from 'src/app/core/services/ishtar365.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { CoreModule } from 'src/app/core/core.module';
import { GraphUser } from 'src/app/core/domain/models/graph-user.model';
import { IshtarApp } from 'src/app/core/domain/models/ishtar-app.model';
import { IshtarLicense } from 'src/app/core/domain/models/ishtar-license.model';
import { IshtarSubscription } from 'src/app/core/domain/models/ishtar-subscription.model';
import { AppSubscriptionFacade } from 'src/app/core/store/app-subscription/app-subscription.facade';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { MicrosoftAuthenticationService } from 'src/app/msal/injectables/microsoft-authentication.service';

@Component({
  selector: 'app-subscription-license-settings',
  standalone: true,
  imports: [
    CoreModule,
    MatCardModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    MatExpansionModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatSnackBarModule,
  ],
  templateUrl: './subscription-license-settings.component.html',
  styleUrls: ['./subscription-license-settings.component.scss'],
})
export class SubscriptionLicenseSettingsComponent implements OnInit, OnDestroy {
  app?: IshtarApp;

  get isIshtar365() {
    return this.app?.name == 'Ishtar.365';
  }

  subscription?: IshtarSubscription;
  licenses: IshtarLicense[] = [];
  filteredLicenses: IshtarLicense[] = [];

  licensesLoaded = false;

  get orderedLicenses() {
    return [...this.licenses].sort((l1, l2) =>
      l1.userInfo.name?.localeCompare(l2.userInfo.name ?? '')
    );
  }

  userSearchControl = new FormControl<string>('');
  foundUsers: GraphUser[] = [];
  activeGraphUser?: GraphUser;

  userIsOrgAdminFunc: (email: string) => boolean = () => false;
  trackByFn = (index: number, item: IshtarLicense) => index;

  destroy$ = new Subject<void>();
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewport?: CdkVirtualScrollViewport;

  filterSearchControl = new FormControl<string>('');
  filterSubject = new BehaviorSubject<string>('');

  constructor(
    public readonly msal: MicrosoftAuthenticationService,
    private readonly appSubscriptionFacade: AppSubscriptionFacade,
    private readonly userLicenseFacade: UserFacade,
    private readonly organizationFacade: OrganizationFacade,
    private readonly ishtar365: Ishtar365Service,
    private readonly route: ActivatedRoute,
    private readonly translations: TranslationService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.organizationFacade.getUserIsOrganizationAdminFunc$
      .pipe(takeUntil(this.destroy$))
      .subscribe((fn) => (this.userIsOrgAdminFunc = fn));
    this.route.paramMap
      .pipe(
        takeUntil(this.destroy$),
        switchMap((params) =>
          params.has('appName')
            ? this.appSubscriptionFacade.activeApp$.pipe(
                first(),
                tap(() => this.filterSearchControl.setValue(''))
              )
            : of(undefined)
        ),
        tap((app) => (this.app = app)),
        filter((app) => !!app),
        withLatestFrom(
          combineLatest([
            this.appSubscriptionFacade.getAppLicensesFunc$,
            this.appSubscriptionFacade.getSubscriptionForAppFunc$,
          ])
        ),
        switchMap(([app, [licensesFunc, subscriptionsFunc]]) => {
          this.licenses = [];
          this.subscription = new IshtarSubscription(subscriptionsFunc(app!));
          const licenses = [...licensesFunc(app!)];
          this.licensesLoaded = !!licenses.length;
          if (this.licensesLoaded) return of(licenses);
          else
            return this.appSubscriptionFacade.getLicensesForApp$(
              false,
              app!.name
            );
        })
      )
      .subscribe((licenses) => {
        this.activeGraphUser = undefined;
        this.licenses = [...licenses];
        this.filteredLicenses = [...licenses];
        this.licensesLoaded = true;
      });
    this.userSearchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        tap((t) => (!t ? (this.foundUsers = []) : undefined)),
        filter((t) => !!t),
        switchMap((t) => this.ishtar365.getGraphUsers(t!))
      )
      .subscribe(
        (users) =>
          (this.foundUsers = users.filter(
            (u) =>
              u.mail &&
              !this.licenses.some(
                (l) => l.userInfo.email.toLowerCase() == u.mail.toLowerCase()
              )
          ))
      );

    this.filterSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (filter) =>
          (this.filteredLicenses = this.licenses.filter(
            (l) =>
              l.userInfo.name?.toLowerCase().includes(filter.toLowerCase()) ||
              l.userInfo.email.toLowerCase().includes(filter.toLowerCase())
          ))
      );
    this.filterSearchControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((filter) => {
        this.filterSubject.next(filter ?? '');
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  getTranslation(label: string) {
    return this.translations.getTranslation(label);
  }

  setActiveGraphUser(user: GraphUser) {
    this.activeGraphUser = user;
  }

  assignLicense() {
    if (this.subscription && this.licenses.length >= this.subscription.plan) {
      this._snackBar
        .open(this.getTranslation('noLicensesLeft'), 'X', {
          panelClass: 'app-notification-error',
        })
        ._dismissAfter(5000);
    } else if (this.app && this.activeGraphUser) {
      this.appSubscriptionFacade
        .addLicense(
          this.app.name,
          this.activeGraphUser.mail,
          this.activeGraphUser.displayName,
          false
        )
        .pipe(
          tap((license) => {
            this.licenses = [...this.licenses, license];
            this.activeGraphUser = undefined;
            this.updateFilteredLicenses();

            if (this.app?.name == 'Ishtar.365') {
              this.organizationFacade.getGroupUsers();
            }
          })
        )
        .subscribe(() => {
          this.userLicenseFacade
            .getUserLicenses$(this.msal.name, true)
            .subscribe();
          this.appSubscriptionFacade
            .getLicensesForApp$(true, this.app!.name)
            .subscribe();
        });
    }
  }

  removeLicense(license: IshtarLicense, accordion: MatAccordion) {
    if (this.app) {
      this.appSubscriptionFacade
        .removeLicense(this.app.name, license.userInfo.email)
        .subscribe(() => {
          this.licenses = this.licenses.filter(
            (l) => l.userInfo.userId !== license.userInfo.userId
          );

          this.appSubscriptionFacade
            .getLicensesForApp$(true, this.app!.name)
            .subscribe();
          this.userLicenseFacade
            .getUserLicenses$(this.msal.name, true)
            .subscribe();

          if (this.app?.name == 'Ishtar.365')
            setTimeout(() => {
              this.organizationFacade.getGroupUsers();
            }, 0);

          this.updateFilteredLicenses();
          accordion.closeAll();
        });
    }
  }

  makeAdmin(license: IshtarLicense) {
    if (this.app) {
      this.appSubscriptionFacade
        .updateLicense(this.app.name, license.userInfo.email, true)
        .subscribe(() => {
          this.licenses = this.licenses.map((l) =>
            l.userInfo.userId === license.userInfo.userId
              ? new IshtarLicense({ ...license, isAdmin: true })
              : l
          );
          this.updateFilteredLicenses();
        });
    }
  }

  removeAdmin(license: IshtarLicense) {
    if (this.app) {
      this.appSubscriptionFacade
        .updateLicense(this.app.name, license.userInfo.email, false)
        .subscribe(() => {
          this.licenses = this.licenses.map((l) =>
            l.userInfo.userId === license.userInfo.userId
              ? new IshtarLicense({ ...license, isAdmin: false })
              : l
          );
          this.updateFilteredLicenses();
        });
    }
  }

  updateFilteredLicenses() {
    const filter = this.filterSubject.value.toLowerCase();
    this.filteredLicenses = this.licenses.filter(
      (license) =>
        license.userInfo.name?.toLowerCase().includes(filter) ||
        license.userInfo.email.toLowerCase().includes(filter)
    );
  }

  focusInput(input: HTMLInputElement) {
    setTimeout(() => input.focus(), 0);
  }
}
