import { AADUser } from 'processdelight-angular-components';
import { GraphGroup } from './graph-group.model';

export class PermanenceAppConfig {
  departmentDelimiter?: string;
  typeOfSync?: 'users' | 'groups';
  userParameters?: {
    aADValue: string;
    ishtarValue: string;
    valueType: string;
    isCustomSecurityAttribute: boolean;
    isExtensionAttribute: boolean;
  }[];
  groupParameters?: {
    parentGroup?: GraphGroup;
    departmentGroups?: { departmentName: string; childGroups: string[] }[];
  };
  departmentParameters?: {
    name: string;
    supervisor: AADUser;
    alias: string[];
    departmentTelNum: string;
  }[];
  departmentUserConfig?: {
    departmentMapping: {
      basedOnField?: string; // JobTitle
      nameValue: string; // Afdeling
      isCustomSecurityAttribute: boolean;
      isExtensionAttribute: boolean;
    };
    departmentNumberMapping: {
      basedOnField?: string; // IP Phone
      isCustomSecurityAttribute: boolean;
      isExtensionAttribute: boolean;
    };
    departmentNameMapping: {
      basedOnField?: string;
      isCustomSecurityAttribute: boolean;
      isExtensionAttribute: boolean;
    };
  };
  departmentWildCard?: {
    basedOnField?: string;
    nameValue: string;
    isCustomSecurityAttribute: boolean;
    isExtensionAttribute: boolean;
  };
  adminConfig?: {
    basedOnField?: string;
    nameValue: string;
    isCustomSecurityAttribute: boolean;
    isExtensionAttribute: boolean;
  };

  roleMapping?: {
    roles?: { role: string; isSupervisor: boolean }[];
    basedOnField?: string;
    isCustomSecurityAttribute: boolean;
    isExtensionAttribute: boolean;
  };
  intervalParameters?: {
    interval: string;
    startTime: string;
    day: number;
  };

  groupsDepartmentConfig?: {
    departments: TreeDepartmentConfig[];
  };

  groupsAdminADGroups?: { id: string; name: string }[];

  constructor(obj: Partial<PermanenceAppConfig>) {
    Object.assign(this, obj);
  }
}

export class TreeDepartmentConfig {
  id!: string;
  departmentName!: string;
  supervisor?: string;
  parentGroup?: string;
  groupIds!: GraphGroup[];
  children: TreeDepartmentConfig[] = [];
}
