import { createAction, props } from '@ngrx/store';
import { IshtarOrganizationAdmin } from '../../domain/models/ishtar-organization-admin.model';
import { IshtarOrganization } from '../../domain/models/ishtar-organization.model';
import { GroupUser } from 'processdelight-angular-components';
import { ActionBase } from '../action-base.interface';

export const getOrganizationInfo = createAction(
  '[Organization] Get organization info',
  props<{ tenantId: string } & ActionBase<IshtarOrganization>>()
);
export const getOrganizationInfoResolved = createAction(
  '[Organization] Get organization info resolved',
  props<{ organizationInfo?: IshtarOrganization }>()
);
export const getOrganizationAdmins = createAction(
  '[Organization] Get organization admins',
  props<ActionBase<IshtarOrganizationAdmin[]>>()
);
export const getOrganizationAdminsResolved = createAction(
  '[Organization] Get organization admins resolved',
  props<{ admins: IshtarOrganizationAdmin[] }>()
);
export const updateOrganizationPersonalization = createAction(
  '[Organization] Update organization personalization',
  props<
    {
      logo: string;
      navColor: string;
      navContrast: string;
    } & ActionBase<IshtarOrganization>
  >()
);
export const updateOrganizationPersonalizationResolved = createAction(
  '[Organization] Update organization personalization resolved',
  props<{
    organizationInfo: IshtarOrganization;
  }>()
);
export const updateTempNavColor = createAction(
  '[Organization] Update organization temp nav color',
  props<{ navColor?: string }>()
);
export const updateTempNavContrast = createAction(
  '[Organization] Update organization temp nav contrast',
  props<{ navContrast?: string }>()
);
export const updateTempLogo = createAction(
  '[Organization] Update organization temp logo',
  props<{ logo?: string }>()
);
export const resetTempProperties = createAction(
  '[Organization] Reset temp properties'
);

export const getGroupUsers = createAction(
  '[Organization] Get groups and users',
  props<ActionBase<GroupUser[]>>()
);
export const getUsers = createAction(
  '[Organization] Get groups',
  props<ActionBase<GroupUser[]>>()
);
export const getGroups = createAction(
  '[Organization] Get users',
  props<ActionBase<GroupUser[]>>()
);
export const getGroupUsersResolved = createAction(
  '[Organization] Get groups and users resolved',
  props<{ groupUsers: GroupUser[] }>()
);
export const getUsersResolved = createAction(
  '[Organization] Get users resolved',
  props<{ users: GroupUser[] }>()
);
export const getGroupsResolved = createAction(
  '[Organization] Get groups resolved',
  props<{ groups: GroupUser[] }>()
);
export const resetSlice = createAction('[Organization] Reset slice');
