<h1 mat-dialog-title>
  <ng-container
    *ngIf="getTranslation$('selectAppImport') | async as selectAppImportLabel"
    >{{ selectAppImportLabel }}
  </ng-container>
</h1>
<div mat-dialog-content>
  <mat-form-field appearance="outline" [hideRequiredMarker]="true" class="mt-1">
    <mat-label
      ><ng-container
        *ngIf="getTranslation$('selectApp') | async as selectAppLabel"
        >{{ selectAppLabel }}
      </ng-container></mat-label
    >
    <mat-select
      cdkFocusInitial
      [formControl]="appControl"
      panelClass="scrollbar"
    >
      <mat-option *ngFor="let app of apps$ | async" [value]="app">
        {{ app.name }}!
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox [formControl]="overwriteControl" *ngIf="canOverwrite">
    <ng-container
      *ngIf="
        getTranslation$('overwriteCurrentGroups')
          | async as overwriteCurrentGroupsLabel
      "
      >{{ overwriteCurrentGroupsLabel }}
    </ng-container>
  </mat-checkbox>
</div>
<div mat-dialog-actions class="justify-content-start">
  <button
    mat-button
    mat-dialog-close
    color="primary"
    [disabled]="this.appControl.invalid"
    (click)="onImport()"
    class="ms-3"
  >
    <ng-container *ngIf="getTranslation$('import') | async as importLabel"
      >{{ importLabel }}
    </ng-container>
  </button>
  <button mat-button mat-dialog-close color="primary">
    <ng-container *ngIf="getTranslation$('cancel') | async as cancelLabel"
      >{{ cancelLabel }}
    </ng-container>
  </button>
</div>
