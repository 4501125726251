<div class="personalization-settings-wrapper">
  <mat-card>
    <mat-card-header>
      <mat-card-title
        *ngIf="
          getTranslation$('personalization') | async as personalizationLabel
        "
      >
        {{ personalizationLabel }}</mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <div class="d-flex flex-column">
        <div
          class="border-dashed border-gray rounded upload-zone m-2 d-flex flex-column align-items-center position-relative"
          (dragover)="dragStart($event)"
        >
          <div
            [hidden]="!dragging"
            class="position-absolute w-100 h-100 rounded drop-zone"
            (drop)="imageDropped($event)"
            (dragleave)="dragStop($event)"
          ></div>
          <input
            #iconInput
            type="file"
            hidden
            accept="image/*"
            (change)="onIconInputChange($event)"
          />
          <mat-icon class="material-icons-outlined upload-icon"
            >cloud_upload</mat-icon
          >
          <div class="d-flex flex-column flex-nowrap align-items-center m-2">
            <div
              *ngIf="
                getTranslation$('dragAndDropYourFile') | async as dragAndDropLabel
              "
            >
              {{ dragAndDropLabel }}
            </div>
            <div
              *ngIf="
                getTranslation$('OR') | async as orLabel
              "
            >
              {{ orLabel.toLowerCase() }}
            </div>
            <a
              mat-raised-button
              [ngStyle]="{'background-color': getThemeColor(), 'color': getAccentColor()}"
              *ngIf="getTranslation$('browse') | async as browseLabel"
              (click)="iconInput.click()"
            >
              {{ browseLabel }}
            </a>
          </div>

        </div>
        <ng-container *ngIf="logoUrl">
          <div class="d-flex flex-row align-items-start">
            <img class="icon m-2" [src]="transform(logoUrl)" alt="Tile icon" />
            <button
              mat-icon-button
              (click)="clearIconInput()"
              class="clear-icon"
            >
              <mat-icon class="material-icons-outlined">cancel</mat-icon>
            </button>
          </div>
        </ng-container>
        <div
          class="d-flex flex-column m-2"
          *ngIf="{
            accept: getTranslation$('accept') | async,
            cancel: getTranslation$('cancel') | async,
            colors: getTranslation$('colors') | async
          } as labels"
        >
          <div class="nav-colors-header mb-1">{{ labels.colors }}</div>
          <div class="d-flex flex-row mb-2">
            <ngx-colors
              ngx-colors-trigger
              [acceptLabel]="labels.accept ?? 'Accept'"
              [cancelLabel]="labels.cancel ?? 'Cancel'"
              colorPickerControls="no-alpha"
              [formControl]="themeColorControl"
            ></ngx-colors>
            <div
              class="ms-2 d-flex justify-content-center align-items-center"
              *ngIf="getTranslation$('theme') | async as themeLabel"
            >
              {{ themeLabel }}
            </div>
          </div>
          <div class="d-flex flex-row">
            <ngx-colors
              ngx-colors-trigger
              [acceptLabel]="labels.accept ?? 'Accept'"
              [cancelLabel]="labels.cancel ?? 'Cancel'"
              colorPickerControls="no-alpha"
              [hideColorPicker]="true"
              [hideTextInput]="true"
              [palette]="['#fff', '#000']"
              [formControl]="accentColorControl"
            ></ngx-colors>
            <div
              class="ms-2 d-flex justify-content-center align-items-center"
              *ngIf="getTranslation$('accent') | async as accentLabel"
            >
              {{ accentLabel }}
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
