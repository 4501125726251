import { createAction, props } from '@ngrx/store';
import { ActionBase } from '../action-base.interface';
import { FormTemplate } from '../../domain/models/form-template.model';

export const getFormTemplates = createAction(
  '[FormTemplate] Get form template',
  props<ActionBase<FormTemplate[]>>()
);
export const getFormTemplatesResolved = createAction(
  '[FormTemplate] Get form template resolved',
  props<{
    formTemplates: FormTemplate[];
  }>()
);