import { Warehouse } from './warehouse.model';

export class WarehouseLocation {
  id!: number;
  warehouseId!: number;
  warehouse?: Warehouse;
  description!: string;

  constructor(data: Partial<WarehouseLocation>) {
    Object.assign(this, data);
    if (data.warehouse) this.warehouse = new Warehouse(data.warehouse);
  }
}
