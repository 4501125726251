<svg
  *ngIf="path"
  class="line-connector"
  [id]="id"
  [style.left]="leftStyle"
  [style.top]="topStyle"
  [attr.viewbox]="viewbox"
  [attr.width]="width"
  [attr.height]="height"
>
  <path
    [attr.d]="path"
    [attr.stroke-width]="size"
    [attr.stroke]="color"
    fill="transparent"
  ></path>
</svg>
