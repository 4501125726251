import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Ishtar365Service } from '../../services/ishtar365.service';
import {
  getTilePages,
  getTilePagesResolved,
  updateTilePages,
} from './tilepage.actions';

@Injectable({ providedIn: 'root' })
export class TilePageEffects {
  constructor(private actions$: Actions, private ishtar365: Ishtar365Service) {}

  getTilePages = createEffect(() =>
    this.actions$.pipe(
      ofType(getTilePages),
      switchMap(({ callback, error }) =>
        this.ishtar365.getTilePages().pipe(
          tap((pages) => (callback ? callback(pages) : undefined)),
          switchMap((pages) => of(getTilePagesResolved({ pages }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
  updateTilePages = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTilePages),
      mergeMap(({ pages, callback, error }) =>
        this.ishtar365.updateTilePages(pages).pipe(
          tap((page) => (callback ? callback(page) : undefined)),
          mergeMap((pages) => of(getTilePagesResolved({ pages }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
