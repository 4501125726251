import { BaseEntity } from 'processdelight-angular-components';
import { TilePageSegmentTile } from './tile-page-segment-tile.model';

export class TilePageSegment extends BaseEntity {
  title!: string;
  disabled?: boolean;
  hideTitle?: boolean;
  position!: number;
  tiles: TilePageSegmentTile[] = [];

  constructor(obj: Partial<TilePageSegment>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.tiles)
      this.tiles = obj.tiles.map((t) => new TilePageSegmentTile(t));
  }
}
