import {
  CdkDragEnd,
  CdkDragEnter,
  CdkDragMove,
  CdkDragStart,
  DragDropModule,
  DragRef,
  Point,
} from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import {
  debounceTime,
  filter,
  first,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { TranslationService } from 'src/app/core/services/translation.service';
import { CoreModule } from 'src/app/core/core.module';
import { GraphUser } from 'src/app/core/domain/models/graph-user.model';
import { IshtarApp } from 'src/app/core/domain/models/ishtar-app.model';
import { IshtarOrganigramGroup } from 'src/app/core/domain/models/ishtar-organigram-group..model';
import { IshtarUser } from 'src/app/core/domain/models/ishtar-user.model';
import { AppSubscriptionFacade } from 'src/app/core/store/app-subscription/app-subscription.facade';
import { GroupFacade } from 'src/app/core/store/group/group.facade';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import {
  LineConnectorComponent,
  LineConnectorInputs,
} from 'src/app/shared/components/line-connector/line-connector.component';
import * as uuid from 'uuid';
import { OrganigramImportPopupComponent } from './organigram-import-popup/organigram-import-popup.component';
import {
  ContextMenuAction,
  LoaderService,
} from 'processdelight-angular-components';
import { Ishtar365ActionsService } from 'src/app/core/services/ishtar365-actions.service';
import { Ishtar365Service } from 'src/app/core/services/ishtar365.service';

class DraggableGroup {
  id: string = uuid.v4();
  name = '';
  parent?: DraggableGroup;
  members: (GraphUser | IshtarUser)[] = [];
  searchControl = new FormControl<string>('');
  foundUsers: GraphUser[] = [];
  parentLine?: LineConnectorInputs;
  draggingLine?: LineConnectorInputs;
  error?: string;

  initialPosition = { x: 0, y: 0 };

  get parentMembers(): (GraphUser | IshtarUser)[] {
    return this.parent
      ? this.parent.members.concat(this.parent.parentMembers)
      : [];
  }

  constructor(obj: Partial<DraggableGroup>) {
    Object.assign(this, obj);
  }
}

@Component({
  selector: 'app-organigram',
  standalone: true,
  imports: [
    CoreModule,
    DragDropModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatCardModule,
    MatListModule,
    ReactiveFormsModule,
    LineConnectorComponent,
    MatDialogModule,
  ],
  templateUrl: './organigram.component.html',
  styleUrls: ['./organigram.component.scss'],
})
export class OrganigramComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('panzoomContainer') panzoomContainer?: ElementRef;
  @ViewChild('lineContainer') lineContainer?: ElementRef;

  groups: DraggableGroup[] = [];
  lines: LineConnectorInputs[] = [];
  reloadLines$ = new Subject<string>();

  activeApp$ = this.appSubscriptionFacade.activeApp$;

  panzoom?: PanzoomObject;

  private _dragMovePointer?: Point;

  destroy$ = new Subject<void>();

  constructor(
    private readonly appSubscriptionFacade: AppSubscriptionFacade,
    private readonly organizationFacade: OrganizationFacade,
    private readonly ishtar365: Ishtar365Service,
    private readonly groupFacade: GroupFacade,
    private readonly loader: LoaderService,
    private readonly translations: TranslationService,
    private readonly ishtar365Actions: Ishtar365ActionsService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.translations.translations$
      .pipe(
        filter((t) => !!Object.keys(t).length),
        first()
      )
      .subscribe(() =>
        this.activeApp$.pipe(takeUntil(this.destroy$)).subscribe((app) => {
          const id = this.loader.startLoading(
            this.translations.getTranslation('loadingOrganigram')
          );
          this.ishtar365Actions.buttonActions = [
            new ContextMenuAction<unknown>({
              label: this.getTranslation$('newGroup'),
              action: () => this.addGroup(),
              icon: 'add',
            }),
            new ContextMenuAction<unknown>({
              label: this.getTranslation$('save'),
              action: () => this.submitOrganigram(),
              icon: 'save',
            }),
            new ContextMenuAction<unknown>({
              label: this.getTranslation$('import'),
              action: () => this.openImportDialog(),
              icon: 'download',
            }),
          ];
          this.panzoom?.reset();
          this.removeLines();
          this.groups = [];
          this._dragMovePointer = undefined;
          if (app) {
            this.groupFacade
              .retrieveOrganigram$(app.name)
              .subscribe((groups) => {
                this.groups = groups.map(
                  (g) =>
                    new DraggableGroup({
                      id: g.guid,
                      name: g.displayName,
                      members: [...g.members],
                      initialPosition: { x: g.xPos, y: g.yPos },
                    })
                );
                this.groups.forEach((group) =>
                  group.searchControl.valueChanges
                    .pipe(
                      takeUntil(this.destroy$),
                      takeUntil(
                        this._groupRemoval$.pipe(filter((id) => group.id == id))
                      ),
                      debounceTime(500),
                      tap((t) => (!t ? (group.foundUsers = []) : undefined)),
                      filter((t) => !!t),
                      switchMap((t) => this.ishtar365.getGraphUsers(t!))
                    )
                    .subscribe(
                      (users) =>
                        (group.foundUsers = users.filter(
                          (u) =>
                            !group.members
                              .concat(group.parentMembers)
                              .some((m) =>
                                m instanceof GraphUser
                                  ? m.id == u.id
                                  : m.email.toLowerCase() ==
                                    u.mail.toLowerCase()
                              )
                        ))
                    )
                );
                groups
                  .filter((g) => g.parentId)
                  .forEach((g) => {
                    const group = this.groups.find((x) => g.guid == x.id);
                    if (group) {
                      group.parent = this.groups.find(
                        (x) => g.parentId == x.id
                      );
                      if (group.parent)
                        group.parentLine = new LineConnectorInputs({
                          id: `from${group.id}to${group.parent.id}`,
                          parent: this.lineContainer?.nativeElement,
                          redraw$: this.reloadLines$,
                          color: 'rgba(0,0,0,0.5)',
                        });
                    }
                  });
                setTimeout(() => {
                  let minX = Number.MAX_SAFE_INTEGER;
                  let minY = Number.MAX_SAFE_INTEGER;
                  let maxX = Number.MIN_SAFE_INTEGER;
                  let maxY = Number.MIN_SAFE_INTEGER;
                  if (this.groups.length)
                    this.groups.forEach((g) => {
                      const elem = document.getElementById(g.id);
                      minX = Math.min(minX, g.initialPosition.x);
                      minY = Math.min(minY, g.initialPosition.y);
                      maxX = Math.max(
                        maxX,
                        g.initialPosition.x + (elem?.clientWidth ?? 0)
                      );
                      maxY = Math.max(
                        maxY,
                        g.initialPosition.y + (elem?.clientHeight ?? 0)
                      );
                    });
                  else {
                    minX = 0;
                    minY = 0;
                    maxX = 0;
                    maxY = 0;
                  }

                  const dimensions =
                    this.panzoomContainer?.nativeElement.parentElement?.getBoundingClientRect() as DOMRect;

                  const newDimensions = {
                    width: maxX - minX,
                    height: maxY - minY,
                  };
                  const scaleX = Math.max(
                    1,
                    (newDimensions.width + 20) / dimensions.width
                  );
                  const scaleY = Math.max(
                    1,
                    (newDimensions.height + 20) / dimensions.height
                  );
                  this.panzoom?.zoomToPoint(1 / Math.max(scaleX, scaleY), {
                    clientX: minX + newDimensions.width / 2,
                    clientY: minY + newDimensions.height / 2,
                  });
                  this.panzoom?.pan(-minX + 10, -minY + 10);
                  this.groups
                    .filter((g) => g.parentLine)
                    .forEach((g) => {
                      const line = g.parentLine!;
                      const start = document.getElementById(g.id);
                      const end = document.getElementById(g.parent!.id);
                      if (start) line.start = start;
                      if (end) line.end = end;
                      this.lines.push(line);
                    });
                  this.updateGroupsLinePositions();
                  this.loader.stopLoading(id);
                }, 0);
              });
          } else this.loader.stopLoading(id);
        })
      );
  }

  private panzoomHandling = false;
  private scrollEventListener = this.panzoomHandleScroll.bind(this);
  private downEventListener = this.panzoomHandleDown.bind(this);
  private moveEventListener = this.panzoomHandleMove.bind(this);
  private upEventListener = this.panzoomHandleUp.bind(this);

  ngAfterViewInit(): void {
    this.panzoom = Panzoom(this.panzoomContainer?.nativeElement, {
      excludeClass: 'panzoom-no-drag',
      noBind: true,
    });
    const mainDropList = document.getElementById('mainDropList');
    if (mainDropList && this.panzoom) {
      mainDropList.addEventListener('wheel', this.scrollEventListener);
      mainDropList.addEventListener('pointerdown', this.downEventListener);
      document.addEventListener('pointermove', this.moveEventListener);
      document.addEventListener('pointerup', this.upEventListener);
    }
  }

  ngOnDestroy(): void {
    this.ishtar365Actions.buttonActions = [];
    this.destroy$.next();
    this.destroy$.complete();
    this.removeLines();
    this.reloadLines$.next('');
    this.reloadLines$.complete();
    this._groupRemoval$.complete();
    this.panzoom?.destroy();
    const mainDropList = document.getElementById('mainDropList');
    if (mainDropList && this.panzoom) {
      mainDropList.removeEventListener('wheel', this.scrollEventListener);
      mainDropList.removeEventListener('pointerdown', this.downEventListener);
      document.removeEventListener('pointermove', this.moveEventListener);
      document.removeEventListener('pointerup', this.upEventListener);
    }
  }

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  private panzoomHandleScroll(event: WheelEvent) {
    this.panzoom?.zoomWithWheel(event);
    setTimeout(() => {
      const scale = this.panzoom?.getScale() ?? 1;
      const size = this.calcLineSize(scale);
      this.groups.forEach((g) => {
        if (g.draggingLine) g.draggingLine.size = size;
        if (g.parentLine) g.parentLine.size = size;
      });
      this.updateGroupsLinePositions();
    }, 0);
  }
  private panzoomHandleDown(event: PointerEvent) {
    this.panzoomHandling = true;
    this.panzoom?.handleDown(event);
  }
  private panzoomHandleMove(event: PointerEvent) {
    this.panzoom?.handleMove(event);
    if (!this.panzoomHandling) return;
    this.updateGroupsLinePositions();
  }
  private panzoomHandleUp(event: PointerEvent) {
    this.panzoomHandling = false;
    this.panzoom?.handleUp(event);
  }

  private calcLineSize(scale: number) {
    if (scale >= 1) return 4;
    else if (scale <= 0.5) return 1;
    else return Math.min(4, Math.max(1, 4 * Math.sqrt(scale)));
  }

  groupTrackByFn(_index: number, item: DraggableGroup) {
    return item.id;
  }

  private _groupRemoval$ = new Subject<string>();

  addGroup() {
    const pos = { x: 10, y: 10 };
    const pan = this.panzoom?.getPan();
    if (pan) {
      pos.x += -pan.x;
      pos.y += -pan.y;
    }

    const group = new DraggableGroup({ initialPosition: pos });
    group.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        takeUntil(this._groupRemoval$.pipe(filter((id) => group.id == id))),
        debounceTime(500),
        tap((t) => (!t ? (group.foundUsers = []) : undefined)),
        filter((t) => !!t),
        switchMap((t) => this.ishtar365.getGraphUsers(t!))
      )
      .subscribe(
        (users) =>
          (group.foundUsers = users.filter(
            (u) =>
              !group.members
                .concat(group.parentMembers)
                .some((m) =>
                  m instanceof GraphUser
                    ? m.id == u.id
                    : m.email.toLowerCase() == u.mail.toLowerCase()
                )
          ))
      );
    this.groups.push(group);
  }

  removeGroup(group: DraggableGroup) {
    this._groupRemoval$.next(group.id);
    this.lines = this.lines.filter((l) => !l.id.includes(group.id));
    this.groups.splice(
      this.groups.findIndex((g) => g.id == group.id),
      1
    );
    this.groups
      .filter((g) => g.parent?.id == group?.id)
      .forEach((g) => {
        g.parent = undefined;
        g.parentLine = undefined;
      });
  }

  getMemberName(member: GraphUser | IshtarUser) {
    return member instanceof GraphUser ? member.displayName : member.name;
  }

  updateGroupName(group: DraggableGroup, event: any) {
    group.name = event.target.value;
  }

  addMemberToGroup(group: DraggableGroup, user: GraphUser) {
    group.members.push(user);
    setTimeout(() => this.updateGroupLinePositions(group), 0);
  }

  removeMemberFromGroup(group: DraggableGroup, member: GraphUser | IshtarUser) {
    group.members = group.members.filter((m) => m != member);
    setTimeout(() => this.updateGroupLinePositions(group), 0);
  }

  dragStart(drag: CdkDragStart) {
    if (
      drag.source.element.nativeElement.classList.contains('selector-parent') &&
      drag.source.data.parent
    ) {
      const line = drag.source.data.parentLine as LineConnectorInputs;
      drag.source.data.draggingLine = line;
      drag.source.data.parentLine = undefined;
      drag.source.data.parent = undefined;
      const previewEl = document.getElementsByClassName(
        'cdk-drag selector cdk-drag-preview'
      );
      line.start = drag.source.dropContainer.element
        .nativeElement as HTMLElement;
      line.startSocket = 'top';
      line.end = (previewEl.item(0) as HTMLElement) ?? undefined;
      line.endSocket = 'auto';
      line.id = `from${drag.source.dropContainer.id}`;
    } else {
      const previewEl = document.getElementsByClassName(
        'cdk-drag selector cdk-drag-preview'
      );
      if (!previewEl.length) return;
      const scale = this.panzoom?.getScale() ?? 1;
      const size = this.calcLineSize(scale);
      const line = new LineConnectorInputs({
        id: `from${drag.source.dropContainer.id}`,
        parent: this.lineContainer?.nativeElement,
        start: drag.source.dropContainer.element.nativeElement,
        end: previewEl.item(0)! as HTMLElement,
        size: size,
        color: 'rgba(0,0,0,0.5)',
        startSocket: drag.source.element.nativeElement.className.includes(
          'parent'
        )
          ? 'top'
          : 'bottom',
        redraw$: this.reloadLines$.asObservable(),
      });
      drag.source.dropContainer.data.draggingLine = line;
      this.lines.push(line);
    }
  }
  dragMove(move: CdkDragMove) {
    this.updateGroupLinePositions(move.source.dropContainer.data);
  }
  private removeLine(line: LineConnectorInputs) {
    this.lines = this.lines.filter((l) => l?.id != line.id);
  }
  private removeLines() {
    this.lines = [];
  }
  dragEnd(end: CdkDragEnd) {
    const line = end.source.dropContainer.data
      .draggingLine as LineConnectorInputs;
    if (line && line.end instanceof Element) {
      const endElement = line.end as Element;
      if (endElement.className.includes('selector')) {
        this.removeLine(line);
      } else {
        const group = this.groups.find((g) => g.id == endElement.id);
        if (group)
          if (line.startSocket == 'top') {
            end.source.data.parent = group;
            end.source.data.parentLine = line;
          } else {
            group.parent = end.source.data;
            group.parentLine = line;
          }
        line.id = `from${line.start?.id}to${line.end?.id}`;
      }
      end.source.dropContainer.data.draggingLine = undefined;
    }
  }
  private checkGroupParentHierarchyForLoop(id: string, group: DraggableGroup) {
    let parent = group.parent;
    while (parent) {
      if (parent.id == id) return true;
      parent = parent.parent;
    }
    return false;
  }
  dragEnter(enter: CdkDragEnter) {
    const item = enter.item;
    const itemData = item.data;
    const container = enter.container;
    const containerData = container.data;
    if (
      !containerData ||
      (itemData.id != containerData.id &&
        !(
          itemData.draggingLine.startSocket == 'bottom' &&
          (containerData.parent != undefined ||
            this.checkGroupParentHierarchyForLoop(containerData.id, itemData))
        ) &&
        !(
          itemData.draggingLine.startSocket == 'top' &&
          this.checkGroupParentHierarchyForLoop(itemData.id, containerData)
        ))
    )
      if (container.id == 'mainDropList') {
        const previewEl = document.getElementsByClassName(
          'cdk-drag selector cdk-drag-preview'
        );
        itemData.draggingLine.end = previewEl.item(0);
        itemData.draggingLine.endSocket = 'auto';
      } else {
        itemData.draggingLine.end = container.element.nativeElement;
        itemData.draggingLine.endSocket =
          itemData.draggingLine.startSocket == 'top' ? 'bottom' : 'top';
      }
    this.updateGroupLinePositions(itemData);
  }

  groupMoved(move: CdkDragMove) {
    const group = move.source.data as DraggableGroup;
    this.updateGroupLinePositions(group);
  }

  groupMoveTransformationWithScaleBound =
    this.groupMoveTransformationWithScale.bind(this);

  groupMoveStart(start: CdkDragStart) {
    if (start.event instanceof MouseEvent) {
      this._dragMovePointer = { x: start.event.pageX, y: start.event.pageY };
    } else {
      const touch = start.event.targetTouches.item(0);
      if (touch) this._dragMovePointer = { x: touch.pageX, y: touch.pageY };
    }
  }
  groupMoveEnd(_end: CdkDragEnd) {
    this._dragMovePointer = undefined;
  }

  groupMoveTransformationWithScale(
    point: Point,
    _dragRef: DragRef,
    _dimensions: DOMRect,
    pickupPositionInElement: Point
  ) {
    if (!this._dragMovePointer) {
      this._dragMovePointer = point;
      return {
        x: point.x - pickupPositionInElement.x,
        y: point.y - pickupPositionInElement.y,
      };
    }
    let zoomMoveXDifference = 0;
    let zoomMoveYDifference = 0;
    const zoomScale = this.panzoom?.getScale() ?? 1;

    if (zoomScale != 1) {
      // TODO: figure out slight problem in movement
      zoomMoveXDifference =
        (point.x - this._dragMovePointer.x) * (1 - zoomScale);
      zoomMoveYDifference =
        (point.y - this._dragMovePointer.y) * (1 - zoomScale);
    }

    return {
      x: point.x - pickupPositionInElement.x + zoomMoveXDifference,
      y: point.y - pickupPositionInElement.y + zoomMoveYDifference,
    };
  }

  updateGroupsLinePositions() {
    this.reloadLines$.next('');
  }

  updateGroupLinePositions(group: DraggableGroup) {
    this.reloadLines$.next(group.id);
  }

  submitOrganigram() {
    this.groups.forEach((g) => {
      if (g.name == '')
        g.error = this.translations.getTranslation('errorDisplayNameRequired');
      else if (!g.members.length)
        g.error = this.translations.getTranslation('errorMinimumGroupMembers');
      else g.error = undefined;
    });
    if (!this.groups.some((g) => g.error))
      this.loader.startLoading(
        this.translations.getTranslation('savingOrganigram'),
        () =>
          this.activeApp$.pipe(
            first(),
            switchMap((app) =>
              this.groupFacade.saveOrganigram$(
                app!.name,
                this.groups.map((g) => {
                  const groupEl = document.getElementById(g.id);
                  const transform = groupEl?.style.transform as string;
                  const regex =
                    /translate3d\((?<x>-?\d+)px, (?<y>-?\d+)px, (?<z>-?\d+)px\)/;
                  const matches = transform?.match(regex);
                  return new IshtarOrganigramGroup({
                    guid: g.id,
                    displayName: g.name,
                    parentId: g.parent?.id,
                    members: g.members.map((m) =>
                      m instanceof IshtarUser
                        ? m
                        : new IshtarUser({
                            email: m.mail,
                            name: m.displayName,
                          })
                    ),
                    xPos: +(matches?.groups?.x ?? 0),
                    yPos: +(matches?.groups?.y ?? 0),
                  });
                })
              )
            ),
            tap(() => this.organizationFacade.getGroupUsers())
          )
      );
  }

  openImportDialog() {
    this.dialog.open(OrganigramImportPopupComponent, {
      data: {
        onImport: this.onImport.bind(this),
        canOverwrite: !!this.groups.length,
      },
    });
  }

  onImport(app: IshtarApp, overwrite: boolean) {
    const loadId = this.loader.startLoading('Importing...');
    this.groupFacade.retrieveOrganigram$(app.name).subscribe((groups) => {
      let minX = Number.MAX_SAFE_INTEGER;
      let minY = Number.MAX_SAFE_INTEGER;
      let maxX = Number.MIN_SAFE_INTEGER;
      if (this.groups.length)
        this.groups.forEach((g) => {
          const elem = document.getElementById(g.id);
          minX = Math.min(minX, g.initialPosition.x);
          minY = Math.min(minY, g.initialPosition.y);
          maxX = Math.max(maxX, g.initialPosition.x + (elem?.clientWidth ?? 0));
        });
      else {
        minX = 0;
        minY = 0;
        maxX = 0;
      }
      let minYNew = Number.MAX_SAFE_INTEGER;
      if (groups) groups.forEach((g) => (minYNew = Math.min(minYNew, g.yPos)));
      else minYNew = 0;
      const currentWidth = maxX - minX;
      const newGroupIds: { [key: string]: string } = groups.reduce(
        (acc, g) => ({ ...acc, [g.guid]: uuid.v4() }),
        {}
      );
      const newOrganigramGroups = groups.map(
        (g) =>
          new IshtarOrganigramGroup({
            ...g,
            guid: newGroupIds[g.guid],
            parentId: g.parentId ? newGroupIds[g.parentId] : undefined,
          })
      );
      const newGroups = newOrganigramGroups.map(
        (g) =>
          new DraggableGroup({
            id: g.guid,
            name: g.displayName,
            members: [...g.members],
            initialPosition: {
              x: g.xPos + (overwrite ? 0 : currentWidth + 10),
              y: g.yPos + (minY - minYNew),
            },
          })
      );
      newGroups.forEach((group) =>
        group.searchControl.valueChanges
          .pipe(
            takeUntil(this.destroy$),
            takeUntil(this._groupRemoval$.pipe(filter((id) => group.id == id))),
            debounceTime(500),
            tap((t) => (!t ? (group.foundUsers = []) : undefined)),
            filter((t) => !!t),
            switchMap((t) => this.ishtar365.getGraphUsers(t!))
          )
          .subscribe(
            (users) =>
              (group.foundUsers = users.filter(
                (u) =>
                  !group.members
                    .concat(group.parentMembers)
                    .some((m) =>
                      m instanceof GraphUser
                        ? m.id == u.id
                        : m.email.toLowerCase() == u.mail.toLowerCase()
                    )
              ))
          )
      );
      this.groups = overwrite ? newGroups : [...this.groups, ...newGroups];
      newOrganigramGroups
        .filter((g) => g.parentId)
        .forEach((g) => {
          const group = this.groups.find((x) => g.guid == x.id);
          if (group) {
            group.parent = this.groups.find((x) => g.parentId == x.id);
            if (group.parent)
              group.parentLine = new LineConnectorInputs({
                id: `from${group.id}to${group.parent.id}`,
                parent: this.lineContainer?.nativeElement,
                redraw$: this.reloadLines$,
                color: 'rgba(0,0,0,0.5)',
              });
          }
        });
      setTimeout(() => {
        let minX = Number.MAX_SAFE_INTEGER;
        let minY = Number.MAX_SAFE_INTEGER;
        let maxX = Number.MIN_SAFE_INTEGER;
        let maxY = Number.MIN_SAFE_INTEGER;
        if (this.groups?.length)
          this.groups.forEach((g) => {
            const elem = document.getElementById(g.id);
            minX = Math.min(minX, g.initialPosition.x);
            minY = Math.min(minY, g.initialPosition.y);
            maxX = Math.max(
              maxX,
              g.initialPosition.x + (elem?.clientWidth ?? 0)
            );
            maxY = Math.max(
              maxY,
              g.initialPosition.y + (elem?.clientHeight ?? 0)
            );
          });
        else {
          minX = 0;
          minY = 0;
          maxX = 0;
          maxY = 0;
        }
        const dimensions =
          this.panzoomContainer?.nativeElement.parentElement?.getBoundingClientRect() as DOMRect;

        const newDimensions = {
          width: maxX - minX,
          height: maxY - minY,
        };
        const scaleX = Math.max(
          1,
          (newDimensions.width + 20) / dimensions.width
        );
        const scaleY = Math.max(
          1,
          (newDimensions.height + 20) / dimensions.height
        );
        this.panzoom?.zoomToPoint(1 / Math.max(scaleX, scaleY), {
          clientX: minX + newDimensions.width / 2,
          clientY: minY + newDimensions.height / 2,
        });
        this.panzoom?.pan(-minX + 10, -minY + 10);
        newGroups
          .filter((g) => g.parentLine)
          .forEach((g) => {
            const line = g.parentLine!;
            const start = document.getElementById(g.id);
            const end = document.getElementById(g.parent!.id);
            if (start) line.start = start;
            if (end) line.end = end;
            this.lines.push(line);
          });
        setTimeout(() => {
          this.updateGroupsLinePositions();
          this.loader.stopLoading(loadId);
        }, 0);
      }, 0);
    });
  }

  focusInput(input: HTMLInputElement) {
    setTimeout(() => input.focus(), 0);
  }
}
