import { IshtarOrderDTO } from '../dto/ishtar-order.dto';
import { OrderStatus } from '../enums/order-status.enum';

export class IshtarOrder {
  id!: number;
  status!: OrderStatus;
  billing!: { email: string; firstname: string; lastname: string };
  lineItems: { name: string; productId: number }[] = [];

  constructor(obj: IshtarOrderDTO) {
    this.id = obj.id;
    this.status = (<any>OrderStatus)[obj.status.toUpperCase()];
    this.billing = obj.billing;
    this.lineItems = obj.line_items;
  }
}
