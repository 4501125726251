import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  AutomationFlowActionType,
  AutomationFlowApplication,
  AutomationFlow,
  Lookup,
  Operator,
  PropertyType,
  TriggerType,
  AutomationFlowConditionType,
} from 'processdelight-angular-components';
import { Day } from 'processdelight-angular-components/lib/domain/models/day';
import { Month } from 'processdelight-angular-components/lib/domain/models/month';

export const automationFlowActions = createActionGroup({
  source: 'AutomationFlow',
  events: {
    'Get Automation Flows': emptyProps(),
    'Get Automation Flows Resolved': props<{
      automationFlows: AutomationFlow[];
    }>(),

    'Create Automation Flow': props<{ automationFlow: AutomationFlow }>(),
    'Create Automation Flow Resolved': props<{
      createdAutomationFlow: AutomationFlow;
    }>(),

    'Update Automation Flow': props<{ automationFlow: AutomationFlow }>(),
    'Update Automation Flow Resolved': props<{
      updatedAutomationFlow: AutomationFlow;
    }>(),

    'Delete Automation Flow': props<{ id: string }>(),
    'Delete Automation Flow Resolved': props<{
      deletedAutomationFlowId: string;
    }>(),

    'Get Applications': emptyProps(),
    'Get Applications Resolved': props<{
      automationFlowApplications: AutomationFlowApplication[];
    }>(),

    'Get Action Types': emptyProps(),
    'Get Action Types Resolved': props<{
      actionTypes: AutomationFlowActionType[];
    }>(),

    'Get Condition Types': emptyProps(),
    'Get Condition Types Resolved': props<{
      conditionTypes: AutomationFlowConditionType[];
    }>(),

    'Get Trigger Types': emptyProps(),
    'Get Trigger Types Resolved': props<{
      triggerTypes: TriggerType[];
    }>(),

    'Get Property Types': emptyProps(),
    'Get Property Types Resolved': props<{
      propertyTypes: PropertyType[];
    }>(),

    'Get Operators': emptyProps(),
    'Get Operators Resolved': props<{
      operators: Operator[];
    }>(),

    'Get Frequencies': emptyProps(),
    'Get Frequencies Resolved': props<{
      frequencies: Lookup[];
    }>(),

    'Get Days': emptyProps(),
    'Get Days Resolved': props<{ days: Day[] }>(),

    'Get Months': emptyProps(),
    'Get Months Resolved': props<{ months: Month[] }>(),

    'Get Rankings': emptyProps(),
    'Get Rankings Resolved': props<{ rankings: Lookup[] }>(),

    'Get Automation Flow Utilities': emptyProps(),

    'Get Object Type Records': props<{
      objectTypeName: string;
      objectTypeId: string;
    }>(),
    'Get Object Type Records Resolved': props<{
      objectTypeId: string;
      objectTypeRecords: Lookup[];
    }>(),
  },
});
