import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { NavigationEnd, Router } from '@angular/router';
import { filter, first, map, Subject, takeUntil } from 'rxjs';
import { TranslationService } from 'src/app/core/services/translation.service';
import { CoreModule } from 'src/app/core/core.module';
import { IshtarApp } from 'src/app/core/domain/models/ishtar-app.model';
import { AppSubscriptionFacade } from 'src/app/core/store/app-subscription/app-subscription.facade';

@Component({
  standalone: true,
  selector: 'app-group-settings',
  templateUrl: './group-settings.component.html',
  styleUrls: ['./group-settings.component.scss'],
  imports: [CoreModule, MatCardModule, MatListModule],
})
export class GroupSettingsComponent implements OnInit, OnDestroy {
  appsWithSubscription$ = this.appSubscriptionFacade.appsWithSubscription$;

  activeApp?: IshtarApp;

  destroy$ = new Subject<void>();

  constructor(
    private readonly appSubscriptionFacade: AppSubscriptionFacade,
    private readonly router: Router,
    private readonly translations: TranslationService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((x) => x instanceof NavigationEnd),
        map((x) => x as NavigationEnd)
      )
      .subscribe((x: NavigationEnd) =>
        this.updateActiveApp(x.url.split('/').pop())
      );
    this.updateActiveApp(this.router.url.split('/').pop());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  updateActiveApp(name?: string) {
    this.appSubscriptionFacade.apps$
      .pipe(first())
      .subscribe(
        (apps) => (this.activeApp = apps.find((app) => app.name == name))
      );
  }

  setActiveApp(app: IshtarApp) {
    this.appSubscriptionFacade.setActiveApp(app);
    this.router.navigate(['settings', 'groups', app.name]);
  }
}
