<div class="period-selector">
  <div class="header">
    <h5>
      {{ getTranslation$("availability") | async }}
      {{ getTranslation$("from") | async }} {{ user.displayName }}
    </h5>
  </div>
  <div>
    <processdelight-action-bar
      [buttonActions]="actions"
      [iconActions]="iconActions"
      [navColor]="'#124464'"
      [navContrast]="'#fff'"
    ></processdelight-action-bar>
  </div>
  <div class="week-table">
    <form [formGroup]="form">
      <table>
        <thead>
          <tr>
            <th></th>
            <th
              *ngFor="
                let day of [
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                  'Sunday'
                ]
              "
            >
              {{ day }}
            </th>
          </tr>
        </thead>
        <tbody formArrayName="weeks">
          <tr *ngFor="let weekForm of formArray.controls; let i = index">
            <ng-container [formGroup]="getGroupControls(weekForm)">
              <td>Week {{ i + 1 }}</td>
              <td
                *ngFor="
                  let day of [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday'
                  ]
                "
              >
                <mat-select
                  formControlName="{{ day + i }}"
                  class="custom-dropdown"
                >
                  <mat-option
                    *ngFor="let type of workRegimeTypes"
                    [value]="type.name"
                    >{{ type.name }}</mat-option
                  >
                </mat-select>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
      <p class="validationParagraph" #validationParagraph></p>
    </form>
  </div>
</div>
