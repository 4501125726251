import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subject, map, takeUntil, withLatestFrom } from 'rxjs';
import { TileAction } from 'src/app/core/domain/enums/tile-action.enum';
import { TilePageSegment } from 'src/app/core/domain/models/tile-page-segment.model';
import { TilePage } from 'src/app/core/domain/models/tile-page.model';
import { TilePageFacade } from 'src/app/core/store/tilepage/tilepage.facade';
import { CustomTileComponent } from '../custom-tile/custom-tile.component';
import { AppTileComponent } from '../app-tile/app-tile.component';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
} from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MicrosoftAuthenticationService } from 'src/app/msal/injectables/microsoft-authentication.service';
import { UserSettings } from 'src/app/core/domain/models/user-settings.model';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { FrameManagerService } from 'src/app/core/services/frame-manager.service';
import { ApplicationBarService } from 'src/app/shared/services/application-bar.service';
import { CoreModule } from 'src/app/core/core.module';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { ActionBarComponent } from 'processdelight-angular-components';
import { Ishtar365ActionsService } from 'src/app/core/services/ishtar365-actions.service';
import { ApplicationService } from 'src/app/shared/services/application.service';

@Component({
  selector: 'app-intranet',
  standalone: true,
  imports: [
    CommonModule,
    CustomTileComponent,
    AppTileComponent,
    MatIconModule,
    CoreModule,
    ActionBarComponent,
  ],
  templateUrl: './intranet.component.html',
  styleUrls: ['./intranet.component.scss'],
})
export class IntranetComponent implements OnInit, OnDestroy {
  page?: TilePage;

  tilePages?: TilePage[] = [];

  TileAction = TileAction;

  menuOpened = false;

  userSettings = new UserSettings();

  intranetUrl?: SafeResourceUrl;

  sideBarHover = false;

  destroy$ = new Subject<void>();

  navColor$ = this.organizationFacade.tempProperties$.pipe(
    map((p) => p.navColor)
  );
  navContrast$ = this.organizationFacade.tempProperties$.pipe(
    map((p) => p.navContrast)
  );

  firstTopMargin = 0;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.firstTopMargin =
      (event.target as Window)?.innerWidth < 550 ||
      (event.target as Window)?.innerHeight < 450
        ? 30
        : 80;
  }

  get navActions() {
    return this.ishtar365Actions.navActions;
  }
  get buttonActions() {
    return this.ishtar365Actions.buttonActions;
  }
  get iconActions() {
    return this.ishtar365Actions.iconActions;
  }
  signedIn$ = this.applicationService.signedIn.asObservable();

  get segments() {
    return this.page?.segments
      .map((s) => s.tilePageSegment)
      .filter((s) => !s.disabled)
      .sort((a, b) => a.position - b.position);
  }

  constructor(
    private tilePageFacade: TilePageFacade,
    private userFacade: UserFacade,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private msal: MicrosoftAuthenticationService,
    private frameManager: FrameManagerService,
    private applicationBarService: ApplicationBarService,
    private organizationFacade: OrganizationFacade,
    private ishtar365Actions: Ishtar365ActionsService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.firstTopMargin =
      window.innerWidth < 550 || window.innerHeight < 450 ? 30 : 80;
    this.applicationBarService.appBarExtended$
      .pipe(takeUntil(this.destroy$))
      .subscribe((b) => {
        this.sideBarHover = b;
      });
    this.applicationBarService.tilePageExtended$
      .pipe(takeUntil(this.destroy$))
      .subscribe((b) => {
        this.menuOpened = b;
      });
    this.route.data
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.tilePageFacade.getTilePageByIdFunc$)
      )
      .subscribe(([data, fn]) => {
        this.page = fn(data.tilePageId);
        this.intranetUrl = this.page?.intranetUrl
          ? this.transform(this.page.intranetUrl)
          : undefined;
      });
    this.tilePageFacade.tilePages$
      .pipe(takeUntil(this.destroy$))
      .subscribe((p) => (this.tilePages = p));
    this.userFacade.userSettings$
      .pipe(takeUntil(this.destroy$))
      .subscribe((s) => (this.userSettings = new UserSettings(s)));
    this.frameManager.openApp$
      .pipe(takeUntil(this.destroy$))
      .subscribe((app) => {
        if (app) {
          this.menuOpened = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  transform(value: string): SafeHtml {
    if (!value.startsWith('http')) value = 'https://' + value;
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  getTiles(segment: TilePageSegment) {
    return segment.tiles
      .map((t) => t.tile)
      .filter(
        (t) =>
          !t.disabled &&
          (!t.permissions?.length ||
            t.permissions
              .map((p) => p.permission)
              .some((p) =>
                p.groupUser.user
                  ? p.groupUser.id == this.msal.id
                  : p.groupUser.group?.members?.some(
                      (m) => m.id == this.msal.id
                    )
              )) &&
          !(
            t.tileActionType == TileAction.TilePage &&
            !this.tilePages?.some((p) => p.id == t.tileAction)
          )
      )
      .sort((a, b) => a.position - b.position);
  }

  togglePinned() {
    this.userSettings.sideBarPinned = !this.userSettings.sideBarPinned;
    this.userFacade
      .updateUserSettings$(new UserSettings(this.userSettings))
      .subscribe();
  }
  toggleTilePage() {
    this.applicationBarService.toggleTilePage();
  }
}
