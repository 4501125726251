import { createAction, props } from '@ngrx/store';
import { ActionBase } from '../action-base.interface';
import { DataRecord } from '../../domain/models/form-template.model';

export const getFormTemplates = createAction(
  '[FormTemplate] Get form template',
  props<{ appName: string } & ActionBase<DataRecord[]>>()
);
export const getFormTemplatesResolved = createAction(
  '[FormTemplate] Get form template resolved',
  props<{
    appName: string;
    formTemplates: DataRecord[];
  }>()
);
