import { Action, createReducer, on } from '@ngrx/store';
import { IshtarOrganigramGroup } from '../../domain/models/ishtar-organigram-group..model';
import {
  getOrganigramResolved,
  resetSlice,
  saveOrganigramResolved,
} from './group.actions';

export const featureSlice = 'group';

export interface State {
  organigrams: { [appName: string]: IshtarOrganigramGroup[] };
}

const defaultState: State = {
  organigrams: {},
};

export function Reducer(state: State | undefined, action: Action) {
  return groupReducer(state, action);
}

export const initialState: State = defaultState;
export const groupReducer = createReducer(
  initialState,
  on(getOrganigramResolved, (state, { appName, groups }) => ({
    ...state,
    organigrams: {
      ...state.organigrams,
      [appName]: groups,
    },
  })),
  on(saveOrganigramResolved, (state, { appName, groups }) => ({
    ...state,
    organigrams: {
      ...state.organigrams,
      [appName]: groups,
    },
  })),
  on(resetSlice, () => initialState)
);
