import { createAction, props } from '@ngrx/store';
import { TilePage } from '../../domain/models/tile-page.model';
import { ActionBase } from '../action-base.interface';

export const getTilePages = createAction(
  '[TilePage] Get tile pages',
  props<ActionBase<TilePage[]>>()
);
export const getTilePagesResolved = createAction(
  '[TilePage] Get tile pages resolved',
  props<{
    pages: TilePage[];
  }>()
);
export const updateTilePages = createAction(
  '[TilePage] Update tile pages',
  props<
    {
      pages: TilePage[];
    } & ActionBase<TilePage[]>
  >()
);

export const resetSlice = createAction('[TilePage] Reset slice');
