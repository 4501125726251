import { BaseEntity, GroupUser } from 'processdelight-angular-components';

export class WorkRegime extends BaseEntity {
  user!: GroupUser;
  capacity!: number;

  constructor(obj: Partial<WorkRegime>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.user) this.user = new GroupUser(obj.user);
  }
}
