import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { BaseFacade } from '../base.facade';
import { getMetadataParameters } from './metadataParameter.actions';
import { metadataParameterSelectors } from './metadataParameter.selector';
import { MetadataParameter } from '../../domain/models/metadata-parameter.model';

@Injectable({
  providedIn: 'root',
})
export class MetadataParameterFacade extends BaseFacade {
  metaParams$ = this._store.pipe(select(metadataParameterSelectors.getMetadataParameters));
 
  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getMetadataParameters(callback?: (metadataParameters: MetadataParameter[]) => void) {
    this._store.dispatch(getMetadataParameters({ callback }));
  }
  getMetadataParameters$() {
    return this.dispatchAction$(getMetadataParameters);
  }
}
