import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export const lastRouteKey = 'Ishtar.365_Last_Route';

@Injectable({
  providedIn: 'root',
})
export class InitialNavigationGuard {
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!location.pathname.includes('signin'))
      localStorage.setItem(lastRouteKey, location.pathname + location.search);
    return true;
  }
}
