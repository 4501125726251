<div class="h-100 d-flex flex-column align-items-center">
  <h2 class="mt-5">Welcome!</h2>
  <h4>
    It seems that you don't have any active licenses. If you think this is a
    mistake, contact your administrator.
  </h4>
  <h4 class="mb-3">
    Go to <a href="https://ishtar365.com">ishtar365.com</a> to learn more
  </h4>
  <div class="ishtar-logo mb-5">
    <img src="../../../../assets/Ishtar365Logo.png" alt="Ishtar.365! logo" />
  </div>
</div>
