import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Ishtar365Service } from '../../services/ishtar365.service';
import {
  getGroupUsers,
  getGroups,
  getUsers,
  getGroupUsersResolved,
  getOrganizationAdmins,
  getOrganizationAdminsResolved,
  getOrganizationInfo,
  getOrganizationInfoResolved,
  updateOrganizationPersonalization,
  updateOrganizationPersonalizationResolved,
  getUsersResolved,
  getGroupsResolved,
} from './organization.actions';

@Injectable({ providedIn: 'root' })
export class OrganizationEffects {
  constructor(private actions$: Actions, private ishtar365: Ishtar365Service) {}

  getOrganizationInfo = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrganizationInfo),
      switchMap(({ tenantId, callback, error }) =>
        this.ishtar365.getOrganization(tenantId).pipe(
          tap((organizationInfo) =>
            callback ? callback(organizationInfo) : undefined
          ),
          switchMap((organizationInfo) =>
            of(getOrganizationInfoResolved({ organizationInfo }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
  getOrganizationAdmins = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrganizationAdmins),
      switchMap(({ callback, error }) =>
        this.ishtar365.getOrganizationAdmins().pipe(
          tap((admins) => (callback ? callback(admins) : undefined)),
          switchMap((admins) => of(getOrganizationAdminsResolved({ admins }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
  updateOrganizationPersonalization = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganizationPersonalization),
      switchMap(({ logo, navColor, navContrast, callback, error }) =>
        this.ishtar365
          .updateOrganizationPersonalization(logo, navColor, navContrast)
          .pipe(
            tap((organizationInfo) =>
              callback ? callback(organizationInfo) : undefined
            ),
            switchMap((organizationInfo) =>
              of(
                updateOrganizationPersonalizationResolved({
                  organizationInfo,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  getGroupUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(getGroupUsers),
      switchMap(({ callback, error }) =>
        combineLatest([
          this.ishtar365.getGroups(),
          this.ishtar365.getUsers(),
        ]).pipe(
          map(([groups, users]) => [...groups, ...users]),
          tap((groupUsers) => (callback ? callback(groupUsers) : undefined)),
          switchMap((groupUsers) => of(getGroupUsersResolved({ groupUsers }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
  getUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(getUsers),
      switchMap(({ callback, error }) =>
          this.ishtar365.getUsers().pipe(
          map(( users) => [ ...users]),
          tap((users) => (callback ? callback(users) : undefined)),
          switchMap((users) => of(getUsersResolved({ users }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
  getGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(getGroups),
      switchMap(({ callback, error }) =>
          this.ishtar365.getGroups().pipe(
          map((groups) => [...groups]),
          tap((groups) => (callback ? callback(groups) : undefined)),
          switchMap((groups) => of(getGroupsResolved({ groups }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
