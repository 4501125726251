import { Component } from '@angular/core';
import { ApplicationService } from '../../services/application.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent {
  signedIn$ = this.applicationService.signedIn.asObservable();
  constructor(private applicationService: ApplicationService) {}
}
