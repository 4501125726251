import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import {
  ContextMenuAction,
  GroupUser,
  LoaderService,
} from 'processdelight-angular-components';
import {
  Subject,
  first,
  forkJoin,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { Ishtar365ActionsService } from 'src/app/core/services/ishtar365-actions.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { AppConfigOoO } from 'src/app/core/domain/models/app-configOoO';
import { WorkRegime } from 'src/app/core/domain/models/workRegime.model';
import { WorkRegimeDay } from 'src/app/core/domain/models/workRegimeDay.model';
import { AppSubscriptionFacade } from 'src/app/core/store/app-subscription/app-subscription.facade';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { WorkRegimeFacade } from 'src/app/core/store/workRegime/workRegime.facade';
import { WorkRegimeSettingsComponent } from './work-regime-settings/work-regime-settings.component';

@Component({
  standalone: true,
  selector: 'app-settings',
  templateUrl: './work-regime.component.html',
  styleUrls: ['./work-regime.component.scss'],
  imports: [
    MatTabsModule,
    RouterModule,
    CommonModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatListModule,
    MatSelectModule,
    MatDialogModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatRadioModule,
  ],
})
export class WorkRegimeComponent implements OnInit, OnDestroy {
  constructor(
    private detailsDialog: MatDialog,
    private readonly translations: TranslationService,
    private workRegimeFacade: WorkRegimeFacade,
    private appFacade: AppSubscriptionFacade,
    private organizationFacade: OrganizationFacade,
    private ishtar365Actions: Ishtar365ActionsService,
    private loaderService: LoaderService,
    private appSubcriptionFacade: AppSubscriptionFacade
  ) {
    this.form = new FormGroup({
      monday: new FormControl(false),
      tuesday: new FormControl(false),
      wednesday: new FormControl(false),
      thursday: new FormControl(false),
      friday: new FormControl(false),
      saturday: new FormControl(false),
      sunday: new FormControl(false),
      standardWorkingHours: new FormControl(40),
      holiday: new FormControl(false),
      employees: new FormArray([]),
    });
  }

  get employeesFormArray() {
    return this.form.get('employees') as FormArray;
  }

  form!: FormGroup;
  daysOfWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];
  amountOfWorkDays = 0;

  users: GroupUser[] = [];

  config: AppConfigOoO | undefined;

  workRegimeDays$ = this.workRegimeFacade.workRegimeDays$;
  workRegimeDays: WorkRegimeDay[] = [];

  workRegimes$ = this.workRegimeFacade.workRegimes$;
  workRegimes: WorkRegime[] = [];

  workRatios: string[] = [];

  searchText = '';

  destroy$ = new Subject<void>();

  @ViewChild('validationParagraph') validationParagraph!: ElementRef;

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  ngOnInit(): void {
    this.ishtar365Actions.buttonActions.push(
      new ContextMenuAction({
        label: 'save',
        icon: 'save',
        iconOutline: true,
        isSymbol: true,
        action: () => this.save(),
      })
    );

    this.appFacade
      .appConfig$('Ishtar.OoO')
      .pipe(takeUntil(this.destroy$))
      .subscribe((config) => {
        this.config = config;
        this.form.patchValue({
          // Use patchValue to set initial values
          monday: this.config?.workDays.includes('monday'),
          tuesday: this.config?.workDays.includes('tuesday'),
          wednesday: this.config?.workDays.includes('wednesday'),
          thursday: this.config?.workDays.includes('thursday'),
          friday: this.config?.workDays.includes('friday'),
          saturday: this.config?.workDays.includes('saturday'),
          sunday: this.config?.workDays.includes('sunday'),
          standardWorkingHours: this.config!.standardWorkingHours,
          holiday: this.config!.holidaysEnabled,
        });
        this.amountOfWorkDays = this.config?.workDays.length || 0;
        this.workRatios = this.getWorkRatios();
      });

    this.workRegimeDays$.pipe(takeUntil(this.destroy$)).subscribe((items) => {
      this.workRegimeDays = items || [];
    });

    this.workRegimes$.pipe(takeUntil(this.destroy$)).subscribe((items) => {
      this.workRegimes = items || [];
    });
    this.loaderService.startLoading('Loading work regimes', () =>
      forkJoin([
        this.workRegimeFacade
          .getWorkRegimeDays$()
          .pipe(tap((data) => (this.workRegimeDays = data || []))),
        this.workRegimeFacade
          .getWorkRegime$()
          .pipe(tap((data) => (this.workRegimes = data || []))),
      ]).pipe(
        switchMap(() =>
          this.organizationFacade.groupUsers$.pipe(
            map((users) => users.filter((u) => u.user))
          )
        ),
        tap((users) => {
          this.users.push(...users);
          const employeesFormArray = this.form.get('employees') as FormArray;
          users.forEach((user) => {
            employeesFormArray.push(
              new FormGroup({
                user: new FormControl(user),
                hoursPerDay: new FormControl({
                  value:
                    (this.getWorkRegimeCapacity(user.id) ||
                      this.config?.standardWorkingHours ||
                      0) /
                    (this.getAmountOfWorkDaysForEmployee(user.id) / 4 ||
                      this.amountOfWorkDays ||
                      this.config?.standardWorkingHours ||
                      0),
                  disabled: true,
                }),
                hoursPerWeek: new FormControl(
                  this.getWorkRegimeCapacity(user.user?.id || '') ||
                    this.config?.standardWorkingHours ||
                    0
                ),
                selectedOption: new FormControl(
                  this.getWorkRatio(user.user?.id || '') || 'fullTime'
                ),
              })
            );
          });
        })
      )
    );

    // users$.pipe(first()).subscribe((users) => {
    //   this.users.push(...users);
    //   const employeesFormArray = this.form.get('employees') as FormArray;
    //   users.forEach((user) => {
    //     employeesFormArray.push(
    //       this.formBuilder.group({
    //         user: user,
    //         hoursPerDay: {
    //           value:
    //             (this.getWorkRegimeCapacity(user.user?.id || '') ||
    //               this.config?.standardWorkingHours ||
    //               0) /
    //             (this.getAmountOfWorkDaysForEmployee(user.user?.id || '') ||
    //               this.amountOfWorkDays ||
    //               this.config?.standardWorkingHours ||
    //               0),
    //           disabled: true,
    //         },
    //         hoursPerWeek:
    //           this.getWorkRegimeCapacity(user.user?.id || '') ||
    //           this.config?.standardWorkingHours ||
    //           0,
    //         selectedOption:
    //           this.getWorkRatio(user.user?.id || '') || 'fullTime',
    //       })
    //     );
    //   });
    // });
  }

  updateStandardWorkdayConfiguration(isChecked: boolean) {
    this.amountOfWorkDays = isChecked
      ? this.amountOfWorkDays + 1
      : this.amountOfWorkDays - 1;
    this.workRatios = this.getWorkRatios();
  }

  updateWorkingHours() {
    const employeesFormArray = this.form.get('employees') as FormArray;
    const employeesData = employeesFormArray.controls.map((control) => {
      return {
        user: control.get('user')?.value,
        hoursPerDay: control.get('hoursPerDay')?.value,
        capacity: control.get('hoursPerWeek')?.value,
        selectedWorkRegime: control.get('selectedOption')?.value,
      };
    });

    for (let i = 0; i < employeesData.length; i++) {
      if (employeesData[i].selectedWorkRegime === 'fullTime') {
        employeesFormArray.controls[i].patchValue({
          hoursPerDay:
            this.form.get('standardWorkingHours')?.value /
            this.amountOfWorkDays,
          hoursPerWeek: this.form.get('standardWorkingHours')?.value,
        });
      }
    }
  }

  getWorkRatios() {
    const headers = [];
    for (let i = 0; i < this.amountOfWorkDays; i++) {
      if (i === 0) {
        headers.push('fullTime');
      } else {
        const ratio = (this.amountOfWorkDays - i) / this.amountOfWorkDays;
        if (
          ratio === 0.5 ||
          ratio === 1 / 3 ||
          ratio === 2 / 5 ||
          ratio === 3 / 7
        ) {
          headers.push('halfTime');
          if (ratio !== 0.5) {
            headers.push(
              `${this.amountOfWorkDays - i}/${this.amountOfWorkDays}`
            );
          }
        } else {
          headers.push(`${this.amountOfWorkDays - i}/${this.amountOfWorkDays}`);
        }
      }
    }
    return headers;
  }

  getWorkRatioTranslation$(value: string) {
    return value.includes('/')
      ? of(value)
      : this.translations.getTranslation$(value);
  }

  filterUsers() {
    if (!this.searchText) {
      return this.users;
    }

    const searchTextLower = this.searchText.toLowerCase();

    return this.users.filter((user) => {
      return user.user?.displayName.toLowerCase().includes(searchTextLower);
    });
  }

  getWorkRegimeCapacity(userId?: string): number {
    const workRegime = this.workRegimes.find(
      (regime) => regime.user?.id === userId
    );
    if (workRegime) return workRegime.capacity;
    return 0;
  }

  getAmountOfWorkDaysForEmployee(userId?: string): number {
    const workRegimeDays = this.workRegimeDays.filter(
      (regime) => regime.workRegime?.user.id === userId
    );
    return workRegimeDays.filter(
      (regime) => regime.workRegimeType?.name !== '-'
    ).length;
  }

  editUserWorkRegime(user: GroupUser, selectedWorkRegime: string) {
    if (selectedWorkRegime !== 'fullTime') {
      const relevantWorkRegimeDays = this.workRegimeDays.filter(
        (regime) => regime.workRegime?.user.id === user.user?.id
      );
      this.detailsDialog.open(WorkRegimeSettingsComponent, {
        autoFocus: false,
        data: {
          user: user,
          regime: relevantWorkRegimeDays,
          amountOfWorkDays: this.amountOfWorkDays,
          selectedWorkRegime: selectedWorkRegime,
        },
        disableClose: true,
      });
    }
  }

  save() {
    const workdays: string[] = [];

    if (this.form.get('monday')?.value) {
      workdays.push('monday');
    }
    if (this.form.get('tuesday')?.value) {
      workdays.push('tuesday');
    }
    if (this.form.get('wednesday')?.value) {
      workdays.push('wednesday');
    }
    if (this.form.get('thursday')?.value) {
      workdays.push('thursday');
    }
    if (this.form.get('friday')?.value) {
      workdays.push('friday');
    }
    if (this.form.get('saturday')?.value) {
      workdays.push('saturday');
    }
    if (this.form.get('sunday')?.value) {
      workdays.push('sunday');
    }
    const standardWorkingHours = this.form.get('standardWorkingHours')?.value;

    const configuration = new AppConfigOoO({
      workDays: workdays,
      holidaysEnabled: true,
      standardWorkingHours: standardWorkingHours,
    });
    this.appFacade
      .updateAppConfig$('Ishtar.OoO', AppConfigOoO, configuration)
      .subscribe();

    const employeesFormArray = this.form.get('employees') as FormArray;

    const employeesData = employeesFormArray.controls.map((control) => {
      return {
        user: control.get('user')?.value,
        hoursPerDay: control.get('hoursPerDay')?.value,
      };
    });

    const workRegimesToUpdate: WorkRegime[] = [];
    const workRegimesToAdd: WorkRegime[] = [];
    employeesData.forEach((employee) => {
      const workRegime = this.workRegimes.find(
        (regime) => regime.user.user?.id === employee.user.user?.id
      );
      if (workRegime) {
        const newWorkRegime: WorkRegime = {
          id: workRegime.id,
          user: workRegime.user,
          capacity: employeesFormArray.controls
            .find(
              (control) =>
                control.get('user')?.value.user?.id === workRegime.user.user?.id
            )
            ?.get('hoursPerWeek')?.value,
        };
        workRegimesToUpdate.push(newWorkRegime);
      } else if (
        employeesFormArray.controls
          .find(
            (control) =>
              control.get('user')?.value.user?.id === employee.user.user?.id
          )
          ?.get('hoursPerWeek')?.value > 0
      ) {
        const newWorkRegime = new WorkRegime({
          user: employee.user,
          capacity: employeesFormArray.controls
            .find(
              (control) =>
                control.get('user')?.value.user?.id === employee.user.user?.id
            )
            ?.get('hoursPerWeek')?.value,
        });
        workRegimesToAdd.push(newWorkRegime);
      }
    });
    forkJoin([
      this.workRegimeFacade.updateWorkRegimes(workRegimesToUpdate),
      this.workRegimeFacade.addWorkRegimes(workRegimesToAdd),
    ]);
  }

  getWorkRatio(userId: string): string {
    const employeeWorkDaysPerWeek =
      this.getAmountOfWorkDaysForEmployee(userId || '') / 4;
    if (employeeWorkDaysPerWeek === 0) return '';
    const companyWorkDaysPerWeek = this.amountOfWorkDays;
    const ratio = employeeWorkDaysPerWeek / companyWorkDaysPerWeek;

    const targetFractions = [];
    for (let i = 1; i <= this.amountOfWorkDays; i++) {
      targetFractions.push(i / this.amountOfWorkDays);
    }
    targetFractions.push(0.5);

    let closestFraction = targetFractions[0];
    let closestDifference = Math.abs(ratio - targetFractions[0]);

    targetFractions.forEach((fraction) => {
      const difference = Math.abs(ratio - fraction);
      if (difference < closestDifference) {
        closestFraction = fraction;
        closestDifference = difference;
      }
    });

    for (let i = 1; i <= this.amountOfWorkDays; i++) {
      if (closestFraction === 0.5) return 'halfTime';
      if (closestFraction === i / this.amountOfWorkDays) {
        if (closestFraction === 1) return 'fullTime';
        const workRegimeString = `${i}/${this.amountOfWorkDays}`;
        return workRegimeString;
      }
    }
    return '';
  }

  validateHoursPerDay(
    user: GroupUser,
    hoursPerWeek: number,
    employeeGroup: AbstractControl
  ) {
    const employeesFormArray = this.form.get('employees') as FormArray;
    let validationString = '';

    employeesFormArray.controls.forEach((control) => {
      if (control === employeeGroup) {
        employeeGroup.setValue({
          user: user,
          hoursPerDay:
            hoursPerWeek /
            (this.getAmountOfWorkDaysForEmployee(user.user?.id || '') / 4 ||
              this.amountOfWorkDays),
          hoursPerWeek: hoursPerWeek,
          selectedOption: this.getWorkRatio(user.user?.id || '') || 'fullTime',
        });
        const hoursPerDay = control.get('hoursPerDay')?.value;
        if (hoursPerDay > 10) {
          validationString =
            'This user works more than 10 hours per day. You might want adjust the hours per week or workregime for this user.';
        } else {
          validationString = '';
        }
        this.validationParagraph.nativeElement.textContent = validationString;
      }
    });

    // employeesFormArray.push(this.formBuilder.group({
    //   user: employee.user,
    //   hoursPerDay: {value: this.getWorkRegimeCapacity(employee.user?.id || '')/(this.getAmountOfWorkDaysForEmployee(employee.user?.id || '')/4) ||0, disabled: true},
    //   hoursPerWeek: this.getWorkRegimeCapacity(employee.user?.id || ''),
    //   selectedOption: this.getWorkRatio(employee.user?.id || ''),
    // }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
