import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Ishtar365Service } from '../../services/ishtar365.service';
import { getLibraries, getLibrariesResolved } from './library.actions';

@Injectable({ providedIn: 'root' })
export class LibraryEffects {
  constructor(private actions$: Actions, private ishtar365: Ishtar365Service) {}

  getLibraries = createEffect(() =>
    this.actions$.pipe(
      ofType(getLibraries),
      switchMap(({ callback, error }) =>
        this.ishtar365.getLibraries().pipe(
          tap((libraries) => (callback ? callback(libraries) : undefined)),
          switchMap((libraries) => of(getLibrariesResolved({ libraries }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  ); 
}
