<div class="d-flex flex-row">
  <div class="d-flex flex-column gap-2">
    <div>
      <mat-card class="typeOfSyncCard">
        <mat-card-header>
          <mat-card-title>{{ getTranslation$("type") | async }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-radio-group
            class="d-flex flex-column"
            [formControl]="typeOfSyncControl"
          >
            <mat-radio-button value="users">{{
              getTranslation$("users") | async
            }}</mat-radio-button>
            <mat-radio-button value="groups">{{
              getTranslation$("groups") | async
            }}</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>
    </div>
    <div>
      <form [formGroup]="intervalForm" autocomplete="off" #i="ngForm">
        <mat-card class="interval">
          <mat-card-header>
            <mat-card-title>Interval:</mat-card-title>
            <p class="titleDescription">
              {{ getTranslation$("aadsyncIntervalDescription") | async }}
            </p>
          </mat-card-header>
          <mat-card-content>
            <mat-radio-group
              #intervalValue="matRadioGroup"
              class="d-flex flex-column"
              formControlName="interval"
            >
              <mat-radio-button value="none">None</mat-radio-button>
              <mat-radio-button value="daily">Daily</mat-radio-button>
              <mat-radio-button value="weekly">Weekly</mat-radio-button>
            </mat-radio-group>
            <mat-form-field
              appearance="outline"
              *ngIf="intervalValue.value !== 'none'"
              class="w-100 noSubscript"
            >
              <mat-label>
                <ng-container
                  *ngIf="getTranslation$('startTime') | async as timeLabel"
                  >{{ timeLabel }}</ng-container
                ></mat-label
              >
              <input
                class="inputFontSize"
                #startTimeInput
                matInput
                [ngxTimepicker]="startTimePicker"
                [format]="24"
                formControlName="startTime"
                [disableClick]="true"
              />
              <ngx-material-timepicker-toggle
                [for]="startTimePicker"
                matIconSuffix
                class="timepicker-icon"
              >
              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker
                [theme]="procDelTheme"
                [appendToInput]="true"
                #startTimePicker
                defaultTime="00:00"
              ></ngx-material-timepicker>
            </mat-form-field>
            <mat-form-field
              *ngIf="intervalValue.value === 'weekly'"
              appearance="outline"
              class="w-100 noSubscript"
            >
              <mat-label>Day</mat-label>
              <mat-select formControlName="day">
                <mat-option *ngFor="let day of days" [value]="day.id">
                  {{ day.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </form>
    </div>
  </div>
  <div class="sync-settings-wrapper d-flex flex-column h-100">
    <div
      class="d-flex flex-sm-row flex-xs-row flex-column w-100 min-height-0 gap-2"
    >
      <div [formGroup]="mappingForm" autocomplete="off" #f="ngForm">
        <ng-container formArrayName="mappingRows">
          <mat-card class="fieldmapping">
            <mat-card-header>
              <mat-card-title>Field mapping:</mat-card-title>
              <p class="titleDescription">
                {{
                  getTranslation$("aadsyncAzureContactMappingDescription")
                    | async
                }}
              </p>
            </mat-card-header>
            <mat-card-content class="scrollbar">
              <ng-container
                *ngFor="let mapping of fieldMappings.controls; let i = index"
              >
                <div [formGroupName]="i" class="fieldRow">
                  <div class="contactField">
                    {{ getFieldDisplayName(i) }}
                  </div>
                  <mat-icon>arrow_right_alt</mat-icon>
                  <div class="azureField">
                    <mat-form-field
                      appearance="outline"
                      class="w-100 noSubscript"
                    >
                      <mat-label>Azure field</mat-label>
                      <mat-select formControlName="azureFieldId">
                        <mat-option
                          *ngFor="let azureField of azureFields"
                          [value]="azureField.id"
                        >
                          {{ azureField.displayName }}
                        </mat-option>
                      </mat-select>
                      <button
                        *ngIf="mapping.get('azureFieldId')?.value"
                        mat-icon-button
                        matSuffix
                        (click)="removeMapping(i); $event.stopPropagation()"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                </div>
                <div
                  class="fieldRow"
                  *ngIf="i === 0 && typeOfSyncControl.value === 'users'"
                >
                  <mat-checkbox
                    (change)="toggleUseDelimter($event.checked)"
                    [checked]="useDelimiter"
                    >Department delimiter
                  </mat-checkbox>
                  <div></div>
                  <mat-form-field
                    appearance="outline"
                    class="w-100 noSubscript"
                    *ngIf="useDelimiter"
                  >
                    <mat-label>Delimiter</mat-label>
                    <input [formControl]="delimiterFormControl" matInput />
                  </mat-form-field>
                </div>
              </ng-container>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </div>
      <div *ngIf="typeOfSyncControl.value === 'users'">
        <div>
          <mat-card>
            <form [formGroup]="roleForm" autocomplete="off" #r="ngForm">
              <mat-card-header>
                <mat-card-title>Role mapping:</mat-card-title>
                <button
                  mat-mini-fab
                  class="ms-auto"
                  (click)="addRole()"
                  [style.background-color]="navColor"
                  [style.color]="navContrast"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </mat-card-header>
              <mat-card-content>
                <div class="wrapper d-flex flex-column h-100 min-height-0">
                  <div class="basedOn d-flex align-items-center">
                    <div class="flexShrink">Based on:</div>
                    <mat-form-field
                      appearance="outline"
                      class="w-100 noSubscript"
                    >
                      <mat-label>Azure field</mat-label>
                      <mat-select formControlName="basedOnField">
                        <mat-option
                          *ngFor="let azureField of azureFields"
                          [value]="azureField.id"
                        >
                          {{ azureField.displayName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="roleRows">
                    <ng-container formArrayName="roleRows">
                      <ng-container
                        *ngFor="
                          let role of roleMappings.controls;
                          let i = index;
                          let last = last
                        "
                      >
                        <div [formGroupName]="i" class="roleRow">
                          <div
                            class="d-flex flex-column align-items-center justify-content-center"
                          >
                            <div
                              class="d-flex align-items-center justify-content-between w-90"
                            >
                              <mat-form-field appearance="outline">
                                <mat-label> {{ i + 1 }}</mat-label>
                                <input formControlName="roleValue" matInput />
                                <mat-hint align="end"
                                  ><mat-checkbox
                                    (change)="onSupervisorChange(i)"
                                    formControlName="isSupervisor"
                                    ><span style="font-size: 0.8rem"
                                      >Department supervisor</span
                                    ></mat-checkbox
                                  ></mat-hint
                                >
                              </mat-form-field>
                              <button
                                class="removeRoleBtn"
                                mat-mini-fab
                                color="warn"
                                (click)="removeRole(i)"
                              >
                                <mat-icon class="text-white">delete</mat-icon>
                              </button>
                            </div>
                            <mat-icon [hidden]="last">arrow_downward</mat-icon>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </mat-card-content>
            </form>
          </mat-card>
        </div>
      </div>
      <div class="d-flex flex-column gap-2 min-width-0">
        <div *ngIf="typeOfSyncControl.value === 'users'">
          <div>
            <mat-card>
              <form
                [formGroup]="departmentUserMappingForm"
                autocomplete="off"
                #r="ngForm"
              >
                <mat-card-header>
                  <mat-card-title>Department mapping:</mat-card-title>
                  <p class="titleDescription">
                    {{ getTranslation$("aadsyncAzureUserMapping") | async }}
                  </p>
                  <p class="titleDescription">
                    Only users with the specified department title will be
                    synced
                  </p>
                </mat-card-header>
                <mat-card-content>
                  <div
                    [formGroup]="departmentMappingFormGroup"
                    class="basedOn d-flex align-items-center"
                  >
                    <div class="flex-shrink-0 titleField">Title:</div>
                    <mat-form-field
                      appearance="outline"
                      class="w-100 noSubscript"
                    >
                      <mat-label>Azure field</mat-label>
                      <mat-select formControlName="basedOnField">
                        <mat-option
                          *ngFor="let azureField of azureFields"
                          [value]="azureField.id"
                        >
                          {{ azureField.displayName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-icon class="flex-shrink-0">arrow_right_alt</mat-icon>
                    <mat-form-field
                      appearance="outline"
                      class="w-100 noSubscript"
                    >
                      <mat-label>Title</mat-label>
                      <input formControlName="nameValue" matInput />
                    </mat-form-field>
                  </div>
                  <div
                    class="basedOn d-flex align-items-center"
                    [formGroup]="departmentNumberMappingFormGroup"
                  >
                    <div class="flex-shrink-0 titleField">Tel:</div>
                    <mat-form-field
                      appearance="outline"
                      class="w-100 noSubscript"
                    >
                      <mat-label>Azure field</mat-label>
                      <mat-select formControlName="basedOnField">
                        <mat-option
                          *ngFor="let azureField of azureFields"
                          [value]="azureField.id"
                        >
                          {{ azureField.displayName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    class="basedOn d-flex align-items-center"
                    [formGroup]="departmentNameMappingFormGroup"
                  >
                    <div class="flex-shrink-0 titleField">Name:</div>
                    <mat-form-field
                      appearance="outline"
                      class="w-100 noSubscript"
                    >
                      <mat-label>Azure field</mat-label>
                      <mat-select formControlName="basedOnField">
                        <mat-option
                          *ngFor="let azureField of azureFields"
                          [value]="azureField.id"
                        >
                          {{ azureField.displayName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </form>
            </mat-card>
          </div>
        </div>
        <div *ngIf="typeOfSyncControl.value === 'users'">
          <div>
            <mat-card>
              <form [formGroup]="adminForm" autocomplete="off" #a="ngForm">
                <mat-card-header>
                  <mat-card-title>Application admin mapping:</mat-card-title>
                  <p class="titleDescription">
                    {{ getTranslation$("aadsyncUserAdminDescription") | async }}
                  </p>
                </mat-card-header>
                <mat-card-content>
                  <div class="basedOn d-flex align-items-center">
                    <div class="flex-shrink-0 titleField">Title:</div>
                    <mat-form-field
                      appearance="outline"
                      class="w-100 noSubscript"
                    >
                      <mat-label>Azure field</mat-label>
                      <mat-select formControlName="basedOnField">
                        <mat-option
                          *ngFor="let azureField of azureFields"
                          [value]="azureField.id"
                        >
                          {{ azureField.displayName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-icon class="flex-shrink-0">arrow_right_alt</mat-icon>
                    <mat-form-field
                      appearance="outline"
                      class="w-100 noSubscript"
                    >
                      <mat-label>Title</mat-label>
                      <input formControlName="nameValue" matInput />
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </form>
            </mat-card>
          </div>
        </div>
        <div class="d-flex flex-column gap-2">
          <mat-card>
            <form [formGroup]="wildCardForm" autocomplete="off" #a="ngForm">
              <mat-card-header>
                <mat-card-title>Department wildcard mapping:</mat-card-title>
                <p class="titleDescription">
                  {{ getTranslation$("aadsyncWildcardDescription") | async }}
                </p>
              </mat-card-header>
              <mat-card-content>
                <div class="basedOn d-flex align-items-center">
                  <div class="flex-shrink-0 titleField">Title:</div>
                  <mat-form-field
                    appearance="outline"
                    class="w-100 noSubscript"
                  >
                    <mat-label>Azure field</mat-label>
                    <mat-select formControlName="basedOnField">
                      <mat-option
                        *ngFor="let azureField of azureFields"
                        [value]="azureField.id"
                      >
                        {{ azureField.displayName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-icon class="flex-shrink-0">arrow_right_alt</mat-icon>
                  <mat-form-field
                    appearance="outline"
                    class="w-100 noSubscript"
                  >
                    <mat-label>Title</mat-label>
                    <input formControlName="nameValue" matInput />
                  </mat-form-field>
                </div>
              </mat-card-content>
            </form>
          </mat-card>

          <mat-card
            class="flex-grow-1"
            *ngIf="typeOfSyncControl.value === 'groups'"
          >
            <mat-card-header>
              <mat-card-title
                class="d-flex flex-row justify-content-between w-100 align-items-center"
                >Admin Groups:
                <!-- only one top deparmtent is supported
                  <button
                    mat-mini-fab
                    (click)="addNewDepConfig()"
                    matTooltip="Add department"
                  >
                    <mat-icon>add</mat-icon>
                  </button> -->
              </mat-card-title>
              <p class="titleDescription">
                {{ getTranslation$("aadsyncGroupAdminDescription") | async }}
              </p>
            </mat-card-header>
            <mat-card-content>
              <div class="d-flex flex-column p-2">
                <mat-form-field
                  appearance="outline"
                  (click)="$event.stopPropagation()"
                >
                  <mat-label>
                    <ng-container
                      *ngIf="getTranslation$('search') | async as searchLabel"
                      >{{ searchLabel }}...</ng-container
                    >
                  </mat-label>
                  <input
                    matInput
                    type="text"
                    [formControl]="adminGroupsFilterControl"
                    [matAutocomplete]="auto"
                    #searchControl
                  />
                  <div matSuffix>
                    <mat-spinner [diameter]="30" *ngIf="isLoadingadminGroups"></mat-spinner>
                  </div>
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option
                      *ngFor="let group of adminGroups"
                      [value]="group.displayName"
                      (click)="addAdminGroup(group); $event.stopPropagation()"
                    >
                      {{ group.displayName }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <div class="d-flex flex-column gap-2">
                  <div
                    *ngFor="let group of adminGroupsControl.value"
                    class="d-flex flex-row align-items-center"
                  >
                    <button
                      mat-mini-fab
                      color="primary"
                      class="smallbtn me-2"
                      (click)="deleteAdminGroup(group)"
                    >
                      <mat-icon>remove</mat-icon>
                    </button>
                    <div>
                      {{ group.name }}
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <div
        *ngIf="typeOfSyncControl.value === 'groups'"
        class="d-flex flex-row gap-2 align-items-start"
      >
        <mat-card class="flex-grow-1">
          <mat-card-header>
            <mat-card-title
              class="d-flex flex-row justify-content-between w-100 align-items-center"
              >Departments:
              <!-- only one top deparmtent is supported
                <button
                  mat-mini-fab
                  (click)="addNewDepConfig()"
                  matTooltip="Add department"
                >
                  <mat-icon>add</mat-icon>
                </button> -->
            </mat-card-title>
            <p class="titleDescription">
              {{
                getTranslation$("aadsyncGroupDepartmentConfigDescription")
                  | async
              }}
            </p>
          </mat-card-header>
          <mat-card-content>
            <div class="p-2">
              <div
                class="departmentDiv"
                appElementRefDirective
                #parentRef="elementRefDirective"
              >
                <div
                  *ngFor="
                    let dep of groupSyncDepartmentDefinitions.controls;
                    let first = first
                  "
                  (click)="selectActiveDepartment(dep)"
                  class="departmentRow scrollbar"
                >
                  <app-group-tree
                    [root]="true"
                    [formGroup]="dep"
                    [pane]="parentRef.elementRef.nativeElement"
                    (cardSelected)="selectActiveDepartment($event)"
                    [cardMovedSubject]="cardMovedSubject"
                  >
                  </app-group-tree>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <app-group-picker
          *ngIf="selectedGroupSyncDep"
          [formGroup]="selectedGroupSyncDep"
          (closeMenu)="selectedGroupSyncDep = undefined"
        ></app-group-picker>
      </div>
    </div>
    <!-- <div class="w-100 min-height-0" *ngIf="extraDepConfig">
    <mat-card>
      <form [formGroup]="departmentForm" autocomplete="off" #r="ngForm">
        <mat-card-header>
          <mat-card-title>Department config:</mat-card-title>
          <button
            mat-mini-fab
            class="ms-auto"
            (click)="addDepartment()"
            [style.background-color]="navColor"
            [style.color]="navContrast"
          >
            <mat-icon>add</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content>
          <ng-container formArrayName="departmentRows">
            <ng-container
              *ngFor="
                let department of departmentMappings.controls;
                let i = index
              "
            >
              <div
                [formGroupName]="i"
                class="departmentRow d-flex align-items-start"
              >
                <div class="depNumber flexShrink">{{ i + 1 }}:</div>
                <mat-form-field appearance="outline" class="w-100 noSubscript">
                  <mat-label>Name</mat-label>
                  <input matInput formControlName="name" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100 noSubscript">
                  <mat-label>Supervisor</mat-label>
                  <mat-select formControlName="supervisor">
                    <mat-option *ngFor="let user of users" [value]="user.id">
                      {{ user.displayName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field
                  class="example-chip-list w-100 noSubscript"
                  appearance="outline"
                >
                  <mat-label>Aliases</mat-label>
                  <mat-chip-grid #chipGrid>
                    <mat-chip-row
                      *ngFor="let alias of getAliases(i)"
                      (removed)="removeAlias(alias, i)"
                      [editable]="true"
                      (edited)="editAlias(alias, $event, i)"
                    >
                      {{ alias }}
                      <button matChipRemove>
                        <mat-icon class="cancelChipIcon">cancel</mat-icon>
                      </button>
                    </mat-chip-row>
                    <input
                      placeholder="New alias..."
                      [matChipInputFor]="chipGrid"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="addOnBlur"
                      (matChipInputTokenEnd)="addAlias($event, i)"
                    />
                  </mat-chip-grid>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100 noSubscript">
                  <mat-label>Tel</mat-label>
                  <input matInput formControlName="departmentTelNum" />
                </mat-form-field>
                <button
                  class="removeDepBtn"
                  mat-mini-fab
                  color="warn"
                  (click)="removeDepartment(i)"
                >
                  <mat-icon class="text-white">delete</mat-icon>
                </button>
              </div>
            </ng-container>
          </ng-container>
        </mat-card-content>
      </form>
    </mat-card>
  </div> -->
  </div>
</div>
