import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Ishtar365Service } from '../../services/ishtar365.service';
import { getMetadataParameters, getMetadataParametersResolved } from './metadataParameter.actions';

@Injectable({ providedIn: 'root' })
export class MetadataParametersEffects {
  constructor(private actions$: Actions, private ishtar365: Ishtar365Service) {}

  getMetadataParameters = createEffect(() =>
    this.actions$.pipe(
      ofType(getMetadataParameters),
      switchMap(({ callback, error }) =>
        this.ishtar365.getMetadataParameters().pipe(
          tap((metadataParameters) => (callback ? callback(metadataParameters) : undefined)),
          switchMap((metadataParameters) => of(getMetadataParametersResolved({ metadataParameters }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
