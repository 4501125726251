<div class="dialogContainer">
  <h1
    mat-dialog-title
    *ngIf="getTranslation$('linkSkills') | async as linkSkillsToUser"
  >
    {{ linkSkillsToUser }}
  </h1>
  <mat-icon class="closeButtonIcon" (click)="onCloseDialog()">close</mat-icon>
  <div mat-dialog-content class="p-3 scrollbar">
    <form [formGroup]="skillForm">
      <div
        class="row"
        style="display: flex; justify-content: center; align-items: center"
      >
        <div class="col-md-6">
          <mat-form-field
            (click)="onUserDropdownClick()"
            class="clickable"
            appearance="outline"
          >
            <mat-label
              class="clickable"
              *ngIf="getTranslation$('user') | async as user"
              >{{ user }}</mat-label
            >
            <mat-select
              formControlName="user"
              (selectionChange)="onUserChange($event)"
              (openedChange)="userControl.patchValue('')"
              panelClass="dropdown-panel"
              [placeholder]="(getTranslation$('selectUser') | async) ?? ''"
            >
              <input
                #userDropdown
                [formControl]="userControl"
                type="text"
                name="filter-options"
                id="filter-options"
                [placeholder]="getTranslation$('searchUser') | async"
                class="searchBar"
                autocomplete="off"
              />
              <div class="scrollbar">
                <mat-option
                  *ngIf="userFormControlVal"
                  [value]="userFormControl.value"
                  >{{ userFormControlVal }}
                </mat-option>
                <mat-option
                  *ngFor="let user of filteredUsers | async"
                  [value]="user.user?.id"
                >
                  {{ user.user?.displayName }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field
            (click)="onSkillDropdownClick()"
            class="clickable"
            appearance="outline"
          >
            <mat-label
              class="clickable"
              *ngIf="getTranslation$('skill') | async as skillLabel"
            >
              {{ skillLabel }}
            </mat-label>
            <mat-select
              formControlName="skill"
              (selectionChange)="onSkillChange($event)"
              (openedChange)="skillControl.patchValue('')"
              panelClass="dropdown-panel"
              [multiple]="true"
            >
              <input
                #skillDropdown
                [formControl]="skillControl"
                type="text"
                name="filter-options"
                id="filter-options"
                [placeholder]="getTranslation$('searchSkill') | async"
                class="searchBar"
                autocomplete="off"
              />
              <div class="scrollbar">
                <ng-container *ngIf="skillControlVal?.length">
                  <mat-option
                    *ngFor="let skillVal of skillControlVal"
                    [value]="skillVal"
                    >{{ getSkillValue(skillVal) }}
                  </mat-option>
                </ng-container>
                <mat-option
                  *ngFor="let skill of filteredSkills | async"
                  [value]="skill.id"
                >
                  {{ skill.title }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row rowbuttons">
        <button
          mat-raised-button
          class="addButton"
          type="submit"
          (click)="onSave()"
          *ngIf="getTranslation$('save') | async as save"
        >
          <mat-icon>save</mat-icon>
          {{ save }}
        </button>
      </div>
    </form>
  </div>
</div>
