<div
  class="window-container position-relative"
  *ngIf="{
    orgLogo: orgLogo$ | async,
    userName: userName$ | async,
    userPhoto: userPhoto$ | async,
    navColor: navColor$ | async,
    navContrast: navContrast$ | async,
    signedIn: signedIn$ | async,
  } as data"
>
  <processdelight-loader
    [appName]="appName"
    appLogo="../../assets/Icon_Ishtar365.svg"
    [navColor]="data.navColor ?? 'var(--processdelightBlue)'"
  ></processdelight-loader>
  <app-top-bar
    [appName]="appName"
    [orgLogo]="data.orgLogo ?? undefined"
    [userPhoto]="data.userPhoto ?? undefined"
    [navColor]="data.navColor ?? 'var(--processdelightBlue)'"
  ></app-top-bar>
  <div
    class="position-absolute w-100"
    [style.height]="framesShown ? 'calc(100% - 48px)' : '0'"
    [style.top.px]="48"
    [style.left]="0"
    *ngIf="data.signedIn"
  >
    <app-app-page></app-app-page>
  </div>
  <div class="h-100 w-100 min-height-0">
    <ng-content></ng-content>
  </div>
</div>
