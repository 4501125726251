import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationEnd,
  Route,
  Router,
} from '@angular/router';
import {
  ApproveNotification,
  ApproveNotificationMessage,
  ContextMenuAction,
  MessageAction,
  Notification,
  NotificationMessage,
  NotificationService,
  ProgressNotification,
  ProgressNotificationMessage,
  RedirectAction,
} from 'processdelight-angular-components';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  combineLatest,
  debounceTime,
  filter,
  first,
  takeUntil,
} from 'rxjs';
import { EmptyComponent } from 'src/app/layout/empty/empty.component';
import { AppFrame } from '../domain/models/app-frame.model';
import { UserSettings } from '../domain/models/user-settings.model';
import { AppSubscriptionFacade } from '../store/app-subscription/app-subscription.facade';
import { UserFacade } from '../store/user/user.facade';

@Injectable({
  providedIn: 'root',
})
export class FrameManagerService {
  activeApp$ = new BehaviorSubject<AppFrame | undefined>(undefined);
  lastActiveAppStack: AppFrame[] = [];
  openApps: AppFrame[] = [];
  possibleApps: AppFrame[] = [];

  openApp$ = new Subject<AppFrame>();
  closeApp$ = new Subject<AppFrame>();

  componentShown = false;

  private lastActiveAppStackMaxSize = 50;
  private pendingRedirects: { [key: string]: RedirectAction } = {};
  private startupApp?: string;

  private routeSubscription?: Subscription;

  private destroy$ = new Subject<void>();

  private userSettingsUpdateSubject = new Subject<UserSettings>();

  constructor(
    private readonly appFacade: AppSubscriptionFacade,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly userFacade: UserFacade
  ) {
    this.userSettingsUpdateSubject
      .pipe(takeUntil(this.destroy$), debounceTime(300))
      .subscribe((settings) => {
        this.userFacade.updateUserSettings$(settings).subscribe();
      });
  }

  onDestroy() {
    window.removeEventListener('message', this.handleMessageEventBound);
    this.destroy$.next();
    this.destroy$.complete();
  }

  private mapMessageAction(a: MessageAction): ContextMenuAction<unknown> {
    return new ContextMenuAction<unknown>({
      id: a.action,
      label: a.label,
      icon: a.icon,
      iconOutline: a.iconOutline,
      isSymbol: a.isSymbol,
      action: () => this.invokeAction(a.action),
      index: a.index,
      children: a.children.map(this.mapMessageAction.bind(this)),
    });
  }

  private mapNotificationMessage(a: NotificationMessage): Notification {
    return new Notification({
      id: a.id,
      label: a.label,
      message: a.message,
      type: a.type,
      canClose: a.canClose,
      closeAction: () =>
        a.closeAction ? this.invokeAction(a.closeAction) : undefined,
      clickAction: () =>
        a.clickAction ? this.invokeAction(a.clickAction) : undefined,
    });
  }

  private mapProgressNotificationMessage(
    a: ProgressNotificationMessage
  ): ProgressNotification {
    return new ProgressNotification({
      id: a.id,
      label: a.label,
      message: a.message,
      type: a.type,
      canClose: a.canClose,
      closeAction: () =>
        a.closeAction ? this.invokeAction(a.closeAction) : undefined,
      clickAction: () =>
        a.clickAction ? this.invokeAction(a.clickAction) : undefined,
      progress: a.progress,
    });
  }

  private mapApprovalNotificationMessage(
    a: ApproveNotificationMessage
  ): ApproveNotification {
    return new ApproveNotification({
      id: a.id,
      label: a.label,
      message: a.message,
      type: a.type,
      canClose: a.canClose,
      closeAction: () =>
        a.closeAction ? this.invokeAction(a.closeAction) : undefined,
      clickAction: () =>
        a.clickAction ? this.invokeAction(a.clickAction) : undefined,
      approveAction: () =>
        a.approveAction ? this.invokeAction(a.approveAction) : undefined,
      rejectAction: () =>
        a.rejectAction ? this.invokeAction(a.rejectAction) : undefined,
      rejectDisabled: a.rejectDisabled,
    });
  }

  private handleMessageEvent(e: MessageEvent) {
    if (
      !this.openApps.some((a) => e.origin.startsWith(new URL(a.appUrl).origin))
    )
      return;
    const app = this.openApps.find((a) =>
      e.origin.startsWith(new URL(a.appUrl).origin)
    );
    if (app && e.data?.message) {
      switch (e.data.message) {
        case 'registerRedirectActions':
          app.redirectActions = e.data.data;
          break;
        case 'registerRedirectAction':
          app.redirectActions.push(e.data.data);
          break;
        case 'unregisterRedirectActions':
          app.redirectActions = [];
          break;
        case 'unregisterRedirectAction':
          app.redirectActions = app.redirectActions.filter(
            (a) => a !== e.data.data
          );
          break;
        case 'addNotification':
          this.notificationService.addNotification(
            this.mapNotificationMessage(e.data.data)
          );
          break;
        case 'addProgressNotification':
          this.notificationService.addNotification(
            this.mapProgressNotificationMessage(e.data.data)
          );
          break;
        case 'addApprovalNotification':
          this.notificationService.addNotification(
            this.mapApprovalNotificationMessage(e.data.data)
          );
          break;
        case 'updateNotification':
          this.notificationService.updateNotification(
            this.mapNotificationMessage(e.data.data)
          );
          break;
        case 'updateProgressNotification':
          this.notificationService.updateNotification(
            this.mapProgressNotificationMessage(e.data.data)
          );
          break;
        case 'updateApprovalNotification':
          this.notificationService.updateNotification(
            this.mapApprovalNotificationMessage(e.data.data)
          );
          break;
        case 'removeNotification':
          this.notificationService.removeNotification({
            id: e.data.data,
            closeAction: () => this.invokeAction(e.data.data + '-close'),
          } as Notification);
          break;
        case 'redirectToApp':
          this.appRedirect(
            e.data.data.appName,
            e.data.data.redirectAction,
            e.data.data.id,
            e.data.data.data
          );
          break;

        default:
          // console.error(`Unknown message from origin '${e.origin}'`, e.data);
          break;
      }
      if (
        this.pendingRedirects[app.appName] &&
        app.redirectActions.some(
          (a) => a == this.pendingRedirects[app.appName].name
        )
      ) {
        this.appRedirect(
          app.appName,
          this.pendingRedirects[app.appName].name,
          this.pendingRedirects[app.appName].id,
          this.pendingRedirects[app.appName].data
        );
        delete this.pendingRedirects[app.appName];
      }
    }
    // else {
    //   console.error(
    //     `Message from origin '${e.origin}' in incorrect format:`,
    //     e.data
    //   );
    // }
  }

  handleMessageEventBound = this.handleMessageEvent.bind(this);

  private registerMessageEventListener() {
    window.addEventListener('message', this.handleMessageEventBound);
  }

  registerApps(appNames: string[]) {
    if (!this.routeSubscription)
      this.routeSubscription = this.router.events
        .pipe(
          takeUntil(this.destroy$),
          filter((e) => e instanceof NavigationEnd)
        )
        .subscribe((event) => {
          const e = event as NavigationEnd;
          this.componentShown = e.urlAfterRedirects.includes('/app/');
          if (this.componentShown) {
            const appName = e.urlAfterRedirects.split('/app/')[1].split('/')[0];
            if (this.possibleApps.length) this.openApp(appName);
            else this.startupApp = appName;
          }
        });

    combineLatest([
      this.appFacade.apps$.pipe(first()),
      this.userFacade.userSettings$.pipe(first()),
    ]).subscribe(([apps, settings]) => {
      this.registerMessageEventListener();
      this.openApps = [];
      this.possibleApps = appNames
        .map((name) => apps.find((app) => app.name === name))
        .filter((a) => !!a)
        .map(
          (app) =>
            new AppFrame({
              appName: app!.name,
              appUrl: app!.appUrl,
              appIconUrl: app!.iconUrl,
            })
        );
      const ishtar365 = this.possibleApps.find(
        (a) => a.appName === 'Ishtar.365'
      );
      if (settings.openTabs.length) {
        settings.openTabs.forEach((appName) => {
          const app = this.possibleApps.find((a) => a.appName === appName);
          if (ishtar365 && app == ishtar365) {
            this.openApps.push(ishtar365);
            this.activeApp$.next(ishtar365);
          } else if (app) this.openApp(appName, false);
        });
      } else if (ishtar365) {
        this.openApps.push(ishtar365);
        this.activeApp$.next(ishtar365);
      }
      if (this.startupApp) {
        const startupApp = this.possibleApps.find(
          (a) => a.appName === this.startupApp
        );
        if (startupApp) {
          this.openApp(startupApp.appName);
        }
      } else if (ishtar365) {
        this.activeApp$.next(ishtar365);
      }
    });
  }

  addPossibleApp(appName: string) {
    const existingApp = this.possibleApps.find((a) => a.appName === appName);
    if (!existingApp) {
      this.appFacade.apps$.pipe(first()).subscribe((apps) => {
        const app = apps.find((a) => a.name === appName);
        if (app) {
          this.possibleApps.push(
            new AppFrame({
              appName: app.name,
              appUrl: app.appUrl,
              appIconUrl: app.iconUrl,
            })
          );
          const routerConfig = [...this.router.config];
          routerConfig.unshift({
            path: 'app/' + app.name,
            data: {
              breadcrumbTitle: app.name,
            },
            component: EmptyComponent,
            children: [
              {
                path: ':redirectAction',
                component: EmptyComponent,
                canActivate: [
                  (snapshot: ActivatedRouteSnapshot) => {
                    const service = inject(FrameManagerService);
                    const action = snapshot.params.redirectAction;
                    const id = snapshot.queryParams.id;
                    service.appRedirect(app.name, action, id);
                    return true;
                  },
                ],
              },
            ],
          } as Route);
          this.router.resetConfig(routerConfig);
        }
      });
    }
  }
  removePossibleApp(appName: string) {
    this.possibleApps = this.possibleApps.filter((a) => a.appName !== appName);
    if (this.openApps.some((a) => a.appName === appName)) {
      this.closeApp(appName);
    }
    let routerConfig = [...this.router.config];
    routerConfig = routerConfig.filter((r) => r.path !== 'app/' + appName);
    this.router.resetConfig(routerConfig);
  }

  private addToLastActiveAppStack(app: AppFrame) {
    if (this.lastActiveAppStack.length && this.lastActiveAppStack[0] == app)
      return;
    this.lastActiveAppStack.unshift(app);
    if (this.lastActiveAppStack.length > this.lastActiveAppStackMaxSize)
      this.lastActiveAppStack.pop();
  }

  backToIshtar365() {
    if (this.activeApp$.value)
      this.addToLastActiveAppStack(this.activeApp$.value);
    const ishtar365 = this.possibleApps.find((a) => a.appName === 'Ishtar.365');
    this.activeApp$.next(ishtar365);

    this.router.navigate(['page']);
  }

  openApp(appName: string, navigate = true) {
    const existingApp = this.openApps.find((a) => a.appName === appName);
    if (existingApp && this.activeApp$.value !== existingApp) {
      this.openApp$.next(existingApp);
      if (this.activeApp$.value)
        this.addToLastActiveAppStack(this.activeApp$.value);
      this.activeApp$.next(existingApp);
    } else if (!existingApp) {
      const app = this.possibleApps.find((a) => a.appName === appName);
      if (app) {
        this.openApps.push(app);
        this.openApp$.next(app);
        if (this.activeApp$.value) {
          this.addToLastActiveAppStack(this.activeApp$.value);
        }
        this.activeApp$.next(app);
      }
    }
    this.userFacade.userSettings$.pipe(first()).subscribe((settings) => {
      if (!settings.openTabs.includes(appName))
        this.userSettingsUpdateSubject.next(
          new UserSettings({
            ...settings,
            openTabs: this.openApps.map((a) => a.appName),
          })
        );
    });
    if (navigate) this.router.navigate(['app', appName]);
  }

  closeApp(appName: string) {
    const existingApp = this.openApps.find((a) => a.appName === appName);
    if (existingApp) {
      this.openApps = this.openApps.filter((a) => a !== existingApp);
      this.closeApp$.next(existingApp);
      this.lastActiveAppStack = this.lastActiveAppStack.filter(
        (a) => a !== existingApp
      );
      if (this.activeApp$.value === existingApp) {
        const lastActiveApp = this.lastActiveAppStack.shift();
        this.activeApp$.next(lastActiveApp);
        if (lastActiveApp && lastActiveApp.appName !== 'Ishtar.365') {
          this.router.navigate(['app', lastActiveApp.appName]);
          this.openApp$.next(lastActiveApp);
        } else {
          this.router.navigate(['page']);
        }
      }
      this.userFacade.userSettings$.pipe(first()).subscribe((settings) => {
        if (settings.openTabs.includes(appName))
          this.userSettingsUpdateSubject.next(
            new UserSettings({
              ...settings,
              openTabs: this.openApps.map((a) => a.appName),
            })
          );
      });
    }
  }

  moveTab(prevIndex: number, newIndex: number) {
    moveItemInArray(this.openApps, prevIndex, newIndex);
  }

  passSearch(search: string) {
    if (this.activeApp$.value?.window) {
      this.activeApp$.value.window.postMessage(
        {
          message: 'search',
          data: search,
        },
        this.activeApp$.value.appUrl
      );
    }
  }

  updateLanguage(language: string) {
    if (this.openApps.length === 0) return;
    this.openApps.forEach((app) => {
      app.window?.postMessage(
        {
          message: 'language',
          data: language,
        },
        app.appUrl
      );
    });
  }

  invokeAction(action: string) {
    if (this.activeApp$.value?.window) {
      this.activeApp$.value.window.postMessage(
        action,
        this.activeApp$.value.appUrl
      );
    }
  }

  appRedirect(appName: string, redirect: string, id?: string, data?: unknown) {
    if (
      this.activeApp$.value?.appName === appName &&
      this.activeApp$.value.redirectActions.includes(redirect)
    ) {
      if (this.activeApp$.value?.window) {
        this.activeApp$.value.window.postMessage(
          {
            message: 'redirect',
            data: new RedirectAction({
              name: redirect,
              id,
              data,
            }),
          },
          this.activeApp$.value.appUrl
        );
      }
    } else {
      const app = this.possibleApps.find((a) => a.appName === appName);
      if (app?.window) {
        if (app.redirectActions.includes(redirect)) {
          this.openApp(app.appName);
          app.window.postMessage(
            {
              message: 'redirect',
              data: new RedirectAction({
                name: redirect,
                id,
                data,
              }),
            },
            app.appUrl
          );
        }
      } else {
        this.pendingRedirects[appName] = new RedirectAction({
          name: redirect,
          id,
          data,
        });
        if (app) this.openApp(app.appName);
      }
    }
  }
}
