import { Component, OnInit } from '@angular/core';
import { lastRouteKey } from 'src/app/core/guards/initial-navigation.guard';
import { MicrosoftAuthenticationService } from '../../injectables/microsoft-authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-error',
  templateUrl: './auth-error.component.html',
  styleUrls: ['./auth-error.component.scss'],
})
export class AuthErrorComponent implements OnInit {
  get error(): string {
    return this.msal.authError;
  }
  constructor(
    private readonly msal: MicrosoftAuthenticationService,
    private readonly router: Router
  ) {}
  ngOnInit(): void {
    localStorage.setItem(lastRouteKey, '/');
    if (!this.error) {
      this.router.navigate(['/']);
    }
  }
}
