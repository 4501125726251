import { createActionGroup, props } from '@ngrx/store';
import { ArticleTypeConfiguration } from '../../domain/models/article-types/article-type-configuration.model';
import { ActionBase } from 'processdelight-angular-components';
import { WarehouseLocation } from '../../domain/models/article-types/warehouse-location.model';

export const articleTypeActions = createActionGroup({
  source: 'ArticleType',
  events: {
    'Get article type configurations':
      props<ActionBase<ArticleTypeConfiguration[]>>(),
    'Get article type configurations resolved': props<{
      articleTypeConfigurations: ArticleTypeConfiguration[];
    }>(),
    'Update article type configurations': props<
      { configurations: ArticleTypeConfiguration[] } & ActionBase<
        ArticleTypeConfiguration[]
      >
    >(),
    'Update article type configurations resolved': props<{
      configurations: ArticleTypeConfiguration[];
    }>(),
    'Get warehouse locations': props<ActionBase<WarehouseLocation[]>>(),
    'Get warehouse locations resolved': props<{
      warehouseLocations: WarehouseLocation[];
    }>(),
  },
});
