import { ColumnType } from '../enums/column-type.enum';
import { MetadataChoice } from './metadataChoice.model';
import { MetadataParamConsolidatedChoice } from './metadataParamConsolidateChoice.model';

export class MetadataParameter{
  id!: string;
  title!: string;
  tooltip?: string;
  type!: ColumnType;
  required = false;
  fileNameParam = false;
  modifiedByParam = false;
  modifiedOnParam = false;
  createdOnParam = false;
  createdByParam = false;
  showGroups = true;
  showUsers = true;
  multi = false;
  format?: string;
  maxLength?: number;
  consolidatedChoices: MetadataParamConsolidatedChoice[] = [];
  hasTranslations = false;

  choices: MetadataChoice[] = [];

  constructor(obj: Partial<MetadataParameter>) {
    Object.assign(this, obj);
    if (obj.choices)
      this.choices = obj.choices
        .map((c) => new MetadataChoice(c))
        .sort((a, b) => a.position - b.position);
    if (obj.consolidatedChoices)
      this.consolidatedChoices = obj.consolidatedChoices.map(
        (c) => new MetadataParamConsolidatedChoice(c)
      );
    if (typeof obj.type == 'string')
      this.type = ColumnType[obj.type as keyof typeof ColumnType];
  }
  }
