import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  debounceTime,
  filter,
  map,
  of,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GraphGroup } from 'src/app/core/domain/models/graph-group.model';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { GraphUser } from 'src/app/core/domain/models/graph-user.model';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { Ishtar365Service } from 'src/app/core/services/ishtar365.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { SelectComponent } from 'processdelight-angular-components';
import { LineConnectorComponent } from 'src/app/shared/components/line-connector/line-connector.component';

@Component({
  selector: 'app-group-picker',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    SelectComponent,
  ],
  templateUrl: './group-picker.component.html',
  styleUrls: ['./group-picker.component.scss'],
})
export class GroupPickerComponent implements OnInit, OnDestroy, OnChanges {
  constructor(
    private ishtar365: Ishtar365Service,
    private readonly translations: TranslationService
  ) {}
  userValueAccesor = (user: GraphUser) => user.id;
  userDisplayValueAccesor = (user: GraphUser) => user.displayName;
  destroy$ = new Subject<void>();

  @Input() showButton = true;
  @Input() formGroup!: FormGroup;
  @Output() closeMenu = new EventEmitter<void>();
  supervisorSearchFormControl = new FormControl('');
  groupSearchFormControl = new FormControl('');
  groupSearchFormValues?: GraphGroup[] = [];
  departmentPeople = new BehaviorSubject<GraphUser[] | undefined>([]);

  formChange = new Subject<void>();
  filteredSupervisor$ = combineLatest([
    this.departmentPeople,
    this.supervisorSearchFormControl.valueChanges.pipe(startWith('')),
  ]).pipe(
    takeUntil(this.destroy$),
    map(([list, value]) =>
      !value || value === ''
        ? list ?? []
        : list?.filter((u) =>
            u.displayName.toLowerCase().includes(value.toLowerCase())
          ) ?? []
    )
  );

  ngOnChanges(changes: SimpleChanges): void {
    const hasChanges = (key: string) =>
      changes[key] && changes[key].previousValue !== changes[key].currentValue;
    if (hasChanges('formGroup')) {
      this.formChange.next();
      this.setValueChangeListener();
    }
  }

  ngOnInit(): void {
    this.groupSearchFormControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(100),
        switchMap((value) => {
          this.groupSearchFormValues = undefined;
          return this.ishtar365.getGraphGroups(value ?? undefined);
        })
      )
      .subscribe((groups) => {
        this.groupSearchFormValues = groups?.filter(
          (g) =>
            this.formGroup.controls.groupIds.value.findIndex(
              (gId: GraphGroup) => gId.id === g.id
            ) === -1
        );
      });
  }

  setValueChangeListener() {
    this.formGroup.controls.groupIds.valueChanges
      .pipe(
        startWith(this.formGroup.controls.groupIds.value as GraphGroup[]),
        takeUntil(this.destroy$),
        takeUntil(this.formChange),
        map((value: GraphGroup[]) => {
          if (!value || value.length == 0) return [];
          this.departmentPeople.next(undefined);
          return value.map((g) => g.id);
        }),
        filter((ids) => ids.length !== 0)
      )
      .subscribe((ids) => {
        this.ishtar365.getGroupsById(ids, true).subscribe((users) => {
          this.departmentPeople.next(
            users
              .flatMap(
                (u) => u.group?.members?.concat(u.group?.owners ?? []) ?? []
              )
              .filter(
                (value, index, array) =>
                  array.findIndex((u) => u.id === value.id) === index
              )
          );
        });
      });
  }

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  selectGroup(group: GraphGroup): void {
    this.groupSearchFormValues = this.groupSearchFormValues?.filter(
      (g) => g.id !== group.id
    );
    if (this.formGroup) {
      this.formGroup
        .get('groupIds')
        ?.setValue(
          [
            ...(this.formGroup.get('groupIds')?.value as GraphGroup[]),
            { id: group.id, displayName: group.displayName },
          ].sort(
            (a, b) => a.displayName?.localeCompare(b.displayName ?? '') ?? 0
          )
        );
    }
  }

  removeDepConfig(i: number) {
    const arr = [...(this.formGroup?.controls.groupIds.value as string[])];
    arr.splice(i, 1);
    this.formGroup?.controls.groupIds.setValue(arr);
  }

  focusInput(input: HTMLInputElement) {
    setTimeout(() => input.focus(), 0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.formChange.next();
    this.formChange.complete();
  }
}
