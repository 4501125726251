import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';

const userState = (state: AppState) => state.coreFeature.user;
const getUserInfo = createSelector(userState, (state) => state.userInfo);
const getUserLicenses = createSelector(userState, (state) => state.licenses);
const getUserSettings = createSelector(userState, (state) => state.settings);
export const userSelectors = {
  getUserInfo,
  getUserLicenses,
  getUserSettings,
};
