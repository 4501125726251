import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs';
import { AppState } from 'src/app/shared/store/app.reducer';
import { Skill } from '../../domain/models/skill.model';
import { UserSkill } from '../../domain/models/user-skill.model';
import { BaseFacade } from '../base.facade';
import {
  addSkills,
  addUserSkills,
  deleteUserSkills,
  getSkills,
  getUserSkills,
  resetSlice,
  softDeleteSkills,
  updateSkills,
} from './skill.actions';
import { skillState } from './skill.selectors';

@Injectable({
  providedIn: 'root',
})
export class SkillFacade extends BaseFacade {
  skills$ = this._store.pipe(
    select(skillState),
    map((state) => state.skills)
  );
  userSkills$ = this._store.pipe(
    select(skillState),
    map((state) => state.userSkills)
  );

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getSkills$() {
    return this.dispatchAction$(getSkills);
  }

  addSkill(skills: Skill[]) {
    return this.dispatchAction$(addSkills, { skills });
  }

  updateSkill(skills: Skill[]) {
    return this.dispatchAction$(updateSkills, { skills });
  }

  softDeleteSkills(skills: Skill[]) {
    return this.dispatchAction$(softDeleteSkills, { skills });
  }

  getUserSkills$() {
    return this.dispatchAction$(getUserSkills);
  }

  addUserSkill(userSkills: UserSkill[]) {
    return this.dispatchAction$(addUserSkills, { userSkills });
  }

  deleteUserSkills(userSkillIds: string[]) {
    return this.dispatchAction$(deleteUserSkills, { userSkillIds });
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
