import { BaseEntity, GroupUser } from 'processdelight-angular-components';

export class Permission extends BaseEntity {
  permissionType!: 'FullControl' | 'View';
  groupUser!: GroupUser;

  constructor(obj: Partial<Permission>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.groupUser) this.groupUser = new GroupUser(obj.groupUser);
  }
}
