<div class="h-100 d-flex flex-column align-items-center">
  <h4>
    Something went wrong with authentication. Please try again. If the problem
    persists, please contact your administrator with the following error
    message:
  </h4>
  <div class="error-message">
    {{ error }}
  </div>
</div>
