import { Action, createReducer, on } from '@ngrx/store';
import { IshtarOrganizationAdmin } from '../../domain/models/ishtar-organization-admin.model';
import { IshtarOrganization } from '../../domain/models/ishtar-organization.model';
import {
  getGroupsResolved,
  getGroupUsersResolved,
  getOrganizationAdminsResolved,
  getOrganizationInfoResolved,
  getUsersResolved,
  resetSlice,
  resetTempProperties,
  updateOrganizationPersonalizationResolved,
  updateTempLogo,
  updateTempNavColor,
  updateTempNavContrast,
} from './organization.actions';
import { GroupUser } from 'processdelight-angular-components';

export const featureSlice = 'organization';

export interface State {
  organizationInfo?: IshtarOrganization;
  admins: IshtarOrganizationAdmin[];
  groupUsers: GroupUser[];
  groups: GroupUser[];
  users: GroupUser[];
  tempNavColor?: string;
  tempNavContrast?: string;
  tempLogo?: string;
}

const defaultState: State = {
  organizationInfo: undefined,
  admins: [],
  groupUsers: [],
  groups: [],
  users: [],
  tempNavColor: undefined,
  tempNavContrast: undefined,
  tempLogo: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return organizationReducer(state, action);
}

export const initialState: State = defaultState;
export const organizationReducer = createReducer(
  initialState,
  on(getOrganizationInfoResolved, (state, { organizationInfo }) => ({
    ...state,
    organizationInfo,
  })),
  on(getOrganizationAdminsResolved, (state, { admins }) => ({
    ...state,
    admins,
  })),
  on(
    updateOrganizationPersonalizationResolved,
    (state, { organizationInfo }) => ({
      ...state,
      organizationInfo,
    })
  ),
  on(updateTempNavColor, (state, { navColor }) => ({
    ...state,
    tempNavColor: navColor,
  })),
  on(updateTempNavContrast, (state, { navContrast }) => ({
    ...state,
    tempNavContrast: navContrast,
  })),
  on(updateTempLogo, (state, { logo }) => ({ ...state, tempLogo: logo })),
  on(resetTempProperties, (state) => ({
    ...state,
    tempNavColor: undefined,
    tempNavContrast: undefined,
    tempLogo: undefined,
  })),
  on(getGroupUsersResolved, (state, { groupUsers }) => ({
    ...state,
    groupUsers,
  })),
  on(getUsersResolved, (state, { users }) => ({
    ...state,
    users,
  })),
  on(getGroupsResolved, (state, { groups }) => ({
    ...state,
    groups,
  })),
  on(resetSlice, () => initialState)
);
