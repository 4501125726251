import { Component, OnInit } from '@angular/core';
import { MicrosoftAuthenticationService } from '../../injectables/microsoft-authentication.service';
import * as uuid from 'uuid';
import * as microsoftTeams from '@microsoft/teams-js';
import { filter, first } from 'rxjs';

@Component({
  selector: 'app-teams-authentication',
  templateUrl: './teams-authentication.component.html',
})
export class TeamsAuthenticationComponent implements OnInit {
  constructor(private readonly msalService: MicrosoftAuthenticationService) {}

  //https://docs.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/authentication/auth-tab-aad#navigate-to-the-authorization-page-from-your-popup-page
  ngOnInit() {
    this.msalService.signIn(uuid.v4(), {}, true, false);
    this.msalService.signedIn
      .pipe(
        filter((b) => b),
        first()
      )
      .subscribe(() => {
        microsoftTeams.authentication.notifySuccess();
      });
  }
}
