import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

import { NgxColorsModule } from 'ngx-colors';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  filter,
  forkJoin,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import {
  ContextMenuAction,
  DashboardComponent,
  Filter,
  LoaderService,
} from 'processdelight-angular-components';
import { Ishtar365ActionsService } from 'src/app/core/services/ishtar365-actions.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { CoreModule } from 'src/app/core/core.module';
import { Skill } from 'src/app/core/domain/models/skill.model';
import { SkillFacade } from 'src/app/core/store/skill/skill.facade';
import { LinkSkillsFormComponent } from './link-skills-form/link-skills-form.component';
import { SkillPopupComponent } from './skill-popup/skill-popup.component';

@Component({
  standalone: true,
  selector: 'app-skills-settings',
  templateUrl: './skills-settings.component.html',
  styleUrls: ['./skills-settings.component.scss'],
  imports: [
    CommonModule,
    CoreModule,
    DashboardComponent,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTableModule,
    NgxColorsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class SkillsSettingsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  searchControl = new FormControl<string>('');
  filterSubject = new BehaviorSubject<string>('');
  filters: Filter[] = [];
  skills$ = this.skillFacade.skills$;
  skills: Skill[] = [];
  selection = new SelectionModel<FormGroup>(true, undefined);
  skillForm!: FormGroup;
  oldForm!: FormGroup;
  addingSkill = false;
  editMode$ = new BehaviorSubject<boolean>(false);
  isSaving$ = new BehaviorSubject<boolean>(false);
  datasource = new MatTableDataSource<AbstractControl>([]);
  skillsToDelete = new BehaviorSubject<{ group: FormGroup; index: number }[]>(
    []
  );
  newlyAddedSkillsCount = 0;

  @ViewChildren('codeInput') codeInputs?: QueryList<
    ElementRef<HTMLInputElement>
  >;
  @ViewChild('table', { read: ElementRef }) table?: ElementRef;

  displayedColumns: string[] = [
    'colorRectangle',
    'Code',
    'Title',
    'Color',
    'Delete',
  ];

  constructor(
    private loaderService: LoaderService,
    private skillFacade: SkillFacade,
    private cd: ChangeDetectorRef,
    private popupDialog: MatDialog,
    private linkSkillsDialog: MatDialog,
    private _snackBar: MatSnackBar,
    private readonly translations: TranslationService,
    private readonly ishtar365Actions: Ishtar365ActionsService
  ) {}

  ngOnInit(): void {
    this.loadSkills();
    this.initializeForm();
    this.subscribeToFormChanges();
  }

  setupActionBar() {
    this.ishtar365Actions.buttonActions = [
      new ContextMenuAction<unknown>({
        label: this.translations.getTranslation$('linkSkills'),
        action: () => this.openLinkSkillsModal(),
        disabled: this.isSaving$.asObservable(),
        icon: 'link',
        iconOutline: true,
      }),
    ];

    if (this.editMode$.value) {
      this.ishtar365Actions.buttonActions.unshift(
        new ContextMenuAction<unknown>({
          label: this.translations.getTranslation$('save'),
          action: () => this.onSubmit(this.skillForm),
          disabled: combineLatest([
            this.editMode$,
            this.skillsToDelete,
            this.skillForm.valueChanges.pipe(startWith(undefined)),
          ]).pipe(
            map(([edit, skillsToDelete]) => {
              const submittedSkillsToDelete = skillsToDelete
                .filter((s) => s.group.get('id')?.value)
                .map((s) => s.group.get('id')?.value);
              return (
                !edit ||
                !(
                  (this.skillForm.dirty && this.skillForm.valid) ||
                  (submittedSkillsToDelete.length > 0 &&
                    (this.skillForm.get('skillRows') as FormArray).controls
                      .filter(
                        (c) =>
                          !submittedSkillsToDelete.includes(c.get('id')?.value)
                      )
                      .every((c) => c.valid))
                )
              );
            })
          ),
          icon: 'save',
          iconOutline: true,
        })
      );
      this.ishtar365Actions.buttonActions.push(
        new ContextMenuAction<unknown>({
          label: this.translations.getTranslation$('addSkill'),
          action: () => this.addNewSkill(),
          disabled: combineLatest([this.isSaving$, this.editMode$]).pipe(
            map(([saving, edit]) => saving || !edit)
          ),
          icon: 'add',
          iconOutline: true,
        })
      );
    }

    this.ishtar365Actions.buttonActions.push(
      new ContextMenuAction<unknown>({
        label: this.editMode$
          .asObservable()
          .pipe(
            switchMap((edit) =>
              edit
                ? this.translations.getTranslation$('cancelEdit')
                : this.translations.getTranslation$('edit')
            )
          ),
        action: () => this.toggleEditMode(),
        disabled: this.isSaving$.asObservable(),
        icon: 'edit',
        iconOutline: true,
      })
    );
  }

  scrollToTop() {
    setTimeout(() => {
      this.table?.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    });
  }

  colorFromFormGroup(group: FormGroup) {
    return group.get('color')?.value;
  }

  onDelete(group: FormGroup, index: number) {
    if (!group.get('id')?.value) {
      (this.skillForm.get('skillRows') as FormArray).removeAt(index);
      this.datasource.data = (
        this.skillForm.get('skillRows') as FormArray
      ).controls;
      return;
    }
    const arrayIndex = this.skillsToDelete.value.findIndex(
      (s) => s.index == index
    );
    const newArray = this.skillsToDelete.value;
    if (arrayIndex !== -1) {
      newArray.splice(arrayIndex, 1);
    } else {
      newArray.push({ group, index });
    }
    this.skillsToDelete.next(newArray);
  }

  onScroll() {
    this.cd.detectChanges();
  }

  onSubmit(form: FormGroup) {
    this.skillForm.enable();
    const actions: Observable<unknown>[] = [];
    if (this.skillsToDelete.value.length) {
      const localSkillsToDelete = this.skillsToDelete.value.filter(
        (p) => !p.group.get('id')?.value
      );
      const submittedSkillsToDelete = this.skillsToDelete.value.filter(
        (p) => p.group.get('id')?.value
      );

      localSkillsToDelete.sort((a, b) => b.index - a.index);
      submittedSkillsToDelete.sort((a, b) => b.index - a.index);

      if (localSkillsToDelete.length > 0) {
        localSkillsToDelete.forEach(({ index }) => {
          (this.skillForm.get('skillRows') as FormArray).removeAt(index);
          if (submittedSkillsToDelete.length > 0) {
            submittedSkillsToDelete.forEach((a) => {
              index < a.index ? (a.index = a.index - 1) : a.index;
            });
          }
        });
      }

      if (submittedSkillsToDelete.length) {
        submittedSkillsToDelete.forEach(({ index }) => {
          (this.skillForm.get('skillRows') as FormArray).removeAt(index);
        });
        const skillsToSoftDelete = [];
        for (let index = 0; index < submittedSkillsToDelete.length; index++) {
          skillsToSoftDelete.push(
            new Skill({
              ...this.skills.find(
                (s) =>
                  s.id == submittedSkillsToDelete[index].group.get('id')?.value
              ),
              isDeleted: true,
            })
          );
        }

        // const skillsToSoftDelete$: Observable<Skill[]> = this.skills$.pipe(
        //   map((skills: Skill[]) =>
        //     skills
        //       .filter((skill) => idarray.includes(skill.id))
        //       .map(({ color, id, title, code }) => ({
        //         color,
        //         id,
        //         title,
        //         code,
        //         isDeleted: true,
        //       }))
        //   ),
        //   take(1)
        // );

        // skillsToSoftDelete$.subscribe((skillsToSoftDelete: Skill[]) => {

        // });
        actions.push(this.skillFacade.softDeleteSkills(skillsToSoftDelete));
      }
    }
    if (form.dirty && form.valid) {
      const dirtySkills = (form.get('skillRows') as FormArray).controls
        .filter((s) => s.dirty === true)
        .map(
          (s) =>
            new Skill({
              id: s.get('id')?.value == '' ? undefined : s.get('id')?.value,
              title: s.get('title')?.value,
              color: s.get('color')?.value,
              code: s.get('code')?.value,
              isDeleted: false,
            })
        );

      const newSkills = dirtySkills.filter((s) => !s.id);
      const updatedSkills = dirtySkills.filter((s) => s.id);
      if (updatedSkills.length > 0) {
        actions.push(this.skillFacade.updateSkill(updatedSkills));
      }
      if (newSkills.length > 0) {
        actions.push(this.skillFacade.addSkill(newSkills));
      }
    } else if (form.invalid) {
      this._snackBar
        .open('Some fields are invalid. Please fill them in.', 'X', {
          panelClass: 'app-notification-error',
        })
        ._dismissAfter(3000);
    }
    if (actions.length > 0) {
      this.loaderService.startLoading('Saving', () =>
        forkJoin(actions).pipe(
          tap((x) => {
            this.skillsToDelete.next([]);
            this.addingSkill = false;
            this.editMode$.next(false);
            this.skillForm.disable();
            this.datasource.data = (
              this.skillForm.get('skillRows') as FormArray
            ).controls;
            this._snackBar
              .open('Skills updated', 'X', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            this.setupActionBar();
          })
        )
      );
    }
  }

  addNewSkill() {
    this.addingSkill = true;
    const control = new FormGroup({
      id: new FormControl({
        value: '',
        disabled: false,
      }),
      title: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      code: new FormControl({ value: '', disabled: false }),
      color: new FormControl({
        value: '#FF2211',
        disabled: false,
      }),
    });
    (this.skillForm.get('skillRows') as FormArray).insert(0, control);
    control.markAsDirty();
    this.datasource.data = (
      this.skillForm.get('skillRows') as FormArray
    ).controls;

    this.newlyAddedSkillsCount++;

    setTimeout(() => {
      this.codeInputs?.last?.nativeElement?.focus();
    }, 0);

    if (this.skillsToDelete.value.length) {
      this.skillsToDelete.value.forEach((x) => (x.index = x.index + 1));
    }
  }

  toggleEditMode() {
    this.editMode$.next(!this.editMode$.value);
    if (this.editMode$.value) this.skillForm.enable();
    else this.skillForm.disable();

    if (this.editMode$.value && !this.skillForm.dirty) {
      this.oldForm = this.skillForm.value;
    }
    this.setupActionBar();

    if (
      (!this.editMode$.value && this.skillForm.dirty) ||
      this.skillsToDelete.value.length > 0
    ) {
      this.skillForm.enable();
      this.popupDialog
        .open(SkillPopupComponent, {
          autoFocus: false,
          data: {
            unsavedChanges: true,
            formGroup: this.skillForm,
          },
          disableClose: true,
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === 'submit') {
            this.skillForm.enable();
            this.onSubmit(this.skillForm);
            this.skillForm.markAsPristine();
            this.selection.clear();
          } else if (result === 'discard') {
            if (this.addingSkill) {
              const skillRowsFormArray = this.skillForm.get(
                'skillRows'
              ) as FormArray;
              if (this.newlyAddedSkillsCount > 0) {
                for (let i = 0; i < this.newlyAddedSkillsCount; i++) {
                  skillRowsFormArray.removeAt(0);
                }
                this.newlyAddedSkillsCount = 0;
              }
              this.addingSkill = false;
            }
            this.skillForm.patchValue(this.oldForm);
            this.skillsToDelete.next([]);
            this.datasource.data = (
              this.skillForm.get('skillRows') as FormArray
            ).controls;
            this.skillForm.disable();
            this.editMode$.next(false);
            this.skillForm.markAsPristine();
            this.selection.clear();
          } else {
            this.editMode$.next(true);
            this.skillForm.enable();
          }
          this.setupActionBar();
        });
    }
  }

  selectRow(group: FormGroup) {
    if (this.selection.isSelected(group)) {
      this.selection.deselect(group);
    } else {
      this.selection.select(group);
    }
  }

  openLinkSkillsModal() {
    this.linkSkillsDialog.open(LinkSkillsFormComponent, {
      autoFocus: false,
      disableClose: true,
    });
  }

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  getTranslation(label: string) {
    return this.translations.getTranslation(label);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.ishtar365Actions.buttonActions = [];
  }

  private loadSkills(): void {
    this.loaderService.startLoading('Loading skills', () =>
      this.skillFacade.skills$.pipe(filter((s) => !!s))
    );

    this.skillFacade.skills$
      .pipe(
        switchMap((skills) =>
          skills?.length
            ? (this.skills = skills)
            : this.skillFacade.getSkills$()
        ),
        take(1)
      )
      .subscribe();
  }

  private initializeForm(): void {
    this.skillForm = new FormGroup({
      skillRows: new FormArray([]),
    });

    this.skills$
      .pipe(
        withLatestFrom(this.filterSubject),
        takeUntil(this.destroy$),
        map(([skills, filter]) => {
          if (skills?.length) {
            this.skills = skills;
            this.skillForm.setControl(
              'skillRows',
              new FormArray(
                skills.map(
                  (s) =>
                    new FormGroup({
                      id: new FormControl({
                        value: s.id,
                        disabled: true,
                      }),
                      title: new FormControl(
                        { value: s.title, disabled: true },
                        Validators.required
                      ),
                      code: new FormControl({ value: s.code, disabled: true }),
                      color: new FormControl({
                        value: s.color,
                        disabled: true,
                      }),
                    })
                )
              )
            );
          } else {
            this.skills = [];
            this.skillForm.setControl('skillRows', new FormArray([]));
          }

          this.datasource.data = (
            this.skillForm.get('skillRows') as FormArray
          ).controls.filter((control) =>
            control
              .get('title')
              ?.value?.toLowerCase()
              .includes(filter.toLowerCase())
          );

          this.isSaving$.next(false);
          this.setupActionBar();
        })
      )
      .subscribe();
  }

  private subscribeToFormChanges(): void {
    this.filterSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (filter) =>
          (this.datasource.data = (
            this.skillForm.get('skillRows') as FormArray
          ).controls.filter((control) =>
            control
              .get('title')
              ?.value?.toLowerCase()
              .includes(filter.toLowerCase())
          ))
      );

    this.searchControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((filter) => {
        this.filterSubject.next(filter ?? '');
      });
  }
}
