import { BaseEntity } from 'processdelight-angular-components';

export class WorkRegimeType extends BaseEntity {
  name!: string;

  constructor(obj: Partial<WorkRegimeType>) {
    super(obj);
    Object.assign(this, obj);
  }
}
