import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { Subject, map } from 'rxjs';
import { workRegimeState } from './workRegime.reducer';
import {
  addWorkRegimeDays,
  addWorkRegimes,
  getDays,
  getWorkRegimeDays,
  getWorkRegimeTypes,
  getWorkRegimes,
  resetSlice,
  updateWorkRegimeDays,
  updateWorkRegimes,
} from './workRegime.actions';
import { WorkRegimeDay } from '../../domain/models/workRegimeDay.model';
import { WorkRegime } from '../../domain/models/workRegime.model';
import { BaseFacade } from '../base.facade';

@Injectable({
  providedIn: 'root',
})
export class WorkRegimeFacade extends BaseFacade {
  workRegimeDays$ = this._store.pipe(
    select(workRegimeState),
    map((state) => state.workRegimeDays)
  );

  workRegimes$ = this._store.pipe(
    select(workRegimeState),
    map((state) => state.workRegimes)
  );

  workRegimeTypes$ = this._store.pipe(
    select(workRegimeState),
    map((state) => state.workRegimeTypes)
  );

  days$ = this._store.pipe(
    select(workRegimeState),
    map((state) => state.days)
  );

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getWorkRegimeDays$() {
    return this.dispatchAction$(getWorkRegimeDays);
  }

  addWorkRegimeDays(workRegimeDays: WorkRegimeDay[]) {
    return this.dispatchAction$(addWorkRegimeDays, { workRegimeDays });
  }

  updateWorkRegimeDays(workRegimeDays: WorkRegimeDay[]) {
    return this.dispatchAction$(updateWorkRegimeDays, { workRegimeDays });
  }

  getWorkRegime$() {
    return this.dispatchAction$(getWorkRegimes);
  }

  addWorkRegimes(workRegimes: WorkRegime[]) {
    return this.dispatchAction$(addWorkRegimes, { workRegimes });
  }

  updateWorkRegimes(workRegimes: WorkRegime[]) {
    return this.dispatchAction$(updateWorkRegimes, { workRegimes });
  }

  getWorkRegimeType$() {
    return this.dispatchAction$(getWorkRegimeTypes);
  }

  getDay$() {
    return this.dispatchAction$(getDays);
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
