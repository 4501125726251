<mat-card *ngIf="formGroup" [formGroup]="formGroup">
  <mat-card-content class="p-2">
    <mat-card-header>
      <mat-card-title
        class="d-flex flex-row justify-content-between w-100 align-items-center"
        >Configure Department
        <button
          height="15"
          mat-mini-fab
          color="primary"
          class="smallbtn"
          (click)="closeMenu.emit()"
          matTooltip="Close menu"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-card-title>
    </mat-card-header>
    <mat-form-field appearance="outline">
      <mat-label>Department Name</mat-label>
      <input matInput placeholder="Name" formControlName="departmentName" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Select Supervisor</mat-label>
      <mat-spinner
        matTextSuffix
        class="ms-2"
        *ngIf="departmentPeople.value === undefined"
      ></mat-spinner>
      <mat-select formControlName="supervisor" panelClass="select-supervisor">
        <mat-form-field>
          <mat-label>Search</mat-label>
          <input
            matInput
            placeholder="Name"
            [formControl]="supervisorSearchFormControl"
          />
        </mat-form-field>
        <mat-option
          *ngFor="let user of filteredSupervisor$ | async"
          [value]="user.id"
        >
          {{ user.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="d-flex flex-row align-content-between">
      <h4 class="ms-2">Included Azure Groups</h4>
      <button
        mat-mini-fab
        color="primary"
        class="ms-auto me-2 smallbtn"
        [matMenuTriggerFor]="addUserMenu"
        (click)="
          focusInput(searchControl); this.groupSearchFormControl.setValue('')
        "
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div class="groupscontainer scrollbar">
      <div
        *ngFor="let dep of formGroup.value.groupIds; let i = index"
        class="d-flex flex-row justify-content-between align-items-center"
      >
        <p class="m-0">{{ dep.displayName }}</p>
        <button
          mat-mini-fab
          color="primary"
          class="smallbtn"
          (click)="removeDepConfig(i)"
        >
          <mat-icon>remove</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-menu
  class="add-user-license-menu"
  xPosition="before"
  yPosition="above"
  (closed)="groupSearchFormControl.patchValue('')"
  #addUserMenu
>
  <mat-form-field appearance="fill" (click)="$event.stopPropagation()">
    <mat-label>
      <ng-container *ngIf="getTranslation$('search') | async as searchLabel"
        >{{ searchLabel }}...</ng-container
      >
    </mat-label>
    <input
      matInput
      type="text"
      [formControl]="groupSearchFormControl"
      #searchControl
    />
    <mat-spinner
      matTextSuffix
      *ngIf="groupSearchFormValues === undefined"
    ></mat-spinner>
    <div class="d-flex flex-column">
      <div
        class="graph-user-item clickable"
        *ngFor="let group of groupSearchFormValues"
        (click)="selectGroup(group); $event.stopPropagation()"
        [title]="group.id"
      >
        {{ group.displayName }}
      </div>
    </div>
  </mat-form-field>
</mat-menu>
