import { Component, OnInit } from '@angular/core';
import { firstValueFrom, timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { MicrosoftAuthenticationService } from '../../injectables/microsoft-authentication.service';
import * as microsoftTeams from '@microsoft/teams-js';

@Component({
  selector: 'app-sign-out-callback',
  templateUrl: './sign-out-callback.component.html',
  styleUrls: ['./sign-out-callback.component.scss'],
})
export class SignOutCallbackComponent implements OnInit {
  constructor(private readonly msalService: MicrosoftAuthenticationService) {}

  async ngOnInit(): Promise<void> {
    await firstValueFrom(timer(100).pipe(first()));
    if (this.msalService.runningOnTeams && window.opener !== window) {
      microsoftTeams.authentication.notifySuccess('Signed out');
      window.close();
    } else this.msalService.completeSignout();
  }
}
