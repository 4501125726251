import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import {
  AutomationFlowObjectType,
  Lookup,
} from 'processdelight-angular-components';

const automationFlowState = (state: AppState) =>
  state.coreFeature.automationFlow;
const getAutomationFlows = createSelector(
  automationFlowState,
  (state) => state.automationFlows
);
const getAutomationFlowApplications = createSelector(
  automationFlowState,
  (state) => state.automationFlowApplications
);
const getActionTypes = createSelector(
  automationFlowState,
  (state) => state.actionTypes
);
const getConditionTypes = createSelector(
  automationFlowState,
  (state) => state.conditionTypes
);
const getPropertyTypes = createSelector(
  automationFlowState,
  (state) => state.propertyTypes
);
const getTriggerTypes = createSelector(
  automationFlowState,
  (state) => state.triggerTypes
);
const getOperators = createSelector(
  automationFlowState,
  (state) => state.operators
);
const getObjectTypes = createSelector(automationFlowState, (state) =>
  state.automationFlowApplications.flatMap((a) =>
    (a.objectTypes as AutomationFlowObjectType[]).map(
      (ot) =>
        new Lookup({
          id: ot.ishtarObjectTypeId,
          name: ot.name,
        })
    )
  )
);
const getObjectTypeRecords = createSelector(
  automationFlowState,
  (state) => state.objectTypeRecords
);
const getRequestedObjectTypeId = createSelector(
  automationFlowState,
  (state) => state.requestedObjectTypeId
);
const getDays = createSelector(automationFlowState, (state) => state.days);
const getMonths = createSelector(automationFlowState, (state) => state.months);
const getFrequencies = createSelector(
  automationFlowState,
  (state) => state.frequencies
);
const getRankings = createSelector(
  automationFlowState,
  (state) => state.rankings
);
const getAutomationFlowUtilities = createSelector(
  getAutomationFlowApplications,
  getActionTypes,
  getConditionTypes,
  getPropertyTypes,
  getTriggerTypes,
  getOperators,
  getObjectTypes,
  getObjectTypeRecords,
  getRequestedObjectTypeId,
  getDays,
  getMonths,
  getFrequencies,
  getRankings,
  (
    applications,
    actionTypes,
    conditionTypes,
    propertyTypes,
    triggerTypes,
    operators,
    objectTypes,
    objectTypeRecords,
    requestedObjectTypeId,
    days,
    months,
    frequencies,
    rankings
  ) => ({
    applications,
    actionTypes,
    conditionTypes,
    propertyTypes,
    triggerTypes,
    operators,
    objectTypes,
    objectTypeRecords,
    requestedObjectTypeId,
    days,
    months,
    frequencies,
    rankings,
  })
);

export const automationFlowSelectors = {
  getAutomationFlows,
  getAutomationFlowApplications,
  getActionTypes,
  getConditionTypes,
  getPropertyTypes,
  getTriggerTypes,
  getOperators,
  getObjectTypes,
  getObjectTypeRecords,
  getRequestedObjectTypeId,
  getDays,
  getMonths,
  getFrequencies,
  getRankings,
  getAutomationFlowUtilities,
};
