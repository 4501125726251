import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { BaseFacade } from '../base.facade';
import { librarySelectors } from './library.selector';
import { getLibraries } from './library.actions';
import { Library } from '../../domain/models/library.model';

@Injectable({
  providedIn: 'root',
})
export class LibraryFacade extends BaseFacade {
  libraries$ = this._store.pipe(select(librarySelectors.getLibraries));

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getLibraries(callback?: (pages: Library[]) => void) {
    this._store.dispatch(getLibraries({ callback }));
  }
  getLibraries$() {
    return this.dispatchAction$(getLibraries);
  }
}
