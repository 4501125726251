import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Ishtar365Service } from '../../services/ishtar365.service';
import { articleTypeActions } from './article-type.actions';

@Injectable({ providedIn: 'root' })
export class ArticleTypeEffects {
  constructor(private actions$: Actions, private ishtar365: Ishtar365Service) {}

  getArticleTypeConfigurations = createEffect(() =>
    this.actions$.pipe(
      ofType(articleTypeActions.getArticleTypeConfigurations),
      switchMap(({ callback, error }) =>
        this.ishtar365.getArticleTypeConfigurations().pipe(
          tap((configurations) =>
            callback ? callback(configurations) : undefined
          ),
          switchMap((configurations) =>
            of(
              articleTypeActions.getArticleTypeConfigurationsResolved({
                articleTypeConfigurations: configurations,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateArticleTypeConfigurations = createEffect(() =>
    this.actions$.pipe(
      ofType(articleTypeActions.updateArticleTypeConfigurations),
      switchMap(({ configurations, callback, error }) =>
        this.ishtar365.updateArticleTypeConfigurations(configurations).pipe(
          tap((configurations) =>
            callback ? callback(configurations) : undefined
          ),
          switchMap((configurations) =>
            of(
              articleTypeActions.updateArticleTypeConfigurationsResolved({
                configurations,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getWarehouseLocations = createEffect(() =>
    this.actions$.pipe(
      ofType(articleTypeActions.getWarehouseLocations),
      switchMap(({ callback, error }) =>
        this.ishtar365.getWarehouseLocations().pipe(
          tap((warehouseLocations) =>
            callback ? callback(warehouseLocations) : undefined
          ),
          switchMap((warehouseLocations) =>
            of(
              articleTypeActions.getWarehouseLocationsResolved({
                warehouseLocations,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
