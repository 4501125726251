import { Action, createReducer, on } from '@ngrx/store';
import { FormTemplate } from '../../domain/models/form-template.model';
import { getFormTemplatesResolved } from './formTemplate.actions';

export const featureSlice = 'formTemplate';

export interface State {
  formTemplates: FormTemplate[];
}

const defaultState: State = {
    formTemplates: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return formTemplateReducer(state, action);
}

export const initialState: State = defaultState;
export const formTemplateReducer = createReducer(
  initialState,
  on(getFormTemplatesResolved, (state, { formTemplates }) => ({
    ...state,
    formTemplates: formTemplates,
  }))
);
