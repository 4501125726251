import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, Routes } from '@angular/router';
import { LoaderService } from 'processdelight-angular-components';
import { Observable, combineLatest, first, map } from 'rxjs';
import { EmptyComponent } from 'src/app/layout/empty/empty.component';
import { MicrosoftAuthenticationService } from 'src/app/msal/injectables/microsoft-authentication.service';
import { appRoutes, routes } from 'src/app/app.routes';
import { settingsRoutes } from 'src/app/settings/settings.component';
import { IntranetComponent } from 'src/app/tile-page/intranet/intranet.component';
import TilePageComponent from 'src/app/tile-page/tile-page.component';
import { TileAction } from '../domain/enums/tile-action.enum';
import { TilePageType } from '../domain/enums/tile-page-type.enum';
import { TilePage } from '../domain/models/tile-page.model';
import { lastRouteKey } from '../guards/initial-navigation.guard';
import { TilePageFacade } from '../store/tilepage/tilepage.facade';
import { UserFacade } from '../store/user/user.facade';
import { FrameManagerService } from './frame-manager.service';
import { TranslationService } from './translation.service';

interface RouteBreadcrumb {
  routeList: Routes;
  path: string;
  title: Observable<string>;
}

@Injectable({
  providedIn: 'root',
})
export class RouteBuilderService {
  breadcrumbs: () => RouteBreadcrumb[] = () => [
    {
      routeList: appRoutes,
      path: 'home',
      title: this.translations.getTranslation$('home'),
    },
    {
      routeList: settingsRoutes,
      path: '',
      title: this.translations.getTranslation$('settings'),
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      routeList: settingsRoutes.find((r) => r.path == '')!.children!,
      path: 'tiles',
      title: this.translations.getTranslation$('tiles'),
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      routeList: settingsRoutes.find((r) => r.path == '')!.children!,
      path: 'tilepages',
      title: this.translations.getTranslation$('tilePages'),
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      routeList: settingsRoutes.find((r) => r.path == '')!.children!,
      path: 'licenses',
      title: this.translations.getTranslation$('licenses'),
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      routeList: settingsRoutes.find((r) => r.path == '')!.children!,
      path: 'admins',
      title: this.translations.getTranslation$('admins'),
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      routeList: settingsRoutes.find((r) => r.path == '')!.children!,
      path: 'groups',
      title: this.translations.getTranslation$('groups'),
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      routeList: settingsRoutes.find((r) => r.path == '')!.children!,
      path: 'personalization',
      title: this.translations.getTranslation$('personalization'),
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      routeList: settingsRoutes.find((r) => r.path == '')!.children!,
      path: 'workregime',
      title: this.translations.getTranslation$('workRegime'),
    },
  ];

  constructor(
    private router: Router,
    private loader: LoaderService,
    private tilePageFacade: TilePageFacade,
    private userFacade: UserFacade,
    private translations: TranslationService,
    private msal: MicrosoftAuthenticationService
  ) {}

  injectBreadcrumbTitles() {
    for (const crumb of this.breadcrumbs()) {
      const route = crumb.routeList.find((r) => r.path == crumb.path);
      if (route) {
        const data = route.data;
        if (data) data.breadcrumbTitle = crumb.title;
        else route.data = { breadcrumbTitle: crumb.title };
      }
    }
  }

  private backToLastRoute() {
    const lastRoute = localStorage.getItem(lastRouteKey);
    if (lastRoute) {
      const loadId = this.loader.startLoading('Getting you back into action');
      const [route, query] = lastRoute.split('?');
      const params = Object.fromEntries(new URLSearchParams(query));
      setTimeout(() => {
        let finalRoute = [];
        if (lastRoute.split('?')[0] == '/') {
          finalRoute = ['/page/Home'];
        } else {
          finalRoute = route
            .split('/')
            .filter((v) => !!v)
            .map((r) => decodeURIComponent(r));
        }
        this.router.navigate(finalRoute, { queryParams: params });
        setTimeout(() => {
          this.loader.stopLoading(loadId);
        }, 1000);
      }, 0);
    }
  }

  buildTilePageRoutes(route: Route, page: TilePage, pages: TilePage[]) {
    const routes: Routes = [];
    (page.segments ?? [])
      .map((s) => s.tilePageSegment)
      .filter((s) => !s.disabled)
      .forEach((s) =>
        s.tiles
          .map((t) => t.tile)
          .filter(
            (t) =>
              !t.disabled &&
              (!t.permissions?.length ||
                t.permissions
                  .map((p) => p.permission)
                  .some((p) =>
                    p.groupUser.user
                      ? p.groupUser.id == this.msal.id
                      : p.groupUser.group?.members?.some(
                          (m) => m.id == this.msal.id
                        )
                  ))
          )
          .forEach((t) => {
            if (t.tileActionType == TileAction.TilePage) {
              const page = pages.find((p) => p.id == t.tileAction);
              if (page) {
                const childRoute: Route = {
                  path: route.path + '/' + page.title,
                  component:
                    page.type == TilePageType.Intranet
                      ? IntranetComponent
                      : TilePageComponent,
                  children: [],
                  data: {
                    breadcrumbTitle: page.title,
                    breadcrumbParent: route,
                    tilePageId: page.id,
                  },
                };
                routes.push(childRoute);
                routes.push(
                  ...this.buildTilePageRoutes(childRoute, page, pages)
                );
              }
            }
          })
      );
    return routes;
  }

  initRoutes(navigate = true) {
    return combineLatest([
      this.tilePageFacade.tilePages$,
      this.userFacade.appsWithLicense$,
    ]).pipe(
      first(),
      map(([pages, apps]) => {
        const r = [...appRoutes, ...routes];
        const homepage = pages.find((p) => p.homepage);
        if (homepage) {
          const defaultRoute: Route = {
            path: 'page',
            redirectTo: 'page/' + homepage.title,
          };
          const route: Route = {
            path: 'page/' + homepage.title,
            component:
              homepage.type == TilePageType.Intranet
                ? IntranetComponent
                : TilePageComponent,
            children: [],
            data: {
              breadcrumbTitle: homepage.title,
              tilePageId: homepage.id,
            },
          };
          r.unshift(defaultRoute);
          r.unshift(...this.buildTilePageRoutes(route, homepage, pages));
          r.unshift(route);
        }
        apps.forEach((app) => {
          r.unshift({
            path: 'app/' + app.name,
            data: {
              breadcrumbTitle: app.name,
            },
            component: EmptyComponent,
            children: [
              {
                path: ':redirectAction',
                component: EmptyComponent,
                canActivate: [
                  (snapshot: ActivatedRouteSnapshot) => {
                    const service = inject(FrameManagerService);
                    const action = snapshot.params.redirectAction;
                    const id = snapshot.queryParams.id;
                    const params = { ...snapshot.queryParams, id: undefined };
                    service.appRedirect(app.name, action, id, params);
                    return true;
                  },
                ],
              },
            ],
          } as Route);
        });
        this.router.resetConfig(r);
        if (navigate) this.backToLastRoute();
        return !navigate;
      })
    );
  }

  resetRoutes() {
    this.router.resetConfig(routes);
  }

  initSpecificRoute(path: string, navigateToLastRoute = false) {
    const r = [...routes];
    const specificRoute = appRoutes.find((r) => r.path == path);
    if (specificRoute) r.unshift(specificRoute);
    this.router.resetConfig(r);
    if (navigateToLastRoute) this.backToLastRoute();
  }
}
