export class AppFrame {
  appName!: string;
  appIconUrl!: string;
  appUrl!: string;
  window?: Window;

  redirectActions: string[] = [];

  constructor(init?: Partial<AppFrame>) {
    Object.assign(this, init);
  }
}
