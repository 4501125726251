import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';

const groupState = (state: AppState) => state.coreFeature.group;
const getOrganigramFn = createSelector(
  groupState,
  (state) => (appName: string) => state.organigrams[appName]
);
export const groupSelectors = {
  getOrganigramFn,
};
