import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap } from 'rxjs';
import {
  addWorkRegimeDays,
  addWorkRegimeDaysResolved,
  addWorkRegimes,
  addWorkRegimesResolved,
  getDays,
  getDaysResolved,
  getWorkRegimeDays,
  getWorkRegimeDaysResolved,
  getWorkRegimeTypes,
  getWorkRegimeTypesResolved,
  getWorkRegimes,
  getWorkRegimesResolved,
  updateWorkRegimeDays,
  updateWorkRegimeDaysResolved,
  updateWorkRegimes,
  updateWorkRegimesResolved,
} from './workRegime.actions';
import { Ishtar365Service } from '../../services/ishtar365.service';

@Injectable({ providedIn: 'root' })
export class WorkRegimeEffects {
  constructor(private actions$: Actions, private ishtar365: Ishtar365Service) {}

  getWorkRegimeDays = createEffect(() =>
    this.actions$.pipe(
      ofType(getWorkRegimeDays),
      switchMap(({ callback, error }) =>
        this.ishtar365.getWorkRegimeDays().pipe(
          tap((result) => (callback ? callback(result) : undefined)),
          switchMap((result) => of(getWorkRegimeDaysResolved({ result }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  addWorkRegimeDays = createEffect(() =>
    this.actions$.pipe(
      ofType(addWorkRegimeDays),
      switchMap(({ workRegimeDays, callback, error }) =>
        this.ishtar365.addWorkRegimeDays(workRegimeDays).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedWorkRegimeDays) =>
            of(addWorkRegimeDaysResolved({ addedWorkRegimeDays }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateWorkRegimeDays = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWorkRegimeDays),
      switchMap(({ workRegimeDays, callback, error }) =>
        this.ishtar365.updateWorkRegimeDays(workRegimeDays).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedWorkRegimeDays) =>
            of(updateWorkRegimeDaysResolved({ updatedWorkRegimeDays }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getWorkRegimes = createEffect(() =>
    this.actions$.pipe(
      ofType(getWorkRegimes),
      switchMap(({ callback, error }) =>
        this.ishtar365.getWorkRegimes().pipe(
          tap((result) => (callback ? callback(result) : undefined)),
          switchMap((result) => of(getWorkRegimesResolved({ result }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  addWorkRegimes = createEffect(() =>
    this.actions$.pipe(
      ofType(addWorkRegimes),
      switchMap(({ workRegimes, callback, error }) =>
        this.ishtar365.addWorkRegimes(workRegimes).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedWorkRegimes) =>
            of(addWorkRegimesResolved({ addedWorkRegimes }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateWorkRegimes = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWorkRegimes),
      switchMap(({ workRegimes, callback, error }) =>
        this.ishtar365.updateWorkRegimes(workRegimes).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedWorkRegimes) =>
            of(updateWorkRegimesResolved({ updatedWorkRegimes }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getWorkRegimeTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(getWorkRegimeTypes),
      switchMap(({ callback, error }) =>
        this.ishtar365.getWorkRegimeTypes().pipe(
          tap((result) => (callback ? callback(result) : undefined)),
          switchMap((result) => of(getWorkRegimeTypesResolved({ result }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
  getDays = createEffect(() =>
    this.actions$.pipe(
      ofType(getDays),
      switchMap(({ callback, error }) =>
        this.ishtar365.getDays().pipe(
          tap((result) => (callback ? callback(result) : undefined)),
          switchMap((result) => of(getDaysResolved({ result }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
