import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationBarService implements OnDestroy {
  appBarExtended$ = new BehaviorSubject<boolean>(false);
  tilePageExtended$ = new BehaviorSubject<boolean>(false);

  ngOnDestroy() {
    this.appBarExtended$.complete();
  }

  toggleMenu() {
    this.appBarExtended$.next(!this.appBarExtended$.value);
  }

  openMenu() {
    this.appBarExtended$.next(true);
  }

  closeMenu() {
    this.appBarExtended$.next(false);
  }

  toggleTilePage() {
    this.tilePageExtended$.next(!this.tilePageExtended$.value);
  }
}
