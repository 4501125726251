import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Ishtar365Service } from '../../services/ishtar365.service';
import { IshtarOrganigramGroup } from '../../domain/models/ishtar-organigram-group..model';
import { IshtarUser } from '../../domain/models/ishtar-user.model';
import {
  getOrganigram,
  getOrganigramResolved,
  saveOrganigram,
  saveOrganigramResolved,
} from './group.actions';

@Injectable({ providedIn: 'root' })
export class GroupEffects {
  constructor(private actions$: Actions, private ishtar365: Ishtar365Service) {}

  getOrganigram = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrganigram),
      switchMap(({ appName, callback, error }) =>
        this.ishtar365.getOrganigram(appName).pipe(
          switchMap((data) => {
            const mappedData = data.map(
              (d) =>
                new IshtarOrganigramGroup({
                  ...d,
                  members: d.members.map((m) => new IshtarUser(m)),
                })
            );
            if (callback) callback(mappedData);
            return of(getOrganigramResolved({ appName, groups: mappedData }));
          }),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
  saveOrganigram = createEffect(() =>
    this.actions$.pipe(
      ofType(saveOrganigram),
      switchMap(({ appName, groups, callback, error }) =>
        this.ishtar365.saveOrganigram(appName, groups).pipe(
          switchMap((data) => {
            const mappedData = data.map(
              (d) =>
                new IshtarOrganigramGroup({
                  ...d,
                  members: d.members.map((m) => new IshtarUser(m)),
                })
            );
            if (callback) callback(mappedData);
            return of(saveOrganigramResolved({ appName, groups: mappedData }));
          }),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
