import { createAction, props } from '@ngrx/store';
import { IshtarOrganigramGroup } from '../../domain/models/ishtar-organigram-group..model';
import { ActionBase } from '../action-base.interface';

export const getOrganigram = createAction(
  '[Group] Get organigram',
  props<
    {
      appName: string;
    } & ActionBase<IshtarOrganigramGroup[]>
  >()
);
export const getOrganigramResolved = createAction(
  '[Group] Get organigram resolved',
  props<{ appName: string; groups: IshtarOrganigramGroup[] }>()
);
export const saveOrganigram = createAction(
  '[Group] Save organigram',
  props<
    {
      appName: string;
      groups: IshtarOrganigramGroup[];
    } & ActionBase<IshtarOrganigramGroup[]>
  >()
);
export const saveOrganigramResolved = createAction(
  '[Group] Save organigram resolved',
  props<{ appName: string; groups: IshtarOrganigramGroup[] }>()
);
export const resetSlice = createAction('[Group] Reset slice');
