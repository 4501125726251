import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { combineLatest, filter, first, map, of, switchMap, take } from 'rxjs';
import { FrameManagerService } from 'src/app/core/services/frame-manager.service';
import { IshtarApp } from 'src/app/core/domain/models/ishtar-app.model';
import { Tile } from 'src/app/core/domain/models/tile.model';
import { AppSubscriptionFacade } from 'src/app/core/store/app-subscription/app-subscription.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { environment } from 'src/environments/environment';

@Component({
  standalone: true,
  selector: 'app-app-tile',
  templateUrl: './app-tile.component.html',
  styleUrls: ['./app-tile.component.scss'],
  imports: [CommonModule],
})
export class AppTileComponent implements OnInit {
  @Input() tile!: Tile;

  @Input() size = 80;
  @Input() margin = 20;
  @Input() intranet = false;
  @Input() inSidebar = false;

  app?: IshtarApp;

  disabled = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (!this.inSidebar) {
      this.size = (event.target as Window)?.innerWidth < 550 ? 50 : 80;
      this.margin = (event.target as Window)?.innerWidth < 550 ? 10 : 20;
    }
  }

  get fontSize() {
    return Math.max(this.size / 5, 10);
  }

  constructor(
    private appSubscriptionFacade: AppSubscriptionFacade,
    private userFacade: UserFacade,
    private frameManagerService: FrameManagerService
  ) {}

  ngOnInit(): void {
    if (!this.inSidebar) {
      this.size = window.innerWidth < 500 ? 50 : 80;
      this.margin = window.innerWidth < 500 ? 10 : 20;
    }
    this.appSubscriptionFacade.apps$
      .pipe(
        take(5),
        map((apps) => apps.find((a) => +this.tile.tileAction == a.productId)),
        filter((a) => !!a),
        switchMap((app) =>
          combineLatest([
            of(app!),
            this.appSubscriptionFacade.getAppHasSubscriptionFunc$.pipe(first()),
            this.userFacade.appHasLicenseFunc$.pipe(first()),
          ])
        )
      )
      .subscribe(([app, fn1, fn2]) => {
        this.disabled = !fn1(app) || !fn2(app);
        this.app = app;
      });
  }
  appIconError(event: Event) {
    const el = event.target as HTMLImageElement;
    el.src = environment.cdnImageNotFound;
  }

  open() {
    if (this.disabled) return;
    this.frameManagerService.openApp(this.app!.name);
  }

  isSingleWord(text: string): boolean {
    return !/\s/.test(text);
  }

  isLongerThanTwoLines(text: string): boolean {
    const lineHeight = 1.2;
    const fontSize = this.fontSize;
    const lines = 2;
    const width = this.size;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
      return false;
    }
    context.font = `${fontSize}px Arial`;
    const textWidth = context.measureText(text).width;

    return textWidth > lineHeight * fontSize * lines * width;
  }
}
