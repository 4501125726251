import {
  DataRecord,
  Entity,
  GroupUserEntity,
} from 'processdelight-angular-components';
import { ArticleType } from './article-type.model';

export class ArticleTypeReminderReceiver extends Entity {
  typeId!: string;
  receiverId!: string;

  receiver?: GroupUserEntity;

  constructor(data: Partial<ArticleTypeReminderReceiver>) {
    super(data);
    if (data.receiver) this.receiver = new GroupUserEntity(data.receiver);
  }
}

export class ArticleTypeWashingOrRepairLocation extends Entity {
  erpLocationId!: number;
  articleTypeId!: string;

  constructor(data: Partial<ArticleTypeWashingOrRepairLocation>) {
    super(data);
  }
}

export class ArticleTypeConfiguration extends Entity {
  erpId!: number;
  articleType!: ArticleType;

  maxTimesOfWashingOrRepair?: number;
  washOrMaintenanceReminder?: number;
  isClothing!: boolean;

  validationTaskTemplateId?: string;
  employmentValidationTaskTemplateId?: string;
  maintenanceRequestTaskTemplateId?: string;
  repairRequestTaskTemplateId?: string;
  outOfServiceTaskTemplateId?: string;

  validationTaskTemplate?: DataRecord;
  employmentValidationTaskTemplate?: DataRecord;
  maintenanceRequestTaskTemplate?: DataRecord;
  repairRequestTaskTemplate?: DataRecord;
  outOfServiceTaskTemplate?: DataRecord;

  articleTypeReminderReceivers?: ArticleTypeReminderReceiver[];
  articleTypeWashingOrRepairLocations?: ArticleTypeWashingOrRepairLocation[];

  constructor(data: Partial<ArticleTypeConfiguration>) {
    super(data);
    if (data.articleType) this.articleType = new ArticleType(data.articleType);
    if (data.validationTaskTemplate)
      this.validationTaskTemplate = new DataRecord(data.validationTaskTemplate);
    if (data.employmentValidationTaskTemplate)
      this.employmentValidationTaskTemplate = new DataRecord(
        data.employmentValidationTaskTemplate
      );
    if (data.maintenanceRequestTaskTemplate)
      this.maintenanceRequestTaskTemplate = new DataRecord(
        data.maintenanceRequestTaskTemplate
      );
    if (data.repairRequestTaskTemplate)
      this.repairRequestTaskTemplate = new DataRecord(
        data.repairRequestTaskTemplate
      );
    if (data.outOfServiceTaskTemplate)
      this.outOfServiceTaskTemplate = new DataRecord(
        data.outOfServiceTaskTemplate
      );

    if (data.articleTypeReminderReceivers)
      this.articleTypeReminderReceivers = data.articleTypeReminderReceivers.map(
        (receiver) => new ArticleTypeReminderReceiver(receiver)
      );
    if (data.articleTypeWashingOrRepairLocations)
      this.articleTypeWashingOrRepairLocations =
        data.articleTypeWashingOrRepairLocations.map(
          (location) => new ArticleTypeWashingOrRepairLocation(location)
        );
  }
}
