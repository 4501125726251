import { Action, createReducer, on } from '@ngrx/store';
import { IshtarSubscription } from '../../domain/models/ishtar-subscription.model';
import { IshtarUser } from '../../domain/models/ishtar-user.model';
import { UserSettings } from '../../domain/models/user-settings.model';
import {
  addUserLicenseResolved,
  getLicensesForUserResolved,
  getUserSettingsResolved,
  removeUserLicenseResolved,
  resetSlice,
  updateUserLicenseResolved,
  updateUserResolved,
  updateUserSettingsResolved,
} from './user.actions';

export const featureSlice = 'user';

export interface State {
  userInfo?: IshtarUser;
  licenses: { subscriptionInfo: IshtarSubscription; isAdmin: boolean }[];
  settings: UserSettings;
}

const defaultState: State = {
  userInfo: undefined,
  licenses: [],
  settings: new UserSettings(),
};

export function Reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

export const initialState: State = defaultState;
export const userReducer = createReducer(
  initialState,
  on(updateUserResolved, (state, { userInfo }) => ({
    ...state,
    userInfo: userInfo,
  })),
  on(getLicensesForUserResolved, (state, { licenses }) => ({
    ...state,
    userInfo: licenses.userInfo,
    licenses: licenses.licenses,
  })),
  on(addUserLicenseResolved, (state, { license }) => ({
    ...state,
    licenses: state.licenses.concat([license]),
  })),
  on(removeUserLicenseResolved, (state, { license }) => ({
    ...state,
    licenses: state.licenses.filter(
      (l) =>
        l.subscriptionInfo.subscriptionId !=
        license.subscriptionInfo.subscriptionId
    ),
  })),
  on(updateUserLicenseResolved, (state, { license }) => ({
    ...state,
    licenses: state.licenses.map((l) =>
      l.subscriptionInfo.subscriptionId ==
      license.subscriptionInfo.subscriptionId
        ? license
        : l
    ),
  })),
  on(getUserSettingsResolved, (state, { settings }) => ({
    ...state,
    settings: settings,
  })),
  on(updateUserSettingsResolved, (state, { settings }) => ({
    ...state,
    settings: settings,
  })),
  on(resetSlice, () => initialState)
);
