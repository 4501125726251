import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { GroupUser, LoaderService } from 'processdelight-angular-components';
import { Observable, Subject, combineLatest, forkJoin } from 'rxjs';
import { map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { Skill } from 'src/app/core/domain/models/skill.model';
import { UserSkill } from 'src/app/core/domain/models/user-skill.model';
import { TranslationService } from 'src/app/core/services/translation.service';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { SkillFacade } from 'src/app/core/store/skill/skill.facade';

const SNACKBAR_DURATION_MS = 3000;

@Component({
  standalone: true,
  selector: 'app-link-skills-form',
  templateUrl: './link-skills-form.component.html',
  styleUrls: ['./link-skills-form.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatSelectModule,
    MatSnackBarModule,
    MatInputModule,
  ],
})
export class LinkSkillsFormComponent implements OnDestroy {
  destroy$ = new Subject<void>();
  users: GroupUser[] = [];
  filteredUsers: Observable<GroupUser[] | undefined> | undefined;
  skills: Skill[] = [];
  filteredSkills: Observable<Skill[] | undefined> | undefined;
  userSkills: UserSkill[] = [];

  skillForm: FormGroup = new FormGroup({});
  skillControl = new FormControl('');
  userControl = new FormControl('');

  selectedUser!: GroupUser;

  get skillFormControl() {
    return this.skillForm.get('skill') as FormControl;
  }

  get skillControlVal() {
    return this.skillFormControl.value as string[];
  }

  get userFormControl() {
    return this.skillForm.get('user') as FormControl;
  }

  get userFormControlVal() {
    return this.users?.find((c) => c?.user?.id == this.userFormControl?.value)
      ?.displayName;
  }

  @ViewChild('skillDropdown')
  skillDropdown?: ElementRef<HTMLInputElement>;
  @ViewChild('userDropdown')
  userDropdown?: ElementRef<HTMLInputElement>;

  constructor(
    private organizationFacade: OrganizationFacade,
    private skillFacade: SkillFacade,
    private loaderService: LoaderService,
    private readonly snackbar: MatSnackBar,
    private readonly translations: TranslationService,
    public dialogRef: MatDialogRef<LinkSkillsFormComponent>
  ) {}

  ngOnInit() {
    this.loadUsers();
    this.loadSkills();
    this.loadUserSkills();
    this.initForm();

    this.filteredUsers = this.userControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._userFilter(value || ''))
    );

    this.filteredSkills = combineLatest([
      this.skillControl.valueChanges.pipe(startWith('')),
      this.skillFormControl.valueChanges.pipe(startWith([])),
    ]).pipe(map(([value]) => this._skillFilter(value || '')));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  onSave() {
    if (this.skillForm.valid) {
      const userSkills = this.buildUserSkills();
      const existingUserSkills = this.userSkills.filter(
        (s) => s.userId === this.selectedUser?.id
      );
      const userSkillsToCreate = userSkills.filter(
        (s) => !existingUserSkills?.some((e) => e.id === s.id)
      );
      const userSkillsToDelete = existingUserSkills
        .filter((s) => !userSkills.some((e) => e.id === s.id))
        .map((s) => s.id);
      const requests: Observable<any>[] = [];
      if (userSkillsToCreate.length) {
        requests.push(this.skillFacade.addUserSkill(userSkillsToCreate));
      }
      if (userSkillsToDelete.length) {
        requests.push(this.skillFacade.deleteUserSkills(userSkillsToDelete));
      }
      if (requests.length)
        this.loaderService.startLoading('Saving', () => forkJoin(requests));

      this.showSuccessSnackbar(this.selectedUser.user?.displayName);
      this.dialogRef.close();
    }
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onSkillChange(event: any) {
    this._skillFilter(this.skillControl.value ?? '');
  }

  onSkillDropdownClick() {
    setTimeout(() => {
      this.skillDropdown?.nativeElement.focus();
    }, 0);
  }

  onUserDropdownClick() {
    setTimeout(() => {
      this.userDropdown?.nativeElement.focus();
    }, 0);
  }

  onUserChange(event: any) {
    this.selectedUser = this.users?.find(
      (u) => u.user?.id === event.value
    ) as GroupUser;
    this.skillForm.enable();
    this._userFilter(this.userControl.value ?? '');

    if (this.selectedUser) {
      const linkedSkillIds = this.userSkills
        .filter(
          (userSkills) =>
            userSkills.user?.user?.id === this.selectedUser.user?.id
        )
        .map((userSkills) => userSkills?.skillId);
      const skillIds = linkedSkillIds.filter((s) =>
        [...this.skills].map((s) => s.id).includes(s)
      );
      this.skillFormControl.patchValue(skillIds);
    }
  }

  getSkillValue(skillId: string) {
    return this.skills.find((s) => skillId === s.id)?.title;
  }

  initForm() {
    this.skillForm = new FormGroup({
      user: new FormControl(
        { value: '', disabled: false },
        Validators.required
      ),
      skill: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
    });
  }

  private loadUsers(): void {
    this.loaderService.startLoading(
      'Loading users with Ishtar.365 license',
      () =>
        new Observable((observer) => {
          this.organizationFacade.groupUsers$.subscribe((g) => {
            this.users = g.map((g) => g);
            this.filteredUsers = this.userControl.valueChanges.pipe(
              startWith(''),
              map((value) => this._userFilter(value || ''))
            );
            observer.next();
            observer.complete();
          });
        })
    );
  }

  private loadUserSkills(): void {
    this.loaderService.startLoading('Loading user skills!', () =>
      this.skillFacade.userSkills$.pipe(
        switchMap((userSkills) =>
          userSkills?.length
            ? (this.userSkills = userSkills)
            : this.skillFacade.getUserSkills$()
        ),
        take(2)
      )
    );
  }

  private loadSkills(): void {
    this.skillFacade.skills$
      .pipe(takeUntil(this.destroy$))
      .subscribe((skills) => {
        if (skills) {
          this.skills = [...skills];
        } else {
          this.skills = [];
        }
      });
  }

  private _userFilter(value: string): GroupUser[] | undefined {
    const filterValue = value.toLowerCase();
    return this.users?.filter(
      (c) =>
        c.user?.displayName?.toLowerCase().includes(filterValue) &&
        c.user?.displayName != this.userFormControlVal
    );
  }

  private _skillFilter(value: string): Skill[] | undefined {
    const filterValue = value.toLowerCase();
    return this.skills?.filter(
      (s) =>
        s.title?.toLowerCase().includes(filterValue) &&
        !this.skillControlVal.includes(s.id ?? 'x')
    );
  }

  private async showSuccessSnackbar(userName?: string) {
    await this.snackbar
      .open('Skills linked to user ' + userName, 'Ok', {
        panelClass: 'app-notification-success',
      })
      ._dismissAfter(SNACKBAR_DURATION_MS);
  }

  private buildUserSkills(): UserSkill[] {
    const userSkills = this.skillForm.get('skill')?.value
      ? (this.skillForm.get('skill')?.value as string[]).map(
          (skillId) =>
            new UserSkill({
              id: this.userSkills.find(
                (s) =>
                  s.userId === this.selectedUser.id && s.skillId === skillId
              )?.id,
              skillId: skillId,
              userId: this.selectedUser.id,
              user: this.selectedUser,
            })
        )
      : [];

    return userSkills;
  }
}
