import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  addSkills,
  addSkillsResolved,
  addUserSkills,
  addUserSkillsResolved,
  getSkills,
  getSkillsResolved,
  getUserSkills,
  getUserSkillsResolved,
  softDeleteSkills,
  softDeleteSkillsResolved,
  updateSkills,
  updateSkillsResolved,
  deleteUserSkills,
  deleteUserSkillsResolved,
} from './skill.actions';
import { catchError, of, switchMap, tap } from 'rxjs';
import { Ishtar365Service } from '../../services/ishtar365.service';

@Injectable({ providedIn: 'root' })
export class SkillEffects {
  constructor(private actions$: Actions, private ishtar365: Ishtar365Service) {}

  getSkills = createEffect(() =>
    this.actions$.pipe(
      ofType(getSkills),
      switchMap(({ callback, error }) =>
        this.ishtar365.getSkills().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((result) => of(getSkillsResolved({ result }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  addSkill = createEffect(() =>
    this.actions$.pipe(
      ofType(addSkills),
      switchMap(({ skills, callback, error }) =>
        this.ishtar365.addSkills(skills).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedSkills) => of(addSkillsResolved({ addedSkills }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateSkill = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSkills),
      switchMap(({ skills, callback, error }) =>
        this.ishtar365.updateSkills(skills).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedSkills) =>
            of(updateSkillsResolved({ updatedSkills }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  softDeleteSkills = createEffect(() =>
    this.actions$.pipe(
      ofType(softDeleteSkills),
      switchMap(({ skills, callback, error }) =>
        this.ishtar365.updateSkills(skills).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap(() => of(softDeleteSkillsResolved({ skills }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getUserSkills = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSkills),
      switchMap(({ callback, error }) =>
        this.ishtar365.getUserSkills().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((result) => of(getUserSkillsResolved({ result }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  addUserSkills = createEffect(() =>
    this.actions$.pipe(
      ofType(addUserSkills),
      switchMap(({ userSkills, callback, error }) =>
        this.ishtar365.addUserSkills(userSkills).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedUserSkills) =>
            of(addUserSkillsResolved({ addedUserSkills }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  deleteUserSkills = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserSkills),
      switchMap(({ userSkillIds, callback, error }) =>
        this.ishtar365.deleteUserSkills(userSkillIds).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap(() =>
            of(deleteUserSkillsResolved({ deletedUserSkillIds: userSkillIds }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
