<div
  class="d-flex flex-row w-100 h-100 overflow-hidden position-relative app-page"
  [class.shown]="shown"
>
  <ng-container *ngIf="page">
    <div
      [hidden]="!menuOpened"
      class="position-absolute w-100 h-100 bg-white z-index-5"
    >
      <div
        class="position-absolute close-menu d-flex justify-content-center align-items-center clickable"
        (click)="menuOpened = false"
      >
        <button mat-mini-fab color="primary">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div
        class="w-100 h-100 d-flex flex-column align-items-center overflow-auto scrollbar"
      >
        <div
          class="d-flex flex-column justify-content-center align-items-center mb-4"
          *ngFor="let segment of segments; let isFirst = first"
          [style.margin-top.px]="isFirst ? firstTopMargin : 0"
        >
          <div
            class="section-title mb-2 fw-bold"
            *ngIf="!segment.hideTitle"
          >
            {{ segment.title }}
          </div>
          <div
            class="d-flex flex-row flex-wrap justify-content-center align-items-flex-start"
          >
            <div *ngFor="let tile of getTiles(segment)">
              <ng-container
                *ngIf="
                  tile.tileActionType === TileAction.IshtarApp;
                  else customTile
                "
              >
                <app-app-tile [tile]="tile"></app-app-tile>
              </ng-container>
              <ng-template #customTile>
                <app-custom-tile [tile]="tile"></app-custom-tile>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="position-relative pb-4 bg-white"
      (mouseenter)="sideBarHover = true"
      (mouseleave)="sideBarHover = false"
      style="transition: max-width 0.2s ease-in-out"
      [style.max-width.px]="userSettings.sideBarPinned || sideBarHover ? 56 : 0"
    >
      <!-- <div
        class="position-absolute open-menu d-flex justify-content-center align-items-center clickable z-index-4"
        [class.pinned]="userSettings.sideBarPinned || sideBarHover"
        (click)="menuOpened = true"
      >
        <mat-icon>chevron_right</mat-icon>
      </div> -->
      <div
        class="pin-menu d-flex justify-content-center align-items-center clickable z-index-4"
        [class.pinned]="userSettings.sideBarPinned"
        [style.width.px]="userSettings.sideBarPinned || sideBarHover ? 56 : 0"
        (click)="togglePinned()"
      >
        <mat-icon [class.material-icons-outlined]="!userSettings.sideBarPinned"
          >push_pin</mat-icon
        >
      </div>
      <div
        class="d-flex flex-column align-items-center scrollbar tile-wrapper"
        [class.ps-2]="userSettings.sideBarPinned || sideBarHover"
        [class.pe-2]="userSettings.sideBarPinned || sideBarHover"
      >
        <ng-container *ngFor="let segment of segments">
          <ng-container *ngFor="let tile of getTiles(segment)">
            <div
              class="mb-2"
              *ngIf="tile.tileActionType !== TileAction.IshtarApp"
            >
              <app-custom-tile
                [tile]="tile"
                [size]="40"
                [intranet]="true"
                [inSidebar]="true"
              ></app-custom-tile>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div class="iframe-container w-100 h-100">
    <app-frame-manager></app-frame-manager>
  </div>
</div>
