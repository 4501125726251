<div class="article-type-settings-wrapper">
  <div class="d-flex flex-row h-100">
    <mat-card class="h-fit-content">
      <mat-card-header>
        <mat-card-title
          *ngIf="
            getTranslation$('manageArticleTypes')
              | async as manageArticleTypesLabel
          "
        >
          {{ manageArticleTypesLabel }}</mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        <ng-container *ngIf="formArray.controls as articleTypes">
          <mat-action-list *ngIf="articleTypes.length; else noArticleTypes">
            <mat-list-item
              class="clickable"
              color="accent"
              [class.active]="getId(activeArticleType) === getId(articleType)"
              *ngFor="let articleType of articleTypes"
              (click)="activeArticleType = articleType"
            >
              {{ getArticleType(articleType)?.name }}
            </mat-list-item>
          </mat-action-list>
        </ng-container>
        <ng-template #noArticleTypes>
          <div
            *ngIf="
              getTranslation$('errorNoArticleTypes')
                | async as errorNoArticleTypesLabel
            "
            class="m-2 ms-4"
          >
            {{ errorNoArticleTypesLabel }}
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="activeArticleType" class="ms-2">
      <mat-card-header>
        <mat-card-title>
          {{ getArticleType(activeArticleType)?.name }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content [formGroup]="activeArticleType">
        <div class="m-2 ms-4 d-flex flex-column">
          <div class="d-flex flex-row align-items-center mb-1">
            <mat-checkbox formControlName="isClothing" color="primary">
            </mat-checkbox>
            <div>
              {{ getTranslation$("isClothing") | async }}
            </div>
          </div>
          <div class="d-flex flex-row align-items-center mb-1">
            <mat-checkbox
              formControlName="washOrMaintenanceReminderEnabled"
              color="primary"
              (change)="
                onEnabledChange(
                  $event.checked,
                  'washOrMaintenanceReminder',
                  'washOrMaintenanceReminderPeople'
                )
              "
            >
            </mat-checkbox>
            <div class="d-flex flex-row align-items-center text-lowercase">
              <div class="text-capitalize-first">
                <ng-container
                  *ngIf="
                    getIsClothing(activeArticleType);
                    else sendMaintenanceReminder
                  "
                >
                  {{ getTranslation$("sendWashReminder") | async }}
                </ng-container>
                <ng-template #sendMaintenanceReminder>
                  {{ getTranslation$("sendMaintenanceReminder") | async }}
                </ng-template>
              </div>
              <mat-form-field
                class="config-number-input ms-2 me-2 no-subscript-wrapper"
              >
                <input
                  matInput
                  type="number"
                  formControlName="washOrMaintenanceReminder"
                  min="0"
                />
              </mat-form-field>
              {{ getTranslation$("days") | async }}
              {{ getTranslation$("to") | async }}
              <mat-form-field
                class="config-choice-input ms-2 me-2 no-subscript-wrapper"
                floatLabel="always"
              >
                <mat-select
                  formControlName="washOrMaintenanceReminderPeople"
                  multiple="true"
                  panelClass="article-type-config-select"
                >
                  <mat-form-field class="no-subscript-wrapper">
                    <mat-label>{{
                      getTranslation$("search") | async
                    }}</mat-label>
                    <input matInput type="text" #searchControl />
                  </mat-form-field>
                  <mat-option
                    *ngFor="let person of groupUsers"
                    [value]="person.id"
                    [hidden]="
                      !person.displayName
                        ?.toLowerCase()
                        ?.includes(searchControl.value.toLowerCase())
                    "
                  >
                    {{ person.displayName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center text-lowercase mb-1">
            <mat-checkbox
              formControlName="maxTimesOfWashingOrRepairEnabled"
              color="primary"
              (change)="
                onEnabledChange(
                  $event.checked,
                  'maxTimesOfWashingOrRepair',
                  'maxTimesOfWashingOrRepairLocations'
                )
              "
            ></mat-checkbox>
            <div class="text-capitalize-first">
              <ng-container
                *ngIf="getIsClothing(activeArticleType); else maxTimesOfRepair"
              >
                {{ getTranslation$("maxTimesOfWashing") | async }}:
              </ng-container>
              <ng-template #maxTimesOfRepair>
                {{ getTranslation$("maxTimesOfRepair") | async }}:
              </ng-template>
            </div>
            <mat-form-field
              class="config-number-input ms-2 me-2 no-subscript-wrapper"
            >
              <input
                matInput
                type="number"
                formControlName="maxTimesOfWashingOrRepair"
                min="0"
              />
            </mat-form-field>
            {{ getTranslation$("times") | async }}
          </div>
          <div class="d-flex flex-row align-items-center ms-2 mb-1">
            <div class="flex-shrink-0">
              {{
                getTranslation$("maxTimesOfWashingOrRepairStockLocation")
                  | async
              }}:
            </div>
            <mat-form-field
              class="config-choice-input ms-2 me-2 no-subscript-wrapper"
            >
              <mat-select
                formControlName="maxTimesOfWashingOrRepairLocations"
                multiple="true"
                panelClass="article-type-config-select"
                [compareWith]="warehouseLocationCompareWithFn"
              >
                <mat-form-field class="no-subscript-wrapper">
                  <mat-label>{{ getTranslation$("search") | async }}</mat-label>
                  <input matInput type="text" #searchControl />
                </mat-form-field>
                <mat-option *ngIf="!warehouseLocations.length" disabled>
                  {{ getTranslation$("errorNoStockLocations") | async }}
                </mat-option>
                <mat-option
                  *ngFor="let location of warehouseLocations"
                  [value]="location.id"
                  [hidden]="
                    !warehouseLocationDisplayFn(location)
                      .toLowerCase()
                      .includes(searchControl.value.toLowerCase())
                  "
                >
                  {{ warehouseLocationDisplayFn(location) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <ng-container
            *ngTemplateOutlet="
              taskTemplateOption;
              context: {
                enabledControl: 'validationTaskTemplateEnabled',
                idControl: 'validationTaskTemplateId',
                translationKey: 'articleValidationTaskTemplate'
              }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              taskTemplateOption;
              context: {
                enabledControl: 'employmentValidationTaskTemplateEnabled',
                idControl: 'employmentValidationTaskTemplateId',
                translationKey: 'articleEmploymentTaskTemplate'
              }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              taskTemplateOption;
              context: {
                enabledControl: 'maintenanceRequestTaskTemplateEnabled',
                idControl: 'maintenanceRequestTaskTemplateId',
                translationKey: 'articleMaintenanceTaskTemplate'
              }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              taskTemplateOption;
              context: {
                enabledControl: 'repairRequestTaskTemplateEnabled',
                idControl: 'repairRequestTaskTemplateId',
                translationKey: 'articleRepairTaskTemplate'
              }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              taskTemplateOption;
              context: {
                enabledControl: 'outOfServiceTaskTemplateEnabled',
                idControl: 'outOfServiceTaskTemplateId',
                translationKey: 'articleOutOfServiceTaskTemplate'
              }
            "
          ></ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<ng-template
  #taskTemplateOption
  let-enabledControl="enabledControl"
  let-idControl="idControl"
  let-translationKey="translationKey"
>
  <div
    class="d-flex flex-row align-items-center mb-1"
    *ngIf="activeArticleType"
    [formGroup]="activeArticleType"
  >
    <mat-checkbox
      [formControlName]="enabledControl"
      color="primary"
      (change)="onEnabledChange($event.checked, idControl)"
    >
    </mat-checkbox>
    <div class="d-flex flex-row align-items-center text-lowercase">
      <div class="text-capitalize-first">
        {{ getTranslation$(translationKey) | async }}:
      </div>
      <mat-form-field
        class="config-choice-input ms-2 me-2 no-subscript-wrapper"
        floatLabel="always"
      >
        <mat-select
          [formControlName]="idControl"
          panelClass="article-type-config-select"
        >
          <mat-form-field class="no-subscript-wrapper">
            <mat-label>{{ getTranslation$("search") | async }}</mat-label>
            <input matInput type="text" #searchControl />
          </mat-form-field>
          <mat-option *ngIf="!taskTemplates.length" disabled>
            {{ getTranslation$("errorNoTemplates") | async }}
          </mat-option>
          <mat-option
            *ngFor="let template of taskTemplates"
            [value]="template.id"
            [hidden]="
              !template.name
                .toLowerCase()
                .includes(searchControl.value.toLowerCase())
            "
          >
            {{ template.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-template>
