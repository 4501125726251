import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Skill } from 'src/app/core/domain/models/skill.model';
import { TranslationService } from 'src/app/core/services/translation.service';

@Component({
  standalone: true,
  selector: 'app-skill-popup',
  templateUrl: './skill-popup.component.html',
  styleUrls: ['./skill-popup.component.scss'],
  imports: [MatDialogModule, MatIconModule, MatButtonModule, CommonModule],
})
export class SkillPopupComponent {
  selectedSkill: Skill | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { unsavedChanges: boolean; formGroup: FormGroup },
    public popupDialogRef: MatDialogRef<SkillPopupComponent>,
    private readonly translations: TranslationService
  ) {}

  onCloseDialog(action: string) {
    this.popupDialogRef.close(action);
  }

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }
}
