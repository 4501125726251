import { createAction, props } from '@ngrx/store';
import { ActionBase } from '../action-base.interface';
import { MetadataParameter } from '../../domain/models/metadata-parameter.model';

export const getMetadataParameters = createAction(
  '[MetadataParameter] Get metadata parameters',
  props<ActionBase<MetadataParameter[]>>()
);
export const getMetadataParametersResolved = createAction(
  '[MetadataParameter] Get metadata parameters resolved',
  props<{
    metadataParameters: MetadataParameter[];
  }>()
);