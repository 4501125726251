import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import * as fromAppSubscription from './app-subscription/app-subscription.reducer';
import * as fromGroup from './group/group.reducer';
import * as fromOrganization from './organization/organization.reducer';
import * as fromUser from './user/user.reducer';
import * as fromTilePage from './tilepage/tilepage.reducer';
import * as fromLibrary from './library/library.reducer';
import * as fromFormTemplate from './formTemplate/formTemplate.reducer';
import * as fromMetadataParameters from './metadataParameter/metadataParameter.reducer';
import * as fromSkill from './skill/skill.reducer';
import * as fromWorkRegime from './workRegime/workRegime.reducer';
import * as fromAutomationFlow from './automation-flow/automation-flow.reducer';
import * as fromInterestGroup from './interest-group/interest-group.reducer';
export const featureKey = 'coreFeature';

export interface CoreState {
  [fromAppSubscription.featureSlice]: fromAppSubscription.State;
  [fromUser.featureSlice]: fromUser.State;
  [fromOrganization.featureSlice]: fromOrganization.State;
  [fromGroup.featureSlice]: fromGroup.State;
  [fromTilePage.featureSlice]: fromTilePage.State;
  [fromLibrary.featureSlice]: fromLibrary.State;
  [fromFormTemplate.featureSlice]: fromFormTemplate.State;
  [fromMetadataParameters.featureSlice]: fromMetadataParameters.State,
  [fromSkill.featureSlice]: fromSkill.State;
  [fromWorkRegime.featureSlice]: fromWorkRegime.State;
  [fromAutomationFlow.featureSlice]: fromAutomationFlow.State;
  [fromInterestGroup.featureSlice]: fromInterestGroup.State;
}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
  featureKey,
  {
    factory: () => ({
      [fromAppSubscription.featureSlice]: fromAppSubscription.Reducer,
      [fromUser.featureSlice]: fromUser.Reducer,
      [fromOrganization.featureSlice]: fromOrganization.Reducer,
      [fromGroup.featureSlice]: fromGroup.Reducer,
      [fromTilePage.featureSlice]: fromTilePage.Reducer,
      [fromLibrary.featureSlice]: fromLibrary.Reducer,
      [fromFormTemplate.featureSlice]: fromFormTemplate.Reducer,
      [fromMetadataParameters.featureSlice]: fromMetadataParameters.Reducer,
      [fromSkill.featureSlice]: fromSkill.Reducer,
      [fromWorkRegime.featureSlice]: fromWorkRegime.Reducer,
      [fromAutomationFlow.featureSlice]: fromAutomationFlow.Reducer,
      [fromInterestGroup.featureSlice]: fromInterestGroup.Reducer,
    }),
  }
);
