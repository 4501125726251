import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import {
  addWorkRegimeDaysResolved,
  getWorkRegimeDaysResolved,
  updateWorkRegimeDaysResolved,
  addWorkRegimesResolved,
  getWorkRegimesResolved,
  updateWorkRegimesResolved,
  getWorkRegimeTypesResolved,
  getDaysResolved,
  resetSlice,
} from './workRegime.actions';
import { WorkRegimeDay } from '../../domain/models/workRegimeDay.model';
import { WorkRegime } from '../../domain/models/workRegime.model';
import { WorkRegimeType } from '../../domain/models/workRegimeType.model';
import { Day } from 'processdelight-angular-components';

export const featureSlice = 'workRegime';

export interface State {
  workRegimeDays: WorkRegimeDay[];
  workRegimes: WorkRegime[];
  workRegimeTypes: WorkRegimeType[];
  days: Day[];
}

const defaultState: State = {
  workRegimeDays: [],
  workRegimes: [],
  workRegimeTypes: [],
  days: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return workRegimeReducer(state, action);
}

export const initialState: State = defaultState;

export const workRegimeReducer = createReducer(
  initialState,
  on(getWorkRegimeDaysResolved, (state, { result }) => ({
    ...state,
    workRegimeDays: [...result],
  })),
  on(updateWorkRegimeDaysResolved, (state, { updatedWorkRegimeDays }) => ({
    ...state,
    workRegimeDays: state.workRegimeDays?.map(
      (u) => updatedWorkRegimeDays.find((s) => u.id === s.id) ?? u
    ),
  })),
  on(addWorkRegimeDaysResolved, (state, { addedWorkRegimeDays }) => ({
    ...state,
    workRegimeDays: addedWorkRegimeDays.concat([
      ...(state.workRegimeDays ?? []),
    ]),
  })),
  on(getWorkRegimesResolved, (state, { result }) => ({
    ...state,
    workRegimes: [...result],
  })),
  on(updateWorkRegimesResolved, (state, { updatedWorkRegimes }) => ({
    ...state,
    workRegimes: state.workRegimes?.map(
      (u) => updatedWorkRegimes.find((s) => u.id === s.id) ?? u
    ),
  })),
  on(addWorkRegimesResolved, (state, { addedWorkRegimes }) => ({
    ...state,
    workRegimes: addedWorkRegimes.concat([...(state.workRegimes ?? [])]),
  })),
  on(getWorkRegimeTypesResolved, (state, { result }) => ({
    ...state,
    workRegimeTypes: [...result],
  })),
  on(getDaysResolved, (state, { result }) => ({
    ...state,
    days: [...result],
  })),
  on(resetSlice, () => initialState)
);

export const workRegimeState = (state: AppState) =>
  state.coreFeature.workRegime;
