import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Subject } from 'rxjs';
import { AppState } from 'src/app/shared/store/app.reducer';
import { IshtarOrganigramGroup } from '../../domain/models/ishtar-organigram-group..model';
import { getOrganigram, resetSlice, saveOrganigram } from './group.actions';
import { groupSelectors } from './group.selectors';
import { BaseFacade } from '../base.facade';

@Injectable({
  providedIn: 'root',
})
export class GroupFacade extends BaseFacade {
  getOrganigramFn$ = this._store.pipe(select(groupSelectors.getOrganigramFn));
  getOrganigram$ = (appName: string) =>
    this.getOrganigramFn$.pipe(map((fn) => fn(appName)));
  constructor(_store: Store<AppState>) {
    super(_store);
  }

  retrieveOrganigram$(appName: string) {
    return this.dispatchAction$(getOrganigram, { appName });
  }
  saveOrganigram$(appName: string, groups: IshtarOrganigramGroup[]) {
    return this.dispatchAction$(saveOrganigram, { appName, groups });
  }
  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
