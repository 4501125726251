<div
  class="w-100 h-100 d-flex flex-column align-items-center overflow-auto scrollbar"
>
  <div
    class="d-flex flex-column justify-content-center align-items-center mb-4"
    *ngFor="let segment of segments; let isFirst = first"
    [style.margin-top.px]="isFirst ? firstTopMargin : 0"
  >
    <div class="section-title mb-2 fw-bold" *ngIf="!segment.hideTitle">
      {{ segment.title }}
    </div>
    <div
      class="d-flex flex-row flex-wrap justify-content-center align-items-flex-start"
    >
      <div *ngFor="let tile of getTiles(segment)">
        <ng-container
          *ngIf="tile.tileActionType === TileAction.IshtarApp; else customTile"
        >
          <app-app-tile [tile]="tile"></app-app-tile>
        </ng-container>
        <ng-template #customTile>
          <app-custom-tile [tile]="tile"></app-custom-tile>
        </ng-template>
      </div>
    </div>
  </div>
</div>
