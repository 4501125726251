import { BaseEntity } from 'processdelight-angular-components';
import { InterestGroup } from './interest-group.model';
export class InterestGroupSubgroup extends BaseEntity {
  parentGroupId!: string;
  subGroupId!: string;
  parentGroup!: InterestGroup;
  subGroup!: InterestGroup;
  constructor(obj: Partial<InterestGroupSubgroup>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.parentGroup) this.parentGroup = new InterestGroup(obj.parentGroup);
    if (obj.subGroup) this.subGroup = new InterestGroup(obj.subGroup);
  }
}
