import { Injectable } from '@angular/core';
import { Client } from '@microsoft/microsoft-graph-client';
import { from, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  public graphClient?: Client;

  public setClient(token: string) {
    this.graphClient = Client.init({
      authProvider: (done) => done(null, token),
    });
  }

  public getUserPhoto(microsoftId: string): Observable<string | undefined> {
    if (this.graphClient)
      return from(
        this.graphClient.api(`/users/${microsoftId}/photo/$value`).get()
      ).pipe(catchError(() => of(undefined)));
    return of(undefined);
  }
}
