import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject, filter, first, takeUntil } from 'rxjs';
import { CoreModule } from 'src/app/core/core.module';
import { TileAction } from 'src/app/core/domain/enums/tile-action.enum';
import { TilePageSegment } from 'src/app/core/domain/models/tile-page-segment.model';
import { TilePage } from 'src/app/core/domain/models/tile-page.model';
import { UserSettings } from 'src/app/core/domain/models/user-settings.model';
import { FrameManagerService } from 'src/app/core/services/frame-manager.service';
import { TilePageFacade } from 'src/app/core/store/tilepage/tilepage.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { FrameManagerComponent } from 'src/app/layout/frame-manager/frame-manager.component';
import { MicrosoftAuthenticationService } from 'src/app/msal/injectables/microsoft-authentication.service';
import { ApplicationBarService } from 'src/app/shared/services/application-bar.service';
import { AppTileComponent } from '../app-tile/app-tile.component';
import { CustomTileComponent } from '../custom-tile/custom-tile.component';

@Component({
  selector: 'app-app-page',
  standalone: true,
  imports: [
    CoreModule,
    AppTileComponent,
    CustomTileComponent,
    FrameManagerComponent,
  ],
  templateUrl: './app-page.component.html',
  styleUrls: ['./app-page.component.scss'],
})
export class AppPageComponent implements OnInit, OnDestroy {
  page?: TilePage;

  tilePages?: TilePage[] = [];

  TileAction = TileAction;

  menuOpened = false;

  userSettings = new UserSettings();

  destroy$ = new Subject<void>();

  sideBarHover = false;

  firstTopMargin = 0;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.firstTopMargin =
      (event.target as Window)?.innerWidth < 550 ||
      (event.target as Window)?.innerHeight < 450
        ? 30
        : 80;
  }

  get segments() {
    return this.page?.segments
      .map((s) => s.tilePageSegment)
      .filter((s) => !s.disabled)
      .sort((a, b) => a.position - b.position);
  }

  get shown() {
    return this.frameManagerService.componentShown;
  }

  constructor(
    private tilePageFacade: TilePageFacade,
    private userFacade: UserFacade,
    private msal: MicrosoftAuthenticationService,
    private frameManagerService: FrameManagerService,
    private applicationBarService: ApplicationBarService
  ) {}

  ngOnInit(): void {
    this.firstTopMargin =
      window.innerWidth < 550 || window.innerHeight < 450 ? 30 : 80;
    this.applicationBarService.appBarExtended$
      .pipe(takeUntil(this.destroy$))
      .subscribe((b) => {
        this.sideBarHover = b;
      });
    this.applicationBarService.tilePageExtended$
      .pipe(takeUntil(this.destroy$))
      .subscribe((b) => {
        this.menuOpened = b;
      });
    this.tilePageFacade.homepage$
      .pipe(
        filter((p) => !!p),
        first()
      )
      .subscribe((p) => (this.page = p));

    this.tilePageFacade.tilePages$
      .pipe(takeUntil(this.destroy$))
      .subscribe((p) => (this.tilePages = p));
    this.userFacade.userSettings$
      .pipe(takeUntil(this.destroy$))
      .subscribe((s) => (this.userSettings = new UserSettings(s)));
    this.frameManagerService.openApp$
      .pipe(takeUntil(this.destroy$))
      .subscribe((app) => {
        if (app) {
          this.menuOpened = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTiles(segment: TilePageSegment) {
    return segment.tiles
      .map((t) => t.tile)
      .filter(
        (t) =>
          !t.disabled &&
          (!t.permissions?.length ||
            t.permissions
              .map((p) => p.permission)
              .some((p) =>
                p.groupUser.user
                  ? p.groupUser.id == this.msal.id
                  : p.groupUser.group?.members?.some(
                      (m) => m.id == this.msal.id
                    )
              )) &&
          !(
            t.tileActionType == TileAction.TilePage &&
            !this.tilePages?.some((p) => p.id == t.tileAction)
          )
      )
      .sort((a, b) => a.position - b.position);
  }

  togglePinned() {
    this.userSettings.sideBarPinned = !this.userSettings.sideBarPinned;
    this.userFacade
      .updateUserSettings$(new UserSettings(this.userSettings))
      .subscribe();
  }
}
