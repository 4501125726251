import { Day, GroupUserEntity } from 'processdelight-angular-components';
import { NotificationConfig } from './notification-config.model';

export class NotificationUserSetting {
  id!: string;
  userId?: string;
  portaluserid?: string;

  scheduledDisabledDays!: Day[];
  scheduleTimeEnabled?: boolean;
  scheduledStartTime?: string;
  scheduledEndTime?: string;

  notificationConfigs: NotificationConfig[] = [];

  user?: GroupUserEntity;
  constructor(obj?: Partial<NotificationUserSetting>) {
    Object.assign(this, obj);
  }
}
