import { GraphUser } from './graph-user.model';

export class GraphGroup {
  public id!: string;
  public displayName?: string;
  public description?: string;
  public email?: string;
  public graphGroupType?: string;
  public owners?: GraphUser[];
  public members?: GraphUser[];
  public ownerGroups?: GraphGroup[];
  public memberGroups?: GraphGroup[];
  constructor(obj: Partial<GraphGroup>) {
    Object.assign(this, obj);
  }
}
