import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  filter,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { NgxColorsModule } from 'ngx-colors';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  AutomationFlowActionType,
  AutomationFlowApplication,
  AutomationFlow,
  AutomationFlowConfigurationPopupComponent,
  ColumnDef,
  DashboardComponent,
  GroupUser,
  LoaderService,
  Lookup,
  Operator,
  PropertyType,
  TranslationService,
  AutomationFlowConditionType,
  IAutomationFlowConfigPopupData,
  AutomationFlowConfigRequest,
  ObjectTypeRecordService,
  Day,
  Month,
} from 'processdelight-angular-components';
import { AutomationFlowFacade } from 'src/app/core/store/automation-flow/automation-flow.facade';
import { BreakpointObserver } from '@angular/cdk/layout';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { ApplicationService } from 'src/app/shared/services/application.service';

export const AUTOMATIONFLOWSPAGESIZE = 'ISHTAR-AUTOMATIONFLOWS-BATCH-PAGESIZE';
export const AUTOMATIONFLOWSCOLUMNS =
  'ISHTAR-AUTOMATIONFLOWS-BATCH-DISPLAYEDCOLUMNS';
export const AUTOMATIONFLOWSSORTDIRECTION =
  'ISHTAR-AUTOMATIONFLOWS-BATCH-SORTDIRECTION';
export const AUTOMATIONFLOWSSORTEDCOLUMN =
  'ISHTAR-AUTOMATIONFLOWS-BATCH-SORTEDCOLUMN';

@Component({
  selector: 'app-automation-flow',
  standalone: true,
  imports: [
    DashboardComponent,
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDialogModule,
    NgxColorsModule,
    MatSnackBarModule,
    AutomationFlowConfigurationPopupComponent,
  ],
  templateUrl: './automation-flow.component.html',
  styleUrls: ['./automation-flow.component.scss'],
})
export class AutomationFlowComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  isLoading$ = new Subject<string>();
  isMobile = false;
  selection = new SelectionModel<FormGroup>(true, undefined);

  translations = this.translationService.translations;

  filterSubject = new BehaviorSubject<string>('');

  automationFlows: AutomationFlow[] = [];
  frequencies: Lookup[] = [];
  days: Day[] = [];
  months: Month[] = [];
  rankings: Lookup[] = [];
  triggerTypes: Lookup[] = [];
  actionTypes: AutomationFlowActionType[] = [];
  conditionTypes: AutomationFlowConditionType[] = [];
  operators: Operator[] = [];
  applications: AutomationFlowApplication[] = [];
  groupUsers: GroupUser[] = [];
  propertyTypes: PropertyType[] = [];

  expandedAutomationFlow: AutomationFlow | undefined;
  sortDirection: 'asc' | 'desc' | '' = 'desc';
  filters: [string, AutomationFlow][] = [];
  pageSize = 10;
  sortedColumn = 'Name';
  lastLoadedPage = 0;
  totalRecordCount = 0;
  firstLoad = true;

  possibleColumns: ColumnDef<AutomationFlow>[] = [
    new ColumnDef({
      internalName: 'Name',
      displayName: 'Name',
      sortable: true,
      valueAccessor: (automationFlow) => automationFlow?.name,
    }),
    new ColumnDef({
      internalName: 'Description',
      displayName: 'Description',
      sortable: true,
      valueAccessor: (automationFlow) => automationFlow?.description,
    }),
    new ColumnDef({
      internalName: 'CreatedBy',
      displayName: 'Created by',
      sortable: true,
      valueAccessor: (automationFlow) => automationFlow?.createdBy?.name,
    }),
    new ColumnDef({
      internalName: 'CreatedOn',
      displayName: 'Created on',
      sortable: true,
      valueAccessor: (automationFlow) =>
        new Date(automationFlow?.createdOn ?? '').toLocaleDateString(),
    }),
  ];

  selectedColumns: string[] = ['Name', 'Description', 'CreatedBy', 'CreatedOn'];
  page$ = new BehaviorSubject<number>(0);

  itemTrackBy = (_: number, item: AutomationFlow) =>
    item.ishtarAutomationFlowId;

  constructor(
    private loaderService: LoaderService,
    private automationFlowFacade: AutomationFlowFacade,
    private translationService: TranslationService,
    private organizationFacade: OrganizationFacade,
    private applicationService: ApplicationService,
    private objectTypeRecordsService: ObjectTypeRecordService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkIsMobileViewMode();
  }

  ngOnInit(): void {
    this.loadAutomationFlows();
    this.loadGroupUsers();
  }

  loadAutomationFlows(): void {
    this.loaderService.startLoading(
      'Loading automation flows',
      () => this.isLoading$
    );

    this.automationFlowFacade.getAutomationFlows();
    this.automationFlowFacade.getAutomationFlowUtilities();

    this.automationFlowFacade.automationFlows$
      .pipe(takeUntil(this.destroy$))
      .subscribe((automationFlows) => {
        this.automationFlows = automationFlows;
      });

    this.automationFlowFacade.automationFlowUtilities$
      .pipe(takeUntil(this.destroy$))
      .subscribe((automationFlowUtilities) => {
        this.frequencies = automationFlowUtilities.frequencies;
        this.days = automationFlowUtilities.days;
        this.months = automationFlowUtilities.months;
        this.rankings = automationFlowUtilities.rankings;
        this.triggerTypes = automationFlowUtilities.triggerTypes;
        this.actionTypes = automationFlowUtilities.actionTypes;
        this.conditionTypes = automationFlowUtilities.conditionTypes;
        this.operators = automationFlowUtilities.operators;
        this.applications = automationFlowUtilities.applications;
        this.propertyTypes = automationFlowUtilities.propertyTypes;
      });

    combineLatest([
      this.automationFlowFacade.automationFlows$,
      this.automationFlowFacade.automationFlowUtilities$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        tap(([automationFlows, automationFlowUtilities]) => {
          if (
            automationFlows.length >= 0 &&
            automationFlowUtilities.frequencies.length > 0 &&
            automationFlowUtilities.days.length > 0 &&
            automationFlowUtilities.months.length > 0 &&
            automationFlowUtilities.rankings.length > 0 &&
            automationFlowUtilities.triggerTypes.length > 0 &&
            automationFlowUtilities.actionTypes.length >= 0 &&
            automationFlowUtilities.conditionTypes.length >= 0 &&
            automationFlowUtilities.operators.length > 0 &&
            automationFlowUtilities.applications.length > 0 &&
            automationFlowUtilities.propertyTypes.length > 0
          ) {
            this.isLoading$.next('');
          }
        })
      )
      .subscribe();
  }

  loadGroupUsers(): void {
    this.organizationFacade.groupUsers$
      .pipe(takeUntil(this.destroy$))
      .subscribe((groupUsers) => {
        this.groupUsers = groupUsers ?? [];
      });
  }

  onAddAutomationFlow(): void {
    const isEdit = false;
    const automationFlowToCreate = new AutomationFlow({
      name: '',
      description: '',
      actions: [],
      conditionGroups: [],
      createdBy: new Lookup({
        id: this.applicationService.msal.id,
        name: this.applicationService.msal.name,
      }),
    });

    this.openAutomationFlowDetailsPopup(automationFlowToCreate, isEdit);
  }

  onEditAutomationFlow(automationFlow: AutomationFlow): void {
    const isEdit = true;
    const automationFlowToEdit = new AutomationFlow({
      ishtarAutomationFlowId: automationFlow.ishtarAutomationFlowId,
      name: automationFlow.name,
      description: automationFlow.description,
      actions: automationFlow.actions,
      conditionGroups: automationFlow.conditionGroups,
      createdBy: automationFlow.createdBy,
    });

    this.openAutomationFlowDetailsPopup(automationFlowToEdit, isEdit);
  }

  onDeleteAutomationFlow(automationFlowId: string): void {
    this.loaderService.startLoading(
      'Deleting automation flow',
      () => this.isLoading$
    );
    this.automationFlowFacade.deleteAutomationFlow(automationFlowId);
  }

  openAutomationFlowDetailsPopup(
    automationFlow: AutomationFlow,
    isEdit: boolean
  ): void {
    const automationFlowConfigPopupData: IAutomationFlowConfigPopupData = {
      automationFlow: automationFlow,
      frequencies: this.frequencies,
      days: this.days,
      months: this.months,
      rankings: this.rankings,
      triggerTypes: this.triggerTypes,
      actionTypes: this.actionTypes,
      conditionTypes: this.conditionTypes,
      operators: this.operators,
      applications: this.applications,
      groupUsers: this.groupUsers,
      propertyTypes: this.propertyTypes,
      isEdit: isEdit,
    };

    this.dialog
      .open(AutomationFlowConfigurationPopupComponent, {
        autoFocus: false,
        disableClose: false,
        width: this.isMobile ? '95%' : '70%',
        height: this.isMobile ? '90%' : '70%',
        data: automationFlowConfigPopupData,
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((automationFlowConfigRequest: AutomationFlowConfigRequest) => {
        const automationFlow = automationFlowConfigRequest.automationFlow;
        this.loaderService.startLoading(
          isEdit ? 'Updating automation flow' : 'Creating automation flow',
          () => this.isLoading$
        );
        if (isEdit) {
          this.automationFlowFacade.updateAutomationFlow(automationFlow);
        } else {
          this.automationFlowFacade.createAutomationFlow(automationFlow);
        }
      });

    this.listenToObjectTypeRecordRequests();
  }

  private checkIsMobileViewMode(): void {
    this.isMobile = this.breakpointObserver.isMatched('(max-width: 680px)');
  }

  private listenToObjectTypeRecordRequests(): void {
    this.objectTypeRecordsService.requestObjectTypeRecords$
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.automationFlowFacade.objectTypes$)
      )
      .subscribe(([objectTypeId, objectTypes]) => {
        const objectTypeName = objectTypes.find(
          (ot) => ot.id === objectTypeId
        )?.name;
        this.automationFlowFacade.getObjectTypeRecords(
          objectTypeId,
          objectTypeName!
        );
      });

    this.automationFlowFacade.objectTypeRecords$
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.automationFlowFacade.requestedObjectTypeId$)
      )
      .subscribe(([objectTypeRecords, requestedObjectTypeId]) => {
        const newRecordsRequested = objectTypeRecords[requestedObjectTypeId];
        this.objectTypeRecordsService.updateObjectTypeRecords(
          requestedObjectTypeId,
          newRecordsRequested
        );
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
