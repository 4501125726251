import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { BaseFacade } from '../base.facade';
import { articleTypeActions } from './article-type.actions';
import { articleTypeSelectors } from './article-type.selector';
import { ArticleTypeConfiguration } from '../../domain/models/article-types/article-type-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class ArticleTypeFacade extends BaseFacade {
  articleTypeConfigurations$ = this._store.pipe(
    select(articleTypeSelectors.getArticleTypeConfigurations)
  );
  warehouseLocations$ = this._store.pipe(
    select(articleTypeSelectors.getWarehouseLocations)
  );

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getArticleTypeConfigurations$() {
    return this.dispatchAction$(
      articleTypeActions.getArticleTypeConfigurations
    );
  }
  getWarehouseLocations$() {
    return this.dispatchAction$(articleTypeActions.getWarehouseLocations);
  }
  updateArticleTypeConfigurations$(configurations: ArticleTypeConfiguration[]) {
    return this.dispatchAction$(
      articleTypeActions.updateArticleTypeConfigurations,
      { configurations }
    );
  }
}
