import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { GraphGroup } from 'src/app/core/domain/models/graph-group.model';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { Subject, map } from 'rxjs';
import { PermanenceSyncComponent } from '../permanence-sync.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { LineConnectorComponent } from 'src/app/shared/components/line-connector/line-connector.component';
import { ElementRefDirective } from './element-ref.component';
import {
  ConfirmDialogData,
  ConfirmPopupComponent,
} from 'processdelight-angular-components';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from 'src/app/core/services/translation.service';

@Component({
  selector: 'app-group-tree',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule,
    LineConnectorComponent,
    ElementRefDirective,
  ],
  templateUrl: './group-tree.component.html',
  styleUrls: ['./group-tree.component.scss'],
})
export class GroupTreeComponent {
  @Input() root = false;
  @Input() formGroup!: FormGroup<{
    id: FormControl<string>;
    departmentName: FormControl<string | null>;
    supervisor: FormControl<string | null>;
    groupIds: FormControl<GraphGroup[] | null>;
    children: FormArray<FormGroup>;
  }>;
  @Input() pane?: HTMLElement;
  @Input() cardMovedSubject!: Subject<string>;
  @Output() cardSelected = new EventEmitter<FormGroup>();
  @Output() cardDeleted = new EventEmitter();

  @ViewChild(MatCard) cardRef?: ElementRef;
  @ViewChild('treeChildren') treeChildDiv?: ElementRef;

  get title() {
    return this.formGroup?.get('departmentName')?.value;
  }

  get children() {
    return this.formGroup?.get('children') as FormArray<FormGroup>;
  }

  constructor(
    public organizationFacade: OrganizationFacade,
    private readonly translations: TranslationService,
    private readonly matDialog: MatDialog
  ) {}

  navColor$ = this.organizationFacade.tempProperties$.pipe(
    map((p) => p.navColor)
  );
  navContrast$ = this.organizationFacade.tempProperties$.pipe(
    map((p) => p.navContrast)
  );

  addChild() {
    const newForm = PermanenceSyncComponent.addNewDepConfig(
      this.formGroup.controls.children
    );
    this.cardSelected.next(newForm);
    setTimeout(() => {
      this.cardMovedSubject.next('');
    }, 0);
  }

  deleteChild(number: number) {
    const config = new ConfirmDialogData({
      title: this.translations.getTranslation('caution'),
      description:
        this.translations.getTranslation('deleteQuestion') +
        '' +
        this.children.at(number).get('departmentName')?.value +
        '?',
      descriptionInHtml: true,
      cancelBtnText: this.translations.getTranslation('cancel'),
      confirmBtnText: this.translations.getTranslation('confirm'),
      onConfirm: () => {
        this.children.removeAt(number);
        setTimeout(() => {
          this.cardMovedSubject.next('');
        }, 0);
      },
    });
    this.matDialog.open(ConfirmPopupComponent, {
      data: config,
      autoFocus: true,
      closeOnNavigation: true,
    });
  }
}
