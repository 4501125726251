<processdelight-top-layout
  [iconActions]="iconActions"
  [hideBreadcrumbs]="true"
  [buttonActions]="buttonActions"
  [searchEnabled]="false"
  [sideBarButtonEnabled]="false"
>
  <mat-tab-group class="h-100 tabgroups">
    <mat-tab label="Profile">
      <ng-template mat-tab-label
        >{{ translations.getTranslation$("notifications") | async }}
      </ng-template>
      <app-notification-settings
        class="tabcontent"
        (buttonActions)="buttonActions = $event"
      />
    </mat-tab>
  </mat-tab-group>
</processdelight-top-layout>
