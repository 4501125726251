import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { automationFlowActions } from './automation-flow.action';
import { BaseFacade } from '../base.facade';
import { AutomationFlow } from 'processdelight-angular-components';
import { automationFlowSelectors } from './automation-flow.selector';

@Injectable({
  providedIn: 'root',
})
export class AutomationFlowFacade extends BaseFacade {
  automationFlows$ = this._store.pipe(
    select(automationFlowSelectors.getAutomationFlows)
  );
  automationFlowUtilities$ = this._store.pipe(
    select(automationFlowSelectors.getAutomationFlowUtilities)
  );
  objectTypes$ = this._store.pipe(
    select(automationFlowSelectors.getObjectTypes)
  );
  objectTypeRecords$ = this._store.pipe(
    select(automationFlowSelectors.getObjectTypeRecords)
  );
  requestedObjectTypeId$ = this._store.pipe(
    select(automationFlowSelectors.getRequestedObjectTypeId)
  );

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getAutomationFlows() {
    this._store.dispatch(automationFlowActions.getAutomationFlows());
  }

  createAutomationFlow(automationFlow: AutomationFlow) {
    this._store.dispatch(
      automationFlowActions.createAutomationFlow({ automationFlow })
    );
  }

  updateAutomationFlow(automationFlow: AutomationFlow) {
    this._store.dispatch(
      automationFlowActions.updateAutomationFlow({ automationFlow })
    );
  }

  deleteAutomationFlow(id: string) {
    this._store.dispatch(automationFlowActions.deleteAutomationFlow({ id }));
  }

  getAutomationFlowUtilities() {
    this._store.dispatch(automationFlowActions.getAutomationFlowUtilities());
  }

  getObjectTypeRecords(objectTypeId: string, objectTypeName: string) {
    this._store.dispatch(
      automationFlowActions.getObjectTypeRecords({
        objectTypeId,
        objectTypeName,
      })
    );
  }
}
