import { BaseEntity } from 'processdelight-angular-components';
import { TilePageType } from '../enums/tile-page-type.enum';
import { TilePageTilePageSegment } from './tile-page-tile-page-segment.model';

export class TilePage extends BaseEntity {
  title!: string;
  disabled?: boolean;
  homepage?: boolean;
  type!: TilePageType;
  intranetUrl?: string;
  segments: TilePageTilePageSegment[] = [];

  app = 'I365';

  constructor(obj: Partial<TilePage>) {
    super(obj);
    Object.assign(this, obj);
    if (typeof obj.type == 'string')
      this.type = TilePageType[obj.type as keyof typeof TilePageType];
    if (obj.segments)
      this.segments = obj.segments.map((s) => new TilePageTilePageSegment(s));
  }
}
