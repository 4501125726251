export enum TileAction {
  TilePage = 1,
  Link = 2,
  Shortcut = 3,
  IshtarApp = 100,
}

export const tileActionEnumMap: { [key: number]: string } = {
  [TileAction.TilePage]: 'Tile page',
  [TileAction.Link]: 'Link',
  [TileAction.Shortcut]: 'Shortcut',
  [TileAction.IshtarApp]: 'Ishtar app',
};
