<mat-card
  appElementRefDirective
  #cardRef="elementRefDirective"
  class="thecard p-2"
  (click)="cardSelected.next(formGroup); $event.stopPropagation()"
>
  <p>{{ title }}</p>
  <div class="btnrow">
    <button
      mat-mini-fab
      *ngIf="!root"
      color="primary"
      (click)="cardDeleted.emit(); $event.stopPropagation()"
      matTooltip="Delete Department and all children"
    >
      <mat-icon>remove</mat-icon>
    </button>
    <button
      mat-mini-fab
      color="primary"
      (click)="addChild(); $event.stopPropagation()"
      matTooltip="Add Child"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</mat-card>
<mat-divider *ngIf="children?.controls?.length"></mat-divider>
<div class="children" #treeChildren>
  <ng-container *ngFor="let child of children?.controls; let i = index">
    <app-line-connector
      [parent]="pane"
      [start]="cardRef.elementRef.nativeElement"
      [end]="childRef.elementRef.nativeElement"
      [startSocket]="'bottom'"
      [endSocket]="'top'"
      [color]="'black'"
      [redraw$]="cardMovedSubject.asObservable()"
    ></app-line-connector>
    <app-group-tree
      appElementRefDirective
      #childRef="elementRefDirective"
      [formGroup]="child"
      [pane]="pane"
      [cardMovedSubject]="cardMovedSubject"
      (cardSelected)="cardSelected.next($event)"
      (cardDeleted)="deleteChild(i)"
    ></app-group-tree>
  </ng-container>
</div>
