import { Action, createReducer, on } from '@ngrx/store';
import { TilePage } from '../../domain/models/tile-page.model';
import { getTilePagesResolved, resetSlice } from './tilepage.actions';

export const featureSlice = 'tilepage';

export interface State {
  tilePages: TilePage[];
}

const defaultState: State = {
  tilePages: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return tilePageReducer(state, action);
}

export const initialState: State = defaultState;
export const tilePageReducer = createReducer(
  initialState,
  on(getTilePagesResolved, (state, { pages }) => ({
    ...state,
    tilePages: pages,
  })),
  on(resetSlice, () => initialState)
);
