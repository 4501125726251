<div class="h-100 d-flex flex-column align-items-center">
  <div class="ishtar-logo">
    <img src="../../../../assets/Ishtar365Logo.png" alt="Ishtar.365! logo" />
  </div>
  <h2 class="mt-3 fw-bold">Welcome!</h2>
  <h4
    class="mb-3 mt-2 mx-3 text-center"
    *ngIf="(signedIn$ | async) === false; else applicationStarting"
  >
    Please log in with your Microsoft 365 account.
  </h4>
  <ng-template #applicationStarting>
    <h4 class="mb-3 text-center">The application is starting.</h4>
  </ng-template>
</div>
