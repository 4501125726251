import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { InitialNavigationGuard } from './core/guards/initial-navigation.guard';
import { AppSigninComponent } from './msal/components/app-signin/app-signin.component';
import { AppSilentSigninComponent } from './msal/components/app-silent-signin/app-silent-signin.component';
import { SignInCallbackComponent } from './msal/components/sign-in-callback/sign-in-callback.component';
import { SignOutCallbackComponent } from './msal/components/sign-out-callback/sign-out-callback.component';
import { TeamsAuthenticationComponent } from './msal/components/teams-authentication/teams-authentication.component';
import { TeamsAdminConsentComponent } from './msal/components/teams-admin-consent/teams-admin-consent.component';
import { TeamsSignOutComponent } from './msal/components/teams-sign-out/teams-sign-out.component';
import { AuthErrorComponent } from './msal/components/auth-error/auth-error.component';
import { NoAppsComponent } from './shared/components/no-apps/no-apps.component';
import { StartupErrorComponent } from './shared/components/startup-error/startup-error.component';
import { WelcomeComponent } from './shared/components/welcome/welcome.component';
export const routes: Routes = [
  {
    path: 'app-signin',
    component: AppSigninComponent,
  },
  {
    path: 'app-silent-signin',
    component: AppSilentSigninComponent,
  },
  {
    path: 'signin',
    component: SignInCallbackComponent,
    data: { breadcrumbTitle: 'Sign in' },
  },
  {
    path: 'signout',
    component: SignOutCallbackComponent,
  },
  {
    path: 'teams-authentication',
    component: TeamsAuthenticationComponent,
  },
  {
    path: 'teams-admin-consent',
    component: TeamsAdminConsentComponent,
  },
  {
    path: 'teams-sign-out',
    component: TeamsSignOutComponent,
  },

  {
    path: 'auth-error',
    component: AuthErrorComponent,
  },
  {
    path: 'no-apps',
    component: NoAppsComponent,
  },
  {
    path: 'startup-error',
    component: StartupErrorComponent,
  },
  {
    path: '',
    component: WelcomeComponent,
    canActivate: [() => inject(InitialNavigationGuard).canActivate()],
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];
export const appRoutes: Routes = [
  {
    path: 'initialconfiguration',
    loadComponent: () => import('./initial-config/initial-config.component'),
  },
  {
    path: 'settings',
    loadComponent: () => import('./settings/settings.component'),
    loadChildren: () =>
      import('./settings/settings.component').then(
        (settings) => settings.settingsRoutes
      ),
  },
];
