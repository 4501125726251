export class Form {
    name!: string;
    applicationId!: string;
    application!: any;
    isDeleted!: boolean;
    id!: string;

    constructor(obj: Partial<Form>) {
        Object.assign(this, obj);
    }
}
