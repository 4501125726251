import { createAction, props } from '@ngrx/store';
import { Skill } from '../../domain/models/skill.model';
import { ActionBase } from '../action-base.interface';
import { UserSkill } from '../../domain/models/user-skill.model';

export const getSkills = createAction(
  '[Skill] Get skills',
  props<ActionBase<Skill[]>>()
);
export const getSkillsResolved = createAction(
  '[Skill] Get skills resolved',
  props<{
    result: Skill[];
  }>()
);

export const softDeleteSkills = createAction(
  '[Skill] Soft delete skill',
  props<
    {
      skills: Skill[];
    } & ActionBase<Skill[]>
  >()
);
export const softDeleteSkillsResolved = createAction(
  '[Skill] Soft delete skill resolved',
  props<{ skills: Skill[] }>()
);

export const updateSkills = createAction(
  '[Skill] Update skills',
  props<{ skills: Skill[] } & ActionBase<Skill[]>>()
);
export const updateSkillsResolved = createAction(
  '[Skill] Update skills resolved',
  props<{ updatedSkills: Skill[] }>()
);

export const addSkills = createAction(
  '[Skill] Add skill',
  props<
    {
      skills: Skill[];
    } & ActionBase<Skill[]>
  >()
);
export const addSkillsResolved = createAction(
  '[Skill] Add skill resolved',
  props<{ addedSkills: Skill[] }>()
);

export const getUserSkills = createAction(
  '[Skill] Get user skills',
  props<ActionBase<UserSkill[]>>()
);
export const getUserSkillsResolved = createAction(
  '[Skill] Get user skills resolved',
  props<{
    result: UserSkill[];
  }>()
);

export const deleteUserSkills = createAction(
  '[Skill] Delete user skills',
  props<{ userSkillIds: string[] } & ActionBase<boolean>>()
);
export const deleteUserSkillsResolved = createAction(
  '[Skill] Delete user skills resolved',
  props<{ deletedUserSkillIds: string[] }>()
);

export const addUserSkills = createAction(
  '[Skill] Add user skills',
  props<
    {
      userSkills: UserSkill[];
    } & ActionBase<UserSkill[]>
  >()
);
export const addUserSkillsResolved = createAction(
  '[Skill] Add user skills resolved',
  props<{ addedUserSkills: UserSkill[] }>()
);

export const resetSlice = createAction('[Skill] Reset slice');
