import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { EffectsRootModule } from '@ngrx/effects';
import { StoreRootModule } from '@ngrx/store';
import { GeneralLayoutComponent } from 'processdelight-angular-components';
import { CoreModule } from './core/core.module';
import { LayoutComponent } from './layout/layout.component';
import { MSALModule } from './msal/msal.module';
import { SharedModule } from './shared/shared.module';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    SharedModule,
    MSALModule,
    CoreModule,
    HttpClientModule,
    StoreRootModule,
    EffectsRootModule,
    GeneralLayoutComponent,
    MatSelectModule,
    LayoutComponent,
  ],
  providers: [],
})
export class AppComponent {
  appName = 'Ishtar.365!';
}
