import { createAction, props } from '@ngrx/store';
import { WorkRegimeDay } from '../../domain/models/workRegimeDay.model';
import { WorkRegime } from '../../domain/models/workRegime.model';
import { WorkRegimeType } from '../../domain/models/workRegimeType.model';
import { ActionBase } from '../action-base.interface';
import { Day } from 'processdelight-angular-components';

export const getWorkRegimeDays = createAction(
  '[IshtarWorkRegimeDay] Get work regime days',
  props<ActionBase<WorkRegimeDay[]>>()
);

export const getWorkRegimeDaysResolved = createAction(
  '[IshtarWorkRegimeDay] Get work regime days resolved',
  props<{ result: WorkRegimeDay[] }>()
);

export const updateWorkRegimeDays = createAction(
  '[IshtarWorkRegimeDay] Update work regime days',
  props<{ workRegimeDays: WorkRegimeDay[] } & ActionBase<WorkRegimeDay[]>>()
);

export const updateWorkRegimeDaysResolved = createAction(
  '[IshtarWorkRegimeDay] Update work regime days resolved',
  props<{ updatedWorkRegimeDays: WorkRegimeDay[] }>()
);

export const addWorkRegimeDays = createAction(
  '[IshtarWorkRegimeDay] Add work regime days',
  props<{ workRegimeDays: WorkRegimeDay[] } & ActionBase<WorkRegimeDay[]>>()
);

export const addWorkRegimeDaysResolved = createAction(
  '[IshtarWorkRegimeDay] Add work regime days resolved',
  props<{ addedWorkRegimeDays: WorkRegimeDay[] }>()
);

export const getWorkRegimes = createAction(
  '[IshtarWorkRegime] Get work regimes',
  props<ActionBase<WorkRegime[]>>()
);

export const getWorkRegimesResolved = createAction(
  '[IshtarWorkRegime] Get work regimes resolved',
  props<{ result: WorkRegime[] }>()
);

export const updateWorkRegimes = createAction(
  '[IshtarWorkRegime] Update work regimes',
  props<{ workRegimes: WorkRegime[] } & ActionBase<WorkRegime[]>>()
);

export const updateWorkRegimesResolved = createAction(
  '[IshtarWorkRegime] Update work regimes resolved',
  props<{ updatedWorkRegimes: WorkRegime[] }>()
);

export const addWorkRegimes = createAction(
  '[IshtarWorkRegime] Add work regimes',
  props<
    {
      workRegimes: WorkRegime[];
    } & ActionBase<WorkRegime[]>
  >()
);

export const addWorkRegimesResolved = createAction(
  '[IshtarWorkRegime] Add work regimes resolved',
  props<{ addedWorkRegimes: WorkRegime[] }>()
);

export const getWorkRegimeTypes = createAction(
  '[IshtarWorkRegimeType] Get work regimes',
  props<ActionBase<WorkRegimeType[]>>()
);

export const getWorkRegimeTypesResolved = createAction(
  '[IshtarWorkRegimeType] Get work regime types resolved',
  props<{ result: WorkRegimeType[] }>()
);

export const getDays = createAction(
  '[getDays] Get days',
  props<ActionBase<Day[]>>()
);

export const getDaysResolved = createAction(
  '[getDaysResolved] Get days resolved',
  props<{ result: Day[] }>()
);

export const resetSlice = createAction('[WorkRegime] Reset slice');
