export class IshtarUser {
  userId!: number;
  email!: string;
  name!: string;
  language!: string;
  lastTenantId?: string;
  microsoftId?: string;
  joined?: Date;
  photo?: string;

  constructor(obj: Partial<IshtarUser>) {
    Object.assign(this, obj);
  }
}
