import { Action, createReducer, on } from '@ngrx/store';
import { IshtarApp } from '../../domain/models/ishtar-app.model';
import { IshtarLicense } from '../../domain/models/ishtar-license.model';
import { IshtarSubscription } from '../../domain/models/ishtar-subscription.model';
import {
  addAppLicenseResolved,
  getAADSyncConfigResolved,
  getAppConfigResolved,
  getAppsResolved,
  getLicensesForAppResolved,
  getLicensesForUserResolved,
  getSubscriptionsResolved,
  removeAppLicenseResolved,
  resetSlice,
  setActiveApp,
  updateAppLicenseResolved,
} from './app-subscription.actions';

export const featureSlice = 'appSubscription';

export interface State {
  apps: IshtarApp[];
  subscriptions: IshtarSubscription[];
  userLicenses: IshtarLicense[];
  appLicenses: IshtarLicense[];
  activeApp?: IshtarApp;
  appConfigs: { [key: string]: any };
  aadSyncConfig : { [key: string]: any };
}

const defaultState: State = {
  apps: [],
  subscriptions: [],
  userLicenses: [],
  appLicenses: [],
  activeApp: undefined,
  appConfigs: {},
  aadSyncConfig: {}
};

export function Reducer(state: State | undefined, action: Action) {
  return appSubscriptionReducer(state, action);
}

export const initialState: State = defaultState;
export const appSubscriptionReducer = createReducer(
  initialState,
  on(getAppsResolved, (state, { apps }) => ({
    ...state,
    apps,
  })),
  on(getSubscriptionsResolved, (state, { subscriptions }) => ({
    ...state,
    subscriptions,
    activeApp:
      subscriptions.length > 0
        ? state.apps.find((a) => a.name == 'Ishtar.365')
        : undefined,
  })),
  on(getLicensesForUserResolved, (state, { licenses }) => ({
    ...state,
    userLicenses: licenses,
  })),
  on(getLicensesForAppResolved, (state, { appId, licenses }) => ({
    ...state,
    appLicenses: state.appLicenses
      .filter((l) => l.subscriptionInfo.productName != appId)
      .concat(licenses),
  })),
  on(addAppLicenseResolved, (state, { license }) => ({
    ...state,
    appLicenses: state.appLicenses.concat([license]),
  })),
  on(removeAppLicenseResolved, (state, { license }) => ({
    ...state,
    appLicenses: state.appLicenses.filter(
      (l) =>
        !(
          l.subscriptionInfo.subscriptionId ==
            license.subscriptionInfo.subscriptionId &&
          l.userInfo.userId == license.userInfo.userId
        )
    ),
  })),
  on(updateAppLicenseResolved, (state, { license }) => ({
    ...state,
    appLicenses: state.appLicenses.map((l) =>
      l.subscriptionInfo.subscriptionId ==
        license.subscriptionInfo.subscriptionId &&
      l.userInfo.userId == license.userInfo.userId
        ? license
        : l
    ),
  })),
  on(setActiveApp, (state, { app }) => ({ ...state, activeApp: app })),
  on(getAppConfigResolved, (state, { appName, config }) => ({
    ...state,
    appConfigs: { ...state.appConfigs, [appName]: config },
  })),
  on(getAADSyncConfigResolved, (state, { appName, config }) => ({
    ...state,
    aadSyncConfig: { ...state.aadSyncConfig, [appName]: config },
  })),
  on(resetSlice, () => initialState)
);
