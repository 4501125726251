import { BaseEntity, GroupUser } from 'processdelight-angular-components';
import { Skill } from './skill.model';

export class UserSkill extends BaseEntity {
  userId!: string;
  user!: GroupUser;
  skillId!: string;
  skill!: Skill;

  constructor(obj: Partial<UserSkill>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.user) this.user = new GroupUser(obj.user);
    if (obj.skill) this.skill = new Skill(obj.skill);
  }
}
