<div class="sync-settings-wrapper">
  <div class="d-flex flex-md-row flex-column gap-2">
    <div>
      <mat-card>
        <mat-card-header>
          <mat-card-title
            *ngIf="getTranslation$('manageSyncs') | async as manageSyncsLabel"
          >
            {{ manageSyncsLabel }}</mat-card-title
          >
        </mat-card-header>
        <mat-card-content>
          <mat-action-list>
            <!-- <ng-container
                *ngIf="
                  activeAppHasSubscription$ | async as activeAppHasSubscription
                "
              >
                <mat-list-item
                  class="active clickable"
                  color="accent"
                  *ngIf="!activeAppHasSubscription"
                >
                  {{ activeApp?.name }}!
                </mat-list-item>
              </ng-container> -->
            <ng-container
              *ngIf="appsWithSubscription$ | async as appsWithSubscription"
            >
              <mat-list-item
                class="clickable"
                color="accent"
                [class.active]="activeApp?.name === app.name"
                [disabled]="!appsWithSync.includes(app.name)"
                *ngFor="let app of appsWithSubscription"
                (click)="
                  appsWithSync.includes(app.name)
                    ? setActiveApp(app)
                    : undefined
                "
              >
                {{ app.name }}!
              </mat-list-item>
            </ng-container>
          </mat-action-list>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="min-width-0">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
