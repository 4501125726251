export enum TileShortcutApps{
    // Projects = "Ishtar.Projects",
    List = "Ishtar.List",
    DMS = "Ishtar.DMS",
    // Tasks = "Ishtar.Tasks"
}

export enum TileShortcutActions{
    FilterTemplate = "Filter on form templates",
    OpenTemplate = "Open form template",
    FilterDocument = "Filter on documents"
}
