import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Subject,
  combineLatest,
  filter,
  first,
  of,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { IshtarApp } from 'src/app/core/domain/models/ishtar-app.model';
import { AppSubscriptionFacade } from 'src/app/core/store/app-subscription/app-subscription.facade';

@Component({
  standalone: true,
  selector: 'app-sync-settings',
  templateUrl: './sync-settings.component.html',
  styleUrls: ['./sync-settings.component.scss'],
})
export class SyncSettingsComponent implements OnInit, OnDestroy {
  app?: IshtarApp;
  destroy$ = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly appSubscriptionFacade: AppSubscriptionFacade
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        takeUntil(this.destroy$),
        switchMap((params) =>
          params.has('appName')
            ? this.appSubscriptionFacade.activeApp$.pipe(first())
            : of(undefined)
        ),
        tap((app) => (this.app = app)),
        filter((app) => !!app)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
