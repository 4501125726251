import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap } from 'rxjs';
import {
  addInterestGroups,
  getInterestGroups,
  updateInterestGroups,
  deleteInterestGroups,
  getInterestGroupsResolved,
  updateInterestGroupsResolved,
  addInterestGroupsResolved,
  deleteInterestGroupsResolved,
} from './interest-group.actions';
import { Ishtar365Service } from '../../services/ishtar365.service';

@Injectable({ providedIn: 'root' })
export class InterestGroupEffects {
  constructor(private actions$: Actions, private ishtar365: Ishtar365Service) {}

  getInterestGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(getInterestGroups),
      switchMap(({ callback, error }) =>
        this.ishtar365.getInterestGroups().pipe(
          tap((result) => (callback ? callback(result) : undefined)),
          switchMap((result) => of(getInterestGroupsResolved({ result }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  addInterestGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(addInterestGroups),
      switchMap(({ interestGroups, callback, error }) =>
        this.ishtar365.addInterestGroups(interestGroups).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedInterestGroups) =>
            of(addInterestGroupsResolved({ addedInterestGroups }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateInterestGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(updateInterestGroups),
      switchMap(({ interestGroups, callback, error }) =>
        this.ishtar365.updateInterestGroups(interestGroups).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedInterestGroups) =>
            of(getInterestGroupsResolved({ result: updatedInterestGroups }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  deleteInterestGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteInterestGroups),
      switchMap(({ interestGroupIds, callback, error }) =>
        this.ishtar365.deleteInterestGroups(interestGroupIds).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((result) =>
            of(
              deleteInterestGroupsResolved({
                result: interestGroupIds,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
