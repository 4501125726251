import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CoreModule } from '../core/core.module';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { StartupErrorComponent } from './components/startup-error/startup-error.component';
@NgModule({
  declarations: [WelcomeComponent, StartupErrorComponent],
  imports: [
    CoreModule,
    MatSidenavModule,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [WelcomeComponent],
})
export class SharedModule {}
