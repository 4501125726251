import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';

const formTemplateState = (state: AppState) => state.coreFeature.formTemplate;
const getFormTemplates = createSelector(
  formTemplateState,
  (state) => state.formTemplates
);
const getFormTemplatesByAppName = createSelector(
  formTemplateState,
  (state) => (appName: string) => state.formTemplates[appName]
);

export const formTemplateSelectors = {
  getFormTemplates,
  getFormTemplatesByAppName,
};
