import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as microsoftTeams from '@microsoft/teams-js';
import { Ishtar365Service } from 'src/app/core/services/ishtar365.service';
import { MicrosoftAuthenticationService } from '../../injectables/microsoft-authentication.service';
import { catchError, filter, first, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-in-callback',
  templateUrl: './sign-in-callback.component.html',
})
export class SignInCallbackComponent implements OnInit {
  private adminConsent?: string;
  error?: string;
  errorDescription?: string;
  tenantId?: string;
  get hasErrors(): boolean {
    return !!this.error && !!this.errorDescription;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private readonly msalService: MicrosoftAuthenticationService,
    private readonly ishtar365Service: Ishtar365Service
  ) {
    this.route.queryParams.pipe(first()).subscribe((params: any) => {
      this.adminConsent = params['admin_consent'];
      this.error = params['error'];
      this.errorDescription = params['error_description'];
      this.tenantId = params['tenant'];
    });
  }

  //https://docs.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/authentication/auth-tab-aad#the-callback-page
  ngOnInit() {
    if (this.adminConsent?.toLowerCase() === 'true') {
      if (!this.hasErrors)
        this.ishtar365Service
          .adminConsented(this.tenantId ?? this.msalService.tenantId)
          .subscribe(() => this.complete());
    } else {
      this.msalService.signedIn
        .pipe(
          filter((b) => !!b),
          first()
        )
        .subscribe(() => {
          // Might be necessary for the OBO flow, but we'll see
          // this.msalService
          //   .getAccessToken(location.origin)
          //   .pipe(
          //     tap(console.log),
          //     tap(() => this.complete()),
          //     catchError(() => {
          //       this.msalService
          //         .userConsent({
          //           scopes: [environment.userAccessScope],
          //           prompt: 'consent',
          //           loginHint: this.msalService.username,
          //         })
          //         .then(() => this.complete());
          //       return of(true);
          //     })
          //   )
          //   .subscribe();
          this.complete();
        });
      this.msalService.completeSignIn();
    }
  }

  private complete(): void {
    if (this.msalService.runningOnTeams)
      microsoftTeams.authentication.notifySuccess();
    else if (window.opener && window.opener !== window) {
      window.opener.postMessage('Consented', location.origin);
      window.close();
    } else this.router.navigate([]);
  }
}
