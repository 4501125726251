import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first, map } from 'rxjs';
import { AppState } from 'src/app/shared/store/app.reducer';
import { IshtarApp } from '../../domain/models/ishtar-app.model';
import { BaseFacade } from '../base.facade';
import {
  addLicense,
  getAADSyncConfig,
  getAppConfig,
  getApps,
  getLicensesForApp,
  getSubscriptions,
  removeLicense,
  resetSlice,
  setActiveApp,
  updateAADSyncConfig,
  updateAppConfig,
  updateLicense,
} from './app-subscription.actions';
import { appSubscriptionSelectors } from './app-subscription.selectors';

@Injectable({
  providedIn: 'root',
})
export class AppSubscriptionFacade extends BaseFacade {
  subscriptions$ = this._store.pipe(
    select(appSubscriptionSelectors.getSubscriptions)
  );
  apps$ = this._store.pipe(select(appSubscriptionSelectors.getApps));
  appsWithSubscription$ = this._store.pipe(
    select(appSubscriptionSelectors.getAppsWithSubscription)
  );
  appsWithoutSubscription$ = this._store.pipe(
    select(appSubscriptionSelectors.getAppsWithoutSubscription)
  );
  activeApp$ = this._store.pipe(select(appSubscriptionSelectors.getActiveApp));
  activeAppHasSubscription$ = this._store.pipe(
    select(appSubscriptionSelectors.getActiveAppHasSubscription)
  );
  activeAppLicenses$ = this._store.pipe(
    select(appSubscriptionSelectors.getActiveAppLicenses)
  );
  getAppLicensesFunc$ = this._store.pipe(
    select(appSubscriptionSelectors.getAppLicensesFunc)
  );
  getSubscriptionForAppFunc$ = this._store.pipe(
    select(appSubscriptionSelectors.getSubscriptionForAppFunc)
  );
  getAppHasSubscriptionFunc$ = this._store.pipe(
    select(appSubscriptionSelectors.getAppHasSubscriptionFunc)
  );
  appConfig$ = (appName: string) =>
    this._store.pipe(
      select(appSubscriptionSelectors.getAppConfigFunc),
      map((fn) => fn(appName))
    );

  aadSyncConfig$ = (appName: string) =>
    this._store.pipe(
      select(appSubscriptionSelectors.getAADSyncConfigFunc),
      map((fn) => fn(appName))
    );

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getApps(callback?: () => void) {
    this._store.dispatch(getApps({ callback }));
  }
  getSubscriptions(callback?: () => void) {
    this._store.dispatch(getSubscriptions({ callback }));
  }
  getApps$() {
    return this.dispatchAction$(getApps);
  }
  getSubscriptions$() {
    return this.dispatchAction$(getSubscriptions);
  }
  getLicensesForApp(resetCache: boolean, appId: string) {
    this._store.dispatch(getLicensesForApp({resetCache, appId }));
  }
  getLicensesForApp$(resetCache: boolean, appId: string) {
    return this.dispatchAction$(getLicensesForApp, { resetCache, appId });
  }
  addLicense(appId: string, email: string, name: string, admin?: boolean) {
    return this.dispatchAction$(addLicense, { appId, email, name, admin });
  }
  updateLicense(appId: string, email: string, admin: boolean) {
    return this.dispatchAction$(updateLicense, { appId, email, admin });
  }
  removeLicense(appId: string, email: string) {
    return this.dispatchAction$(removeLicense, { appId, email });
  }

  setActiveApp(app?: IshtarApp) {
    this.activeApp$.pipe(first()).subscribe((activeApp) => {
      if (activeApp?.name === app?.name) return;
      this._store.dispatch(
        setActiveApp({ app: app ? new IshtarApp(app) : undefined })
      );
    });
  }

  getAppConfig$(appName: string, constructor: new (obj: Partial<any>) => any) {
    return this.dispatchAction$(getAppConfig, { appName, constructor });
  }

  updateAppConfig$(
    appName: string,
    constructor: new (obj: Partial<any>) => any,
    config: any
  ) {
    return this.dispatchAction$(updateAppConfig, {
      appName,
      constructor,
      config,
    });
  }

  getAADSyncConfig$(
    appName: string,
    constructor: new (obj: Partial<any>) => any
  ) {
    return this.dispatchAction$(getAADSyncConfig, { appName, constructor });
  }

  updateAADSyncConfig(
    appName: string,
    constructor: new (obj: Partial<any>) => any,
    config: any
  ) {
    return this.dispatchAction$(updateAADSyncConfig, {
      appName,
      constructor,
      config,
    });
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
