import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IshtarApp } from '../../domain/models/ishtar-app.model';
import { AppSubscriptionFacade } from '../app-subscription/app-subscription.facade';
import { OrganizationFacade } from '../organization/organization.facade';
import {
  getLicensesForUser,
  getUserInfo,
  getUserSettings,
  resetSlice,
  updateUserLanguage,
  updateUserLastTenantId,
  updateUserSettings,
} from './user.actions';
import { userSelectors } from './user.selectors';
import { AppState } from 'src/app/shared/store/app.reducer';
import { UserSettings } from '../../domain/models/user-settings.model';
import { IshtarUser } from '../../domain/models/ishtar-user.model';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {
  userInfo$ = this._store.pipe(select(userSelectors.getUserInfo));
  userLicenses$ = this._store.pipe(select(userSelectors.getUserLicenses));

  isAdmin$ = this.userLicenses$.pipe(
    map(
      (licenses) =>
        !!licenses.find((l) => l.subscriptionInfo.productName == 'Ishtar.365')
          ?.isAdmin
    )
  );
  isMainAdmin$ = combineLatest([
    this.userInfo$,
    this.organizationFacade.organizationAdmins$,
  ]).pipe(
    map(([user, admins]) =>
      admins.some((a) => a.mainAdmin && a.user.userId == user?.userId)
    )
  );
  appsWithLicense$ = combineLatest([
    this.userLicenses$,
    this.appSubscriptionFacade.subscriptions$,
    this.appSubscriptionFacade.appsWithSubscription$,
  ]).pipe(
    map(([licenses, subscriptions, apps]) =>
      apps.filter((a) =>
        subscriptions
          .filter((s) =>
            licenses.some(
              (l) => l.subscriptionInfo.subscriptionId == s.subscriptionId
            )
          )
          .some((s) => s.productName == a?.name)
      )
    )
  );
  appsWithoutLicense$ = combineLatest([
    this.userLicenses$,
    this.appSubscriptionFacade.apps$,
  ]).pipe(
    map(([licenses, apps]) =>
      apps.filter(
        (a) =>
          !licenses.some(
            (l) =>
              l.subscriptionInfo.active &&
              l.subscriptionInfo.productName == a.name
          )
      )
    )
  );
  appHasLicenseFunc$ = this.userLicenses$.pipe(
    map(
      (licenses) => (app: IshtarApp) =>
        licenses.some((l) => l.subscriptionInfo.productName == app.name)
    )
  );

  userSettings$ = this._store.pipe(select(userSelectors.getUserSettings));

  constructor(
    private _store: Store<AppState>,
    private organizationFacade: OrganizationFacade,
    private appSubscriptionFacade: AppSubscriptionFacade
  ) {}

  getUserInfo(
    email: string,
    name?: string,
    callback?: (userInfo: IshtarUser) => void
  ) {
    this._store.dispatch(getUserInfo({ email, name, callback }));
  }

  getUserInfo$(email: string, name?: string) {
    const subject = new Subject<IshtarUser>();
    this._store.dispatch(
      getUserInfo({
        email,
        name,
        callback: (userInfo) => {
          subject.next(userInfo);
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  getUserLicenses(name?: string, resetCache?: boolean, callback?: () => void) {
    this._store.dispatch(getLicensesForUser({ name, resetCache, callback }));
  }

  getUserLicenses$(name?: string, resetCache?: boolean) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getLicensesForUser({
        name,
        resetCache,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateUserLanguage(lang: string, callback?: () => void) {
    this._store.dispatch(updateUserLanguage({ lang, callback }));
  }

  updateUserLanguage$(lang: string) {
    const subject = new Subject<void>();
    this._store.dispatch(
      updateUserLanguage({
        lang,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateUserLastTenantId(tenantId: string, callback?: () => void) {
    this._store.dispatch(updateUserLastTenantId({ tenantId, callback }));
  }

  updateUserLastTenantId$(tenantId: string) {
    const subject = new Subject<void>();
    this._store.dispatch(
      updateUserLastTenantId({
        tenantId,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  getUserSettings$() {
    const subject = new Subject<void>();
    this._store.dispatch(
      getUserSettings({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateUserSettings$(settings: UserSettings) {
    const subject = new Subject<void>();
    this._store.dispatch(
      updateUserSettings({
        settings,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
