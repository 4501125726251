<div class="license-settings-wrapper">
  <div class="d-flex flex-md-row flex-column">
    <div>
      <mat-card>
        <mat-card-header>
          <mat-card-title
            *ngIf="
              getTranslation$('manageLicenses') | async as manageLicensesLabel
            "
          >
            {{ manageLicensesLabel }}</mat-card-title
          >
        </mat-card-header>
        <mat-card-content>
          <mat-action-list>
            <!-- <ng-container
              *ngIf="
                activeAppHasSubscription$ | async as activeAppHasSubscription
              "
            >
              <mat-list-item
                class="active clickable"
                color="accent"
                *ngIf="!activeAppHasSubscription"
              >
                {{ activeApp?.name }}!
              </mat-list-item>
            </ng-container> -->
            <ng-container
              *ngIf="appsWithSubscription$ | async as appsWithSubscription"
            >
              <mat-list-item
                class="clickable"
                color="accent"
                [class.active]="activeApp?.name === app.name"
                *ngFor="let app of appsWithSubscription"
                (click)="setActiveApp(app)"
              >
                {{ app.name }}!
              </mat-list-item>
            </ng-container>
          </mat-action-list>
        </mat-card-content>
      </mat-card>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
