import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';

const organizationState = (state: AppState) => state.coreFeature.organization;
const getOrganizationInfo = createSelector(
  organizationState,
  (state) => state.organizationInfo
);
const getTempProperties = createSelector(organizationState, (state) => ({
  navColor: state.tempNavColor ?? state.organizationInfo?.navColor,
  navContrast: state.tempNavContrast ?? state.organizationInfo?.navContrast,
  logo: state.tempLogo ?? state.organizationInfo?.logo,
}));
const getOrganizationAdmins = createSelector(
  organizationState,
  (state) => state.admins
);
const getGroupUsers = createSelector(
  organizationState,
  (state) => state.groupUsers
);
const getUsers = createSelector(
  organizationState,
  (state) => state.users
);
const getGroups = createSelector(
  organizationState,
  (state) => state.groups
);
const getUserIsOrganizationAdminFunc = createSelector(
  organizationState,
  (state) => (email: string) =>
    state.admins.some((a) => a.user.email.toLowerCase() === email.toLowerCase())
);
export const organizationSelectors = {
  getOrganizationInfo,
  getTempProperties,
  getOrganizationAdmins,
  getGroupUsers,
  getUsers,
  getGroups,
  getUserIsOrganizationAdminFunc,
};
