import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { automationFlowActions } from './automation-flow.action';
import { AutomationFlow } from 'processdelight-angular-components';
import { AutomationFlowService } from '../../services/automation-flow.service';
import { UserFacade } from '../user/user.facade';

@Injectable({ providedIn: 'root' })
export class AutomationFlowEffects {
  constructor(
    private automationFlowService: AutomationFlowService,
    private userFacade: UserFacade,
    private actions$: Actions
  ) {}

  getAutomationFlows = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.getAutomationFlows),
      switchMap(() =>
        this.automationFlowService.getAutomationFlows().pipe(
          switchMap((automationFlows) =>
            of(
              automationFlowActions.getAutomationFlowsResolved({
                automationFlows,
              })
            )
          )
        )
      )
    )
  );

  createAutomationFlow = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.createAutomationFlow),
      switchMap(({ automationFlow }) =>
        this.automationFlowService.createAutomationFlow(automationFlow).pipe(
          switchMap((automationFlow) =>
            of(
              automationFlowActions.createAutomationFlowResolved({
                createdAutomationFlow: new AutomationFlow(automationFlow),
              })
            )
          )
        )
      )
    )
  );

  updateAutomationFlow = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.updateAutomationFlow),
      switchMap(({ automationFlow }) =>
        this.automationFlowService.updateAutomationFlow(automationFlow).pipe(
          switchMap((automationFlow) =>
            of(
              automationFlowActions.updateAutomationFlowResolved({
                updatedAutomationFlow: new AutomationFlow(automationFlow),
              })
            )
          )
        )
      )
    )
  );

  deleteAutomationFlow = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.deleteAutomationFlow),
      switchMap(({ id }) =>
        this.automationFlowService.deleteAutomationFlow(id).pipe(
          switchMap((deletedAutomationFlowId) =>
            of(
              automationFlowActions.deleteAutomationFlowResolved({
                deletedAutomationFlowId,
              })
            )
          )
        )
      )
    )
  );

  getApplications = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.getApplications),
      withLatestFrom(this.userFacade.userInfo$),
      switchMap(([_, userInfo]) =>
        this.automationFlowService.getApplications(userInfo?.email).pipe(
          switchMap((automationFlowApplications) =>
            of(
              automationFlowActions.getApplicationsResolved({
                automationFlowApplications,
              })
            )
          )
        )
      )
    )
  );

  getActionTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.getActionTypes),
      switchMap(() =>
        this.automationFlowService.getActionTypes().pipe(
          switchMap((actionTypes) =>
            of(
              automationFlowActions.getActionTypesResolved({
                actionTypes,
              })
            )
          )
        )
      )
    )
  );

  getConditionTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.getConditionTypes),
      switchMap(() =>
        this.automationFlowService.getConditionTypes().pipe(
          switchMap((conditionTypes) =>
            of(
              automationFlowActions.getConditionTypesResolved({
                conditionTypes,
              })
            )
          )
        )
      )
    )
  );

  getTriggerTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.getTriggerTypes),
      switchMap(() =>
        this.automationFlowService.getTriggerTypes().pipe(
          switchMap((triggerTypes) =>
            of(
              automationFlowActions.getTriggerTypesResolved({
                triggerTypes,
              })
            )
          )
        )
      )
    )
  );

  getOperators = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.getOperators),
      switchMap(() =>
        this.automationFlowService.getOperators().pipe(
          switchMap((operators) =>
            of(
              automationFlowActions.getOperatorsResolved({
                operators,
              })
            )
          )
        )
      )
    )
  );

  getFrequencies = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.getFrequencies),
      switchMap(() =>
        this.automationFlowService.getFrequencies().pipe(
          switchMap((frequencies) =>
            of(
              automationFlowActions.getFrequenciesResolved({
                frequencies,
              })
            )
          )
        )
      )
    )
  );

  getDays = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.getDays),
      switchMap(() =>
        this.automationFlowService
          .getDays()
          .pipe(
            switchMap((days) =>
              of(automationFlowActions.getDaysResolved({ days }))
            )
          )
      )
    )
  );

  getMonths = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.getMonths),
      switchMap(() =>
        this.automationFlowService.getMonths().pipe(
          switchMap((months) =>
            of(
              automationFlowActions.getMonthsResolved({
                months,
              })
            )
          )
        )
      )
    )
  );

  getPropertyTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.getPropertyTypes),
      switchMap(() =>
        this.automationFlowService.getPropertyTypes().pipe(
          switchMap((propertyTypes) =>
            of(
              automationFlowActions.getPropertyTypesResolved({
                propertyTypes,
              })
            )
          )
        )
      )
    )
  );

  getRankings = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.getRankings),
      switchMap(() =>
        this.automationFlowService.getRankings().pipe(
          switchMap((rankings) =>
            of(
              automationFlowActions.getRankingsResolved({
                rankings,
              })
            )
          )
        )
      )
    )
  );

  getAutomationFlowUtilities = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.getAutomationFlowUtilities),
      mergeMap(() => [
        automationFlowActions.getApplications(),
        automationFlowActions.getActionTypes(),
        automationFlowActions.getConditionTypes(),
        automationFlowActions.getTriggerTypes(),
        automationFlowActions.getPropertyTypes(),
        automationFlowActions.getOperators(),
        automationFlowActions.getFrequencies(),
        automationFlowActions.getDays(),
        automationFlowActions.getMonths(),
        automationFlowActions.getRankings(),
      ])
    )
  );

  getObjectTypeRecords = createEffect(() =>
    this.actions$.pipe(
      ofType(automationFlowActions.getObjectTypeRecords),
      switchMap(({ objectTypeId, objectTypeName }) =>
        this.automationFlowService.getObjectTypeRecords(objectTypeName).pipe(
          switchMap((objectTypeRecords) =>
            of(
              automationFlowActions.getObjectTypeRecordsResolved({
                objectTypeId,
                objectTypeRecords,
              })
            )
          )
        )
      )
    )
  );
}
