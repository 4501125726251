import { Action, createReducer, on } from '@ngrx/store';
import { MetadataParameter } from '../../domain/models/metadata-parameter.model';
import { getMetadataParametersResolved } from './metadataParameter.actions';

export const featureSlice = 'metadataParameter';

export interface State {
  metadataParameters: MetadataParameter[];
}

const defaultState: State = {
    metadataParameters: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return metadataParameterReducer(state, action);
}

export const initialState: State = defaultState;
export const metadataParameterReducer = createReducer(
  initialState,
  on(getMetadataParametersResolved, (state, { metadataParameters }) => ({
    ...state,
    metadataParameters: metadataParameters,
  }))
);
