import { Action, createReducer, on } from '@ngrx/store';
import { Library } from '../../domain/models/library.model';
import { getLibrariesResolved } from './library.actions';

export const featureSlice = 'library';

export interface State {
  libraries: Library[];
}

const defaultState: State = {
  libraries: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return libraryReducer(state, action);
}

export const initialState: State = defaultState;
export const libraryReducer = createReducer(
  initialState,
  on(getLibrariesResolved, (state, { libraries }) => ({
    ...state,
    libraries: libraries,
  }))
);
