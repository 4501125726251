import { BaseEntity } from 'processdelight-angular-components';
import { Permission } from './permission.model';
import { Tile } from './tile.model';

export class TilePermission extends BaseEntity {
  tileId!: string;
  tile!: Tile;
  permissionId!: string;
  permission!: Permission;

  constructor(obj: Partial<TilePermission>) {
    super(obj);
    Object.assign(this, obj);
    if (obj?.tile) {
      this.tile = obj.tile instanceof Tile ? obj.tile : new Tile(obj.tile);
    }
    if (obj?.permission) {
      this.permission =
        obj.permission instanceof Permission
          ? obj.permission
          : new Permission(obj.permission);
    }
  }
}
