import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { BaseFacade } from '../base.facade';
import { FormTemplate } from '../../domain/models/form-template.model';
import { getFormTemplates } from './formTemplate.actions';
import { formTemplateSelectors } from './formTemplate.selector';

@Injectable({
  providedIn: 'root',
})
export class FormTemplateFacade extends BaseFacade {
  formTemplates$ = this._store.pipe(select(formTemplateSelectors.getFormTemplates));
 
  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getFormTemplates(callback?: (formTemplates: FormTemplate[]) => void) {
    this._store.dispatch(getFormTemplates({ callback }));
  }
  getFormTemplates$() {
    return this.dispatchAction$(getFormTemplates);
  }
}
