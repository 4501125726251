import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslationService } from 'src/app/core/services/translation.service';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgxColorsModule, validColorValidator } from 'ngx-colors';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import {
  combineLatest,
  first,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { Ishtar365ActionsService } from 'src/app/core/services/ishtar365-actions.service';
import {
  ConfirmDialogData,
  ConfirmPopupComponent,
  ContextMenuAction,
} from 'processdelight-angular-components';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-personalization',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    NgxColorsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatDialogModule,
  ],
  templateUrl: './personalization.component.html',
  styleUrls: ['./personalization.component.scss'],
})
export class PersonalizationComponent implements OnInit, OnDestroy {
  logoInputControl = new FormControl<Blob | undefined>(undefined);
  logoUrl?: string;
  oldLogoUrl?: string;

  themeColorControl = new FormControl<string>('#124464', [
    validColorValidator,
    Validators.required,
  ]);
  accentColorControl = new FormControl<string>('#fff', [
    validColorValidator,
    Validators.required,
  ]);

  get saveEnabled() {
    return this.themeColorControl.valid && this.accentColorControl.valid;
  }

  dragging = false;

  destroy$ = new Subject<void>();

  constructor(
    private readonly translations: TranslationService,
    private readonly sanitizer: DomSanitizer,
    private readonly organizationFacade: OrganizationFacade,
    private readonly ishtar365Actions: Ishtar365ActionsService,
    private readonly matDialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.organizationFacade.organizationInfo$.pipe(first()).subscribe((org) => {
      this.themeColorControl.patchValue(org?.navColor ?? '#124464');
      this.accentColorControl.patchValue(org?.navContrast ?? '#fff');
      this.oldLogoUrl = org?.logo;
      this.logoUrl = org?.logo;
      combineLatest([
        this.translations.getTranslation$('save'),
        this.themeColorControl.valueChanges.pipe(
          startWith(org?.navColor ?? '#124464')
        ),
        this.accentColorControl.valueChanges.pipe(
          startWith(org?.navContrast ?? '#fff')
        ),
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe(([label]) => {
          this.ishtar365Actions.buttonActions = [
            new ContextMenuAction<unknown>({
              label,
              action: () => this.submit(),
              disabled: !this.saveEnabled,
              icon: 'save',
              iconOutline: true,
            }),
          ];
        });
    });
    this.themeColorControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((color) => {
        const rgb = this.hexToRgb(color!);
        if (rgb)
          this.accentColorControl.patchValue(
            rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 <= 186
              ? '#fff'
              : '#000'
          );
        this.organizationFacade.updateTempNavColor(color ?? undefined);
      });
    this.accentColorControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((color) =>
        this.organizationFacade.updateTempNavContrast(color ?? undefined)
      );
  }

  ngOnDestroy(): void {
    this.organizationFacade.resetTempProperties();
    this.destroy$.next();
    this.destroy$.complete();
    this.ishtar365Actions.buttonActions = [];
  }

  hexToRgb(hex: string) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (_, r, g, b) {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : undefined;
  }

  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  getTranslation(label: string) {
    return this.translations.getTranslation(label);
  }

  dragStart(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dragging) return;
    this.dragging = true;
  }

  dragStop(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.dragging) return;
    this.dragging = false;
  }

  imageDropped(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
    if (event.dataTransfer?.files && event.dataTransfer.files.length) {
      const file = event.dataTransfer.files[0];
      if (!this.isValidFileSize(file)) {
        this.showConfirmDialog();
        return;
      }

      this.logoInputControl.patchValue(file);
      this.updateIconBlobUrl();
    }
  }

  onIconInputChange(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input?.files && input.files.length) {
      const file = input.files[0];
      if (!this.isValidFileSize(file)) {
        this.showConfirmDialog();
        return;
      }

      this.logoInputControl.patchValue(file);
      this.updateIconBlobUrl();
    }
  }

  isValidFileSize(file: File, maxSizeKB = 150): boolean {
    if (!file) {
      return false;
    }

    const fileSizeKB = file.size / 1024; // Convert bytes to kilobytes

    return fileSizeKB <= maxSizeKB;
  }

  showConfirmDialog() {
    const config = new ConfirmDialogData({
      title: this.translations.getTranslation('caution'),
      description: this.translations.getTranslation('fileSizeExceeds'),
      descriptionInHtml: true,
      cancelBtnVisible: false,
      confirmBtnText: this.translations.getTranslation('confirm'),
    });

    this.matDialog.open(ConfirmPopupComponent, {
      data: config,
      autoFocus: true,
      closeOnNavigation: true,
    });
  }

  clearIconInput() {
    this.logoInputControl.patchValue(undefined);
    this.updateIconBlobUrl();
  }

  updateIconBlobUrl() {
    if (this.logoUrl) {
      if (this.logoUrl == this.oldLogoUrl) this.logoUrl = undefined;
      else {
        URL.revokeObjectURL(this.logoUrl);
        this.logoUrl = this.oldLogoUrl;
      }
    }
    this.logoInputControl.markAsDirty();
    if (this.logoInputControl.value)
      this.logoUrl = URL.createObjectURL(this.logoInputControl.value);
    if (this.logoUrl?.includes('blob'))
      this.blobToBase64(this.logoInputControl.value!)
        .pipe(first())
        .subscribe((b64) => this.organizationFacade.updateTempLogo(b64));
    else this.organizationFacade.updateTempLogo(this.logoUrl);
  }

  private blobToBase64(blob: Blob) {
    const subject = new Subject<string>();
    const fr = new FileReader();
    fr.onloadend = () => {
      subject.next(fr.result as string);
      subject.complete();
    };
    fr.readAsDataURL(blob);
    return subject.asObservable();
  }

  submit() {
    if (this.saveEnabled) {
      const logoObs = !this.logoUrl
        ? of('')
        : !this.logoInputControl.value
        ? of(this.logoUrl)
        : this.blobToBase64(this.logoInputControl.value);
      logoObs
        .pipe(
          switchMap((logo) =>
            this.organizationFacade.updateOrganizationPersonalization$(
              logo,
              this.themeColorControl.value!,
              this.accentColorControl.value!
            )
          )
        )
        .subscribe({
          complete: () => {
            this._snackBar
              .open(this.getTranslation('personalizationSuccessful'), 'X', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(5000);
          },
        });
    }
  }

  getThemeColor() {
    return this.themeColorControl.value;
  }

  getAccentColor() {
    return this.accentColorControl.value;
  }
}
