import { BaseEntity } from 'processdelight-angular-components';
import { TilePage } from './tile-page.model';
import { TilePageSegment } from './tile-page-segment.model';

export class TilePageTilePageSegment extends BaseEntity {
  tilePageId!: string;
  tilePage!: TilePage;
  tilePageSegmentId!: string;
  tilePageSegment!: TilePageSegment;

  constructor(obj: Partial<TilePageTilePageSegment>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.tilePage) this.tilePage = new TilePage(obj.tilePage);
    if (obj.tilePageSegment)
      this.tilePageSegment = new TilePageSegment(obj.tilePageSegment);
  }
}
