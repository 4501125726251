export class IshtarSubscription {
  subscriptionId!: number;
  productName!: string;
  productAppUrl!: string;
  plan!: number;
  status!: string;
  organization!: string;
  tenantId!: string;
  active!: boolean;
  startDate?: Date;
  endDate?: Date;
  dataverseEnvironmentUrl?: string;

  constructor(obj?: Partial<IshtarSubscription>) {
    Object.assign(this, obj);
  }
}
