import { createAction, props } from '@ngrx/store';
import { IshtarLicense } from '../../domain/models/ishtar-license.model';
import { IshtarUserLicenses } from '../../domain/models/ishtar-user-licenses.model';
import { IshtarUser } from '../../domain/models/ishtar-user.model';
import { UserSettings } from '../../domain/models/user-settings.model';

export const getUserInfo = createAction(
  '[User] Get user info',
  props<{
    email: string;
    name?: string;
    callback?: (userInfo: IshtarUser) => void;
  }>()
);

export const getLicensesForUser = createAction(
  '[User] Get licenses for user',
  props<{
    name?: string;
    resetCache?: boolean;
    callback?: () => void;
  }>()
);
export const getLicensesForUserResolved = createAction(
  '[User] Get licenses for user resolved',
  props<{ licenses: IshtarUserLicenses }>()
);
export const addUserLicenseResolved = createAction(
  '[User] Add user license resolved',
  props<{ license: IshtarLicense }>()
);
export const removeUserLicenseResolved = createAction(
  '[User] Remove user license resolved',
  props<{ license: IshtarLicense }>()
);
export const updateUserLicenseResolved = createAction(
  '[User] Update user license resolved',
  props<{ license: IshtarLicense }>()
);
export const updateUserLanguage = createAction(
  '[User] Update user language',
  props<{ lang: string; callback?: () => void }>()
);
export const updateUserLastTenantId = createAction(
  '[User] Update user last tenant id',
  props<{ tenantId: string; callback?: () => void }>()
);
export const updateUserResolved = createAction(
  '[User] Update user',
  props<{ userInfo?: IshtarUser }>()
);
export const getUserSettings = createAction(
  '[User] Get user settings',
  props<{ callback?: () => void }>()
);
export const getUserSettingsResolved = createAction(
  '[User] Get user settings resolved',
  props<{ settings: UserSettings }>()
);
export const updateUserSettings = createAction(
  '[User] Update user settings',
  props<{ settings: UserSettings; callback?: () => void }>()
);
export const updateUserSettingsResolved = createAction(
  '[User] Update user settings resolved',
  props<{ settings: UserSettings }>()
);
export const resetSlice = createAction('[User] Reset slice');
