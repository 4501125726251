import { Form } from './form.model';

export class FormTemplate{
    recordId!: string;
    formId!: string;
    form!: Form;
    isDeleted!: boolean;
    isTemplate!: boolean;
    dataObjects!: any[];
    id!: string;

    constructor(obj: Partial<FormTemplate>) {
        Object.assign(this, obj);
        if (obj?.form) {
            this.form = obj.form instanceof Form ? obj.form : new Form(obj.form);
          }
    }
  }
