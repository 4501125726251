import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Ishtar365Service } from '../../services/ishtar365.service';
import {
  addUserLicenseResolved,
  removeUserLicenseResolved,
  updateUserLicenseResolved,
} from '../user/user.actions';
import { UserFacade } from '../user/user.facade';
import {
  addAppLicenseResolved,
  addLicense,
  addPossibleAppToFrames,
  getAADSyncConfig,
  getAADSyncConfigResolved,
  getAppConfig,
  getAppConfigResolved,
  getApps,
  getAppsResolved,
  getLicensesForApp,
  getLicensesForAppResolved,
  getSubscriptions,
  getSubscriptionsResolved,
  removeAppLicenseResolved,
  removeLicense,
  removePossibleAppFromFrames,
  updateAADSyncConfig,
  updateAppConfig,
  updateAppLicenseResolved,
  updateLicense,
} from './app-subscription.actions';
import { FrameManagerService } from '../../services/frame-manager.service';

@Injectable({ providedIn: 'root' })
export class AppSubscriptionEffects {
  constructor(
    private actions$: Actions,
    private ishtar365: Ishtar365Service,
    private frameService: FrameManagerService,
    private userFacade: UserFacade
  ) {}

  getApps = createEffect(() =>
    this.actions$.pipe(
      ofType(getApps),
      switchMap(({ callback, error }) =>
        this.ishtar365.getApps().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((apps) => of(getAppsResolved({ apps }))),
          catchError((error) => {
            console.error(error);
            return [];
          })
        )
      )
    )
  );

  getSubscriptions = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubscriptions),
      switchMap(({ callback, error }) =>
        this.ishtar365.getSubscriptions().pipe(
          tap((subscriptions) =>
            callback ? callback(subscriptions) : undefined
          ),
          switchMap((subscriptions) =>
            of(getSubscriptionsResolved({ subscriptions }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getLicensesForApp = createEffect(() =>
    this.actions$.pipe(
      ofType(getLicensesForApp),
      mergeMap(({ resetCache, appId, callback, error }) =>
        this.ishtar365.getLicensesForApp(resetCache, appId).pipe(
          tap((licenses) => (callback ? callback(licenses) : undefined)),
          switchMap((licenses) => [
            getLicensesForAppResolved({ licenses, appId }),
          ]),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  addLicense = createEffect(() =>
    this.actions$.pipe(
      ofType(addLicense),
      switchMap(({ appId, email, name, admin, callback, error }) =>
        this.ishtar365.addLicense(appId, email, name, admin ?? false).pipe(
          withLatestFrom(this.userFacade.userInfo$),
          tap(([license]) => (callback ? callback(license) : undefined)),
          switchMap(([license, userInfo]) =>
            ([addAppLicenseResolved({ license })] as any[]).concat(
              userInfo?.email.toLowerCase() == email.toLowerCase()
                ? [
                    addUserLicenseResolved({ license }),
                    addPossibleAppToFrames({
                      appName: license.subscriptionInfo.productName,
                    }),
                  ]
                : []
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateLicense = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLicense),
      switchMap(({ appId, email, admin, callback, error }) =>
        this.ishtar365.updateLicense(appId, email, admin).pipe(
          tap((license) => (callback ? callback(license) : undefined)),
          withLatestFrom(this.userFacade.userInfo$),
          switchMap(([license, userInfo]) =>
            ([updateAppLicenseResolved({ license })] as any[]).concat(
              userInfo?.email.toLowerCase() == email.toLowerCase()
                ? [updateUserLicenseResolved({ license })]
                : []
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeLicense = createEffect(() =>
    this.actions$.pipe(
      ofType(removeLicense),
      switchMap(({ appId, email, callback, error }) =>
        this.ishtar365.removeLicense(appId, email).pipe(
          tap((license) => (callback ? callback(license) : undefined)),
          withLatestFrom(this.userFacade.userInfo$),
          switchMap(([license, userInfo]) =>
            ([removeAppLicenseResolved({ license })] as any[]).concat(
              userInfo?.email.toLowerCase() == email.toLowerCase()
                ? [
                    removeUserLicenseResolved({ license }),
                    removePossibleAppFromFrames({
                      appName: license.subscriptionInfo.productName,
                    }),
                  ]
                : []
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getAppConfig = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppConfig),
      switchMap(({ appName, constructor, callback, error }) =>
        this.ishtar365.getAppConfig(appName, constructor).pipe(
          tap((config) => (callback ? callback(config) : undefined)),
          switchMap((config) => of(getAppConfigResolved({ appName, config }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateAppConfig = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAppConfig),
      switchMap(({ appName, constructor, config, callback, error }) =>
        this.ishtar365.updateAppConfig(appName, constructor, config).pipe(
          tap((config) => (callback ? callback(config) : undefined)),
          switchMap((config) => of(getAppConfigResolved({ appName, config }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getAADSyncConfig = createEffect(() =>
    this.actions$.pipe(
      ofType(getAADSyncConfig),
      switchMap(({ appName, constructor, callback, error }) =>
        this.ishtar365.getAADSyncConfig(appName, constructor).pipe(
          tap((config) => (callback ? callback(config) : undefined)),
          switchMap((config) =>
            of(getAADSyncConfigResolved({ appName, config }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateAADSyncConfig = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAADSyncConfig),
      switchMap(({ appName, constructor, config, callback, error }) =>
        this.ishtar365.updateAADSyncConfig(appName, constructor, config).pipe(
          tap((config) => (callback ? callback(config) : undefined)),
          switchMap((config) =>
            of(getAADSyncConfigResolved({ appName, config }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  addPossibleAppToFrames = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addPossibleAppToFrames),
        tap(({ appName }) => this.frameService.addPossibleApp(appName))
      ),
    { dispatch: false }
  );
  removePossibleAppFromFrames = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removePossibleAppFromFrames),
        tap(({ appName }) => this.frameService.removePossibleApp(appName))
      ),
    { dispatch: false }
  );
}
