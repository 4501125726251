import { MetadataParameter } from './metadata-parameter.model';

export  class MetadataParamConsolidatedChoice{
    metadataParameterId?: string;
  metadataConsolidatedChoiceId?: string;
  metadataParameter?: MetadataParameter;

  constructor(obj: Partial<MetadataParamConsolidatedChoice>) {
    Object.assign(this, obj);
    if (obj.metadataParameter) {
      this.metadataParameter = obj.metadataParameter;
    }
  }
}