import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { BaseFacade } from '../base.facade';
import { getFormTemplates } from './formTemplate.actions';
import { formTemplateSelectors } from './formTemplate.selector';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormTemplateFacade extends BaseFacade {
  formTemplates$ = this._store.pipe(
    select(formTemplateSelectors.getFormTemplates)
  );
  formTemplatesByAppName$ = (appName: string) =>
    this._store.pipe(
      select(formTemplateSelectors.getFormTemplatesByAppName),
      map((selector) => selector(appName) ?? [])
    );

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getFormTemplates$(appName: string) {
    return this.dispatchAction$(getFormTemplates, { appName });
  }
}
