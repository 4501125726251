<div
  class="dashboard-wrapper container-fluid"
  *ngIf="skills$ | async as skills"
>
  <div
    class="topRowContainer d-flex flex-row align-items-center justify-content-between"
  >
    <mat-form-field>
      <mat-label *ngIf="getTranslation$('filter') | async as filter">{{
        filter
      }}</mat-label>
      <input
        type="text"
        matInput
        placeholder="filter"
        [formControl]="searchControl"
        #input
        class="filter"
      />
    </mat-form-field>
  </div>
  <div
    style="margin: auto"
    *ngIf="datasource.data.length === 0 && skills.length > 0; else skillTable"
  >
    {{ getTranslation("noResultsFilter") }}
  </div>

  <ng-template #skillTable>
    <div
      class="table-container scrollbar horizontal-scrollbar position-relative"
      #scrollContainer
      (scroll)="onScroll()"
    >
      <form [formGroup]="skillForm" autocomplete="off" #f="ngForm">
        <ng-container formArrayName="skillRows">
          <table
            mat-table
            [dataSource]="datasource"
            class="mat-elevation-z8 tableGeneral"
            #table
          >
            <ng-container matColumnDef="colorRectangle">
              <th mat-header-cell *matHeaderCellDef></th>
              <td
                style="width: 5px"
                class="p-0"
                mat-cell
                *matCellDef="let element; let i = index"
              >
                <div
                  style="width: 5px; height: 40px"
                  [ngStyle]="{
                    'background-color': colorFromFormGroup(element)
                  }"
                ></div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Code">
              <th mat-header-cell *matHeaderCellDef>Code</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroup]="element"
              >
                <input
                  [value]="element.code"
                  type="text"
                  formControlName="code"
                  maxlength="250"
                />
              </td>
            </ng-container>
            <ng-container matColumnDef="Title">
              <th mat-header-cell *matHeaderCellDef>Title*</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroup]="element"
              >
                <input
                  [value]="element.title"
                  type="text"
                  formControlName="title"
                  maxlength="250"
                />
              </td>
            </ng-container>

            <ng-container matColumnDef="Color">
              <th mat-header-cell *matHeaderCellDef>Color</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroup]="element"
              >
                <ngx-colors
                  ngx-colors-trigger
                  style="display: inline-block; margin: 5px"
                  formControlName="color"
                  format="hex"
                ></ngx-colors>
              </td>
            </ng-container>

            <ng-container matColumnDef="Delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroup]="element"
                style="
                  text-align: center !important;
                  color: var(--processdelightBlue) !important;
                  padding: 0px;
                "
              >
                <mat-icon
                  (click)="selectRow(element)"
                  (click)="onDelete(element, i)"
                  [ngStyle]="{ display: editMode$.value ? 'block' : 'none' }"
                  >delete</mat-icon
                >
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{ selected: selection.isSelected(row) }"
            ></tr>
          </table>
        </ng-container>
      </form>
    </div>
    <button
      style="position: fixed; right: 5px; bottom: 5px"
      color="primary"
      mat-raised-button
      class="topButton"
      (click)="scrollToTop()"
      [hidden]="scrollContainer.scrollTop === 0"
    >
      {{ getTranslation("backToTop") }}
    </button>
  </ng-template>
</div>
