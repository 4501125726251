<div class="dashboard-wrapper">
  <processdelight-dashboard
    [data]="automationFlows"
    [possibleColumns]="possibleColumns"
    [showExpandedDetails]="true"
    [maxSizeItems]="totalRecordCount"
    [pageSize]="pageSize"
    [selectedColumns]="selectedColumns"
    [expandedDetailsTemplate]="expandedDetails"
    [showFilterControl]="true"
    [itemTrackByFn]="itemTrackBy"
    (addButtonClicked)="onAddAutomationFlow()"
    [sortColumn]="sortedColumn"
    [sortDirection]="sortDirection"
    [showAddButton]="true"
    [highlightOnHover]="true"
  ></processdelight-dashboard>
</div>
<ng-template #expandedDetails let-element="item">
  <div class="container-fluid p-0">
    <div class="row">
      <div
        *ngFor="let column of possibleColumns"
        class="example-element-position col-md-2 my-2"
      >
        <span class="bold">{{ column.displayName }} : </span>
        <span>{{ column.valueAccessor(element) }}</span>
      </div>
    </div>
    <div class="buttonDetailContainer">
      <button
        mat-raised-button
        class="menuButton"
        (click)="onEditAutomationFlow(element)"
      >
        <mat-icon>info</mat-icon>
        {{ translations.edit }}
      </button>
      <button
        (click)="onDeleteAutomationFlow(element.ishtarAutomationFlowId)"
        mat-raised-button
        class="menuButton"
      >
        <mat-icon>delete</mat-icon>
        {{ translations.delete }}
      </button>
    </div>
  </div>
</ng-template>
