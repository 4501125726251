import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appElementRefDirective]',
  exportAs: 'elementRefDirective',
  standalone: true,
})
export class ElementRefDirective {
  constructor(public elementRef: ElementRef) {}
}
