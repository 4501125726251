import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { TilePageSegment } from '../../domain/models/tile-page-segment.model';

const tilePageState = (state: AppState) => state.coreFeature.tilepage;
const getTilePages = createSelector(tilePageState, (state) => state.tilePages);
const getTilePagesForSegmentFunc = createSelector(
  tilePageState,
  (state) => (segmentId: string) =>
    state.tilePages.filter((t) => t.segments.some((s) => s.id == segmentId))
);
const getSegments = createSelector(tilePageState, (state) =>
  state.tilePages
    .map((t) => t.segments.map((s) => s.tilePageSegment))
    .reduce(
      (acc, s) => [
        ...acc,
        ...s.filter((s1) => !acc.some((s2) => s1.id == s2.id)),
      ],
      [] as TilePageSegment[]
    )
);
const getSegmentsForTileFunc = createSelector(
  getSegments,
  (segments) => (tileId: string) =>
    segments.filter((s) => s.tiles.some((t) => t.id == tileId))
);
const getTilePageByIdFunc = createSelector(
  getTilePages,
  (state) => (id: string) => state.find((p) => p.id == id)
);
const getHomePage = createSelector(getTilePages, (state) =>
  state.find((p) => p.homepage)
);
const getHomePageSegments = createSelector(getTilePages, (state) =>
  state.find((p) => p.homepage&&!!p)?.segments
);
export const tilePageSelectors = {
  getTilePages,
  getTilePagesForSegmentFunc,
  getSegments,
  getSegmentsForTileFunc,
  getTilePageByIdFunc,
  getHomePage,
  getHomePageSegments
};
