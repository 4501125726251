import { IshtarUser } from './ishtar-user.model';

export class IshtarOrganigramGroup {
  guid!: string;
  displayName!: string;
  members: IshtarUser[] = [];
  parentId?: string;
  xPos = 0;
  yPos = 0;

  constructor(obj: Partial<IshtarOrganigramGroup>) {
    Object.assign(this, obj);
  }
}
