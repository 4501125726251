import { createAction, props } from '@ngrx/store';
import { ActionBase } from '../action-base.interface';
import { Library } from '../../domain/models/library.model';

export const getLibraries = createAction(
  '[Libraries] Get libraries',
  props<ActionBase<Library[]>>()
);
export const getLibrariesResolved = createAction(
  '[Libraries] Get libraries resolved',
  props<{
    libraries: Library[];
  }>()
);
