import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import * as fromCoreFeature from './store/core.feature';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppSubscriptionEffects } from './store/app-subscription/app-subscription.effects';
import { GroupEffects } from './store/group/group.effects';
import { OrganizationEffects } from './store/organization/organization.effects';
import { UserEffects } from './store/user/user.effects';
import { TilePageEffects } from './store/tilepage/tilepage.effects';
import { SkillEffects } from './store/skill/skill.effects';
import { WorkRegimeEffects } from './store/workRegime/workRegime.effects';
import { AutomationFlowEffects } from './store/automation-flow/automation-flow.effect';
import { MatButtonModule } from '@angular/material/button';
import { InterestGroupEffects } from './store/interest-group/interest-group-effects';
import { LibraryEffects } from './store/library/library.effects';
import { MetadataParametersEffects } from './store/metadataParameter/metadataParameter.effects';
import { FormTemplateEffects } from './store/formTemplate/formTemplate.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    StoreModule.forFeature(
      fromCoreFeature.featureKey,
      fromCoreFeature.reducers
    ),
    EffectsModule.forFeature([
      AppSubscriptionEffects,
      UserEffects,
      OrganizationEffects,
      GroupEffects,
      LibraryEffects,
      FormTemplateEffects,
      MetadataParametersEffects,
      TilePageEffects,
      SkillEffects,
      WorkRegimeEffects,
      AutomationFlowEffects,
      InterestGroupEffects,
    ]),
  ],
  exports: [CommonModule, RouterModule, MatIconModule, MatButtonModule],
})
export class CoreModule {}
