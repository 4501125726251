import { createAction, props } from '@ngrx/store';
import { InterestGroup } from 'processdelight-angular-components';
import { ActionBase } from '../action-base.interface';

export const getInterestGroups = createAction(
  '[InterestGroup] Get interest groups',
  props<ActionBase<InterestGroup[]>>()
);
export const getInterestGroupsResolved = createAction(
  '[InterestGroup] Get interest groups resolved',
  props<{ result: InterestGroup[] }>()
);
export const updateInterestGroups = createAction(
  '[InterestGroup] Update interest groups',
  props<{ interestGroups: InterestGroup[] } & ActionBase<InterestGroup[]>>()
);
export const updateInterestGroupsResolved = createAction(
  '[InterestGroup] Update interest groups resolved',
  props<{ updatedInterestGroups: InterestGroup[] }>()
);
export const addInterestGroups = createAction(
  '[InterestGroup] Add interest groups',
  props<{ interestGroups: InterestGroup[] } & ActionBase<InterestGroup[]>>()
);
export const addInterestGroupsResolved = createAction(
  '[InterestGroup] Add interest groups resolved',
  props<{ addedInterestGroups: InterestGroup[] }>()
);
export const deleteInterestGroups = createAction(
  '[InterestGroup] Delete interest groups',
  props<{ interestGroupIds: string[] } & ActionBase<boolean>>()
);
export const deleteInterestGroupsResolved = createAction(
  '[InterestGroup] Delete interest groups resolved',
  props<{ result: string[] }>()
);
