import { Component } from '@angular/core';
import {
  ActionBarComponent,
  LoaderComponent,
} from 'processdelight-angular-components';
import { map } from 'rxjs';
import { FrameManagerService } from '../core/services/frame-manager.service';
import { Ishtar365ActionsService } from '../core/services/ishtar365-actions.service';
import { CoreModule } from '../core/core.module';
import { OrganizationFacade } from '../core/store/organization/organization.facade';
import { UserFacade } from '../core/store/user/user.facade';
import { AppPageComponent } from '../tile-page/app-page/app-page.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { ApplicationService } from '../shared/services/application.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CoreModule,
    LoaderComponent,
    TopBarComponent,
    ActionBarComponent,
    AppPageComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  appName = 'Ishtar.365!';

  userLang = '';
  showExtended = false;

  orgLogo$ = this.organizationFacade.tempProperties$.pipe(map((o) => o?.logo));

  userName$ = this.userFacade.userInfo$.pipe(map((u) => u?.name));
  userPhoto$ = this.userFacade.userInfo$.pipe(map((u) => u?.photo));

  navColor$ = this.organizationFacade.tempProperties$.pipe(
    map((p) => p.navColor)
  );
  navContrast$ = this.organizationFacade.tempProperties$.pipe(
    map((p) => p.navContrast)
  );

  activeApp$ = this.frameManagerService.activeApp$;

  get framesShown() {
    return this.frameManagerService.componentShown;
  }
  get navActions() {
    return this.ishtar365Actions.navActions;
  }
  get buttonActions() {
    return this.ishtar365Actions.buttonActions;
  }
  get iconActions() {
    return this.ishtar365Actions.iconActions;
  }
  signedIn$ = this.applicationService.signedIn.asObservable();

  constructor(
    private readonly organizationFacade: OrganizationFacade,
    private readonly userFacade: UserFacade,
    private readonly frameManagerService: FrameManagerService,
    private readonly ishtar365Actions: Ishtar365ActionsService,
    private readonly applicationService: ApplicationService
  ) {}
}
