import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { LogLevel } from '@azure/msal-browser';
import { SessionExpiredService } from 'processdelight-angular-components';
import {
  BehaviorSubject,
  Observable,
  Subject,
  forkJoin,
  of,
  timer,
} from 'rxjs';
import {
  delay,
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { selectedTenant$ } from 'src/app/core/data/data.observables';
import { AppConfigOoO } from 'src/app/core/domain/models/app-configOoO';
import { DataverseEnvironment } from 'src/app/core/domain/models/dataverse-environment.model';
import { lastRouteKey } from 'src/app/core/guards/initial-navigation.guard';
import { FrameManagerService } from 'src/app/core/services/frame-manager.service';
import { GraphService } from 'src/app/core/services/graph.service';
import { Ishtar365Service } from 'src/app/core/services/ishtar365.service';
import { RouteBuilderService } from 'src/app/core/services/route-builder.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { AppSubscriptionFacade } from 'src/app/core/store/app-subscription/app-subscription.facade';
import { GroupFacade } from 'src/app/core/store/group/group.facade';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { SkillFacade } from 'src/app/core/store/skill/skill.facade';
import { TilePageFacade } from 'src/app/core/store/tilepage/tilepage.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { WorkRegimeFacade } from 'src/app/core/store/workRegime/workRegime.facade';
import { MicrosoftAuthenticationService } from 'src/app/msal/injectables/microsoft-authentication.service';
import { environment } from 'src/environments/environment';
import { Tenant } from '../models/tenant.model';
import { InterestGroupFacade } from 'src/app/core/store/interest-group/interest-group.facade';
import { LibraryFacade } from 'src/app/core/store/library/library.facade';
import { FormTemplateFacade } from 'src/app/core/store/formTemplate/formTemplate.facade';
import { MetadataParameterFacade } from 'src/app/core/store/metadataParameter/metadataParameter.facade';
import { Ishtar365NotificationsService } from 'src/app/core/services/ishtar365-notifications.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService implements OnDestroy {
  signedIn = new BehaviorSubject<boolean>(false);

  selectedTenant?: Tenant;
  tenants: Tenant[] = [];

  get isNativeTenant(): boolean {
    return this.selectedTenant?.tenantId == this.msal.tenantId;
  }

  destroy$ = new Subject<void>();

  constructor(
    private readonly httpClient: HttpClient,
    public readonly msal: MicrosoftAuthenticationService,
    private readonly groupFacade: GroupFacade,
    private readonly userFacade: UserFacade,
    private readonly organizationFacade: OrganizationFacade,
    private readonly appSubscriptionFacade: AppSubscriptionFacade,
    private readonly tilePageFacade: TilePageFacade,
    private readonly libraryFacade: LibraryFacade,
    private readonly formTemplateFacade: FormTemplateFacade,
    private readonly metadataParameterFacade: MetadataParameterFacade,
    private readonly skillFacade: SkillFacade,
    private readonly workRegimeFacade: WorkRegimeFacade,
    private readonly routeBuilder: RouteBuilderService,
    private readonly graphService: GraphService,
    private readonly translations: TranslationService,
    private readonly ishtar365Service: Ishtar365Service,
    private readonly frameManagerService: FrameManagerService,
    private readonly sessionExpiredService: SessionExpiredService,
    private readonly interestGroupFacade: InterestGroupFacade,
    private readonly notificationService: Ishtar365NotificationsService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async boot(): Promise<void> {
    this.routeBuilder.injectBreadcrumbTitles();
    this.addAuthentication();
    setTimeout(() => {
      this.msal.signedIn
        .asObservable()
        .pipe(
          filter((b) => b),
          first(),
          switchMap(() => this.msal.getAccessToken())
        )
        .subscribe((token) => this.graphService.setClient(token));
    }, 0);
    timer(15 * 60 * 1000, 15 * 60 * 1000)
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.signedIn.value && !!this.selectedTenant),
        switchMap(() => this.ishtar365Service.sessionKeepAlive())
      )
      .subscribe();
  }

  public loadDataverseEnvironments(): Observable<
    { value: DataverseEnvironment[] } | undefined
  > {
    if (this.msal.isPersonalAccount) {
      console.log('Logged in with personal account. Actions might be limited.');
      return of(undefined);
    }
    this.msal.addInterceptor('https://api.bap.microsoft.com/', [
      'https://service.powerapps.com//.default',
    ]);
    return this.httpClient.get<{ value: any[] }>(
      'https://api.bap.microsoft.com/providers/Microsoft.BusinessAppPlatform/scopes/admin/environments?api-version=2020-10-01'
    );
  }
  public dataverseAddAppUser(id: string) {
    return this.httpClient.post<{ value: any }>(
      `https://api.bap.microsoft.com/providers/Microsoft.BusinessAppPlatform/scopes/admin/environments/${id}/addAppUser?api-version=2020-10-01`,
      { servicePrincipalAppId: environment.adminConsentClientId }
    );
  }
  public loadTenants() {
    if (this.msal.isPersonalAccount) {
      console.log('Logged in with personal account. Actions might be limited.');
      return of([] as Tenant[]);
    }
    this.msal.addInterceptor('https://management.azure.com/', [
      'https://management.azure.com//user_impersonation',
    ]);
    return this.httpClient
      .get<{ value: Tenant[] }>(
        'https://management.azure.com/tenants?api-version=2020-01-01'
      )
      .pipe(
        map(({ value }: { value: Tenant[] }) => value),
        tap((tenants: Tenant[]) => (this.tenants = tenants))
      );
  }
  public setSelectedTenant(tenant: Tenant) {
    this.selectedTenant = tenant;
    this.msal.changeAuthority(environment.instance + tenant.tenantId);
    selectedTenant$.next(tenant);
  }

  public resetApplication() {
    this.routeBuilder.resetRoutes();
    this.selectedTenant = undefined;
    localStorage.removeItem(lastRouteKey);
    this.appSubscriptionFacade.resetSlice();
    this.organizationFacade.resetSlice();
    this.userFacade.resetSlice();
    this.groupFacade.resetSlice();
    this.tilePageFacade.resetSlice();
    this.skillFacade.resetSlice();
    this.workRegimeFacade.resetSlice();
  }

  public silentSignIn(): void {
    this.msal.silentSignIn();
  }

  public signIn() {
    this.msal.authenticate();
  }
  public signOut() {
    this.msal.signOut();
  }

  public consent() {
    return this.msal.adminConsent(this.selectedTenant?.tenantId);
  }

  // public goToAppSource(): void {
  //   window.open(this.appConfig.appSourceUrl, "_blank");
  // }

  private addAuthentication(): void {
    this.msal
      .configure({
        auth: {
          clientId: environment.clientId,
          authority: environment.authority,
          redirectUri: `${location.origin}/signin`,
          navigateToLoginRequestUrl: false,
          postLogoutRedirectUri: `${location.origin}/signout`,
        },
        cache: {
          cacheLocation: 'localStorage', // This configures where your cache will be stored
          storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
          allowRedirectInIframe: true,
          loggerOptions: {
            loggerCallback: (
              level: LogLevel,
              message: string,
              containsPii: boolean
            ): void => {
              if (!environment.piiLogging && containsPii) return;
              switch (level) {
                case LogLevel.Error:
                  console.error(message);
                  return;
                case LogLevel.Info:
                  console.info(message);
                  return;
                case LogLevel.Verbose:
                  console.debug(message);
                  return;
                case LogLevel.Warning:
                  console.warn(message);
                  return;
              }
            },
            piiLoggingEnabled: true,
          },
        },
      })
      .then(() => {
        this.msal.signedIn
          .pipe(takeUntil(this.destroy$))
          .subscribe((b) => this.signedIn.next(b));
      });
  }

  public initAppData() {
    if (!this.selectedTenant) return of();
    const tenantId = this.selectedTenant.tenantId;

    return this.ishtar365Service.registerSession(tenantId).pipe(
      switchMap(() =>
        forkJoin([
          this.appSubscriptionFacade.getApps$(),
          this.appSubscriptionFacade.getSubscriptions$(),
          this.organizationFacade.getOrganizationInfo$(tenantId),
          this.tilePageFacade.getTilePages$(),
          this.libraryFacade.getLibraries$(),
          this.formTemplateFacade.getFormTemplates$(),
          this.metadataParameterFacade.getMetadataParameters$(),
          this.userFacade.getUserLicenses$(this.msal.name, true),
          this.userFacade.getUserSettings$(),
          this.interestGroupFacade.getInterestGroups$(),
        ])
      ),
      delay(10),
      tap(() =>
        this.userFacade.userLicenses$
          .pipe(
            first(),
            switchMap((licenses) =>
              licenses.find(
                (l) => l.subscriptionInfo.productName == 'Ishtar.OoO'
              )
                ? this.appSubscriptionFacade.getAppConfig$(
                    'Ishtar.OoO',
                    AppConfigOoO
                  )
                : of(true)
            )
          )
          .subscribe()
      ),
      tap(() => this.organizationFacade.getGroupUsers$().subscribe()),
      tap(() => this.organizationFacade.getUsers$().subscribe()),
      tap(() => this.organizationFacade.getGroups$().subscribe()),
      tap(() => this.organizationFacade.getOrganizationAdmins$().subscribe()),
      tap(() => this.notificationService.Init()),
      switchMap(() =>
        this.userFacade.appsWithLicense$.pipe(
          tap((licenses) =>
            this.frameManagerService.registerApps(licenses.map((l) => l.name))
          )
        )
      ),
      switchMap(() => this.routeBuilder.initRoutes())
    );
  }

  public startInitialConfiguration() {
    this.routeBuilder.initSpecificRoute('initialconfiguration', true);
  }
}
