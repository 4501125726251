import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';

const articleTypeState = (state: AppState) => state.coreFeature.articleType;
const getArticleTypeConfigurations = createSelector(
  articleTypeState,
  (state) => state.articleTypeConfigurations
);
const getWarehouseLocations = createSelector(
  articleTypeState,
  (state) => state.warehouseLocations
);

export const articleTypeSelectors = {
  getArticleTypeConfigurations,
  getWarehouseLocations,
};
