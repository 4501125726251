import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { InterestGroup } from 'processdelight-angular-components';
import { map } from 'rxjs';
import { AppState } from 'src/app/shared/store/app.reducer';
import { BaseFacade } from '../base.facade';
import {
  addInterestGroups,
  deleteInterestGroups,
  getInterestGroups,
  updateInterestGroups,
} from './interest-group.actions';
import {
  interestGroupState
} from './interest-group.reducer';

@Injectable({
  providedIn: 'root',
})
export class InterestGroupFacade extends BaseFacade {
  interestGroups$ = this._store.pipe(
    select(interestGroupState),
    map((state) => state.interestGroups)
  );

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getInterestGroups$() {
    return this.dispatchAction$(getInterestGroups);
  }

  addInterestGroup(interestGroups: InterestGroup[]) {
    return this.dispatchAction$(addInterestGroups, { interestGroups });
  }

  updateInterestGroup(interestGroups: InterestGroup[]) {
    return this.dispatchAction$(updateInterestGroups, { interestGroups });
  }
  deleteInterestGroup(interestGroupIds: string[]) {
    return this.dispatchAction$(deleteInterestGroups, { interestGroupIds });
  }
}
