import { Injectable } from '@angular/core';
import { ContextMenuAction } from 'processdelight-angular-components';

@Injectable({
  providedIn: 'root',
})
export class Ishtar365ActionsService {
  navActions: ContextMenuAction<unknown>[] = [];
  buttonActions: ContextMenuAction<unknown>[] = [];
  iconActions: ContextMenuAction<unknown>[] = [];

  reset() {
    this.navActions = [];
    this.buttonActions = [];
    this.iconActions = [];
  }
}
