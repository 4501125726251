import { BaseEntity } from 'processdelight-angular-components';
import { Form } from './form.model';

export class DataRecord extends BaseEntity {
  name!: string;
  recordId!: string;
  formId!: string;
  form!: Form;
  isDeleted!: boolean;
  isTemplate!: boolean;
  dataObjects!: any[];

  constructor(obj: Partial<DataRecord>) {
    super(obj);
    Object.assign(this, obj);
    if (obj?.form) {
      this.form = obj.form instanceof Form ? obj.form : new Form(obj.form);
    }
  }
}
