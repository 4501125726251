import { Action, createReducer, on } from '@ngrx/store';
import { automationFlowActions } from './automation-flow.action';
import { AppState } from 'src/app/shared/store/app.reducer';
import {
  AutomationFlowActionType,
  AutomationFlow,
  AutomationFlowApplication,
  Lookup,
  Operator,
  PropertyType,
  TriggerType,
  AutomationFlowConditionType,
} from 'processdelight-angular-components';
import { Day } from 'processdelight-angular-components/lib/domain/models/day';
import { Month } from 'processdelight-angular-components/lib/domain/models/month';

export const featureSlice = 'automationFlow';

export interface State {
  automationFlows: AutomationFlow[];
  automationFlowApplications: AutomationFlowApplication[];
  triggerTypes: TriggerType[];
  actionTypes: AutomationFlowActionType[];
  conditionTypes: AutomationFlowConditionType[];
  propertyTypes: PropertyType[];
  operators: Operator[];
  requestedObjectTypeId: string;
  objectTypeRecords: Record<string, Lookup[]>;
  days: Day[];
  months: Month[];
  rankings: Lookup[];
  frequencies: Lookup[];
}

const defaultState: State = {
  automationFlows: [],
  automationFlowApplications: [],
  triggerTypes: [],
  actionTypes: [],
  conditionTypes: [],
  propertyTypes: [],
  operators: [],
  requestedObjectTypeId: '',
  objectTypeRecords: {},
  days: [],
  months: [],
  rankings: [],
  frequencies: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return automationFlowReducer(state, action);
}

export const initialState: State = defaultState;
export const automationFlowReducer = createReducer(
  initialState,
  on(
    automationFlowActions.getAutomationFlowsResolved,
    (state, { automationFlows }) => ({
      ...state,
      automationFlows: automationFlows,
    })
  ),
  on(
    automationFlowActions.createAutomationFlowResolved,
    (state, { createdAutomationFlow }) => ({
      ...state,
      automationFlows: [...state.automationFlows, createdAutomationFlow],
    })
  ),
  on(
    automationFlowActions.updateAutomationFlowResolved,
    (state, { updatedAutomationFlow }) => ({
      ...state,
      automationFlows: state.automationFlows?.map((af) =>
        af.ishtarAutomationFlowId ===
        updatedAutomationFlow.ishtarAutomationFlowId
          ? updatedAutomationFlow
          : af
      ),
    })
  ),
  on(
    automationFlowActions.deleteAutomationFlowResolved,
    (state, { deletedAutomationFlowId }) => ({
      ...state,
      automationFlows: state.automationFlows?.filter(
        (af) => af.ishtarAutomationFlowId !== deletedAutomationFlowId
      ),
    })
  ),
  on(
    automationFlowActions.getApplicationsResolved,
    (state, { automationFlowApplications }) => ({
      ...state,
      automationFlowApplications,
    })
  ),
  on(
    automationFlowActions.getActionTypesResolved,
    (state, { actionTypes }) => ({
      ...state,
      actionTypes,
    })
  ),
  on(
    automationFlowActions.getTriggerTypesResolved,
    (state, { triggerTypes }) => ({
      ...state,
      triggerTypes,
    })
  ),
  on(
    automationFlowActions.getPropertyTypesResolved,
    (state, { propertyTypes }) => ({
      ...state,
      propertyTypes,
    })
  ),
  on(automationFlowActions.getOperatorsResolved, (state, { operators }) => ({
    ...state,
    operators,
  })),
  on(automationFlowActions.getObjectTypeRecords, (state) => ({
    ...state,
    requestedObjectTypeId: '',
  })),
  on(
    automationFlowActions.getObjectTypeRecordsResolved,
    (state, { objectTypeId, objectTypeRecords }) => ({
      ...state,
      requestedObjectTypeId: objectTypeId,
      objectTypeRecords: {
        ...state.objectTypeRecords,
        [objectTypeId]: objectTypeRecords,
      },
    })
  ),
  on(
    automationFlowActions.getFrequenciesResolved,
    (state, { frequencies }) => ({
      ...state,
      frequencies,
    })
  ),
  on(automationFlowActions.getDaysResolved, (state, { days }) => ({
    ...state,
    days,
  })),
  on(automationFlowActions.getMonthsResolved, (state, { months }) => ({
    ...state,
    months,
  })),
  on(automationFlowActions.getRankingsResolved, (state, { rankings }) => ({
    ...state,
    rankings,
  }))
);

export const automationFlowState = (state: AppState) =>
  state.coreFeature.automationFlow;
