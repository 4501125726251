import { BaseEntity, Day } from 'processdelight-angular-components';
import { WorkRegime } from './workRegime.model';
import { WorkRegimeType } from './workRegimeType.model';

export class WorkRegimeDay extends BaseEntity {
  workRegimeType!: WorkRegimeType;
  workRegime?: WorkRegime;
  week!: number;
  day!: Day;

  constructor(obj: Partial<WorkRegimeDay>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.workRegime) this.workRegime = new WorkRegime(obj.workRegime);
    if (obj.workRegimeType)
      this.workRegimeType = new WorkRegimeType(obj.workRegimeType);
    if (obj.day) this.day = new Day(obj.day);
  }
}
