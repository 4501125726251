import { Injectable, OnInit } from '@angular/core';
import {
  ContextMenuAction,
  Notification,
  NotificationService,
  NotificationType,
} from 'processdelight-angular-components';
import { Ishtar365Service } from './ishtar365.service';
import { SendNotification } from '../domain/models/send-notification.model';
import { FrameManagerService } from './frame-manager.service';
import {
  BehaviorSubject,
  buffer,
  debounceTime,
  delay,
  of,
  Subject,
  tap,
} from 'rxjs';
import { error } from 'console';

@Injectable({
  providedIn: 'root',
})
export class Ishtar365NotificationsService {
  constructor(
    private notifications: NotificationService,
    private ishtar365: Ishtar365Service,
    private frameManager: FrameManagerService
  ) {}

  notificationClose = new Subject<string>();
  private readonly pollingDelay = 30000;
  failedAttempts = 0;

  Init(): void {
    this.GetNotifications();

    this.notificationClose
      .pipe(buffer(this.notificationClose.pipe(debounceTime(500))))
      .subscribe((ids) => {
        this.ishtar365.markNotificationsAsRead(ids).subscribe(() => {
          ids.forEach((id) => {
            const notif = this.notifications.notifications.find(
              (n) => n.id === id
            );
            if (notif) this.notifications.removeNotification(notif);
          });
        });
      });
  }

  GetNotifications() {
    this.ishtar365
      .getNotifications()
      .pipe(
        tap(
          (notifications) => {
            notifications
              .filter(
                (n) =>
                  !this.notifications.notifications.some((en) => en.id === n.id)
              )
              .forEach((notification) => {
                this.notifications.addNotification(
                  this.mapSendNotification(notification)
                );
              });
          },
          (err) => {
            if (this.failedAttempts >= 4) {
              console.error(
                'Attempted 5 times to get notifications, but failed.'
              );
              return;
            }
            of(true)
              .pipe(delay(this.pollingDelay))
              .subscribe(() => {
                this.failedAttempts++;
                this.GetNotifications();
              });
          }
        ),
        delay(this.pollingDelay)
      )
      .subscribe(() => {
        this.GetNotifications();
        this.failedAttempts = 0;
      });
  }

  mapSendNotification(notification: SendNotification) {
    return new Notification({
      id: notification.id,
      label: notification.title,
      message: notification.content,
      type: NotificationType.Info,
      closeAction: () => this.notificationClose.next(notification.id),
      clickAction: () =>
        this.frameManager.appRedirect(
          notification.application.name.replace('Ishtar', 'Ishtar.'),
          notification.navigationKey,
          notification.navigationProperties.id,
          notification.navigationProperties
        ),
    });
  }
}
