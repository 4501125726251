<form [formGroup]="form">
  <h4>{{ getTranslation$("workdayConfiguration") | async }}</h4>
  <div class="checkbox-group">
    <div *ngFor="let day of daysOfWeek">
      <mat-checkbox
        [formControlName]="day"
        (change)="updateStandardWorkdayConfiguration($event.checked)"
      >
        {{ getTranslation$(day) | async }}
      </mat-checkbox>
    </div>
  </div>
  <mat-form-field class="standardWorkingHours">
    <mat-label>Standard Working Hours Per Week</mat-label>
    <input
      matInput
      formControlName="standardWorkingHours"
      type="number"
      (change)="updateWorkingHours()"
    />
  </mat-form-field>

  <h4>{{ getTranslation$("holidayConfiguration") | async }}</h4>
  <mat-checkbox formControlName="holiday"
    >Holidays are counted as work days</mat-checkbox
  >

  <h4>{{ getTranslation$("employeeConfiguration") | async }}</h4>
  <div class="employee-configuration">
    <div class="employee-search">
      <table>
        <thead>
          <tr>
            <th>
              {{ getTranslation$("employee") | async }}
              <mat-form-field>
                <input
                  matInput
                  placeholder="{{ getTranslation$('searchEmployees') | async }}"
                />
              </mat-form-field>
            </th>
            <th>
              {{ getTranslation$("workRegime") | async }}
            </th>
            <th>{{ getTranslation$("hoursPerDay") | async }}</th>
            <th>{{ getTranslation$("hoursPerWeek") | async }}</th>
          </tr>
        </thead>
        <tbody formArrayName="employees">
          <tr
            *ngFor="
              let employeeGroup of employeesFormArray.controls;
              let i = index
            "
            [formGroupName]="i"
          >
            <td>
              {{ employeeGroup.get("user")?.value?.displayName }}
              <p class="validationParagraph" #validationParagraph></p>
            </td>
            <td>
              <mat-form-field appearance="fill">
                <mat-label>Dropdown</mat-label>
                <mat-select formControlName="selectedOption">
                  <mat-option
                    *ngFor="let workRatio of workRatios"
                    [value]="workRatio"
                    (click)="
                      editUserWorkRegime(
                        employeeGroup.get('user')?.value,
                        workRatio
                      )
                    "
                    >{{
                      getWorkRatioTranslation$(workRatio) | async
                    }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <input type="number" formControlName="hoursPerDay" />
            </td>
            <td>
              <input
                type="number"
                formControlName="hoursPerWeek"
                (change)="
                  validateHoursPerDay(
                    employeeGroup.get('user')?.value,
                    employeeGroup.get('hoursPerWeek')?.value,
                    employeeGroup
                  )
                "
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
