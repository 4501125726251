import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { MicrosoftAuthenticationService } from "../../injectables/microsoft-authentication.service";

@Component({
  selector: "app-teams-sign-out",
  templateUrl: "./teams-sign-out.component.html",
  styleUrls: ["./teams-sign-out.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamsSignOutComponent implements OnInit {
  constructor(private msal: MicrosoftAuthenticationService) {}

  ngOnInit(): void {
    this.msal.signOutRedirect();
  }
}
