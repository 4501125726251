import { Action, createReducer, on } from '@ngrx/store';
import { Skill } from '../../domain/models/skill.model';
import {
  addSkillsResolved,
  addUserSkillsResolved,
  getSkillsResolved,
  getUserSkillsResolved,
  resetSlice,
  softDeleteSkillsResolved,
  updateSkillsResolved,
  deleteUserSkillsResolved,
} from './skill.actions';
import { UserSkill } from '../../domain/models/user-skill.model';

export const featureSlice = 'skill';

export interface State {
  skills: Skill[];
  userSkills: UserSkill[];
}

const defaultState: State = {
  skills: [],
  userSkills: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return skillReducer(state, action);
}

export const initialState: State = defaultState;

export const skillReducer = createReducer(
  initialState,
  on(getSkillsResolved, (state, { result }) => ({
    ...state,
    skills: [...result],
  })),
  on(softDeleteSkillsResolved, (state, { skills }) => {
    return {
      ...state,
      skills: state.skills.filter(
        (s) => !skills.some((skill) => skill.id === s.id)
      ),
    };
  }),
  on(updateSkillsResolved, (state, { updatedSkills }) => ({
    ...state,
    skills: state.skills?.map(
      (u) => updatedSkills.find((s) => u.id === s.id) ?? u
    ),
  })),
  on(addSkillsResolved, (state, { addedSkills }) => ({
    ...state,
    skills: addedSkills.concat([...(state.skills ?? [])]),
  })),
  on(getUserSkillsResolved, (state, { result }) => ({
    ...state,
    userSkills: [...result],
  })),
  on(deleteUserSkillsResolved, (state, { deletedUserSkillIds }) => ({
    ...state,
    userSkills: state.userSkills?.filter(
      (u) => !deletedUserSkillIds.some((id) => u.id === id)
    ),
  })),
  on(addUserSkillsResolved, (state, { addedUserSkills }) => ({
    ...state,
    userSkills: addedUserSkills.concat([...(state.userSkills ?? [])]),
  })),
  on(resetSlice, () => initialState)
);
