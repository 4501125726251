import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { IshtarApp } from '../../domain/models/ishtar-app.model';

const appSubscriptionState = (state: AppState) =>
  state.coreFeature.appSubscription;
const getSubscriptions = createSelector(
  appSubscriptionState,
  (state) => state.subscriptions
);
const getApps = createSelector(appSubscriptionState, (state) =>
  [...state.apps].sort((a, b) => a.name.localeCompare(b.name))
);
const getAppsWithSubscription = createSelector(appSubscriptionState, (state) =>
  [
    ...state.apps.filter(
      (a) =>
        !!a &&
        state.subscriptions
          .filter((s) => s.active)
          .some((s) => a.name == s.productName)
    ),
  ].sort((a, b) => a.name.localeCompare(b.name))
);
const getAppsWithoutSubscription = createSelector(
  appSubscriptionState,
  (state) =>
    [
      ...state.apps.filter(
        (a) =>
          !!a &&
          !state.subscriptions
            .filter((s) => s.active)
            .some((s) => a.name == s.productName)
      ),
    ].sort((a, b) => a.name.localeCompare(b.name))
);
const getActiveApp = createSelector(
  appSubscriptionState,
  (state) => state.activeApp
);
const getActiveAppHasSubscription = createSelector(
  appSubscriptionState,
  getAppsWithSubscription,
  (state, apps) => apps.some((app) => app.name == state.activeApp?.name)
);
const getActiveAppLicenses = createSelector(
  appSubscriptionState,
  getActiveApp,
  (state, app) =>
    state.appLicenses.filter((l) => l.subscriptionInfo.productName == app?.name)
);
const getAppLicensesFunc = createSelector(
  appSubscriptionState,
  (state) => (app: IshtarApp) =>
    state.appLicenses.filter((l) => l.subscriptionInfo.productName == app?.name)
);
const getSubscriptionForAppFunc = createSelector(
  appSubscriptionState,
  (state) => (app: IshtarApp) =>
    state.subscriptions.find((s) => s.productName == app.name)
);
const getAppHasSubscriptionFunc = createSelector(
  getAppsWithSubscription,
  (state) => (app: IshtarApp) => state.some((a) => a.name == app.name)
);
const getAppConfigFunc = createSelector(
  appSubscriptionState,
  (state) => (appName: string) => state.appConfigs[appName]
);
const getAADSyncConfigFunc = createSelector(
  appSubscriptionState,
  (state) => (appName: string) => state.aadSyncConfig[appName]
);
export const appSubscriptionSelectors = {
  getSubscriptions,
  getApps,
  getAppsWithSubscription,
  getAppsWithoutSubscription,
  getActiveApp,
  getActiveAppHasSubscription,
  getActiveAppLicenses,
  getAppLicensesFunc,
  getSubscriptionForAppFunc,
  getAppHasSubscriptionFunc,
  getAppConfigFunc,
  getAADSyncConfigFunc,
};
