<div class="d-flex flex-column h-100 w-100" #test>
  <!-- <div class="d-flex flex-row">
    <button
      mat-raised-button
      color="primary"
      (click)="submitOrganigram()"
      *ngIf="getTranslation$('save') | async as saveLabel"
    >
      {{ saveLabel }}
    </button>
    <button
      mat-raised-button
      color="accent"
      (click)="addGroup()"
      *ngIf="getTranslation$('newGroup') | async as newGroupLabel"
    >
      {{ newGroupLabel }} +
    </button>
  </div> -->
  <div
    class="drag-wrapper position-relative w-100 h-100 border border-gray border-dashed border-width-2 rounded mt-1"
    cdkDropListGroup
  >
    <div #lineContainer>
      <app-line-connector
        *ngFor="let line of lines"
        [id]="line.id"
        [parent]="line.parent"
        [start]="line.start"
        [end]="line.end"
        [redraw$]="line.redraw$"
        [size]="line.size"
        [startSize]="line.startSize"
        [endSize]="line.endSize"
        [startSocket]="line.startSocket"
        [endSocket]="line.endSocket"
        [color]="line.color"
      ></app-line-connector>
    </div>
    <div
      class="position-absolute w-100 h-100"
      cdkDropList
      id="mainDropList"
    ></div>
    <div #panzoomContainer class="w-0">
      <mat-card
        cdkDrag
        *ngFor="let group of groups; trackBy: groupTrackByFn"
        class="draggable-group position-absolute p-2 panzoom-no-drag"
        [id]="group.id"
        cdkDropList
        [cdkDragFreeDragPosition]="group.initialPosition"
        [cdkDropListData]="group"
        [cdkDragData]="group"
        (cdkDragStarted)="groupMoveStart($event)"
        (cdkDragEnded)="groupMoveEnd($event)"
        (cdkDragMoved)="groupMoved($event)"
        [cdkDragConstrainPosition]="groupMoveTransformationWithScaleBound"
      >
        <div
          class="selector selector-parent"
          cdkDrag
          cdkDragBoundary=".drag-wrapper"
          [cdkDragData]="group"
          (cdkDragStarted)="dragStart($event)"
          (cdkDragMoved)="dragMove($event)"
          (cdkDragEnded)="dragEnd($event)"
          (cdkDragEntered)="dragEnter($event)"
        ></div>
        <div
          class="selector selector-child"
          cdkDrag
          cdkDragBoundary=".drag-wrapper"
          [cdkDragData]="group"
          (cdkDragStarted)="dragStart($event)"
          (cdkDragMoved)="dragMove($event)"
          (cdkDragEnded)="dragEnd($event)"
          (cdkDragEntered)="dragEnter($event)"
        ></div>
        <div class="drag-handle" cdkDragHandle>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              d="M20.5 11.75V8L25.5 13L20.5 18V14.25H14.25V20.5H18L13 25.5L8 20.5H11.75V14.25H5.5V18L0.5 13L5.5 8V11.75H11.75V5.5H8L13 0.5L18 5.5H14.25V11.75H20.5Z"
              fill="#C8C8C8"
            />
          </svg>
        </div>

        <mat-menu
          class="add-user-group-menu"
          xPosition="before"
          (closed)="group.searchControl.patchValue('')"
          #addUserMenu
        >
          <mat-form-field appearance="fill" (click)="$event.stopPropagation()">
            <mat-label *ngIf="getTranslation$('search') | async as searchLabel"
              >{{ searchLabel }}...</mat-label
            >
            <input
              matInput
              type="text"
              [formControl]="group.searchControl"
              #searchControl
            />
          </mat-form-field>
          <div class="d-flex flex-column">
            <div
              class="graph-user-item clickable"
              *ngFor="let user of group.foundUsers"
              (click)="addMemberToGroup(group, user)"
              [title]="user.mail"
            >
              {{ user.displayName }}
            </div>
          </div>
        </mat-menu>
        <div class="group-error" *ngIf="group.error">{{ group.error }}</div>
        <div class="d-flex flex-row align-items-end">
          <button
            class="align-self-start"
            mat-icon-button
            color="warn"
            (click)="removeGroup(group)"
          >
            <mat-icon class="material-icons-outlined">delete</mat-icon>
          </button>
          <mat-form-field>
            <mat-label
              *ngIf="getTranslation$('displayName') | async as displayNameLabel"
              >{{ displayNameLabel }}
            </mat-label>
            <input
              matInput
              type="text"
              [value]="group.name"
              (change)="updateGroupName(group, $event)"
            />
          </mat-form-field>
          <button
            mat-mini-fab
            color="primary"
            class="mt-1"
            [matMenuTriggerFor]="addUserMenu"
            (click)="focusInput(searchControl)"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <mat-list aria-orientation="vertical" class="group-user-list scrollbar">
          <mat-list-item *ngFor="let member of group.members">
            <div class="d-flex flex-row align-items-center">
              <button
                mat-icon-button
                aria-label="Remove member"
                (click)="removeMemberFromGroup(group, member)"
              >
                <mat-icon class="material-icons-outlined">cancel</mat-icon>
              </button>
              <span class="ms-2">
                {{ getMemberName(member) }}
              </span>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-card>
    </div>
  </div>
</div>
