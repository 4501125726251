import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppSubscriptionFacade } from 'src/app/core/store/app-subscription/app-subscription.facade';
import { IshtarApp } from 'src/app/core/domain/models/ishtar-app.model';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { map, withLatestFrom } from 'rxjs';
import { TranslationService } from 'src/app/core/services/translation.service';

@Component({
  selector: 'app-organigram-import-popup',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './organigram-import-popup.component.html',
  styleUrls: ['./organigram-import-popup.component.scss'],
})
export class OrganigramImportPopupComponent {
  apps$ = this.appSubscriptionFacade.appsWithSubscription$.pipe(
    withLatestFrom(this.appSubscriptionFacade.activeApp$),
    map(([apps, activeApp]) => apps.filter((a) => a.name != activeApp?.name))
  );

  appControl = new FormControl<IshtarApp | undefined>(
    undefined,
    Validators.required
  );

  overwriteControl = new FormControl(false);

  canOverwrite = false;

  onImportFunc: (app: IshtarApp, overwrite: boolean) => void = () => undefined;

  constructor(
    private readonly appSubscriptionFacade: AppSubscriptionFacade,
    private readonly translations: TranslationService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      onImport: (app: IshtarApp, overwrite: boolean) => void;
      canOverwrite: boolean;
    }
  ) {
    this.onImportFunc = data.onImport;
    this.canOverwrite = data.canOverwrite;
  }

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  onImport() {
    this.appControl.markAsTouched();
    if (this.appControl.valid && this.appControl.value)
      this.onImportFunc(
        this.appControl.value,
        this.overwriteControl.value ?? false
      );
  }
}
